import React, { useState } from "react"
import { Button, Card, Divider, Form, Radio, Space } from "antd"
import { DownloadOutlined } from "@ant-design/icons"
import PropTypes from "prop-types"
import { useT } from "../../i18n"

export default function ExportDevicesDialog({ selection, tableConfig, csvExport, hardwareCsvExport }) {
    // export form config
    const defaultExportValues = {
        exportType: "table",
        scope: "rows",
    }

    const t = useT()
    const [rerenderForm, setRerenderForm] = useState(false)
    const [exportForm] = Form.useForm()

    const onFieldsChange = () => {
        setRerenderForm(!rerenderForm)
    }

    const onFinish = (values) => {
        if (values?.exportType === "table") {
            csvExport.export(tableConfig, selection, values?.scope === "all")
        } else if (values?.exportType === "hardware") {
            hardwareCsvExport.export(selection, values?.scope === "all")
        }
    }

    return (
        <Card type="inner" size="small" title={t("devices.export.title", "Export Devices")} style={{ width: "17rem" }}>
            <Form form={exportForm} layout={"vertical"} onFinish={onFinish} onFieldsChange={onFieldsChange} initialValues={defaultExportValues}>
                <Form.Item
                    label={t("devices.export.type.title", "Type")}
                    name="exportType"
                    tooltip={t("devices.export.type.info", "Create a CSV that either contains the table config or follows the hardware import standard.")}>
                    <Radio.Group buttonStyle="solid">
                        {csvExport && <Radio.Button value="table">{t("devices.export.type.table", "Table CSV")}</Radio.Button>}

                        {hardwareCsvExport && <Radio.Button value="hardware">{t("devices.export.type.hardware", "Hardware CSV")}</Radio.Button>}
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    label={t("devices.export.scope.title", "Scope")}
                    name="scope"
                    tooltip={t("devices.export.scope.info", "Export only the selected or all devices of the device type table.")}>
                    <Radio.Group buttonStyle="solid">
                        <Radio.Button value="rows" disabled={selection?.selections?.length === 0}>
                            {t("devices.export.scope.selectedrows", "Selected Devices")}
                        </Radio.Button>
                        <Radio.Button value="all">{t("devices.export.scope.allrows", "All Devices")}</Radio.Button>
                    </Radio.Group>
                </Form.Item>
                <Divider style={{ margin: "0.2rem 0 0.7rem 0" }} />

                <Space.Compact direction="horizontal" style={{ color: "blue" }} block>
                    <Button
                        disabled={exportForm.getFieldValue("scope") === "rows" && selection?.selections?.length === 0}
                        loading={csvExport?.isProcessing || hardwareCsvExport?.isProcessing}
                        type="primary"
                        size="middle"
                        icon={<DownloadOutlined />}
                        block
                        htmlType="submit">
                        {t("device-types.export-button", "Export")}
                    </Button>
                </Space.Compact>
            </Form>
        </Card>
    )
}

ExportDevicesDialog.propTypes = {
    selection: PropTypes.object, // selection object from useMultiSelection
    tableConfig: PropTypes.object, // tableConfig object from useAppTableConfig
    csvExport: PropTypes.func, // function to exportTableCSV
    hardwareCsvExport: PropTypes.func, // function to exportHardwareCSV
}
