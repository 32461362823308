import {CustomPropTypes} from "../../propTypes/customPropTypes";
import React from "react";
import PropTypes from "prop-types";
import {classnames} from "../../wrapper/classnames";

export function DescriptionListEntry(props) {
    let {description, label, variant, labelTitle, descriptionTitle, truncateLabels, children} = props;
    if (!labelTitle && typeof label === "string") {
        labelTitle = label;
    }

    if (!descriptionTitle && typeof description === "string") {
        descriptionTitle = description;
    }
    if (children) {
        description = children;
    }
    if (!descriptionTitle && typeof description === "string") {
        descriptionTitle = description;
    }

    return <>
        <dt className={classnames({
            "slds-dl_inline__label": variant === "inline",
            "slds-dl_horizontal__label": variant === "horizontal",
            "slds-truncate": truncateLabels,
        })} title={labelTitle}>{label}</dt>
        <dd className={classnames({
            "slds-dl_inline__detail": variant === "inline",
            "slds-dl_horizontal__detail": variant === "horizontal",
        })
        } title={descriptionTitle}>{description}</dd>
    </>;
}

DescriptionListEntry.defaultProps = {
    variant: "horizontal",
    truncateLabels: true,
};

DescriptionListEntry.propTypes = {
    labelTitle: PropTypes.string,
    label: PropTypes.string,
    description: PropTypes.string,
    children: CustomPropTypes.children,
    variant: PropTypes.oneOf(["base", "inline", "horizontal"]),
    truncateLabels: PropTypes.bool,
};

export default function DescriptionList(props) {
    const {children, variant} = props;
    return <dl className={classnames("slds-wrap",
        {
            "slds-dl_inline": variant === "inline",
            "slds-dl_horizontal": variant === "horizontal",
        }
    )}>
        {children}
    </dl>;
}

DescriptionList.defaultProps = {
    variant: "horizontal",
};

DescriptionList.propTypes = {
    children: CustomPropTypes.children,
    variant: PropTypes.oneOf(["base", "inline", "horizontal"]),
};
