import PropTypes from "prop-types";
import React, {useContext} from "react";
import classNames from 'classnames'
import {Icon} from "../../../../common/slds/icons/icon";
import {SldsInputField} from "../../../../common/ui/form/formElements";
import gql from "graphql-tag";
import {useQuery} from "@apollo/client";
import {useGraphqlLoadingComponent} from "../../../../common/graphql";
import _ from 'underscore'
import {AppContext} from "../../../appPage";
import {Log} from "../../../../common/log";
import {Link} from "react-router-dom";

const QUERRY_ALARMS = gql`
    query getAlarms($appId: ID) {
        alarms(appId: $appId) {
            id
            active
            reason
            triggeredLast
            alarmConfig {
                id
                name
            }
        }
    }
`;

export const SingleAlarmToastWidget = (props) => {
    const {settings} = props;
    const {labelGood, labelBad, alarmConfigId, href} = settings;
    const app = useContext(AppContext);

    const alarmsResult = useQuery(QUERRY_ALARMS, {
        pollInterval: 5000,
        variables: {appId: app.id}
    });


    const loading = useGraphqlLoadingComponent(alarmsResult);
    if (loading) {
        return loading;
    }

    const alarms = alarmsResult.data.alarms || [];
    let isAlarm = !_.isEmpty(alarms);

    Log.Debug("alarmConfigId", alarmConfigId);

    // noinspection EqualityComparisonWithCoercionJS
    if (alarmConfigId && alarmConfigId != 0) {
        // noinspection EqualityComparisonWithCoercionJS
        isAlarm = !_.isEmpty(alarms.find((a) => a.alarmConfig.id == alarmConfigId && a.active));
    }

    return <div className={
        classNames("slds-notify slds-notify_toast", {
            "slds-theme_success": !isAlarm,
            "slds-theme_error": isAlarm,
        })}
                role="status"
                style={{minWidth: "0px", display: "block"}}
    >
        <div className="slds-grid slds-nowrap">
            <Icon category={"utility"} name={isAlarm ? "error" : "success"} size={"small"} colorVariant={"base"} containerClassName={"slds-m-right_small slds-no-flex slds-align-top"}/>
            <div className="slds-notify__content slds-align-middle">
                {href ? <Link className="slds-text-heading_small" to={href}>{isAlarm ? labelBad : labelGood}</Link>
                    : <h2 className="slds-text-heading_small">{isAlarm ? labelBad : labelGood}</h2>}
            </div>
        </div>
    </div>
};

SingleAlarmToastWidget.propTypes = {
    settings: PropTypes.object.isRequired
};
export const SingleAlarmToastWidgetSettings = () => {

    return <React.Fragment>
        <SldsInputField name={"labelGood"} label={"Label Good"} placeholder={"Display text when alarm is inactive"}/>
        <SldsInputField name={"labelBad"} label={"Label Bad"} placeholder={"Display text when alarm is active"}/>
        <SldsInputField name={"href"} label={"URL"} placeholder={"/<appId>/devices/"}/>
        <SldsInputField name={"alarmConfigId"} label={"Alarm Config ID (0 or empty for 'any')"} placeholder={"0"}/>
    </React.Fragment>
};

SingleAlarmToastWidgetSettings.propTypes = {
    settings: PropTypes.object.isRequired,
};

