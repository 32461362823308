import React from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import ListBox from "./ListBox";

// Children should be PillContainerItems
const PillContainer = (props) => {
    const {children, className} = props;
    return <div className={classNames("slds-pill_container", className)}>
        <ListBox>
            {children}
        </ListBox>
    </div>
};

PillContainer.propTypes = {
    children: PropTypes.any.isRequired,
    className: PropTypes.string,
};

export default PillContainer