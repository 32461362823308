import SingleLookupField from "../../common/ui/lookup/singleLookupField";
import * as React from "react";
import {useQuery} from "@apollo/client";
import gql from "graphql-tag";
import PropTypes from "prop-types";

const QUERY_ORGANISATIONS = gql`
    query($search: String) {
        getOrganisationList(search: $search) {
            id
            name
        }
    }`;

export default function OrganisationLookupField(props) {
    const {name, label, required} = props;
    const organisationsResult = useQuery(QUERY_ORGANISATIONS, {
        variables: {
            page: {
                offset: 0,
                limit: 10
            }
        }
    });

    return <SingleLookupField
        required={required}
        name={name} label={label}
        titleExtractor={it => it.name}
        subtitleExtractor={it => it.id}
        loadSuggestions={keyword => organisationsResult.refetch({search: keyword})
            .then(result => result.data.getOrganisationList)}
    />;
}

OrganisationLookupField.defaultProps = {
    name: "organisation",
    label: "Organisation",
    required: false,
};

OrganisationLookupField.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool,
};