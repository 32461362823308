
console.log("Production mode:", process.env.REACT_APP_PRODUCTION);

function Debug(msg, ...args) {
    if (!process.env.REACT_APP_PRODUCTION || process.env.REACT_APP_PRODUCTION === "false") {
        console.log(msg, ...args)
    }
}

function Info(msg, ...args) {
    console.log(msg, ...args)
}

function Error(msg, ...args) {
    console.log(msg, ...args)
}

export {Debug, Info, Error}

export const Log = {
    Debug,
    Info,
    Error,
};