import React from "react";
import gql from "graphql-tag";
import { createContext, useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { useAuthContext } from "./authContext";

export const QUERY_ORGANISATION = gql`
    query ($orgId: ID!) {
        organisation: getOrganisation(id: $orgId) {
            id
            maxSubOrganisations
            effectiveSubOrgDepth
            maxSubOrgDepth
            name
            subOrganisations {
                id
            }
        }
    }
`

export const OrganisationContext = createContext(null)

export const OrganisationContextProvider = ({ children }) => {
  const auth = useAuthContext()

  const [orgId, setOrgId] = useState(auth.organisationId())
    useEffect(() => {
        auth.onChange(() => {
            setOrgId(auth.organisationId())
        })
    })

    const organisationResult = useQuery(QUERY_ORGANISATION, {
        variables: { orgId: orgId },
    })

    return <OrganisationContext.Provider value={organisationResult?.data?.organisation}>
      {children}
    </OrganisationContext.Provider>
}
