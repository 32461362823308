import Page from "../../common/ui/page";
import {Link} from "react-router-dom";
import * as React from "react";
import {useState} from "react";
import {useQuery} from "@apollo/client";
import GenericDataTable from "../../common/ui/genericDataTable/genericDataTable";
import DataTableColumn from "../../common/ui/data-table/column";
import moment from "moment";
import {usePagination} from "../../common/hooks/usePagination";
import {ButtonGroup} from "@salesforce/design-system-react";

import gql from "graphql-tag";
import {useSearch} from "../../common/hooks/useSearch";
import Button from "../../common/slds/buttons/button";
import DataTableCell from "../../common/ui/data-table/cell";
import AddOrganisationDialog from "../../components/organisation/addOrganisationDialog";

const QUERY_ORGANISATION_LIST = gql`
    query($page: PaginationInputType, $search: String) {
        getOrganisationList(page: $page, sort: {field: "id", direction: "DESC"}, search: $search) {
            id
            name
            parentOrganisation {
                id,
                name
            }
            createdAt
        }
    }
`;

const OrganisationsListPage = () => {
    const page = usePagination();
    const search = useSearch();
    const organisationsListResult = useQuery(QUERY_ORGANISATION_LIST, {
        fetchPolicy: "network-only",
        variables: {
            page: page.getGraphqlPageInput(),
            search: search.getGraphqlSearchInput()
        }
    });
    const [showNewOrgaModal, setShowNewOrgaModal] = useState(false);

    return <Page
        trail={[<Link to="/configuration/organisations" key={1}>Organisations</Link>]}
        title={"Organisations"}
        actions={<ButtonGroup><Button iconName={"add"} onClick={() => setShowNewOrgaModal(true)}>New
            Organisation</Button></ButtonGroup>}
    >
        <GenericDataTable id={"organisation-table"} items={organisationsListResult?.data?.getOrganisationList}
                          gqlResult={organisationsListResult}
                          page={page}
                          search={search}>
            <DataTableColumn label="ID" property="id" title={"id"}>
                <DataTableCell key="id" render={(props) => (
                  <Link to={`/configuration/organisations/${props.item.id}`}>{props.item.id}</Link>
                )}/>
            </DataTableColumn>
            <DataTableColumn label="Name" property="name" title={"name"}>
                <DataTableCell key="id" render={(props) => (
                    <Link to={`/configuration/organisations/${props.item.id}`}>{props.item.name || "- no name -"}</Link>
                )}/>
            </DataTableColumn>
            <DataTableColumn label="Parent Organisation" property={"parentOrganisation"}>
                <DataTableCell key="id" render={(props) => ( props.item?.parentOrganisation ?
                    <Link to={`/configuration/organisations/${props.item.parentOrganisation.id}`}>{props.item.parentOrganisation.name}</Link> : null
                )}/>
            </DataTableColumn>
            <DataTableColumn label={"Created"} property={"createdAt"} title={"createdAt"}>
                <DataTableCell render={(props) => {
                    return <div
                        className="slds-cell-wrap">{moment(props.item.createdAt).fromNow()} ({moment(props.item.createdAt).format('DD.MM.YYYY HH:mm:ss')})</div>;
                }}/>
            </DataTableColumn>
        </GenericDataTable>
        <AddOrganisationDialog isOpen={showNewOrgaModal} onRequestClose={() => setShowNewOrgaModal(false)}
                               onAdded={() => organisationsListResult.refetch()}/>
    </Page>;
};

export default OrganisationsListPage;
