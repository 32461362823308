import React from "react";
import {CustomPropTypes} from "../../propTypes/customPropTypes";
import PropTypes from "prop-types";
import classNames from "classnames";

export function TileDetail(props) {
    const {children} = props;
    return <div className="slds-tile__detail">
        {children}
    </div>
}



TileDetail.propTypes = {
    children: CustomPropTypes.children,
};

export default function Tile(props) {
    const {children, className} = props;
    const classes = classNames("slds-tile slds-hint-parent", className);
    return <article className={classes}>
        {children}
    </article>
}

Tile.propTypes = {
    children: CustomPropTypes.children,
    className: PropTypes.string,
};