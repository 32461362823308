import Page from "../../../common/ui/page";
import React, {useContext, useState} from "react";
import {Link} from "react-router-dom";
import DataTableColumn from "../../../common/ui/data-table/column";
import GenericDataTable from "../../../common/ui/genericDataTable/genericDataTable";
import {CancelButtonField, FormActions, SldsInputField, SubmitButtonField} from "../../../common/ui/form/formElements";
import {HasPermission} from "../../../common/ui/permissions";
import {Modal} from "../../../common/slds";
import {Formik} from "formik";
import {Log} from "../../../common/log";
import {Form} from "../../../common/ui/form/formik";
import {useMutation, useQuery} from "@apollo/client";
import {MUTATE_CREATE_DASHBOARD, QUERY_DASHBOARDS} from "./queries";
import {useGraphqlLoadingComponent} from "../../../common/graphql";
import {AppContext} from "../../appPage";
import Button from "../../../common/slds/buttons/button";
import DataTableCell from "../../../common/ui/data-table/cell";


export const DashboardsPage = () => {
    const [newDashboardModalOpen, setNewDashboardModalOpen] = useState(false);

    const app = useContext(AppContext);
    const dasbhoardsResult = useQuery(QUERY_DASHBOARDS, {variables: {appId: app.id}});
    const [createDashboard] = useMutation(MUTATE_CREATE_DASHBOARD, {
        variables: {appId: app.id}, refetchQueries: [
            {
                query: QUERY_DASHBOARDS,
                variables: {
                    appId: app.id
                }
            }
        ]
    });


    const loading = useGraphqlLoadingComponent(dasbhoardsResult);
    if (loading) {
        return loading;
    }
    const dashboards = dasbhoardsResult.data.dashboards;

    return <Page title={"Dashboards"}
                 trail={[<Link to="." key={1}>Dashboard</Link>]}
                 actions={<div>
                     <HasPermission role={"admin"}><Button iconName={"add"} onClick={() => setNewDashboardModalOpen(true)}>New Dashboard</Button></HasPermission>
                 </div>}

    >
        <Modal isOpen={newDashboardModalOpen} onRequestClose={() => setNewDashboardModalOpen(false)}
               title={"Create Dashboard"}
        >
            <Formik
                initialValues={{}}
                onSubmit={(values, actions) => {
                    Log.Debug("NewDashboard.submit", values);
                    createDashboard({
                        variables: {
                            input: {
                                name: values.name,
                            }
                        }
                    }).then(() => {
                        actions.setSubmitting(false);
                        setNewDashboardModalOpen(false);
                    }, (e) => {
                        Log.Error("Failed to create dashboard.", e);
                        actions.setSubmitting(false);
                        setNewDashboardModalOpen(false);
                    });
                }}
            >
                {() => {
                    return <Form>
                        <SldsInputField name={"name"} label={"Name"} placeholder={"Dashboard Name"} required={true}/>
                        <FormActions>
                            <SubmitButtonField>Save</SubmitButtonField>
                            <CancelButtonField onClick={() => setNewDashboardModalOpen(false)}/>
                        </FormActions>
                    </Form>
                }}
            </Formik>
        </Modal>
        <GenericDataTable id={"dashboards"} hideDetails={true} items={dashboards} tableConfigDefault={{}}>
            <DataTableColumn label={"Name"} property="name">
                <DataTableCell render={(props) => (
                    <Link to={`${props.item.id}/`}>{props.item.name ? props.item.name : "- no name -"}</Link>
                )}/>
            </DataTableColumn>
        </GenericDataTable>

    </Page>
};