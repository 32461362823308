import {useMutation, useQuery} from "@apollo/client";
import gql from "graphql-tag";
import React, {useState} from "react";
import {Formik} from "formik";
import {useAuthContext} from "../common/context/authContext";
import * as Log from "../common/log";
import Button from "../common/slds/buttons/button";
import DynamicMenu from "../common/slds/dynamicMenu/dynamicMenu";
import SingleLookupField from "../common/ui/lookup/singleLookupField";
import {CancelButtonField, FormActions, SubmitButtonField} from "../common/ui/form/formElements";
import {HasPermission} from "../common/ui/permissions";
import {useT} from "../common/i18n";

const QUERY_USER_ORG = gql`
    query($id: ID!) {
        getUser(id: $id) {
            id
            organisation {
                id
                name
            }
        }
    }`;

const MUTATION_UPDATE_USER_ORG = gql`
    mutation($id: ID!, $input: UserInput) {
        updateUser(id: $id, input: $input) {
            id
            organisation {
                id
                name
            }
        }
    }`;

export default function OrganisationSelector() {
    const t = useT();
    const auth = useAuthContext();
    const [menuOpen, setMenuOpen] = useState(false);
    const userDetailResult = useQuery(QUERY_USER_ORG, {
        variables: {
            id: auth.userId(),
        }
    });
    const userDetail = userDetailResult?.data?.getUser;

    const [mutateUpdateUser] = useMutation(MUTATION_UPDATE_USER_ORG, {
        variables: {
            id: auth.userId(),
        }
    });

    const organisationListResult = useQuery(gql`
        query($search: String) {
            getOrganisationList(search: $search) {
                id
                name
            }
        }`, {
        fetchPolicy: "network-only",
        variables: {
            page: {
                offset: 0,
                limit: 10
            }
        }
    });

    return (
        <Formik
            initialValues={{
                organisation: userDetail?.organisation,
            }}
            onSubmit={(values, actions) => {
                Log.Debug("On submit", values)
                return mutateUpdateUser({
                    variables: {
                        input: {
                            organisationId: values.organisation.id,
                        },
                    },
                })
                    .then(() => {
                        setMenuOpen(false)
                        auth.refreshTheToken().then(() => {
                            location.reload()
                        })
                    })
                    .catch((err) => {
                        Log.Error("Failed to update user", err)
                        actions.setFieldError("global", "Failed to update user")
                    })
            }}
            enableReinitialize={true}>
            <HasPermission role={["admin", "org-admin"]}>
                <Button iconName="prompt_edit" className="slds-m-left--x-small slds-m-top--x-small" onClick={() => setMenuOpen(true)} noBorder={true} />

                <DynamicMenu title={t("org-selector.change-org", "Change Organisation")} isOpen={menuOpen} top={"1.6rem"} left={"-2.1rem"}>
                    <SingleLookupField
                        name={"organisation"}
                        label={t("common.organisation", "Organisation")}
                        autoFocus={true}
                        loadSuggestions={(keyword) => organisationListResult.refetch({ search: keyword }).then((result) => result.data?.getOrganisationList)}
                        titleExtractor={(it) => it.name + " - " + it.id}
                        suggestionTitleExtractor={it => it.name}
                        subtitleExtractor={(it) => it.id}
                    />
                    <div className="slds-p-vertical_x-small" />
                    <FormActions>
                        <SubmitButtonField>{t("org-selector.change-org", "Change Organisation")}</SubmitButtonField>
                    </FormActions>
                    <FormActions>
                        <CancelButtonField onClick={() => setMenuOpen(false)} />
                    </FormActions>
                </DynamicMenu>
            </HasPermission>
        </Formik>
    )
}