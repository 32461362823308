import React from "react";
import * as log from '../log'

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {hasError: false};
        //Log.Debug("Error Props: ", props)
    }

    componentDidCatch(error, info) {
        // Display fallback UI
        this.setState({hasError: true, error: error});
        // You can also Log the error to an error reporting service
        log.Error("Error:", error, info);
    }

    render() {
        let state = this.state;
        if (state.hasError) {
            // You can render any custom fallback UI
            return <div>
                <h1>Something went wrong</h1>
                <p>{state.error ? state.error.message : "Check console for error details"}</p>
            </div>;
        }
        if (!this.props.children) {
            return <div></div>
        }
        return this.props.children;

    }
}


export default ErrorBoundary
