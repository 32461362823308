import * as React from "react";
import 'react-18-image-lightbox/style.css';
import * as PropTypes from "prop-types";
import Lightbox from "react-18-image-lightbox"; // only needed once per app


export default class LightboxImage extends React.Component {

    state = {
        photoIndex: 0,
        isOpen: false
    };

    static propTypes = {
        // String or array of strings
        src: PropTypes.any.isRequired
    };

    src(offset) {
        const src = this.props.src;

        if (typeof src === 'string') {
            if (offset === 0) {
                return src;
            }
            return undefined;
        } else if (Array.isArray(src) && src.length > 0) {
            const idx = (this.state.photoIndex + offset) % src.length;
            return src[idx % src.length]
        }
        return ""
    }

    get moveNext() {
        const src = this.props.src;
        if (Array.isArray(src) && src.length > 0) {
            return () => {
                const idx = (this.state.photoIndex + 1) % src.length;
                this.setState({
                    photoIndex: idx,
                })
            }
        }
        return undefined;
    }

    get movePrev() {
        const src = this.props.src;
        if (Array.isArray(src) && src.length > 0) {
            return () => {
                const idx = (this.state.photoIndex - 1) % src.length;
                this.setState({
                    photoIndex: idx,
                })
            }
        }
        return undefined;
    }

    render() {
        const {isOpen} = this.state;

        return <React.Fragment>
            <img src={this.src(0)} onClick={() => this.setState({isOpen: true})} style={{cursor: 'pointer'}}/>

            {isOpen ?
                <Lightbox mainSrc={this.src(0)}
                          nextSrc={this.src(1)}
                          prevSrc={this.src(-1)}
                          onCloseRequest={() => this.setState({isOpen: false})}
                          onMovePrevRequest={this.moveNext}
                          onMoveNextRequest={this.movePrev}
                />
                : null}
        </React.Fragment>
    }
}