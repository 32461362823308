import React from "react";
import PropTypes from "prop-types"
import classNames from "classnames"
import {Link, useLocation} from "react-router-dom";

export const Tab = (props) => {
    const {children, to, title} = props;
    const location = useLocation();
    const active = location.pathname.endsWith(to)
    return <li className={classNames("slds-tabs_default__item", {"slds-is-active": active})} title={`${title || children}`}
               role="presentation">
        <Link className="slds-tabs_default__link" role="tab" aria-selected={active ? "true" : "false"}
              to={to}>{children}</Link>
    </li>
};

Tab.propTypes = {
    children: PropTypes.any.isRequired,
    title: PropTypes.string,
    to: PropTypes.string.isRequired
};

export const TabNavigation = (props) => {
    const {tabs, children} = props;
    return <div className="slds-tabs_default">
        <ul className="slds-tabs_default__nav" role="tablist">
            {tabs}
        </ul>
        <div id="tab-default-1" className="slds-tabs_default__content slds-show" role="tabpanel">{children}</div>
    </div>
};

TabNavigation.propTypes = {
    children: PropTypes.any.isRequired,
    tabs: PropTypes.array.isRequired,
};