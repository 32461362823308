export const disabledGreyButtonCSS = {
    cursor: "not-allowed",
    borderColor: "#d9d9d9",
    color: "rgba(0, 0, 0, 0.25)",
    boxShadow: "none",
}

export const blueButtonCSS = {
    color: "#4096ff",
    borderColor: "#4096ff",
}
