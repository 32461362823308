import {Link} from "react-router-dom";
import React from "react";
import Page from "../../../common/ui/page";
import {useT} from "../../../common/i18n";

export default function DeviceNotFoundPage(props) {
    let devId = props.devId;
    let t = useT();

    return    <Page title={t("device.not-found.header", {devId: devId},"Device with ID {{devId}} not found")}
              trail={[<Link to={`../`} key={1}>Devices</Link>, devId]}
        >
        {t("device.not-found.body", "The Device could not be found. The device may not exist or you may lack the rights to view the device.")}
    </Page>
}