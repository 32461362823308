import { Modal } from "../../common/slds"
import { Formik } from "formik"
import { Log } from "../../common/log"
import { Form } from "../../common/ui/form/formik"
import { CancelButtonField, FormActions, SldsInputField, SubmitButtonField } from "../../common/ui/form/formElements"
import * as React from "react"
import { useMutation } from "@apollo/client"
import gql from "graphql-tag"
import { useNavigate } from "react-router-dom"
import OrganisationLookupField from "./organisationLookupField"


const AddOrganisationDialog = (props) => {
    let {isOpen, onRequestClose, parentOrgId, onlyCloseAfterCreation} = props;
    const navigate = useNavigate();

    const [createOrganisation] = useMutation(gql`
        mutation($input: OrganisationInput) {
            createOrganisation(input: $input) {
                id
            }
        }
    `, {
        onCompleted: (data) => props.onAdded(data.createOrganisation)
    });

    return <Modal isOpen={isOpen} onRequestClose={onRequestClose} heading={"Create Organisation"}>
        <Formik
            initialValues={{}}
            onSubmit={(values, actions) => {
                createOrganisation({
                    variables: {
                        input: {
                            name: values.name,
                            parentOrganisationId: parentOrgId ? parentOrgId : (values.parentOrganisation ? values.parentOrganisation.id : null)
                        }
                    }
                }).then((res) => {
                    onRequestClose();
                    if (!onlyCloseAfterCreation) {
                        navigate(`/configuration/organisations/${res.data.createOrganisation.id}`)
                    }
                }, (e) => {
                    actions.setFieldError("global", e);
                    Log.Error("Failed to create organisation", e);
                }).finally(() => {
                    actions.setSubmitting(false);
                })
            }}>
            <Form>
                <SldsInputField name={"name"} label={"Name"} placeholder={"Organisation Name"} required={true}/>
                { parentOrgId ? null :
                <OrganisationLookupField label={"Parent Organisation"} name={"parentOrganisation"}/>
                }
                <FormActions>
                    <SubmitButtonField>Create</SubmitButtonField>
                    <CancelButtonField onClick={onRequestClose}/>
                </FormActions>
            </Form>
        </Formik>
    </Modal>;
};

export default AddOrganisationDialog;