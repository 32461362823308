import {useT} from "../../common/i18n";
import {useAuthContext} from "../../common/context/authContext";
import {Link} from "react-router-dom";
import Page from "../../common/ui/page";
import React from "react";
import {OrgStatView} from "../../configuration/apiStatistics/orgStatView";

export const OrgApiStatisticsPage = () => {
    const t = useT();
    const auth = useAuthContext();

    // Route will always route to stats of user organisation via auth context
    // Don't show tabs if user is not org admin TODO: Better (looking) error message
    if (!(auth.hasRole("org-admin"))) {
        return (
            <Page
                trail={[<Link key={1} to={"."}>{t("config.settings.api-statistics.title", "REST API Statistics")}</Link>]}
                title={t("config.settings.api-statistics.title", "REST API Statistics")}
            >
                <div>Insufficient rights!</div>
            </Page>
        )
    }

    return (
        <div className={"slds-grid"}>
            <div className={"slds-col"}>
                <Page
                    trail={[<Link key={1}
                                  to={"."}>{t("config.settings.api-statistics.title", "REST API Statistics")}</Link>]}
                    title={t("config.settings.api-statistics.title", "REST API Statistics")}
                >
                        <OrgStatView forOrg={auth.organisationId()}/>
                </Page>
            </div>
        </div>
    )
}