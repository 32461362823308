import React from "react";
import PropTypes from "prop-types";
import {CustomPropTypes} from "../../propTypes/customPropTypes";
import classNames from "classnames";

export default function HorizontalList(props) {
    const {children, dividers} = props;
    return <ul className={classNames("slds-list_horizontal", {
        "slds-has-dividers_left": dividers,
    })}>
        {children}
    </ul>;
}
HorizontalList.defaultProps = {
    dividers: true,
};
HorizontalList.propTypes = {
    children: CustomPropTypes.children,
    dividers: PropTypes.bool,
};