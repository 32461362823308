import React from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";

const TopNavigationDropdownLink = (props) => {
    let {to, label} = props;

    return <li className="slds-dropdown__item">
        <Link to={to} title={label}>
            <span className="slds-truncate" title={label}>{label}</span>
        </Link>
    </li>
};

TopNavigationDropdownLink.propTypes = {
    to: PropTypes.string.isRequired,
    label: PropTypes.string
};

export default TopNavigationDropdownLink