import React from "react"
import PropTypes from "prop-types"
import {Button} from "@salesforce/design-system-react";
import classNames from "classnames"
import {Field, Formik, useFormikContext} from "formik";
import {DateTimePicker, SldsFormElementCompound, SldsFormElementRow} from "./form/formElements"
import {Form} from "./form/formik"
import {Log} from "../log"
import {useT} from "../i18n"
import moment from "moment/moment";
import {Icon} from "../slds/icons/icon";

// Map the filters to graphQL "FilterInputType"
// [{"field": "a", "op": "lte", "value": "123"}]
function filtersToGraphQL(filters, fieldName) {
    let gqlFilters = []
    Log.Debug("filterPanel.filtersToGraphQL", "New filters: ", filters)

    if (!filters) {
        return gqlFilters
    }

    if (filters.until) {
        gqlFilters.push({ field: fieldName, op: "lte", value: new Date(filters.until.getTime()) })
    }
    if (filters.from) {
        gqlFilters.push({ field: fieldName, op: "gte", value: new Date(filters.from.getTime()) })

    }
    return gqlFilters
}

FilterPanel.propTypes = {
    align: PropTypes.oneOf(["center", "left", "right"]),
    size: PropTypes.oneOf(["small", "medium", "large", "x-large", "full"]),
    onClose: PropTypes.func,
    filters: PropTypes.object, // From useFilter() Hook
    fieldName: PropTypes.string,
    pagination: PropTypes.object,
    showQuickAction: PropTypes.bool,
}

FilterPanel.defaultProps = {
    align: "left",
    size: "medium",
    filters: {
        until: new Date(),
    },
    fieldName: "receivedAt",
    showQuickAction: false,
}



NowButton.propTypes = { onClick: PropTypes.func }
export default function FilterPanel(props) {
    const t = useT()
    const { filters, align, className, size, fieldName, pagination, showQuickAction = false} = props
    const { setFilterTabVisible } = filters
    const docked = align !== "center"

    let fieldname = "receivedAt"
    if (fieldName) {
        fieldname = fieldName
    }
    let from, until
    for (let filter of filters.filter) {
        if (filter.field === fieldname && filter.op === "gte") {
            from = new Date(filter.value.getTime())
        }
        if (filter.field === fieldname && filter.op === "lte") {
            until = new Date(filter.value.getTime())
        }
    }
    return (
        <div
            className={classNames(
                {
                    "slds-is-open": true,
                    [`slds-panel_docked-${align}`]: docked,
                    'slds-panel_docked"': docked,
                },
                `slds-size_${size}`,
                "lds-panel",
                className
            )}
            aria-hidden="false">
            <div className="slds-panel__header">
                <div className="slds-panel__header-title slds-text-heading_small slds-truncate" title="Panel Header">
                    Filter
                </div>
                <Button
                    iconCategory="utility"
                    variant="icon"
                    iconName="close"
                    iconSize="small"
                    assistiveText={{ icon: "Close filter panel" }}
                    className="slds-button--icon slds-panel__close"
                    onClick={() => setFilterTabVisible(false)}
                />
            </div>
            <div className="slds-panel__body">
                {/* Render the filter options*/}
                <Formik
                    initialValues={{
                        until: until,
                        from: from,
                    }}
                    enableReinitialize={true}
                    onSubmit={(values, actions) => {
                            Log.Debug("filterPanel.onSubmit", "Submit: ", values, actions)
                        let newFilters = filtersToGraphQL(values, fieldname)
                        Log.Debug("filterPanel.onSubmit", "New filters: ", newFilters)
                        filters.setFilter(newFilters)
                        actions.setSubmitting(false)
                    }}
                    >
                    <Form>
                        <SldsFormElementCompound>
                            <SldsFormElementRow>
                                <div className="slds-size_1-of-6">
                                    <Field component={DateTimePicker} submitFormOnChange={true} label={t("filter-panel.from", "From")} name="from" />
                                </div>
                                <div className="slds-size_1-of-6">
                                    <Field component={DateTimePicker} submitFormOnChange={true} label={t("filter-panel.until", "Until")} name="until" />
                                </div>
                                <div className="slds-size_1-of-6">
                                    { showQuickAction && <NowButton pagination={pagination}/> }
                                </div>
                            </SldsFormElementRow>
                        </SldsFormElementCompound>
                    </Form>
                </Formik>
            </div>
        </div>
    )
}

function NowButton({pagination}) {
    const t = useT()
    const formik = useFormikContext()

    const setNowDate = () => {
        let now = moment().toDate()
        formik.setFieldValue("until", now)
        formik.handleSubmit()
        if (pagination) {
            pagination.reset()
        }
    }

    return (
      <div className="slds-form-element">
          <label className="slds-form-element__label" htmlFor="input-01">
              &nbsp;
          </label>
          <div className="slds-form-element__control">
              <Button onClick={() => setNowDate()} >
                  <Icon category="utility" name="refresh" size="xx-small" />
                  &nbsp;
                  {t("filter-panel.now", "Now")}
              </Button>
          </div>
      </div>
    )
}
