import gql from "graphql-tag"
import React, { useContext, useEffect } from "react"
import { BannerContext, QUERY_NOTIFICATION } from "../../common/context/bannerContextProvider"
import { Alert, Button, Card, DatePicker, Flex, Form, Input, Select, Space, Switch, Typography } from "antd"
import usePrevious from "../../common/hooks/usePrevious"
import { useMutation } from "@apollo/client"
import {
    CheckCircleFilled,
    CheckOutlined,
    CloseCircleFilled,
    CloseOutlined,
    CloudUploadOutlined,
    ExclamationCircleFilled,
    InfoCircleFilled,
    RedoOutlined,
} from "@ant-design/icons"
import dayjs from "dayjs"
import { Log } from "../../common/log"
import FormItem from "antd/es/form/FormItem"
import { blue, green, orange, red } from "./colors"
import { MUTATION_DELETE_NOTIFICATION, QUERY_FUTURE_NOTIFICATIONS } from "./announcementPage";
import { useT } from "../../common/i18n"
import Markdown from "react-markdown"
import remarkGfm from "remark-gfm"

import MDEditor, { commands } from "@uiw/react-md-editor"
import { EditAnnouncementItemContext } from "./editAnnouncementContextProvider"

const { Text } = Typography
const { RangePicker } = DatePicker

const CREATE_NOTIFICATION = gql`
    mutation createNotification($title: String, $message: String, $level: NotificationLevel!, $activeFrom: DateTime!, $activeUntil: DateTime!) {
        createNotification(title: $title, message: $message, level: $level, activeFrom: $activeFrom, activeUntil: $activeUntil, type: banner, scope: global) {
            id
            title
            message
            type
            level
            activeFrom
            activeUntil
        }
    }
`

export default function NewAnnouncementForm({ closeCallback }) {
    const t = useT()
    const bannerContext = useContext(BannerContext)
    const editAnnouncementContext = useContext(EditAnnouncementItemContext)

    const initialValues = {
        level: "info",
    }

    // Form Values
    const [form] = Form.useForm()
    const titleValue = Form.useWatch("title", form)
    const textValue = Form.useWatch("text", form)
    const levelValue = Form.useWatch("level", form)
    const isBannerPreview = Form.useWatch("bannerPreview", form)

    const wasBannerPreview = usePrevious(isBannerPreview)

    const [createNotification, createNotificationResult] = useMutation(CREATE_NOTIFICATION)
    const [deleteNotification] = useMutation(MUTATION_DELETE_NOTIFICATION)

    const [isDone, setIsDone] = React.useState(false)

    // remove the preview notification if the form is closed
    useEffect(() => {
        return () => {
            reset()
        }
    }, [])

    useEffect(() => {
        if (editAnnouncementContext.editAnnouncement) {
            Log.Debug("NewAnnouncementForm", "editAnnouncement", editAnnouncementContext.editAnnouncement)
            form.setFieldsValue({
                existingId: editAnnouncementContext?.editAnnouncement?.id,
                title: editAnnouncementContext?.editAnnouncement?.title,
                text: editAnnouncementContext?.editAnnouncement?.message,
                level: editAnnouncementContext?.editAnnouncement?.level,
            })
            if (editAnnouncementContext?.isCopy) {
                form.setFieldsValue({
                    existingId: null,
                    range: [null, null],
                })
            } else {
                form.setFieldsValue({
                    range: [dayjs(editAnnouncementContext?.editAnnouncement?.activeFrom), dayjs(editAnnouncementContext?.editAnnouncement?.activeUntil)],
                })
            }
            setIsDone(false)
        }
    }, [editAnnouncementContext.editAnnouncement])

    useEffect(() => {
        if (wasBannerPreview && !isBannerPreview) {
            // Restore the previous notifications
            bannerContext.resetPreview()
            bannerContext.setShowPreviewNotification(false)
        }
        if (!wasBannerPreview && isBannerPreview) {
            setTimeout(() => window.scrollTo({ top: 0, behavior: "smooth" }), 100)
        }

        if (isBannerPreview) {
            // while active overwrite the notifications
            bannerContext.setPreview([
                {
                    id: "preview",
                    title: titleValue,
                    message: textValue,
                    type: "banner",
                    level: levelValue,
                    activeFrom: "2024-01-01T00:00:00Z",
                    activeUntil: "2025-01-01T00:00:00Z",
                    isPreview: true,
                },
            ])
            bannerContext.setShowPreviewNotification(true)
        }
    }, [isBannerPreview, titleValue, textValue, levelValue])

    const levelOptions = [
        {
            label: "Info",
            value: "info",
            emoji: <InfoCircleFilled style={{ color: blue }} />,
        },
        {
            label: "Success",
            value: "success",
            emoji: <CheckCircleFilled style={{ color: green }} />,
        },
        {
            label: "Warning",
            value: "warning",
            emoji: <ExclamationCircleFilled style={{ color: orange }} />,
        },
        {
            label: "Error",
            value: "error",
            emoji: <CloseCircleFilled style={{ color: red }} />,
        },
    ]

    const labelRender = (props) => {
        const { value } = props

        let option = levelOptions.find((option) => option.value === value)

        return (
            <Space>
                <span>{option?.emoji}</span>
                {option?.label}
            </Space>
        )
    }

    const disabledDate = (current) => {
        // Can not select days before today and today
        return current && current < dayjs().startOf("day")
    }

    const reset = () => {
        form.resetFields()
        bannerContext.resetPreview()
        editAnnouncementContext.setEditAnnouncement(null)
        setIsDone(false)
    }

    const submit = () => {
        form.validateFields()
            .then((values) => {
                // set time range defaults
                if (!values.range) {
                    values.range = [dayjs().startOf("day"), dayjs().add(7, "day").startOf("day")]
                }
                if (!values.range[0]) {
                    values.range[0] = dayjs().startOf("day")
                }
                if (!values.range[1]) {
                    values.range[1] = values.range[0].add(7, "day").startOf("day")
                }
                Log.Debug("NewAnnouncementForm.submit", "Form Values", values, "from", values.range[0].toISOString(), "until", values.range[1].toISOString())
                return values
            })
            .then((values) => {
                if (values?.existingId) {
                    deleteNotification({
                        variables: {
                            id: values.existingId,
                        },
                    })
                }
                createNotification({
                    variables: {
                        title: values.title || "",
                        message: values.text || "",
                        level: values.level,
                        activeFrom: values.range[0].toISOString(),
                        activeUntil: values.range[1].toISOString(),
                    },
                    refetchQueries: [{ query: QUERY_FUTURE_NOTIFICATIONS }, { query: QUERY_NOTIFICATION }],
                })
                    .then(() => {
                        editAnnouncementContext.setEditAnnouncement(null)
                        bannerContext.resetPreview()
                        setIsDone(true)
                    })
                    .catch((err) => {
                        Log.Debug("NewAnnouncementForm.submit", "err", err)
                    })
            })
    }

    return (
        <Card
            style={{ width: "40%" }}
            extra={<Button size="small" color="default" variant="text" shape="circle" icon={<CloseOutlined />} onClick={() => closeCallback()} />}
            size="small"
            title={editAnnouncementContext.editAnnouncement ? t("announcement.form-edit.title", "Edit Announcement"): t("announcement.form-new.title", "New Announcement")}>
            <Form form={form} requiredMark={false} labelCol={{ span: 5 }} wrapperCol={{ span: 16 }} initialValues={initialValues}>
                <Form.Item name="existingId" style={{ display: "none" }}/>
                <Form.Item style={{ marginBottom: 0 }} label={t("announcement.form-new.title-input", "Title")} name="title">
                    <Form.Item name="level" style={{ display: "inline-block", width: "30%" }}>
                        <Select
                            options={levelOptions}
                            labelRender={labelRender}
                            optionRender={(option) => (
                                <Space>
                                    <span>{option?.data?.emoji}</span>
                                    {option?.data?.label}
                                </Space>
                            )}
                        />
                    </Form.Item>
                    <Form.Item style={{ display: "inline-block", width: "calc(70% - 8px)", marginLeft: "8px" }} name="title" rules={[{ required: false }]}>
                        <Input placeholder={t("announcement.form-new.title-input", "Title")} />
                    </Form.Item>
                    {/*</Space.Compact>*/}
                </Form.Item>
                <Form.Item label={t("announcement.form-new.message", "Message")} name="text" rules={[{ required: false }]}>
                    <MDEditor
                        value={textValue}
                        preview="edit"
                        commands={[
                            commands.bold,
                            commands.italic,
                            commands.strikethrough,
                            commands.hr,
                            commands.divider,
                            commands.link,
                            commands.code,
                            commands.codeBlock,
                            commands.image,
                            commands.unorderedListCommand,
                            commands.orderedListCommand,
                            commands.checkedListCommand,
                            commands.quote,
                            commands.table,
                        ]}
                        extraCommands={[commands.fullscreen, commands.help]}
                        onChange={(val) => {
                            form.setFieldsValue({ text: val })
                        }}
                    />
                </Form.Item>
                <Form.Item
                    label={t("announcement.form-new.active-time", "Active Time")}
                    name="range"
                    rules={[
                        {
                            required: false,
                            message: t("announcement.form-new.active-time-input-error", "Please input!"),
                        },
                    ]}>
                    <RangePicker
                        format="YYYY-MM-DD HH:mm"
                        showTime={{ format: "HH:mm" }}
                        disabledDate={disabledDate}
                        placeholder={[t("announcement.form-new.today", "Today"), t("announcement.form-new.seven-days", "+7 Days")]}
                        allowEmpty={[true, true]}
                    />
                </Form.Item>
                <Form.Item label={t("announcement.form-new.preview", "Preview")}>
                    {titleValue && (
                        <Alert
                            message={<Markdown remarkPlugins={[remarkGfm]}>{titleValue}</Markdown>}
                            description={<Markdown remarkPlugins={[remarkGfm]}>{textValue}</Markdown>}
                            type={levelValue}
                            showIcon
                        />
                    )}
                    {!titleValue && <Alert message={<Markdown remarkPlugins={[remarkGfm]}>{textValue}</Markdown>} type={levelValue} showIcon />}
                </Form.Item>
                <FormItem label={t("announcement.form-new.preview-description", "Live Preview")} name="bannerPreview">
                    <Switch />
                </FormItem>
                <Form.Item
                    wrapperCol={{
                        offset: 5,
                    }}
                    extra={
                        (createNotificationResult.error && (
                            <>
                                <Text style={{ color: red }}>{t("announcement.form-new.scheduling-error", "Scheduling the announcement failed!")}</Text>
                            </>
                        )) ||
                        (createNotificationResult?.data && isDone && (
                            <>
                                <Text style={{ color: green }}>{t("announcement.form-new.scheduling-success", "Scheduling successful")}&nbsp;</Text>
                                <CheckOutlined style={{ color: green }} />
                            </>
                        ))
                    }>
                    <Flex gap="small">
                        <Button
                            type="primary"
                            onClick={() => submit()}
                            loading={createNotificationResult.loading}
                            disabled={createNotificationResult?.data && isDone}
                            icon={<CloudUploadOutlined />}>
                            {t("announcement.form-new.submit", "Submit")}
                        </Button>
                        <Button type="primary" onClick={() => reset()} icon={<RedoOutlined />}>
                            {(createNotificationResult?.data && isDone && t("announcement.form-new.schedule-announcement", "Schedule new Announcement")) ||
                                t("announcement.form-new.reset", "Reset")}
                        </Button>
                        <Button danger type="text" onClick={() => closeCallback()} icon={<CloseOutlined />}>
                            {t("announcement.form-new.close", "Close")}
                        </Button>
                    </Flex>
                </Form.Item>
            </Form>
        </Card>
    )
}
