import * as React from "react";
import {useAuthContext} from "../context/authContext";
import {Accordion, AccordionPanel} from "./accordion";
import Roles from "../../model/roles";


export const DangerZone = (props) => {
    const {children} = props;
    return useAuthContext().hasRole(Roles.ADMIN, Roles.ORG_ADMIN, Roles.DEVICE_ADMIN) ?
        <Accordion id="base-example-accordion" className={"slds-m-top--medium"}>
            <AccordionPanel
                expanded={false}
                id={"danger-zone"}
                key={"danger-zone"}
                summary={"Danger Zone"}
            >
                {children}
            </AccordionPanel>
        </Accordion> : null;
};


