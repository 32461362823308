import gql from "graphql-tag"
import { useT } from "../../../../common/i18n"
import { useNotificationContext } from "../../../../notifications/notificationContext"
import { useMutation } from "@apollo/client"
import { usePagination } from "../../../../common/hooks/usePagination"
import * as React from "react"
import { useState } from "react"
import * as log from "../../../../common/log"
import { TabsPanel } from "@salesforce/design-system-react"
import GenericDataTable from "../../../../common/ui/genericDataTable/genericDataTable"
import DataTableColumn from "../../../../common/ui/data-table/column"
import Tooltip from "../../../../common/slds/tooltip/tooltip"
import { Icon } from "../../../../common/slds/icons/icon"
import { Form } from "../../../../common/ui/form/formik"
import { FormActions, SubmitButtonField } from "../../../../common/ui/form/formElements"
import { Formik } from "formik"
import DeviceTypeLookupField from "../../../../components/deviceType/deviceTypeLookupField"
import { useAuthContext } from "../../../../common/context/authContext"
import _ from "lodash"
import OrganisationLookupField from "../../../../components/organisation/organisationLookupField"

const MUTATION_UPDATE_DEVICE = gql`
    mutation updateConfig($devId: ID!, $input: DeviceInput!) {
        updateDevice(id: $devId, input: $input) {
            id
            organisation {
                id
                name
            }
            deviceType {
                id
                displayName
            }
        }
    }
`

export const ChangeDeviceSettingsTab = (props) => {
    const auth = useAuthContext()
    const t = useT()
    const notify = useNotificationContext()
    const [updateDevice] = useMutation(MUTATION_UPDATE_DEVICE)
    const page = usePagination()
    const [results, setResults] = useState(new Map())

    let multiSelection = props.multiSelection
    return (
        <TabsPanel label={t("device.bulkoperation.devicesettings.label", "Device Settings")}>
            <div className="slds-p-horizontal--xx-small">
                <Formik
                    initialValues={{}}
                    validateOnChange={true}
                    validateOnMount={false}
                    validate={(values) => {
                        let errors = {}
                        if (!values.organisation && !values.deviceType) {
                            errors.organisation = t("device.bulkoperation.devicesettings.validation", "DeviceType or Organisation must be selected")
                            errors.deviceType = t("device.bulkoperation.devicesettings.validation", "DeviceType or Organisation must be selected")
                        }
                        return errors
                    }}
                    onSubmit={(values) => {
                        setResults(new Map())
                        let promises = []
                        const input = {}
                        if (values.deviceType) {
                            input.deviceTypeId = values.deviceType.id
                        }
                        if (values.organisation) {
                            input.organisationId = values.organisation.id
                        }
                        log.Debug("input:", input)

                        multiSelection.selections.forEach((currentValue) => {
                            promises.push(
                                updateDevice({
                                    variables: {
                                        devId: currentValue.id,
                                        input: input,
                                    },
                                })
                                    .catch((err) => {
                                        setResults(results.set(currentValue.id, err))
                                        return true
                                    })
                                    .finally(() => {
                                        setResults(results.set(currentValue.id, false))
                                        return false
                                    })
                            )
                        })
                        Promise.all(promises).then((values) => {
                            const failed = _.filter(values, (v) => {
                                return v === true
                            })
                            if (failed.length > 0) {
                                //"Only use {{allowed}}", {allowed: "[0-9, a-z, A-Z, -]"}
                                notify.error(
                                    t("device.bulkoperation.devicesettings.failed", "Device update failed for {{ammount}} devices", { ammount: failed.length }),
                                    "change of device failed on some devices"
                                )
                            } else {
                                notify.success(t("device.bulkoperation.devicesettings.success", "Successfully updated devices"))
                            }
                        })
                    }}
                    render={(formik) => {
                        return (
                            <Form>
                                <DeviceTypeLookupField orgId={auth.organisationId()} />
                                {auth.hasRole("org-admin", "admin") && <OrganisationLookupField />}
                                <FormActions>
                                    <SubmitButtonField iconName={"play"} disabled={!formik.isValid}>
                                        {t("device.bulkoperation.devicesettings.update-button", "Update Devices")}
                                    </SubmitButtonField>
                                </FormActions>
                            </Form>
                        )
                    }}
                />
            </div>
            <br />
            <h1>{t("device.bulkoperation.table-heading", "Affected Devices")}:</h1>
            <GenericDataTable id={"bulk-operation-table"} items={multiSelection.selections} page={page} tableConfigDefault={{}} selection={multiSelection}>
                <DataTableColumn
                    label={t("device.table-config.heading.serial", "Serial")}
                    property={"serial"}
                    render={(props) => {
                        return (
                            <div className="slds-truncate" title={props.item.serial}>
                                <a href={`/#/organisation/devices/${props.item.id}/device-data`}>{props.item.serial}</a>
                            </div>
                        )
                    }}
                />
                <DataTableColumn
                    label={t("device.table-config.heading.address", "Address")}
                    property="addr"
                    title={"Addr"}
                    render={(props) => {
                      return (
                        <div className="slds-truncate" title={props.item.addr}>
                          <a href={`/#/organisation/devices/${props.item.id}/device-data`}>{props.item.addr}</a>
                        </div>
                      )
                    }}
                />
                <DataTableColumn
                    label={t("device.table-config.heading.name", "Name")}
                    property="name"
                    title={"Name"}
                    render={(props) => {
                        return props.item.name
                    }}
                />
                <DataTableColumn
                    label={t("device.table-config.heading.type", "Type")}
                    property={"deviceType"}
                    render={(props) => {
                        return props.item.deviceType.displayName
                    }}
                />
                <DataTableColumn
                    label={t("device.table-config.heading.app", "App")}
                    property={"app"}
                    render={(props) => {
                        return props.item.app ? props.item.app.name : null
                    }}
                />
                <DataTableColumn
                    label={t("common.organisation", "Organisation")}
                    property={"Organisation"}
                    render={(props) => {
                        return props.item.organisation ? props.item.organisation.name : ""
                    }}
                />
                <DataTableColumn
                    label={t("device.bulkoperation.devicesettings.table.result", "Updated Device")}
                    property={"result"}
                    render={(props) => {
                        if (results.get(props.item.id) == null) {
                            return null
                        }

                        return (
                            <Tooltip
                                left="-10px"
                                top="-50px"
                                content={results.get(props.item.id) ? results.get(props.item.id) : t("common.success", "Success")}>
                                {results.get(props.item.id) ? <Icon name="close" size={"small"} /> : <Icon name="check" size={"small"} />}
                            </Tooltip>
                        )
                    }}
                />
            </GenericDataTable>
        </TabsPanel>
    )
}
