import {Field, Formik} from "formik";
import React, {useState} from "react";
import * as yup from "yup";
import {useQuery} from "@apollo/client";
import {useLocation, useMatch, useNavigate} from "react-router-dom";
import gql from "graphql-tag";
import {backendUrl, getQueryStringParams} from "../common/helper";
import {Log} from "../common/log";
import {useAuthContext} from "../common/context/authContext";
import {Form} from "../common/ui/form/formik";
import {FormActions, SldsInputField} from "../common/ui/form/formElements";
import Button from "../common/slds/buttons/button";
import PlatformLogo from "../PlatformLogo";
import {useGraphqlLoadingComponent} from "../common/graphql";

const ChangePasswordPage = (props) => {
    const [failed, setFailed] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const match = useMatch("/auth/changePassword/:userId");
    const auth = useAuthContext();

    const userId = match.params.userId;

    const queryStringParams = getQueryStringParams(location.search);
    const otp = queryStringParams.otp;

    Log.Debug("location", location, queryStringParams);

    let changePasswordUrl = backendUrl() + "/api/setPasswordWithOtp";

    const userResult = useQuery(gql`
        query($userId: ID!, $otp: String!) {
            getUser(id: $userId, otp: $otp) {
                id
                login
                email
            }
        }
    `, {
        variables: {
            userId: userId,
            otp: otp,
        }
    });



    let body = {
        "otp":otp,
        "userId":Number(userId)
    }

    let changePassword = (newPassword) => {
        body.newPassword = newPassword
        return fetch(changePasswordUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Basic ${btoa("lobaro-dashboard:")}`
            },
            body: JSON.stringify(body)
        })
    };

    const loading = useGraphqlLoadingComponent(userResult);
    if (loading) {
        return loading;
    }

    const user = userResult?.data?.getUser;

    return <>
        <div className="slds-grid slds-grid--align-center">
            <div className="slds-p-top--large">
                <div className="slds-p-around--medium">
                        <PlatformLogo classNames="slds-global-header__logo slds-m-bottom--medium" />
                    <p className="slds-text-heading--medium">Change Password</p>
                </div>
                <div className="slds-card slds-p-around--medium">
                    {failed &&
                    <div style={{color: "rgb(194, 57, 52)"}}>
                        Password change failed. please try again.
                    </div>
                    }
                    {
                        !user && <div style={{color: "rgb(194, 57, 52)"}}>
                            Invalid URL
                        </div>
                    }
                    <Formik
                        initialValues={user || {}}
                        enableReinitialize={true}
                        validationSchema={yup.object().shape({
                            password: yup
                                .string()
                                .label('Password')
                                .required()
                                .min(6, 'Must be at least 6 characters')
                                .max(20, 'Can have max. 20 characters'),
                            confirmPassword: yup
                                .string()
                                .required()
                                .label('Confirm password')
                                .test('passwords-match', 'Passwords do not match', function (value) {
                                    return this.parent.password === value;
                                })
                        })}
                        onSubmit={(values, actions) => {
                            changePassword(values.password).then(result => {
                                if (result.error) {
                                    setFailed(true);
                                } else {
                                    return auth.login(values.login, values.password).then(() => {
                                        navigate("/");
                                    });
                                }
                            }).finally(() => {
                                actions.setSubmitting(false);
                            })
                        }}>
                        <Form>
                            <SldsInputField name={"login"} label={"Username / Login"} readOnly={true}/>
                            <SldsInputField name={"email"} label={"Email"} readOnly={true}/>
                            <SldsInputField name={"password"} label={"Password"} type="password" required={true} readOnly={!user}/>
                            <SldsInputField name={"confirmPassword"} label={"Confirm Password"} type="password" required={true} readOnly={!user}/>
                            {user && <FormActions>
                                <Field render={(formik) => <Button
                                    type="submit" className="slds-button--brand"
                                    disabled={formik.form.isSubmitting} {...props}>Change Password</Button>}
                                />
                            </FormActions>}
                        </Form>
                    </Formik>
                </div>
            </div>
        </div>
    </>;
};

export default ChangePasswordPage;