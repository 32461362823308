import {useQuery} from "@apollo/client";
import SingleLookupField from "../../common/ui/lookup/singleLookupField";
import * as React from "react";
import gql from "graphql-tag";
import PropTypes from "prop-types"


const IntegrationFilterLookupField = (props) => {
    const {orgId, name, label} = props;
    const deviceTypesResult = useQuery(gql`
        query ($orgId: ID!, $search: String) {
            integrationInputFilterList(page: {limit: 10, offset: 0}, orgId: $orgId, search: $search) {
                id
                name
            }
        }
    `, {
        variables: {
            "orgId": orgId,
        }
    });

    return <SingleLookupField
        name={name} label={label}
        subtitleExtractor={it => it.id + " - " + it.name}
        titleExtractor={it => it.name}
        loadSuggestions={keyword => deviceTypesResult.refetch({search: keyword})
            .then(result => result.data.integrationInputFilterList)
        }
    />;
}
IntegrationFilterLookupField.defaultProps = {
    name: "integrationInputFilter",
    label: "Filter"
}
IntegrationFilterLookupField.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    orgId: PropTypes.number.isRequired
}

export default IntegrationFilterLookupField;