import React, { useContext } from "react";
import VerticalNav, {
    VerticalNavEntries,
    VerticalNavEntry,
    VerticalNavSection,
    VerticalNavTitle,
} from "../../common/slds/verticalNavigation/verticalNavigation"
import {Outlet} from "react-router-dom"
import {useT} from "../../common/i18n"
import {useAuthContext} from "../../common/context/authContext"
import {FrontendConfigContext} from "../../common/context/frontendConfigContext";
import {useFeatureContext} from "../../common/context/featureContext";
import { OrganisationContext } from "../../common/context/organisationContextProvider";

export function OrgVerticalNav() {
    const auth = useAuthContext()
    const t = useT()
    const hardwareActivationEnabled = React.useContext(FrontendConfigContext)?.hardwareActivationEnabled;
    const deviceFeature = useFeatureContext().validateFeatures("lobaro-device-gateway")

    const org = useContext(OrganisationContext)

    const hasSubOrgs = org?.subOrganisations?.length > 0;
    const allowedToHaveSubOrgs = org?.maxSubOrganisations !== 0 && org?.maxSubOrgDepth > 0 && org?.effectiveSubOrgDepth > 0;

    const canSeeSubOrgOptions = auth.hasRole("admin") || (auth.hasRole("org-admin")  && auth.baseOrganisationId() !== auth.organisationId()) || (auth.hasRole("org-admin") && (allowedToHaveSubOrgs || hasSubOrgs));

    return <div className="slds-col slds-no-flex">
        <VerticalNav>
            <VerticalNavSection>
                <VerticalNavTitle>{t("org.config.nav.heading", "Organisation")}</VerticalNavTitle>
                <VerticalNavEntries>
                    <VerticalNavEntry
                        to={"/organisation/config/settings"}>{t("org.config.nav.settings", "Settings")}</VerticalNavEntry>
                    <VerticalNavEntry
                        to={"/organisation/config/wmbus"}>{t("org.config.nav.wmbus-keys", "wMbus Keys")}</VerticalNavEntry>
                    <VerticalNavEntry
                        to={"/organisation/config/users"}>{t("org.config.nav.users", "Users")}</VerticalNavEntry>
                    {/* TODO: Hide if rest-api license feature isnt available? */
                        auth.hasRole("org-admin") && <VerticalNavEntry
                        to={"/organisation/config/api-stats"}>{t("configuration.nav.api-stats")}</VerticalNavEntry>}
                    {canSeeSubOrgOptions ? <VerticalNavEntry
                        to={"/organisation/config/suborganisations"}>{t("org.config.nav.suborganisations", "Sub-Organisations")}</VerticalNavEntry> : null}
                    {hardwareActivationEnabled && deviceFeature ? <VerticalNavEntry
                        to={"/hardwareActivation/org/"}>{t("org.config.nav.hardware-activation", "Hardware Activation")}</VerticalNavEntry> : null}
                </VerticalNavEntries>
            </VerticalNavSection>
        </VerticalNav>
    </div>;
}

export default function OrgConfigPage() {
    const auth = useAuthContext()
    if (auth.hasRole("org-admin") || auth.hasRole("admin")) {
        return <div className="slds-container--fluid">
            <div className="slds-grid">
                <OrgVerticalNav/>
                <div className="slds-col">
                    <Outlet/>
                </div>
            </div>
        </div>
    } else {
        return null
    }

}