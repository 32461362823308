import { useEffect, useRef, useState } from "react"

// Helper to get some pagination state
export function useDebouncedPagination(pageSize = 100, refetchQuery = null) {
    const [total, setTotal] = useState(0)

    const [doExecuteOscillator, setDoExecuteOscillator] = useState(false)
    const pageInfo = useRef({ offset: 0, limit: pageSize })
    const timeoutActiveRef = useRef(null)
    const timer = useRef(null)

    const timeoutMillis = 300

    const createTimeout = () => {
        timer.current = setTimeout(() => {

            timeoutActiveRef.current = false
            setDoExecuteOscillator((prev ) => !prev)
        }, timeoutMillis)
    }

    const setPageInfo = (pageUpdate) => {

        pageInfo.current = pageUpdate

        if (!timeoutActiveRef.current) {
            timeoutActiveRef.current = true
            createTimeout(pageUpdate)
        } else {
            timeoutActiveRef.current = true
            clearTimeout(timer.current)
            createTimeout(pageUpdate)
        }
    }

    let pageItemCount = pageInfo.current.limit // Used to guess if there is a next page when total  == 0

    useEffect(() => {
        if (refetchQuery) {
            refetchQuery({
                page: getGraphqlPageInput(),
            })
        }
    }, [doExecuteOscillator, pageSize])

    function reset() {
        setPageInfo({ offset: 0, limit: pageSize })
        pageItemCount = pageInfo.current.limit
    }

    function getGraphqlPageInput() {
        if (!pageInfo.current.offset && !pageInfo.current.limit) {
            return null
        }
        return {
            offset: pageInfo.current.offset || 0,
            limit: pageInfo.current.limit || 100,
        }
    }

    function nextOffset() {
        if (total === 0) {
            return pageInfo.current.offset + pageInfo.current.limit
        }

        if (pageInfo.current.offset + pageInfo.current.limit > total) {
            return pageInfo.current.offset
        }
        return pageInfo.current.offset + pageInfo.current.limit
    }

    function prevOffset() {
        if (pageInfo.current.offset - pageInfo.current.limit < 0) {
            return 0
        }
        return pageInfo.current.offset - pageInfo.current.limit
    }

    return {
        pageInfo: pageInfo.current,
        setPageInfo: setPageInfo,
        setTotal: (value) => {
            setTotal(value)
        },
        getTotal: () => total,
        from: () => pageInfo.current.offset + 1,
        to: () => {
            if (pageItemCount > 0) {
                return pageInfo.current.offset + pageItemCount
            } else {
                return pageInfo.current.offset + pageInfo.current.limit
            }
        },
        currentPage: () => {
            return Math.ceil(pageInfo.current.offset / pageInfo.current.limit) + 1
        },
        totalPages: () => {
            return Math.ceil(total / pageInfo.current.limit)
        },
        hasPrev: () => pageInfo.current.offset > 0,
        hasNext: () => {
            return pageInfo.current.offset + pageInfo.current.limit < total || (total === 0 && pageItemCount === pageInfo.current.limit)
        },
        next: () => {
            setPageInfo({
                offset: nextOffset(),
                limit: pageInfo.current.limit,
            })
        },
        prev: () => {
            setPageInfo({
                offset: prevOffset(),
                limit: pageInfo.current.limit,
            })
        },
        getGraphqlPageInput,
        // You can update the actual page item count when the "total" value is not known to better guess if there is a next page
        setPageItemCount: (cnt) => {
            pageItemCount = cnt
        },
        getPageItemCount: () => pageItemCount,
        reset,
    }
}
