import * as React from "react"
import {useContext} from "react"
import {FrontendConfigContext} from "../../common/context/frontendConfigContext"
import {backendUrl} from "../../common/helper"
import {useT} from "../../common/i18n"


export default function ApiDocumentation() {
    const t = useT();
    const integrationDocUrl = useContext(FrontendConfigContext)?.integrationDocUrl
    const swagerApiUrl = backendUrl()+'/api'

    const links = [];
    if(integrationDocUrl) {
        links.push(<li key={1}><a href={integrationDocUrl} target="_blank">{t("integrations.rest.rest-integration-doc", "REST Integration Documentation")}</a></li>)
    }
    if (swagerApiUrl){
        links.push(<li key={2}><a href={swagerApiUrl} target="_blank">{t("integrations.rest.swagger-doc", "Swagger Documentation & API Client")}</a></li>)
    }
    if(links.length) {
        return <div className="apiDocumentationElement slds-p-horizontal--medium slds-p-top--x-small">
            <div className="slds-text-heading--medium slds-m-bottom--small">{t("integrations.rest.api-doc", "API Documentation")}</div>
            <p>{t("integrations.rest.api-doc-description", "REST API provided by the backend to fetch data.")}</p>
            <ul className="slds-list--dotted">
                {links}
            </ul>
            <hr className={"slds-m-vertical--small"}/>
        </div>
    } else {
        return <div></div>
    }

}
