import React, { useState } from "react"
import { useT } from "../../common/i18n"
import { useFilter } from "../../common/hooks/useFilter"
import { useQuery } from "@apollo/client"
import { QUERY_API_STATISTICS, QUERY_ORG_NAMES_BY_IDS } from "./queries"
import Highcharts from "highcharts"
import { Log } from "../../common/log"
import HighchartsReact from "highcharts-react-official"
import { ApiStatisticGQL, ApiStatisticResult, OrgByIDsGQL } from "./apiStatisticsPage"
import { Accordion, AccordionPanel, Checkbox, Combobox, comboboxFilterAndLimit } from "@salesforce/design-system-react"
import FilterPanel from "../../common/ui/filterPanel"
import { Col, Row, Splitter } from "antd"
import { useGraphqlLoadingComponent } from "../../common/graphql"
import { Icon } from "../../common/slds/icons/icon"
import { thirtyOneDays } from "../../common/magicNumbers"
import DataTableColumn from "../../common/ui/data-table/column"
import DataTable from "../../common/ui/data-table"

interface SldsComboboxOption {
    icon?: React.ReactNode
    id: string
    label: string
    subTitle?: string
    title?: string
    type?: "separator"
    disabled?: boolean
    tooltipContent?: string
}

function findLabel(options: SldsComboboxOption[], label: string): number {
    return options.findIndex((option) => option.label === label)
}

// findLabelName is a helper to extract the true label (org/endpoint name) from the combobox option.
// The event is triggered on one of the children of the combobox option (icon, title, subtitle), so we have to find the correct one (title)
// This can be done by accessing the parent of the current node (wrapping the combobox option) and then finding the child element with the class "slds-listbox__option-text"
function findLabelName(targetElem: HTMLElement): string {
    const parent = targetElem.parentElement
    if (!parent) {
        Log.Debug("API stats selector: No parent found", targetElem)
        return ""
    }
    const titleElem = parent.getElementsByClassName("slds-listbox__option-text")
    if (titleElem.length < 1) {
        Log.Debug("API stats selector: No title element found", parent)
        return ""
    }
    return titleElem[0].textContent || ""
}

// Make graphs from API statistics data, and filter them by selected URLs and/or orgs. Orgs are mapped from the result org ids, so they should always contain the relevant orgs
function makeGraphs(
    data: ApiStatisticResult[] | undefined,
    selectedUrls: SldsComboboxOption[] = [],
    allUrls: SldsComboboxOption[] = [],
    selectedOrgs: SldsComboboxOption[] | undefined = [],
    allOrgs: Map<number, string>
): Highcharts.SeriesOptions[] {
    if (!data || data.length < 1) {
        return []
    }
    const targetOrgs =
        selectedOrgs.length > 0
            ? selectedOrgs
            : [...allOrgs.entries()].map(([orgId, orgName]): SldsComboboxOption => {
                  return { id: orgId.toString(), label: orgName }
              })
    const targetUrls = selectedUrls.length > 0 ? selectedUrls : allUrls
    Log.Debug("makeGraphs all data", data, selectedUrls, allOrgs)
    Log.Debug("makeGraphs targets", targetOrgs, targetUrls)
    // TempRes: Map data to Highcharts series per org and/or url. If a url is missing for an org or vice versa, return null
    const tempRes = targetUrls.map(({ label: url }) => {
        return targetOrgs.map(({ id: orgIdStr, label: orgName }) => {
            const orgId = parseInt(orgIdStr) // reparse org id as number
            //Log.Debug("Org ID", orgId)

            const entry = {
                type: "column",
                name:
                    selectedOrgs.length < 1 && selectedUrls.length < 1
                        ? `Endpoint ${url}`
                        : (orgName && `${orgName} (id ${orgId}), Endpoint ${url}`) || `Org ${orgId}, Endpoint ${url}`,
                // If no url is selected, map all
                data: data
                    .filter((stat) => stat.organisationId == orgId && stat.url === url)
                    .map((stat) => {
                        //Log.Debug(stat)
                        return { x: new Date(stat.createdAt), y: stat.count }
                    }),
            }
            if (entry.data.length > 0) {
                return entry
            } else {
                return null
            }
        })
    })
    // Flatten tempRes and filter out entries with no data
    const res = tempRes.flat().filter((entry) => entry != null) as Array<{
        type: string
        name: string
        data: Array<{ x: Date; y: number }>
    }>
    if (res.length < 1) {
        Log.Debug("API stat graph is empty", [])
        return []
    }
    // if no url is selected, reduce res to one entry per url (per timestamp); no org
    if (selectedUrls.length < 1) {
        Log.Debug("API stat graph pre redux", JSON.parse(JSON.stringify(res)))
        const reduxRes = res.reduce(
            (acc, entry) => {
                const curIdx = acc.findIndex((accEntry) => accEntry.name === entry.name)
                if (curIdx === -1) {
                    acc.push(entry)
                } else {
                    // Same url, merge data for the same timestamp
                    entry.data.forEach((dataEntry) => {
                        const dataIdx = acc[curIdx].data.findIndex((accData) => accData.x.getTime() === dataEntry.x.getTime())
                        if (dataIdx === -1) {
                            acc[curIdx].data.push(dataEntry)
                        } else {
                            acc[curIdx].data[dataIdx].y += dataEntry.y
                        }
                    })
                }
                return acc
            },
            [res[0]]
        )
        reduxRes.forEach((red) => red.data.sort((a, b) => a.x.getTime() - b.x.getTime()))
        Log.Debug("API stat graph", reduxRes)
        return reduxRes
    }
    Log.Debug("API stat graph", res)
    return res
}

const initialFilter = [
    {
        field: "createdAt",
        op: "gte",
        value: new Date(new Date(Date.now() - thirtyOneDays).setHours(0, 0, 0, 0)),
    },
    { field: "createdAt", op: "lte", value: new Date(Date.now()) },
] // 31 days, starting from midnight

export const OrgStatView = ({ forOrg = 0 }) => {
    const t = useT()
    const filters = useFilter(true, initialFilter)
    const sort = { field: "createdAt", direction: "ASC" } // static sort for Highcharts
    const [selectedUrls, setUrls] = useState<SldsComboboxOption[]>([])
    const [urlInput, setInput] = useState<string>("")
    const [selectedOrgs, setOrgs] = useState<SldsComboboxOption[]>([])
    const [orgInput, setOrgInput] = useState<string>("")
    const [groupHourly, setGroupHourly] = useState<boolean>(false)

    const perOrgData = useQuery<ApiStatisticGQL>(QUERY_API_STATISTICS, {
        variables: {
            filter: filters.getGraphqlFilterInput(),
            sort: sort,
            orgID: forOrg,
            separateOrgs: true,
            withSuborgs: forOrg !== 0,
            groupHourly: groupHourly,
        },
    })

    const loading = useGraphqlLoadingComponent(perOrgData)

    const comboboxUrls: SldsComboboxOption[] = [...new Set(perOrgData.data?.apiStatistic.map((stat: ApiStatisticResult) => stat.url) || null).values()].map(
        (url, i) => {
            return {
                id: i.toString(),
                label: url,
                icon: <Icon category={"standard"} name={"hierarchy"} />,
                title: url,
            }
        }
    )
    const resOrgs = [...new Set(perOrgData.data?.apiStatistic.map((stat: ApiStatisticResult) => stat.organisationId) || null).values()]

    const orgInfo = useQuery<OrgByIDsGQL>(QUERY_ORG_NAMES_BY_IDS, {
        variables: {
            ids: resOrgs.filter((orgId) => orgId > 0), // Exclude 0, -1 and -2
        },
    })
    // "Flatten" org info to id -> name map
    const orgNames = new Map(orgInfo.data?.getOrganisationsByIds.map((org) => [parseInt(org.id), org.name]) || [])
    // Add 0 -> "Total",  -1 -> "Public Access", -2 -> "Admin Token" to orgNames
    orgNames.set(0, "Total")
    orgNames.set(-1, "Public Access")
    orgNames.set(-2, "Admin Token")
    // Ensure all orgs in resOrgs are in orgNames, or set their names to ""
    //Log.Debug("Sanity test: Sane = ", orgNames.has(0), orgNames.has(-1), orgNames.has(-2))
    //Log.Debug("orgNames", orgNames)
    resOrgs.forEach((orgId) => {
        //Log.Debug("Checking orgId", orgId)
        //Log.Debug("type", typeof orgId)
        //Log.Debug("InMap", orgNames.has(orgId))
        if (!orgNames.has(orgId)) {
            orgNames.set(orgId, "")
        }
    })
    //Log.Debug("New orgNames", orgNames)
    // For Org combobox; All displayed entries, but no Total org, for organisation view (forOrg) no "Public access" and "Admin token" as well
    const comboboxOrgs: SldsComboboxOption[] = [...orgNames.entries()]
        .filter(([, orgName]) => (forOrg === 0 ? orgName !== "Total" : !["Total", "Public Access", "Admin Token"].includes(orgName)))
        .map(([orgId, orgName]) => {
            return {
                id: orgId.toString(),
                label: orgName,
                title: orgName,
                subTitle: `${orgId}`,
                icon: <Icon category={"standard"} name={"account"} />,
            }
        })

    const graphData = makeGraphs(perOrgData.data?.apiStatistic, selectedUrls, comboboxUrls, selectedOrgs, orgNames) as Highcharts.SeriesOptionsType[]
    Log.Debug("Graph data", graphData)

    const chartOptions: Highcharts.Options = {
        title: {
            text: t("config.settings.api-statistics.chart", "REST API Access Statistics") || "REST API Access Statistics",
        },
        xAxis: {
            gridLineWidth: 1,
            type: "datetime",
            minTickInterval: (groupHourly ? 1 : 24) * 3600 * 1000, // Interval for Datetime axes is in milliseconds
            title: {
                text: t("config.settings.api-statistics.time", "Time") || "Time",
            },
        },
        yAxis: {
            title: {
                text: t("config.settings.api-statistics.count", "Access Count") || "Access Count",
            },
        },
        series: graphData,
        chart: {
            panKey: "ctrl",
            zooming: {
                type: "x",
            },
        },
        time: {
            useUTC: false,
        },
        plotOptions: {
            column: {
                pointPadding: 0,
                pointWidth: 42,
                borderWidth: 1,
                groupPadding: 0.1,
                shadow: false,
            },
        },
    }

    return (
        <div>
            <FilterPanel align={"center"} filters={filters} size={"full"} fieldName={"createdAt"} showQuickAction={true} />

            <div className="slds-m-horizontal--small">
                <Combobox
                    id="combobox-url"
                    events={{
                        onSelect: (e) => {
                            Log.Debug("Selected: ", e.target, e)
                            const label = findLabelName(e.target)
                            setUrls((old) => (label ? [...old, comboboxUrls[findLabel(comboboxUrls, label)]] : old))
                        },
                        onChange: (e) => {
                            Log.Debug("Changed text: ", e)
                            setInput(e.target.value)
                        },
                        onRequestRemoveSelectedOption: (e, data) => {
                            Log.Debug("Removal event: ", e, data)
                            Log.Debug("Removed: ", data.selection)
                            setUrls(data.selection)
                        }, // Not unused, needed by SLDS combobox
                    }}
                    labels={{
                        label: t("config.settings.api-statistics.select-url", "Select endpoints") || "Select endpoints",
                        placeholder:
                            t("config.settings.api-statistics.select-url-long", "Select endpoints to view statistics for") ||
                            "Select endpoints to view statistics for",
                    }}
                    multiple={true}
                    options={comboboxFilterAndLimit({
                        inputValue: urlInput,
                        limit: 5,
                        options: comboboxUrls,
                        selection: selectedUrls,
                    })}
                    predefinedOptionsOnly={true}
                    selection={selectedUrls}
                    value={urlInput}
                />
                <Combobox
                    id="combobox-org"
                    events={{
                        onSelect: (e) => {
                            Log.Debug("Selected: ", e.target, e)
                            const label = findLabelName(e.target)
                            setOrgs((old) => (label ? [...old, comboboxOrgs[findLabel(comboboxOrgs, label)]] : old))
                        },
                        onChange: (e) => {
                            Log.Debug("Changed text: ", e)
                            setOrgInput(e.target.value)
                        },
                        onRequestRemoveSelectedOption: (e, data) => {
                            Log.Debug("Removal event: ", e, data)
                            Log.Debug("Removed: ", data.selection)
                            setOrgs(data.selection)
                        }, // Not unused, needed by SLDS combobox
                    }}
                    labels={{
                        label: t("config.settings.api-statistics.select-org", "Select organisations") || "Select organisations",
                        placeholder:
                            t("config.settings.api-statistics.select-org-long", "Select organisations to view statistics for") ||
                            "Select organisations to view statistics for",
                    }}
                    multiple={true}
                    options={comboboxFilterAndLimit({
                        inputValue: orgInput,
                        limit: 5,
                        options: comboboxOrgs,
                        selection: selectedOrgs,
                    })}
                    predefinedOptionsOnly={true}
                    selection={selectedOrgs}
                    value={orgInput}
                />
                <Checkbox
                    id={"stat-group-hour-check"}
                    labels={{ label: t("config.settings.api-statistics.per-hour", "Display access counts per hour") || "Display access counts per hour" }}
                    checked={groupHourly}
                    onChange={() => setGroupHourly((old) => !old)}
                    className={"slds-m-top--xx-small"}
                />
                <Row align={"middle"} justify={"center"} className={"slds-p-vertical--medium"}>
                    {!loading && perOrgData.data?.apiStatistic.length && graphData.length ? (
                        <Col span={24}>
                            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
                        </Col>
                    ) : (
                        <Col className={"slds-p-vertical--xx-large slds-m-bottom--xx-large"}>
                            {t("config.settings.api-statistics.no-results", "No statistics found")}
                        </Col>
                    )}
                </Row>
                <StatisticSum
                    data={perOrgData.data?.apiStatistic || []}
                    selectedOrgs={selectedOrgs.length > 0 ? selectedOrgs : comboboxOrgs}
                    selectedUrls={selectedUrls.length > 0 ? selectedUrls : comboboxUrls}
                />
            </div>
        </div>
    )
}

interface statisticSumProps {
    data: ApiStatisticResult[] | undefined
    selectedOrgs: SldsComboboxOption[]
    selectedUrls: SldsComboboxOption[]
}

const StatisticSum = ({ data, selectedOrgs, selectedUrls }: statisticSumProps): React.ReactElement => {
    const t = useT()
    const [displaySum, setDisplay] = useState(false)

    const displayedUrls = selectedUrls.map((url) => url.label)
    const displayedOrgs = selectedOrgs.map((org) => org.id)
    // Merge data for selected orgs and urls each
    const byOrg = new Map<number, number>()
    const byUrl = new Map<string, number>()
    // Only include displayed orgs and urls, or all if none are selected. Statistic needs to be in both lists to be included
    data?.forEach((stat) => {
        if (
            displayedOrgs.length < 1 ||
            (displayedOrgs.includes(stat.organisationId.toString()) && (displayedUrls.length < 1 || displayedUrls.includes(stat.url)))
        ) {
            byOrg.set(stat.organisationId, (byOrg.get(stat.organisationId) || 0) + stat.count)
            byUrl.set(stat.url, (byUrl.get(stat.url) || 0) + stat.count)
        }
    })

    const orgData = [...byOrg.entries()]
        .sort((a, b) => (a[0] < b[0] ? -1 : a[0] > b[0] ? 1 : 0)) // Org IDs are used here!
        .map(([orgId, count]) => {
            return {
                id: orgId.toString(),
                organisation: `${selectedOrgs.find((org) => org.id === orgId.toString())?.label || "unknown org"} (id ${orgId})`,
                count: count,
            }
        })
    const urlData = [...byUrl.entries()]
        .sort((a, b) => a[0].localeCompare(b[0]))
        .map(([url, count]) => {
            return {
                id: url,
                url: url,
                count: count,
            }
        })

    return (
        <div className={"slds-m-end--small slds-m-bottom--small"}>
            <Accordion>
                <AccordionPanel
                    summary={t("config.settings.api-statistics.sum", "Cumulated API access counts")}
                    onTogglePanel={() => setDisplay((old) => !old)}
                    expanded={displaySum}
                    id={"api-stats-sum-panel"}
                    className={"slds-m-bottom--small"}>
                    <Splitter className={"slds-m-bottom--small slds-m-top--x-small"}>
                        <Splitter.Panel size={"50%"} resizable={false}>
                            <DataTable id={"api-stat-org-sum"} items={orgData} fixedLayout={true}>
                                <DataTableColumn label={t("common.organisation", "Organisation")} property={"organisation"} />
                                <DataTableColumn label={t("config.settings.api-statistics.count", "Access Count")} property={"count"} />
                            </DataTable>
                        </Splitter.Panel>
                        <Splitter.Panel size={"50%"} resizable={false} className={"slds-m-left--small"}>
                            <DataTable id={"api-stat-endpoint-sum"} items={urlData} fixedLayout={true}>
                                <DataTableColumn label={t("config.settings.api-statistics.url", "Endpoint")} property={"url"} />
                                <DataTableColumn label={t("config.settings.api-statistics.count", "Access Count")} property={"count"} />
                            </DataTable>
                        </Splitter.Panel>
                    </Splitter>
                </AccordionPanel>
            </Accordion>
        </div>
    )
}
