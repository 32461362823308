import React from "react"
import Page from "../../common/ui/page";
import {Link} from "react-router-dom";
import {useT} from "../../common/i18n";
import {useAuthContext} from "../../common/context/authContext";
import {OrgStatView} from "./orgStatView";

export const ApiStatisticsPage = () => {
    const t = useT();
    const auth = useAuthContext();

    // Don't show tabs if user is not admin TODO: Better (looking) error message
    if (!auth.hasRole("admin")) {
        return (
            <Page
                trail={[<Link key={1} to={"."}>{t("config.settings.api-statistics.title", "REST API Statistics")}</Link>]}
                title={t("config.settings.api-statistics.title", "REST API Statistics")}
            >
                <div>Insufficient rights!</div>
            </Page>
        )
    }

    return (
        <Page
            trail={[<Link key={1} to={"."}>{t("config.settings.api-statistics.title", "REST API Statistics")}</Link>]}
            title={t("config.settings.api-statistics.title", "REST API Statistics")}
        >
                <OrgStatView/>
        </Page>
    )
}

export interface ApiStatisticResult {
    createdAt: string
    count: number
    url: string
    organisationId: number
}

export interface ApiStatisticGQL {
    apiStatistic: ApiStatisticResult[]
}

export interface EndpointStatViewProps {
    forOrg?: number
}

export interface OrgByIDsGQL {
    getOrganisationsByIds: MinimalOrg[]
}

export interface MinimalOrg {
    id: string // Org ids are strings because
    name: string
}
