import React from "react"
import { Link, Outlet } from "react-router-dom"
import Page from "../../common/ui/page"
import { Tab, TabNavigation } from "../../common/ui/tabNavigation"
import { useT } from "../../common/i18n"
import {useFeatureContext} from "../../common/context/featureContext";


export const IntegrationsPage = () => {
    const t = useT();
    const licenseCfg = useFeatureContext()

    return <Page
        title={t("integrations.titel","Integrations")}
        trail={[<Link to={`.`} key={1}>{t("integrations.titel","Integrations")}</Link>]}
    >
        <TabNavigation tabs={[
            licenseCfg.validateFeatures("lobaro-device-gateway") ? <Tab key={"filter"} to={"filter"}>{t("integrations.nav.filter", "Filter")}</Tab> : null,
            (licenseCfg.validateFeatures("rest-api") || licenseCfg.validateFeatures("wmbus-api")) ?
                <Tab key={"rest"} to={"rest"}>{t("integrations.nav.rest", "REST")}</Tab> : null,
            licenseCfg.validateFeatures("lobaro-device-gateway") ? <Tab key={"http"} to={"http"}>{t("integrations.nav.http", "HTTP")}</Tab> : null,
            licenseCfg.validateFeatures("lobaro-device-gateway") ? <Tab key={"mqtt"} to={"mqtt"}>{t("integrations.nav.mqtt", "MQTT")}</Tab> : null,
            licenseCfg.validateFeatures("lobaro-device-gateway") ? <Tab key={"sftp"} to={"sftp"}>{t("integrations.nav.sftp", "SFTP")}</Tab> : null,
            licenseCfg.validateFeatures("lobaro-device-gateway") ? <Tab key={"lorawan"} to={"lorawan"}>{t("integrations.nav.lorawan", "LoRaWAN")}</Tab> : null,
        ].filter((t => t != null))}>
            <Outlet/>
        </TabNavigation></Page>;
};