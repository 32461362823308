// Graphql
import {Query} from '@apollo/client/react/components'
import gql from 'graphql-tag'
import {GraphqlLoadingErrorHandler} from '../../common/graphql'
// Table
import {DataTable, DataTableCell, DataTableColumn} from '@salesforce/design-system-react';
import React, {useContext} from "react";
import PropTypes from 'prop-types';
import _ from 'underscore'
import moment from 'moment'
import 'moment-timezone'
import * as log from "../../common/log";
import {AppContext} from "../appPage";
import {Link} from "react-router-dom";

const LinkDataTableCell = ({children, ...props}) => {
    // Log.Debug("PROPS:", props);
    return <DataTableCell title={children} {...props}>
        <a
            href={props.href ? props.href(props) : ""}
            target={props.target}
        >
            {children}
        </a>
    </DataTableCell>;
};
LinkDataTableCell.displayName = DataTableCell.displayName;

function tryParseJson(json, def) {
    try {
        return JSON.parse(json)
    } catch (err) {
        log.Error(err);
        return def
    }
}

class DevicesTableComponent extends React.Component {

    state = {};

    editItem = (item) => {
        this.setState({selection: [item]});
        this.props.onEditItem(item);
    };

    selectionChanged = (e, {selection}) => {
        if (selection.length > 2) {
            // when "all" are selected just remove selection
            this.editItem(undefined);
            return
        }

        if (selection.length > 0) {
            this.editItem(selection[selection.length - 1]);
        } else {
            this.editItem(undefined);
        }
        return true;
    };

    render() {
        const props = this.props;
        let devices = props.data.devices.devices;


        // This slightly complicated mapping finds outdoor, min and max temperature
        // for all sensors based on the last X values and the ensorInfo.
        // sensorInfo name is used to identify the internal sensor.
        const mappedItems = devices.map(dev => {
            let sensorInfo = tryParseJson(dev.config.sensorInfo, []);

            let parsed = _.groupBy(dev.parsedData.map(p => {
                return p.data;
            }), 'sensorId');
            parsed = _.mapObject(parsed, (val) => val[0]);
            parsed = _.values(parsed);
            parsed = parsed.map(p => {
                const info = sensorInfo.find(s => s.id === p.sensorId) || {};
                return {
                    ...p,
                    name: info.name,
                }
            });

            let extern = parsed.filter((v) => v.name !== 'intern');
            let intern = parsed.find((v) => v.name === 'intern');

            return {
                id: dev.id,
                addr: dev.addr,
                description: dev.description,
                //data: parsed,
                outdoorTemp: intern.temperature,
                time: moment(intern.time).tz("Europe/Berlin").fromNow(),//.format("DD.MM.YYYY HH:mm"),
                minTemp: _.min(extern, (v) => v.temperature).temperature,
                maxTemp: _.max(extern, (v) => v.temperature).temperature,

            }
        });

        return <div className="slds-m-bottom--large">
            <DataTable
                fixedLayout
                items={mappedItems}
                id="DevicesTable"
                onRowChange={this.selectionChanged}
                // onSort={this.handleSort}
                selection={this.state.selection}
                selectRows={false}
                striped
            >
                <DataTableColumn label="Weiche" property="description">
                    <DataTableCell render={
                        (props) => {
                            return <Link to={`/app/22/temperature/devices/${props.item.id}`}>{props.item.description}</Link>
                        }
                    }>
                    </DataTableCell>
                </DataTableColumn>
                <DataTableColumn label="Sensor Adresse" property="addr">
                    <DataTableCell render={
                        (props) => {
                            return <Link to={`/app/22/temperature/devices/${props.item.id}`}>{props.item.addr}</Link>
                        }
                    }>
                    </DataTableCell>
                </DataTableColumn>
                <DataTableColumn label="Außen [°C]" property="outdoorTemp"/>
                <DataTableColumn label="Min [°C]" property="minTemp"/>
                <DataTableColumn label="Max [°C]" property="maxTemp"/>
                <DataTableColumn label="Letztes Update" property="time"/>
                {/* <DataTableColumn label="Config" property="configRaw"/> */}
            </DataTable>
        </div>;
    }
}

DevicesTableComponent.propTypes = {
    onEditItem: PropTypes.func,
    deleteDatasource: PropTypes.func, // graphql
    datasources: PropTypes.object,
};

const query = {
    getDevices: gql`
        query getDevices($appId: ID!) {
            devices(appId:$appId, scope: "organisation") {
                devices{
                    id
                    addr
                    description
                    config {
                        sensorInfo: jsonValue(path:"sensorInfo")
                    }
                    parsedData(page: {limit: 100}) {
                        data {
                            time: stringValue(path: "time")
                            sensorId: stringValue(path:"id")
                            temperature: floatValue(path:"temperature")
                        }
                    }
                }
            }
        }
    `
};

const DevicesTable = (props) => {
    const app = useContext(AppContext);
    return <Query query={query.getDevices}
                  variables={{
                      appId: app.id
                  }}>
        {(result) => {
            return <GraphqlLoadingErrorHandler result={result}>
                <DevicesTableComponent data={result.data.devices} {...props}/>
            </GraphqlLoadingErrorHandler>
        }}
    </Query>
};

export default DevicesTable



