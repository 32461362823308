import {Form, Formik} from "formik";
import {
    FormActions,
    SldsCheckboxField,
    SldsInputField,
    SldsTextareaField,
    SubmitButtonField
} from "../../../common/ui/form/formElements";
import React from "react";
import * as PropTypes from "prop-types";
import {useT} from "../../../common/i18n";

export default function DownlinkFormik(props) {
 let onSubmitFunction = props.onSubmitFunction
 let t = useT()

  return  <Formik
        id={"create-downlink"}
        initialValues={{}}
        validate={(values) => {
            let errors = {};
            if (!IsJsonString(values.data)) {
                errors.data = t("common.json.invalid", "JSON invalid")
            }
            return errors
        }}
        onSubmit={onSubmitFunction}>
        <Form className="slds-form slds-form-element_stacked">
            <SldsInputField name="type" placeholder="fw/config" id="create-downlink-type"
                            label={t("device.downlinks.type", "Type")} required={true}/>
            <SldsCheckboxField name="confirmable" inlineLabel={t("device.downlinks.confirmable", "Confirmable")}
                               id="create-downlink-confirmable"/>
            <SldsTextareaField rows={4} name="data" placeholder="JSON as expected by the device"
                               id="create-downlink-data" label={t("device.downlinks.data", "Data")}
                               required={true} sanitize={true}/>
            <FormActions>
                <SubmitButtonField type="submit">{t("common.button.create", "Create")}</SubmitButtonField>
            </FormActions>
        </Form>
    </Formik>
}

DownlinkFormik.propTypes = {
    onSubmitFunction: PropTypes.func.isRequired,
};


function IsJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}