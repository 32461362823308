// React
import React from 'react';
// Graphql
import DataSourceForm from './datasourceForm'
import {Button, Card, CardEmpty, Icon, ButtonGroup} from '@salesforce/design-system-react'
import PropTypes from 'prop-types';
import * as log from "../../../common/log";

class DataSourceCard extends React.Component {

    state = {
        isFiltering: false,
        item: this.props.item,
    };

    componentWillReceiveProps(newProps) {
        if (newProps.item !== this.state.item) {
            this.setState({
                item: newProps.item,
                isCreate: false
            });
        }
    }

    handleAddItem = (event) => {
        log.Debug("Add item", event);
        this.setState({
            item: {name: "New Datasource"},
            isCreate: true
        });
    };

    close = () => {
        this.setState({
            item: undefined
        });
    };

    render() {
        const props = this.props;
        let isEmpty = this.state.item === undefined;
        let isEdit = this.state.item && this.state.item.id !== undefined;
        return <div className="slds-grid slds-grid--vertical">
            <Card
                id="DatasourceCard"
                className={props.className}
                headerActions={
                    !isEmpty && (
                        <ButtonGroup>
                            <Button label="New Datasource" onClick={this.handleAddItem}/>
                        </ButtonGroup>
                    )
                }
                heading={isEmpty ? "Datasource" : isEdit ? "Edit Datasource" : "Create Datasource"}
                icon={<Icon category="standard" name="document" size="small"/>}
                empty={
                    isEmpty ? (
                        <CardEmpty heading="No Datasource selected">
                            <ButtonGroup>
                                <Button label="New Datasource" onClick={this.handleAddItem}/>
                            </ButtonGroup>
                        </CardEmpty>
                    ) : null
                }
            >
                <div className="slds-m-horizontal--small">
                    <DataSourceForm item={this.state.item} onCancel={this.close} onSave={this.close}/>
                </div>
            </Card>
        </div>
    }
}

DataSourceCard.propTypes = {
    item: PropTypes.object,
    onClose: PropTypes.func, // When the current edit is ended
};

export default DataSourceCard