import React, {useCallback, useRef, useState} from "react";
import {CustomPropTypes} from "../propTypes/customPropTypes";
import PropTypes from "prop-types";
import {classnames} from "../wrapper/classnames";

export function HeaderCol(props) {
    let {children, wrap, truncate, title, className, width} = props;

    if (!title && typeof children === "string") {
        title = children;
    }

    let style = {};
    if (width) {
        style.width = width;
    }

    return <th className={classnames({
        "slds-cell-wrap": wrap,
    }, className)} scope="col" style={style}>
        <div className={classnames(className, {
            "slds-truncate": truncate,
        })} title={title}>
            {children}
        </div>
    </th>;
}

HeaderCol.propTypes = {
    children: CustomPropTypes.children,
    className: PropTypes.string,
    width: PropTypes.string,
    wrap: PropTypes.bool,
    truncate: PropTypes.bool,
    title: PropTypes.string,
};


export function TableHead(props) {
    const {children} = props;
    return <thead>
    <tr className="slds-line-height_reset">
        {children}
    </tr>
    </thead>;
}

TableHead.propTypes = {
    children: CustomPropTypes.children,
};

export function TableBody(props) {
    const {children} = props;
    return <tbody>
    {children}
    </tbody>;
}

TableBody.propTypes = {
    children: CustomPropTypes.children,
};

export function Row(props) {
    const {children, wrap} = props;
    return <tr className={classnames("slds-hint-parent", {
        "slds-cell-wrap": wrap,
    })}>
        {children}
    </tr>;
}

Row.propTypes = {
    children: CustomPropTypes.children,
    wrap: PropTypes.bool,
};

function Th(props) {
    return <th {...props}/>;
}

function Td(props) {
    return <th {...props}/>;
}

let idGenerator = 0;

export function EditCellContent(props) {
    let {children, renderInput, title, truncate, id, editInputId, readOnly, onEdit, onStopEdit} = props;

    if (id === undefined) {
        id = idGenerator++;
    }

    let editInputRef = useRef();

    useCallback(node => {
        if (node !== null) {
            node.focus();
        }
    }, []);


    const [editing, setEditing] = useState(false);

    const startEdit = () => {
        if (readOnly) return;

        if (onEdit) {
            if (onEdit() === false) {
                return;
            }
        }

        setEditing(true);
        setTimeout(() => {
            editInputRef.current.focus();
        }, 100);
    };

    const stopEdit = () => {
        setEditing(false);
        if (onStopEdit) {
            onStopEdit();
        }

    };
    if (!title && typeof children === "string") {
        title = children;
    }

    return <><span className="slds-grid slds-grid_align-spread" onClick={startEdit}>
            <span className={classnames({"slds-truncate": truncate})} title={title}>{children}</span>
        {!readOnly ? <button className="slds-button slds-button_icon slds-cell-edit__button slds-m-left_x-small" disabled="" tabIndex="-1" title="Edit" onClick={(e) => e.preventDefault()}>
            <svg className="slds-button__icon slds-button__icon_hint slds-button__icon_edit" aria-hidden="true">
                <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#edit"/>
            </svg>
            {/*<span className="slds-assistive-text">Edit Confidence of salesforce.com - 1,000 Widgets</span>*/}
        </button> : null}
          </span>
        {editing ? <section aria-describedby={"dialog-body-id-" + id} className="slds-popover slds-popover slds-popover_edit" role="dialog"
                            style={{"width": "auto", "position": "absolute", "top": "0", "left": "0.0625rem"}}>
            <div className="slds-popover__body" id={"dialog-body-id-" + id}>
                <div className="slds-form-element slds-grid slds-wrap">
                    <label className="slds-form-element__label slds-form-element__label_edit slds-no-flex" htmlFor={editInputId}>
                        {/*<span className="slds-assistive-text">Company</span>*/}
                    </label>
                    <div className="slds-form-element__control slds-grow">
                        {renderInput({inputRef: editInputRef, stopEdit: stopEdit})}
                        {/*<input ref={editInputRef} type="text" className="slds-input" onBlur={()=> setEditing(false)}/>*/}
                        {/*editInput*/}
                    </div>
                </div>
            </div>
        </section> : null}

    </>;
}

EditCellContent.propTypes = {
    children: CustomPropTypes.children,
    renderInput: PropTypes.func.isRequired,
    editInputId: PropTypes.string,
    title: PropTypes.string,
    truncate: PropTypes.bool,
    id: PropTypes.string,
    readOnly: PropTypes.bool,
    onEdit: PropTypes.func,
    onStopEdit: PropTypes.func,
};


export function Col(props) {
    let {
        children, className, title, truncate,
        header, wrap, noStripes,
        width, canEdit,
    } = props;

    if (!title && typeof children === "string") {
        title = children;
    }


    let style = {};

    if (width) {
        style.width = width;
        //style.maxWidth = maxWidth;
        if (wrap) {
            // A hack to make word wrap really work!
            style.wordWrap = "anywhere";
            style.overflowWrap = "anywhere";
        }
    }
    if (noStripes) {
        style.backgroundColor = "white";
    }

    // We can render a th or td element here
    let Elem = header ? Th : Td;
    let Space = <span>&nbsp;</span>;

    return <Elem className={classnames({
        "slds-cell-wrap": wrap,
        "slds-cell-edit": canEdit,
    }, className)}
                 style={style}
                 scope="col">
        <div className={classnames({
            "slds-truncate": truncate,
        })} title={title}>
            {children || Space}
        </div>
    </Elem>;
}

Col.propTypes = {
    children: CustomPropTypes.children,
    className: PropTypes.string,
    width: PropTypes.string,
    maxWidth: PropTypes.string,
    title: PropTypes.string,
    truncate: PropTypes.bool,
    header: PropTypes.bool,
    wrap: PropTypes.bool,
    // Force to not stripe the table, e.g. when nested in another table
    noStripes: PropTypes.bool,
};

// slds-table slds-table_cell-buffer slds-table_bordered slds-table_striped
const DataTable = (props) => {
    const {children, striped, bordered, columnDividers, rowHover, headless, fixedLayout} = props;


    return <table className={classnames("slds-table slds-table_cell-buffer ", {
        "slds-table_striped": striped,
        "slds-table_bordered": bordered,
        "slds-table_col-bordered": columnDividers,
        "slds-no-row-hover": !rowHover,
        "slds-table_header-hidden": headless,
        "slds-table_fixed-layout": fixedLayout,
    })}>
        {children}
    </table>;
}

DataTable.defaultProps = {
    rowHover: true,
    bordered: true,
};

DataTable.propTypes = {
    children: CustomPropTypes.children,
    // Set table to use fixed layout for width and truncation purposes
    // Used for all resizable variations which are not supported yet
    fixedLayout: PropTypes.bool,
    label: PropTypes.string,
    bordered: PropTypes.bool,
    striped: PropTypes.bool,
    columnDividers: PropTypes.bool,
    rowHover: PropTypes.bool,
    headless: PropTypes.bool,
};

export default DataTable;