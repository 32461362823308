import * as formik from "formik";
import React from "react";
import classNames from 'classnames';
import PropTypes from "prop-types";


export function Form(props) {
    const {children, stacked, className, id, withPadding, ...otherProps} = props;

    return <formik.Form
        id={id}
        className={classNames("slds-form",{
            "slds-form-element_stacked": stacked,
            "slds-p-around--x-small": withPadding,
        }, className)} {...otherProps}
    >
        {typeof (children) === "function" ? children() : children}
    </formik.Form>;
}

Form.propTypes = {
    children: PropTypes.node.isRequired,
    stacked: PropTypes.bool,
    className: PropTypes.string,
    id: PropTypes.string,
};

Form.defaultProps = {
    stacked: false,
};