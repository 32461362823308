import React from "react"
import { useT } from "../../i18n"
import { Button, ConfigProvider, Divider, Space, Table, Tree } from "antd";
import PropTypes from "prop-types"
import { CaretLeftFilled, CaretRightFilled, LeftOutlined, PushpinFilled, PushpinOutlined, RightOutlined } from "@ant-design/icons"

export const TableVisibilityConfigurationDialog = ({ treeData, onCheck, onClickAll, onClickDefault, onClickMinimal }) => {
    const t = useT()

    let reorderdTreeData = treeData.sort( (a,b) => a?.preOrder > b?.preOrder ? 1 : a?.preOrder < b?.preOrder ? -1 : 0)

    return (
        <>
            <Tree checkable height={200} treeData={reorderdTreeData} checkedKeys={treeData?.filter((item) => item.checked).map((item) => item.key)} onCheck={onCheck} />
            <Divider style={{ margin: "0.2rem 0 0.7rem 0" }} />
            <Space.Compact direction="vertical" style={{ color: "blue" }} block>
                <Button type="primary" onClick={onClickAll} size="middle" block>
                    {t("generic-data-table.column-visibility.show-all", "Show all")}
                </Button>
                <Button onClick={onClickDefault} size="middle" block>
                    {t("generic-data-table.column-visibility.show-default", "Show default")}
                </Button>
                <Button danger onClick={onClickMinimal} size="middle" type="dashed" block>
                    {t("generic-data-table.column-visibility.show-minimal", "Show minimal")}
                </Button>
            </Space.Compact>
        </>
    )
}

TableVisibilityConfigurationDialog.propTypes = {
    treeData: PropTypes.array, // Array of objects with keys: key, title, checked
    onCheck: PropTypes.func, // Function to handle check event, receives checkedKeys
    onClickAll: PropTypes.func, // Function to handle click on "Show all" button
    onClickDefault: PropTypes.func, // Function to handle click on "Show default" button
    onClickMinimal: PropTypes.func, // Function to handle click on "Show minimal" button
}

export const TablePinConfigurationDialog = ({ pinData, handleOnPin, handlePinReset }) => {
    const t = useT()

    const localColumns = [
        {
            title: "Pin Left",
            dataIndex: "left",
            key: "PinConfLeft",
            width: "0.8rem",
            render: (_, record) => pinLeftButton(record),
        },
        {
            title: "Edit Pin",
            dataIndex: "edit",
            key: "PinConfEdit",
            width: "0.8rem",
            render: (_, record) => editButton(record),
        },
        {
            title: "Pin Right",
            dataIndex: "right",
            key: "PinConfRight",
            width: "0.8rem",
            render: (_, record) => pinRightButton(record),
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "PinConfName",
            width: "8rem",
        },
    ]
    
    let reorderedPinData = pinData.sort( (a,b) => a?.preOrder > b?.preOrder ? 1 : a?.preOrder < b?.preOrder ? -1 : 0)

    const editButton = (record) => {
        if (record.fixed) {
            return <Button block type="text" icon={<PushpinFilled />} onClick={() => onPinClick(record)}></Button>
        }
        return (
            <>
                <Button block type="text" icon={<PushpinOutlined />} onClick={() => onPinClick(record)}></Button>
            </>
        )
    }

    const pinLeftButton = (record) => {
        if (record.fixed === "left") {
            return <Button block type="text" icon={<CaretLeftFilled />} onClick={() => onPinSide(record, "left")}></Button>
        }
        return <Button block type="text" icon={<LeftOutlined />} onClick={() => onPinSide(record, "left")}></Button>
    }

    const pinRightButton = (record) => {
        if (record.fixed === "right") {
            return <Button block type="text" icon={<CaretRightFilled />} onClick={() => onPinSide(record, "right")}></Button>
        }
        return <Button block type="text" icon={<RightOutlined />} onClick={() => onPinSide(record, "right")}></Button>
    }

    const onPinSide = (record, side) => {
        let entry = pinData.find((item) => item.key === record.key)
        if (entry.fixed === side) {
            entry.fixed = undefined
            handleOnPin(entry.key, undefined)
        } else {
            entry.fixed = side
            handleOnPin(entry.key, side)
        }
    }

    const onPinClick = (record) => {
        if (record.fixed === undefined || record.fixed === null) {
            onPinSide(record, "left")
        }else if(record.fixed === "left") {
           onPinSide(record, undefined)
        }else if(record.fixed === "right") {
                onPinSide(record, undefined)
        }
    }

    return (
        <>
            <ConfigProvider
                theme={{
                    components: {
                        Table: {
                            cellFontSizeSM: 14,
                            cellPaddingBlockSM: 2,
                            cellPaddingInlineSM: 2
                        },
                    },
                }}>
                <Table
                    scroll={{ y: 310 }}
                    size="small"
                    columns={localColumns}
                    dataSource={reorderedPinData}
                    bordered={false}
                    showHeader={false}
                    showFooter={false}
                    pagination={false}
                />
                <Button block danger type="dashed" onClick={() => handlePinReset()} size="middle">
                    {t("generic-data-table.column-settings.column-pin.reset", "Release all pinned columns")}
                </Button>
            </ConfigProvider>
        </>
    )
}

TablePinConfigurationDialog.propTypes = {
    pinData: PropTypes.array, // Array of objects with keys: key, title, fixed
    handleOnPin: PropTypes.func, // Function to handle pin event, receives key and side
    handlePinReset: PropTypes.func, // Function to handle reset events
}
