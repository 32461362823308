import {Modal} from "../../common/slds";
import {Formik} from "formik";
import {Log} from "../../common/log";
import {Form} from "../../common/ui/form/formik";
import {CancelButtonField, FormActions, SldsInputField, SubmitButtonField} from "../../common/ui/form/formElements";
import * as React from "react";
import {useMutation} from "@apollo/client";
import {MUTATE_CREATE_ACTIVATION_GROUP} from "../queries";

const AddActivationGroupDialog = (props) => {
    let {isOpen, onRequestClose} = props;

    const [createActivationGroup] = useMutation(MUTATE_CREATE_ACTIVATION_GROUP, {
        onCompleted: (data) => {props.onAdded && props.onAdded(data.createActivationGroup.id)}
    });

    return <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
        <Formik
            initialValues={{}}
            onSubmit={(values, actions) => {
                createActivationGroup({
                    variables: {
                        input: {
                            Nr: values.nr,
                            DeviceIds: []
                        }
                    }
                }).then(() => {
                    onRequestClose();
                }, (e) => {
                    actions.setFieldError("global", e);
                    Log.Error("Failed to create Activation Group", e);
                }).finally(() => {
                    actions.setSubmitting(false);
                })
            }}>
            <Form>
                <SldsInputField name={"nr"} label={"Name"} placeholder={"Name"} required={true} autoFocus={true}/>
                <FormActions>
                    <SubmitButtonField>Create</SubmitButtonField>
                    <CancelButtonField onClick={onRequestClose}/>
                </FormActions>
            </Form>
        </Formik>
    </Modal>;
};

export default AddActivationGroupDialog;