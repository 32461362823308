import L from 'leaflet';



const PinIcon = L.Icon.extend({
    options: {
        iconUrl: '/static/img/icons/map/017-pin-6.svg',
        shadowUrl: '/static/img/icons/map/marker-shadow.png',
        iconRetinaUrl: null,
        popupAnchor: [0, -35],

        iconSize: [40, 40],
        iconAnchor: [20, 40],

        shadowSize: [40, 40],
        shadowAnchor: [12, 40],

    }
});

const redIcon = new PinIcon({
    iconUrl: '/static/img/icons/map/017-pin-6.svg'
});


export {redIcon};