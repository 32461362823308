import React from "react"
import * as slds from '@salesforce/design-system-react';
import PropTypes from "prop-types"

/*
The Top Navigation gives access to the module browser
as well as provides module specific navigation

Modules are separate react applications for a specific usecae
example modules are: app (IoT applications), wmbusapi, configuration (for the whole dashboard)
 */
const TopNavigation = (props) => {
    const {moduleName, secondary, tertiary} = props;

    return <slds.GlobalNavigationBar>
        <slds.GlobalNavigationBarRegion region="primary">
            <div className="slds-context-bar__app-name slds-align-middle">{moduleName}</div>
        </slds.GlobalNavigationBarRegion>
        <slds.GlobalNavigationBarRegion region="secondary" navigation>
            {secondary ? secondary : null}
        </slds.GlobalNavigationBarRegion>
        <slds.GlobalNavigationBarRegion region="tertiary">
            {tertiary ? tertiary : null}

        </slds.GlobalNavigationBarRegion>
    </slds.GlobalNavigationBar>
};

TopNavigation.propTypes = {
    moduleName: PropTypes.string.isRequired,
    secondary: PropTypes.any, // List of: TopNavigationLink, more to come ...
    tertiary: PropTypes.any, // List of: TopNavigationLink, more to come ...
};

export default TopNavigation