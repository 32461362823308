import React, {Fragment, useState} from "react";
import {HasPermission} from "../common/ui/permissions";
import {Modal} from "../common/slds";
import classNames from "classnames";
import PropTypes from "prop-types";
import {matchPath, NavLink, useLocation} from "react-router-dom";
import {CancelButtonField, FormActions, SldsButton, SldsButtonGroup, SldsInputField, SubmitButtonField} from "../common/ui/form/formElements";
import {FieldArray, Form, Formik} from "formik";
import {Log} from "../common/log";
import Button from "../common/slds/buttons/button";

const NavigationEditForm = (props) => {
    const {navigation, onSubmit, onCancel} = props;

    const location = useLocation();
    const [editNavEntryIdx, setEditNavEntryIdx] = useState(null);

    return <Formik
        initialValues={{
            navEntries: navigation || []
        }}
        enableReinitialize={true}
        onSubmit={(values, actions) => {
            Log.Debug("VerticalNavigation.onSubmit", values);
            if (onSubmit) {
                onSubmit(values, actions).then(() => {
                    actions.setSubmitting(false);
                }).catch(() => {
                    actions.setSubmitting(false);
                });
            }
        }}
    >
        {({values, setFieldValue, submitForm}) => {
            return <Form>
                <div className="slds-grid slds-grid--vertical slds-navigation-list--vertical">
                    <div className="slds-grid">
                        <h2 className="slds-text-title--caps slds-p-top--medium slds-p-left--small" id="entity-header">Navigation</h2>
                    </div>
                    <FormActions>
                        <SubmitButtonField>Save</SubmitButtonField>
                        <CancelButtonField onClick={() => onCancel()}/>
                    </FormActions>
                    <FieldArray name={"navEntries"}>{(arrayHelpers) => {
                        return <React.Fragment>
                            <ul className="slds-m-top--small">
                                <Modal isOpen={editNavEntryIdx !== null} onRequestClose={() => setEditNavEntryIdx(null)}
                                       footer={<Button iconName={"close"} onClick={() => setEditNavEntryIdx(null)}>Close</Button>}>
                                    <SldsInputField name={`navEntries.${editNavEntryIdx}.label`} label={"Label"}/>
                                    <SldsInputField name={`navEntries.${editNavEntryIdx}.url`} label={"Url"}/>
                                    <SldsInputField name={`navEntries.${editNavEntryIdx}.role`} label={"Role"}/>
                                </Modal>
                                {
                                    values.navEntries.map((nav, idx) => {
                                        return <li key={idx} className={classNames({"slds-is-active": matchPath({path: nav.url}, location.pathname, )})}>
                                            <div className="slds-grid">
                                                <HasPermission role={nav.role}> <NavLink to={nav.url || ""} activeClassName="slds-is-active"
                                                                                         className="slds-navigation-list--vertical__action slds-text-link--reset slds-grow"
                                                                                         aria-describedby="entity-header">
                                                    {nav.label}
                                                </NavLink></HasPermission>

                                                <SldsButtonGroup className="slds-col_bump-left slds-shrink">
                                                    <SldsButton iconCategory={"utility"} iconName={"arrowup"} iconVariant={"border"} variant={"icon"} iconSize={"small"} disabled={idx === 0} onClick={() => arrayHelpers.move(idx, idx - 1)}/>
                                                    <SldsButton iconCategory={"utility"} iconName={"arrowdown"} iconVariant={"border"} variant={"icon"} iconSize={"small"} disabled={idx === values.navEntries.length - 1} onClick={() => arrayHelpers.move(idx, idx + 1)}/>
                                                    <SldsButton iconCategory={"utility"} iconName={"settings"} iconVariant={"border"} variant={"icon"} iconSize={"small"} onClick={() => setEditNavEntryIdx(idx)}/>
                                                    <SldsButton className={"slds-button_icon-error"} iconCategory={"utility"} iconName={"delete"} iconSize={"small"} iconVariant={"border-filled"} variant={"icon"} onClick={() => arrayHelpers.remove(idx)}/>
                                                </SldsButtonGroup>
                                            </div>
                                        </li>
                                    })
                                }

                            </ul>
                            <Fragment>
                                <Button className="slds-m-top--small" iconCategory={"utility"} iconName={"add"} iconPosition={"left"} label={"New Entry"} onClick={() => arrayHelpers.push({label: "New Page"})}/>
                                <Button variant="destructive" className="slds-m-top--small" onClick={() => {
                                    setFieldValue("navEntries", [], false);
                                    void submitForm();
                                }} iconCategory={"utility"} iconName={"undo"} iconPosition={"left"}>Restore Default</Button>
                            </Fragment>
                        </React.Fragment>
                    }}</FieldArray>

                </div>
            </Form>
        }}
    </Formik>
};

NavigationEditForm.propTypes = {
    navigation: PropTypes.any,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default NavigationEditForm