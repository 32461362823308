import React, { createContext, useState } from "react";
import { Log } from "../../common/log"

export const EditAnnouncementItemContext = createContext({
    editAnnouncement: () => {
        Log.Debug("EditAnnouncementItemContext", "editItem not set")
    },
    setEditAnnouncement: () => {
        Log.Debug("EditAnnouncementItemContext", "setEditItem not set")
    },
    isCopy: false,
    setIsCopy: () => {
        Log.Debug("EditAnnouncementItemContext", "setIsCopy not set")
    },
})


export const EditAnnouncementItemProvider = ({ children }) => {
    const [editAnnouncement, setEditAnnouncement] = useState(null)
    const [isCopy, setIsCopy] = useState(false)


    const set = (notification) => {
        Log.Debug("EditAnnouncementItemContext", "set", notification)
        setEditAnnouncement(notification)
        if (!notification) {
            setIsCopy(false)
        }
    }

    return <EditAnnouncementItemContext.Provider
      value={{ editAnnouncement, setEditAnnouncement: set, isCopy, setIsCopy }}>
        {children}
    </EditAnnouncementItemContext.Provider>
}
