import React from "react";
import PropTypes from "prop-types";
import classNames from 'classnames';

export default function Spinner(props) {
    const {type, className} = props;
    let isFixed, withContainer, isInline = false;
    switch (type) {
    case "inlined":
        isInline = true;
        break;
    case "with-container":
        withContainer = true;
        break;
    case "fixed-container":
        withContainer = true;
        isFixed = true;
        break;
    case "inlined-container":
        withContainer = true;
        isInline = true;
        break;
    }

    if (withContainer) {
        return <div className={classNames("slds-spinner_container", {"slds-spinner_inline": isInline, "slds-is-fixed": isFixed}, className)}>
            <div role="status" className="slds-spinner slds-spinner_small slds-spinner_brand slds-spinner_delayed">
                <span className="slds-assistive-text">Loading</span>
                <div className="slds-spinner__dot-a"></div>
                <div className="slds-spinner__dot-b"></div>
            </div>
        </div>;
    } else {
        return <div role="status" className={classNames("slds-spinner slds-spinner_small", {"slds-spinner_inline": isInline, "slds-is-fixed": isFixed}, className)}>
            <span className="slds-assistive-text">Loading</span>
            <div className="slds-spinner__dot-a"></div>
            <div className="slds-spinner__dot-b"></div>
        </div>;
    }
}

Spinner.defaultProps= {
    type: "inlined-container",
};

Spinner.propTypes = {
    type: PropTypes.oneOf(["inlined", "without-container", "with-container", "fixed-container", "inlined-container"]),
    className: PropTypes.string,
};

