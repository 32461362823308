import * as log from "../../common/log";
import WmbusTelegramDetails from "../../app/wmbus/wmbusTelegram";
import {Accordion, AccordionPanel} from "../../common/ui/accordion";
import Json from "../../common/ui/json";
import React from "react";
import PropTypes from "prop-types";

export default function WmbusDetailComponent(props) {
    const {data} = props;
    log.Debug("Detail data:", data);
    const parseError = data.parseError || data?.data?.parseError || data?.data?.mbus?.parseError;
    const mbus = data?.data?.mbus || {};

    return <>
        <div className="slds-m-top--small">
            <WmbusTelegramDetails mbusJson={mbus} parseError={parseError}/>
            <Accordion>
                <AccordionPanel summary="wMBus Data (Json)" id="wmbus-json-panel">
                    <Json json={mbus}/>
                </AccordionPanel>
            </Accordion>
        </div>
    </>

}

WmbusDetailComponent.propTypes = {
    data: PropTypes.object.isRequired,
};