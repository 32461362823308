import gql from "graphql-tag";

export const QUERY_APPLICATION = gql`
    query($appId: ID!){
        appInstance(id: $appId) {
            id
            appId
            name
            type
            logo
            navigationRaw
        }
    }
`;
export const MUTATE_APPLICATION = gql`
    mutation($appId: ID!, $input: AppInstanceInput!){
        updateApplication(id: $appId, input: $input) {
            id
            appId
            name
            type
            navigationRaw
        }
    }
`;