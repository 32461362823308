import gql from "graphql-tag";


export const QUERY_DASHBOARDS = gql`
    query($appId: ID!){
        dashboards: getDashboards(appId: $appId) {
            id
            appId
            name
            layoutRaw
            widgetsRaw
        }
    }
`;
export const QUERY_DASHBOARD = gql`
    query($dashboardId: ID!){
        dashboard: getDashboard(id: $dashboardId) {
            id
            appId
            name
            layoutRaw
            widgetsRaw
        }
    }
`;

export const MUTATE_CREATE_DASHBOARD = gql`
    mutation createDashboard($appId: ID!, $input: DashboardInputType) {
        createDashboard(appId: $appId, input: $input) {
            id
            name
            appId
            layoutRaw
            widgetsRaw
        }
    }
`;

export const MUTATE_UPDATE_DASHBOARD = gql`
    mutation updateDashboard($dashboardId: ID!, $input: DashboardInputType) {
        updateDashboard(id: $dashboardId, input: $input) {
            id
            name
            appId
            layoutRaw
            widgetsRaw
        }
    }
`;
