//localStorage (set key i18nextLng=LANGUAGE)
import React from "react";
import i18n from "i18next";
import {Log} from "../common/log";
import gql from "graphql-tag";
import {useQuery} from "@apollo/client";
import { includes } from "lodash";

const QUERY_AVAILABLE_LANGUES = gql`query {getAvailableLanguages}`;

export const LanguageSelector = () => {
    Log.Info("Selected language: ", i18n.language )
    const gqlResult = useQuery(QUERY_AVAILABLE_LANGUES);
    let selectedValue = i18n.language.substring(0, 2) // for example "en-US" -> "en"

    if (!gqlResult.data?.getAvailableLanguages) {
        const fallbackSelection = selectedValue === "de" ? "de" : "en"
        return <select
            id="dropdown"
            onChange={(e) => i18n.changeLanguage(e.target.value)}
            value={fallbackSelection}>
            <option value="de">DE</option>
            <option value="en">EN</option>
        </select>
    }

    // fallback to "en" if selected language is not available
    if(Array.isArray(gqlResult.data?.getAvailableLanguages) && !includes(gqlResult.data?.getAvailableLanguages, selectedValue)) {
       selectedValue = "en"
    }
    return <select
        id="dropdown"
        onChange={(e) => i18n.changeLanguage(e.target.value)}
        value={selectedValue}
        >
        {gqlResult.data?.getAvailableLanguages?.map((l:string) => {
          return <option key={l} value={l}>{l.toUpperCase()}</option>
        })}
    </select>
}
