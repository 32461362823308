import * as React from "react";
import {useContext} from "react";
import gql from "graphql-tag";
import * as log from "../../common/log";
import {useMutation, useQuery} from "@apollo/client";
import {AppContext} from "../../app/appPage";
import {useGraphqlLoadingComponent} from "../../common/graphql";
import {decodeToken, useAuthContext} from "../../common/context/authContext";
import ApiDocumentation from "./apiDocumentation";
import {useT} from "../../common/i18n";
import {useNotificationContext} from "../../notifications/notificationContext";
import {FeatureContext} from "../../common/context/featureContext";
import {FeatureNotEnabled} from "../../common/featureNotEnabled";
import {CreateApiCredentials} from "../../components/organisation/createApiCredentials";
import {ApiCredentials} from "../../components/organisation/apiCredentials";
import {CardBody} from "../../common/slds/cards/card";
import {Card} from "@salesforce/design-system-react";

// TODO: Limit suggestions to roles usable by enabled features
const ROLES = [
    {
        name: "network-server",
        displayName: "Network Server",
        requires: "lobaro-device-gateway",
    },
    {
        name: "org-admin",
        displayName: "Organisation Admin",
        requires: null,
    },
    {
        name: "device-admin",
        displayName: "Device Admin",
        requires: "lobaro-device-gateway",
    },
    {
        name:  "lansen-mqtt-input",
        displayName: "Lansen Mqtt",
        requires: "lobaro-device-gateway",
    },
    {
        name:  "lobaro-mqtt-input",
        displayName: "Lobaro Mqtt",
        requires: "lobaro-device-gateway",
    },
    {
        name: "suborg",
        displayName: "Read-only access to sub-organisations",
        requires: null,
    },
    {
        name: "mbus-parser",
        displayName: "Access to wMBus parser API only",
        requires: "wmbus-api",
    }
];

function getRole(name) {
    return ROLES.find(it => it.name === name) || {name: name, displayName: name + "*"};
}

const MUTATION_CREATE_API_TOKEN = gql`
    mutation createToken($apiToken: ApiTokenInput!) {
        createApiToken(input: $apiToken) {
            id
            name
            token
            organisationId
        }
    }
`;


const MUTATION_DELETE_API_TOKEN = gql`
    mutation createToken($tokenId: ID!) {
        deleteApiToken(id: $tokenId) {
            id
            name
            token
        }
    }
`;

const QUERY_API_TOKENS = gql`
    query apiTokens($appId: ID, $orgId: ID) {
        apiTokenList(appId: $appId, organisationId: $orgId) {
            id
            appId
            organisationId
            name
            token
            password
        }
    }
`;

const refetchApiTokens = (orgId, appId) => ({
    query: QUERY_API_TOKENS,
    variables: {
        appId: appId,
        orgId: orgId,
    }
});


export default function RestApiTab() {
    const license = useContext(FeatureContext)
    if (!(license.validateFeatures("lobaro-device-gateway") || license.validateFeatures("rest-api") || license.validateFeatures("wmbus-api"))) {
        return <FeatureNotEnabled/>
    }
    const t = useT();
    const app = useContext(AppContext);
    const auth = useAuthContext();
    const orgId = auth.organisationId();
    const notify = useNotificationContext();

    const apiTokensResult = useQuery(QUERY_API_TOKENS, {
        variables: {
            orgId: orgId,
            appId: app.id,
        }
    });

    const [createTokenMutation] = useMutation(MUTATION_CREATE_API_TOKEN, {
        refetchQueries: [refetchApiTokens(orgId, app.id)],
    });
    const [deleteTokenMutation] = useMutation(MUTATION_DELETE_API_TOKEN, {
        refetchQueries: [refetchApiTokens(orgId, app.id)],
    });

    const loading = useGraphqlLoadingComponent(apiTokensResult);
    if (loading) {
        return loading;
    }

    const {data} = apiTokensResult;
    let apiTokens = data.apiTokenList;
    apiTokens = apiTokens.map((tok) => {
        return {
            ...tok,
            tokenParsed: decodeToken(tok.token),
        };
    });

    function getFeaturedRole(name) {
        const res = ROLES.find(it => it.name === name) || {name: name, displayName: name + "*"};
        if (res && res.requires && !license.validateFeatures(res.requires)) {
            return undefined;
        }
        return res
    }


    function deleteToken(tok) {

        if (!window.confirm(t("integrations.rest.confirm.delete-token", "Delete API Token '{{name}}' with id {{id}}?", {
            name: tok.name,
            id: tok.id
        }))) {
            return;
        }

        deleteTokenMutation({
            variables: {
                tokenId: tok.id
            }
        }).catch((err) => {
            log.Error("Failed to delete api token:", err);
            notify.error(t("integrations.rest.notify.delete-token-failed", "Failed to delete api token."));
        });
    }


    return <div className="slds-m-horizontal--x-small">
        <ApiDocumentation/>
        <Card heading={<h2
            className="slds-text-heading--medium">{t("integrations.rest.api-credentials", "API Credentials")}</h2>}>
            <CardBody inner={true}>
                {
                    apiTokens.map((tok) => {
                        return <ApiCredentials tok={tok} deleteToken={deleteToken} key={tok.id} getRole={getRole}/>;
                    })
                }
                <CreateApiCredentials roles={ROLES} getRole={getFeaturedRole} handleFormikSubmit={(values, actions) => {
                    createTokenMutation({
                        variables: {
                            apiToken: {
                                appId: app.id,
                                organisationId: orgId,
                                name: values.name,
                                roles: values.roles || [],
                                generatePassword: values.type === "basic-auth",
                            }
                        }
                    })
                        .catch((err) => {
                            // Error will be logged to console by notify.error()
                            notify.error(t("integrations.rest.notify.create-token-failed", "Failed to create token. Check console for details."), err);
                        })
                        .finally(() => {
                            actions.setSubmitting(false);
                        });
                }}/>
            </CardBody>
        </Card>
    </div>;
}

RestApiTab.propTypes = {};