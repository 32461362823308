import * as React from "react"
import { Button } from "@salesforce/design-system-react"
import gql from "graphql-tag"
import * as log from "../../../common/log"
import EditDeviceForm from "./editDeviceForm"
import ParserForm from "../../../common/ui/parserForm"
import { DangerZone } from "../../../common/ui/dangerZone"
import { useAuthContext } from "../../../common/context/authContext"
import { useMutation, useQuery } from "@apollo/client"
import { useGraphqlLoadingComponent } from "../../../common/graphql"
import { NotifyUser } from "../../../common/userNotification"
import { CustomPropTypes } from "../../../common/propTypes/customPropTypes"
import { Field, Formik } from "formik"
import { DateTimePicker, FormActions, SldsFormElementCompound, SldsFormElementRow, SubmitButtonField } from "../../../common/ui/form/formElements"
import { Form } from "../../../common/ui/form/formik"
import CodeEditor from "../../../common/ui/codeEditor"
import { backendUrl } from "../../../common/helper"
import authenticatedFetch from "../../../common/authenticatedFetch"
import { useT } from "../../../common/i18n"
import Roles from "../../../model/roles"
import { useParams } from "react-router"
import { useNavigate } from "react-router-dom"
import {useContext} from "react";
import {FeatureContext} from "../../../common/context/featureContext";
import {FeatureNotEnabled} from "../../../common/featureNotEnabled";

const MUTATION_DELETE_DEVICE = gql`
    mutation deleteDevice($devId: ID!) {
        deleteDeviceById(id:$devId) {
            id
            name
        }
    }
`;

const MUTATION_UPDATE_DEVICE = gql`
    mutation updateDevice($devId: ID!, $device: DeviceInput!) {
        updateDevice(id: $devId, input: $device) {
            id
            appId
            name
            addr
            description
            addr
            parserCode
            parserEnabled
            serial
            deviceType {
                id
                name
                displayName
                parserCode
            }
            organisation {
                id
                name
            }
            tags
            certEnforcesDtls
        }
    }
`;

const QUERY_DEVICE = gql`
    query device($devId: ID!) {
        device(id: $devId) {
            id
            name
            description
            serial
            addr
            parserCode
            parserEnabled
            propertiesRaw
            tags
            certEnforcesDtls
            location {
                lat
                lon
            }
            organisation{
                id
                name
            }
            deviceType {
                id
                name
                displayName
                parserCode
            }
            app {
                id
                name
                apiTokens {
                    id
                    name
                    token
                }
            }
        }
        # For deviceSettings page
        deviceTypes {
            id
            name
            displayName
        }
        sensorData(page: {limit: 1, offset: 0}) {
            id
            dataRaw
        }
    }
`;


const MUTATION_RESEND_DEVICE_PARSED_DATA = gql`
    mutation resendDeviceParsedData($deviceIds: [ID!], $start:  DateTime!, $end:  DateTime!) {
        resendDeviceParsedData(deviceIds: $deviceIds, start: $start, end: $end)
    }
`;

export default function DeviceSettings(props) {
    const license = useContext(FeatureContext)
    if (!license.validateFeatures("lobaro-device-gateway")) {
        return <FeatureNotEnabled/>
    }
    const t = useT();
    const auth = useAuthContext();
    const navigate = useNavigate();
    const devId = useParams().deviceId;

    const deviceResult = useQuery(QUERY_DEVICE, {
        fetchPolicy: 'cache-first',
        variables: {devId: devId},
    });

    const [mutationUpdateDevice] = useMutation(MUTATION_UPDATE_DEVICE, {
        variables: {
            devId: devId
        }
    });
    const [mutationDeleteDevice] = useMutation(MUTATION_DELETE_DEVICE, {
        variables: {devId: devId},
    });
    const [mutationResendParsedData] = useMutation(MUTATION_RESEND_DEVICE_PARSED_DATA, {
        variables: {deviceIds: [devId]},
    })

    const [mutationDeleteParsedDataForDevice] = useMutation(gql`
        mutation deleteParsedDataForDevice($devId: ID!) {
            deleteParsedDataForDevice(id:$devId)
        }
    `, {
        variables: {devId: devId},
    });
    const [mutationDeleteSensorDataForDevice] = useMutation(gql`
        mutation deleteSensorDataForDevice($devId: ID!) {
            deleteSensorDataForDevice(id:$devId)
        }
    `, {
        variables: {devId: devId},
    });

    const loading = useGraphqlLoadingComponent(deviceResult);
    if (loading) {
        return loading;
    }


    const {device, deviceTypes, sensorData} = deviceResult.data;

    function updateDevice(updatedDevice) {
        const input = {
            name: updatedDevice.name,
            description: updatedDevice.description,
            deviceTypeId: updatedDevice.deviceType.id,
            serial: updatedDevice.serial,
            appId: updatedDevice.app?.id || 0, // 0 to clear the app ID, null would be ignored!
            tags: updatedDevice.tags,
            certEnforcesDtls: updatedDevice.certEnforcesDtls,
            organisationId: updatedDevice.organisation?.id || 0
        }
        if (device.addr !== updatedDevice.addr) {
            input.addr = updatedDevice.addr;
        }
        const mut = mutationUpdateDevice({
            variables: {
                device: input
            }
        });

        log.Debug("Update device:", device);
        return mut;
    }

    function deleteDevice() {
        if (window.confirm(`Delete device "${device.name || "Device"}" [${devId}] with all it's data?`)) {

            mutationDeleteDevice().then(() => {
                navigate("..");
            }, (err) => {
                NotifyUser.Error("Failed to delete device:", err);
            });
        }
    }

    function deleteDeviceData() {
        if (window.confirm(t("device.settings.confirm.delete-all", "Delete all Data for Device: {{name}} [id: {{id}}; Address: {{address}}]?", {
            name: device.name,
            id: devId,
            address: device.addr
        }))) {
            mutationDeleteParsedDataForDevice().then(() => {
                mutationDeleteSensorDataForDevice().then(() => {
                    log.Info("Successfully deleted Device Data for", device.name)
                }, (err) => {
                    NotifyUser.Error(t("device.settings.notify.failed-to-delete", "Failed to delete device sensor data:"), err)
                })
            }, (err) => {
                NotifyUser.Error(t("device.settings.notify.failed-to-delete-parsed-data", "Failed to delete device parsed data:"), err);
            })
        }
    }

    function saveParser(values, actions) {
        const {parserCode, parserSelection} = values;
        const parserEnabled = (parserSelection !== "default");

        mutationUpdateDevice({
            mutation: MUTATION_UPDATE_DEVICE,
            variables: {
                device: {
                    parserCode: parserCode,
                    parserEnabled: parserEnabled,
                }
            }
        }).then(() => {
            log.Debug("Updated parser:", device);
        }, (err) => {
            NotifyUser.Error(t("device.settings.notify.failed-to-save-parser", "Failed to save parser."), err);
        }).finally(() => {
            actions.setSubmitting(false);
        });
    }

    const inputCode = (sensorData[0] || {}).dataRaw || t("device.settings.parser-input-example", "{\"info\": \"No data received yet\"}");

    return <div className="slds-m-left--x-small">
        <EditDeviceForm device={device}
                        deviceTypes={deviceTypes}
                        onUpdate={(values) => updateDevice(values)}
        />
        {auth.hasRole(Roles.ADMIN, Roles.ORG_ADMIN, Roles.DEVICE_ADMIN) &&
            <ParserForm parserCode={device.parserCode}
                        defaultParserCode={device.deviceType.parserCode}
                        hasParserSelection={true}
                        inputCode={inputCode}
                        parserSelection={device.parserEnabled ? "custom" : "default"}
                        saveParser={(values, actions) => saveParser(values, actions)}
            />}

        {auth.hasRole(Roles.ADMIN, Roles.ORG_ADMIN, Roles.DEVICE_ADMIN) && <>
            <div
                className="slds-text-heading--medium slds-m-vertical--small">{t("device.settings.simulate-uplink", "Simulate Uplink")}</div>
            <Formik
                initialValues={{payload: `{}`}}
                onSubmit={(values) => {
                    authenticatedFetch(`${backendUrl()}/api/devices/${device.id}/uplink`, {
                        method: "POST",
                        body: values.payload,
                    }).then(() => {
                        NotifyUser.Info(t("device.settings.payload-sent", "Payload was sent to device"));
                        //refetch data,
                        deviceResult.refetch({devId: devId})
                            .then(r => {
                                log.Debug("device.settings", "refetching device data", "result", r)
                            })
                    }).catch(err => {
                        NotifyUser.Error(t("device.settings.payload-sent-failed", "Failed to send payload"), err);
                    });
                }}>{(formik) => <Form>
                <div className="slds-size_3-of-6">
                    <CodeEditor id={"editor-parser"}
                                defaultValue={formik.values["payload"]}
                                minLines={15}
                                maxLines={30}
                                value={formik.values["payload"]}
                                onChange={(val) => {
                                    formik.setFieldValue("payload", val);
                                }}/>
                    <FormActions>
                        <SubmitButtonField
                            iconName={"send"}>{t("device.settings.send-data", "Send data")}</SubmitButtonField>
                    </FormActions>
                </div>
            </Form>}
            </Formik>
        </>}


        <DangerZone>
            <div>
                <h1>Resend Data over Integration</h1>
                <Formik
                    initialValues={{
                        until: new Date(Date.now()),
                        from: new Date(Date.now()),
                    }}

                    onSubmit={(values, actions) => {
                        log.Debug("Submit: ", values, actions);
                        mutationResendParsedData({
                            variables: {
                                end: values.until,
                                start: values.from,
                            }
                        }).then(() => {
                            Log.Debug("Send")
                        }, (err) => {
                            NotifyUser.Error("Failed to resend:", err);
                        });

                    }}
                    // onReset={(values, actions) => {
                    //     log.Debug("Clearing Filters");
                    //     filters.setFilter([])
                    // }}
                >{() => <Form>
                    <SldsFormElementCompound>
                        <SldsFormElementRow>
                            <Field component={DateTimePicker} submitFormOnChange={false}
                                   label={t("filter-panel.from", "From")} name="from"/>
                            <Field component={DateTimePicker} submitFormOnChange={false}
                                   label={t("filter-panel.until", "Until")} name="until"/>
                        </SldsFormElementRow>
                    </SldsFormElementCompound>
                    <FormActions>
                        <SubmitButtonField iconName={"play"}>Run Replay</SubmitButtonField>
                    </FormActions>
                </Form>
                }</Formik>
            </div>
            <br/>
            <br/>
            <Button type={"button"} variant={"destructive"}
                    onClick={() => deleteDeviceData(props)}>{t("device.settings.delete-device-data", "Delete Device Data")}</Button>
            {auth.hasRole(Roles.ADMIN) ? <Button type={"button"} variant={"destructive"}
                                                 onClick={() => deleteDevice(props)}>{t("device.settings.delete-device", "Delete Device")}</Button> : null}
        </DangerZone>
    </div>;
}

DeviceSettings.propTypes = {
    // From GraphQL result
    client: CustomPropTypes.deprecated("Not used anymore"),
    data: CustomPropTypes.deprecated("Not used anymore"),
};

