import {Formik} from "formik";
import React, {useState} from "react";
import {FormActions, SldsInputField, SubmitButtonField} from "../common/ui/form/formElements";
import {Form} from "../common/ui/form/formik";
import {useMutation} from "@apollo/client";
import gql from "graphql-tag";
import {useNotificationContext} from "../notifications/notificationContext";
import PlatformLogo from "../PlatformLogo";

export default function RestorePasswordPage () {
    const [registrationFailed] = useState(false);

    const [mutateRestorePassword] = useMutation(gql`
        mutation ($login: String!) {
            sendForgotPasswordEmail(login: $login)
        }`, {}
    );

    const notify = useNotificationContext();

    return <>
        <div className="slds-grid slds-grid--align-center">
            <div className="slds-p-top--large">
                <div className="slds-p-around--medium">
                    <PlatformLogo className="slds-global-header__logo slds-m-bottom--medium" />
                    <p className="slds-text-heading--medium">Restore Password</p>
                </div>
                <div className="slds-card slds-p-around--medium">
                    {registrationFailed &&
                    <div style={{color: "rgb(194, 57, 52)"}}>
                        Registration failed. please try again.
                    </div>
                    }
                    <Formik
                        initialValues={{}}
                        onSubmit={(values) => {
                            mutateRestorePassword({
                                variables: {
                                    login: values.login,
                                }
                            }).then(() => {
                                notify.info("Password recovery mail sent.");
                            }).catch((err) => {
                                notify.error("Failed to send password recovery mail.", err);
                            })
                        }}>
                        <Form>
                            <SldsInputField name={"login"} label={"Username / Login"} required={false}/>
                            <FormActions>
                                <SubmitButtonField iconName={""} variant="brand" >Recover password</SubmitButtonField>
                            </FormActions>
                        </Form>
                    </Formik>
                </div>
            </div>
        </div>
    </>
}