import PropTypes from "prop-types";
import React, {useState} from "react";
import {CancelButtonField, FormActions, SldsCheckboxField, SldsInputField, SubmitButtonField} from "../../../common/ui/form/formElements";
import {LoadingWidget} from "./widgets/loadingWidget";
import {SingleAlarmToastWidget, SingleAlarmToastWidgetSettings} from "./widgets/singleAlarmToastWidget";
import {Modal} from "../../../common/slds";
import {ButtonGroup} from "@salesforce/design-system-react";
import {Log} from "../../../common/log";
import {AlarmPillsWidget, AlarmPillsWidgetSettings} from "./widgets/alarmPillsWidget";
import {DataTableWidget, DataTableWidgetSettings} from "./widgets/dataTableWidget";
import {Form} from "../../../common/ui/form/formik";
import {Formik} from "formik";
import Button from "../../../common/slds/buttons/button";

export function WidgetButton(props) {
    const {iconName, visible, onClick} = props;

    return <Button iconName={iconName} className={"no-drag" + (visible !== false ? "" : " slds-hide")}
                       onClick={onClick}>
    </Button>
}

WidgetButton.propTypes = {
    description: PropTypes.string, // TODO: Render description as tooltip info
    iconName: PropTypes.string,
    visible: PropTypes.bool,
    onClick: PropTypes.func,
};

export const widgetRegistry = {
    "single-alarm-toast": {
        displayName: "Single Alarm Toast",
        widget: SingleAlarmToastWidget,
        settings: SingleAlarmToastWidgetSettings,
    },
    "alarm-pills": {
        displayName: "Alarm Pills",
        widget: AlarmPillsWidget,
        settings: AlarmPillsWidgetSettings,
    },
    "data-table": {
        displayName: "Data Table",
        widget: DataTableWidget,
        settings: DataTableWidgetSettings,
    },
};

const WidgetSettingsForm = (props) => {
    const {settings, onSubmit, onClose, children} = props;

    return <Formik initialValues={settings}
                   enableReinitialize={true}
                   onSubmit={(values, actions) => {

                       if (onSubmit) {
                           onSubmit(values, actions);
                       } else {
                           actions.setSubmitting(false);
                           onClose();
                       }

                   }}
    >
        <Form>
            <SldsInputField name={"name"} label={"Name"} placeholder={"Widget Name"}/>
            <SldsCheckboxField name={"hideTitleBar"} inlineLabel={"Hide Title"}/>
            {children}

            <FormActions>
                <SubmitButtonField>Submit</SubmitButtonField>
                <CancelButtonField onClick={() => onClose()}/>
            </FormActions>
        </Form>
    </Formik>
};
WidgetSettingsForm.propTypes = {
    onSubmit: PropTypes.func,
    children: PropTypes.any.isRequired,
    onClose: PropTypes.func.isRequired,
    settings: PropTypes.object.isRequired,
};


/**
 * The Dragable Frame of a Widget.
 * Contains generic UI controls, shared by all Widgets
 */
export const WidgetFrame = (props) => {
    const {widget, onDelete, isReadOnly, onSettingsChanged} = props;

    // TODO Get the height as prop?
    let availableHeightPx = "auto";
    const [settingsOpen, setSettingsOpen] = useState(false);
    let settings = widget.settings || {};

    const {hideTitleBar, name} = settings;

    const widgetComponents = widgetRegistry[widget.type] || {};

    const WidgetComponent = widgetComponents.widget || LoadingWidget;
    const WidgetSettingsComponent = widgetComponents.settings;

    return (
        <div className="lob-shadow--raised slds-card"
             style={{margin: 5, overflow: "hidden", backgroundColor: "#fff"}}
             key={widget.id}>
            <Modal isOpen={settingsOpen} onRequestClose={() => setSettingsOpen(false)}>
                {WidgetSettingsComponent ? <WidgetSettingsForm
                    settings={settings}
                    onClose={() => setSettingsOpen(false)}
                    onSubmit={(values, actions) => {
                        Log.Debug("WidgetSettingsComponent.onSubmit", values);
                        Promise.resolve(onSettingsChanged(values, actions)).then(() => {
                            setSettingsOpen(false);
                        }, (e) => {
                            Log.Error("Failed to save widget settings:", e);
                        }).finally(() => {
                            actions.setSubmitting(false);
                        });
                    }}>
                    <WidgetSettingsComponent settings={settings}/>
                </WidgetSettingsForm> : <div>No Settings</div>}
            </Modal>
            <div className="slds-grid slds-wrap slds-has-dividers--bottom slds-scrollable--y" style={{height: "100%"}}>
                {hideTitleBar && isReadOnly ? null : <div className={"slds-size--1-of-1 slds-item" + (isReadOnly ? "" : " drag")} style={{padding: 8}}>
                    {isReadOnly ? null :
                        <div className="slds-float--right">
                            <ButtonGroup>
                                {WidgetSettingsComponent ? <WidgetButton
                                    description="settings"
                                    visible={true}
                                    iconName="settings"
                                    onClick={() => setSettingsOpen(true)}
                                /> : null}
                                <WidgetButton description="remove"
                                              iconName="delete"
                                              onClick={() => {
                                                  onDelete()
                                              }}
                                />
                            </ButtonGroup>
                        </div>
                    }
                    <div className={"" + (isReadOnly ? "" : " drag")}>
                        {name || "\u00a0"}
                    </div>
                </div>}

                {/* Actual widget content*/}
                <div className="slds-size--1-of-1 slds-is-relative"
                     style={{height: availableHeightPx, padding: 0, border: "red dashed 0px"}}>
                    <WidgetComponent {...props} settings={settings}/>
                    {
                        //WidgetComponent(props)
                        //<LoadingWidget widget={widget}/>
                    }
                </div>
            </div>
        </div>)
};


export const widgetPropType = PropTypes.shape({
    id: PropTypes.string.isRequired,
    col: PropTypes.number.isRequired,
    row: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    settings: PropTypes.object,
});

WidgetFrame.propTypes = {
    widget: widgetPropType.isRequired,
    onDelete: PropTypes.func.isRequired,
    isReadOnly: PropTypes.bool.isRequired,
    onSettingsChanged: PropTypes.func.isRequired,
};