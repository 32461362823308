import React, {useContext} from "react";
import {FeatureContext} from "../../common/context/featureContext";
import {FeatureNotEnabled} from "../../common/featureNotEnabled";

const DeviceDataIndexPage = () => {
    const license = useContext(FeatureContext)
    if (!license.validateFeatures("device-data")) {
        return <FeatureNotEnabled />
    }
    return <div className="slds-p-around--x-small slds-size--1-of-1">
        <h2 className="slds-text-heading--medium slds-p-around--medium"
            id="entity-header">Welcome</h2>
        Please select a device type from the list.
    </div>
}

export default DeviceDataIndexPage