import React from "react";
import PropTypes from "prop-types";

const TopNavigationDropdown = (props) => {
    return <li className="slds-context-bar__item slds-context-bar__dropdown-trigger slds-dropdown-trigger">
        <div className="slds-context-bar__label-action">
            {props.label}
        </div>
        <div className="slds-context-bar__icon-action slds-p-left_none">
            <button className="slds-button slds-button_icon slds-button_icon slds-context-bar__button"
                    aria-haspopup="true" title="Open menu item submenu">
                <svg className="slds-button__icon" aria-hidden="true">
                    <use href="/assets/icons/utility-sprite/svg/symbols.svg#chevrondown"></use>
                </svg>
                <span className="slds-assistive-text">Open menu item submenu</span>
            </button>
        </div>
        <div className="slds-dropdown slds-dropdown_right">
            <ul className="slds-dropdown__list" role="menu">
                <li className="slds-dropdown__header slds-has-divider_top-space" role="separator">
                    <span>{props.label}</span>
                </li>
                {props.children}
            </ul>
        </div>
    </li>
};

TopNavigationDropdown.propTypes = {
    label: PropTypes.string.isRequired,
    children: PropTypes.any,
};

export default TopNavigationDropdown;