import classNames from "classnames";
import {ButtonIcon} from "@salesforce/design-system-react";
import PropTypes from "prop-types";
import React from "react";


export const SldsPill = (props) => {
    const {icon, title, label, error, onRemove, onClick, value} = props;

    return <span className={classNames("slds-pill", {"slds-has-error": error, "slds-pill_link": onClick})}>
        {icon ? <span className="slds-pill__icon_container">
            <span className="slds-icon_container">
                {icon}
            </span>
        </span> : null}

        {onClick ? <a className={classNames("slds-pill__action", {"slds-p-right--x-small": !onRemove})} title={title} onClick={(e) => onClick(value, e)}>
                <span className="slds-pill__label">{label}</span>
            </a>
            :
            <span className={classNames("slds-pill__label", {"slds-p-right--x-small": !onRemove})}>{label}</span>
        }

        {onRemove ? <button className="slds-button slds-button_icon slds-button_icon slds-pill__remove" title="Remove" onClick={(e) => onRemove(value, e)}>
            <ButtonIcon category={"utility"} name={"close"}/>
            <span className="slds-assistive-text">Remove</span>
        </button> : null}
</span>
};

SldsPill.propTypes = {
    value: PropTypes.object, // Returned onClick and onRemove
    icon: PropTypes.element,
    title: PropTypes.string,
    label: PropTypes.string,
    error: PropTypes.bool,
    onRemove: PropTypes.func,
    onClick: PropTypes.func,
};
