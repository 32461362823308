import React from "react";
import PropTypes from "prop-types";
import {classnames} from "../../wrapper/classnames";
import {CustomPropTypes} from "../../propTypes/customPropTypes";

export default function ScopedNotification(props) {
    const {children, className, theme, title} = props;
    return <div className={classnames("slds-scoped-notification slds-media slds-media_center", {
        [`slds-scoped-notification_${theme}`]: theme
    }, className)} role="status">
        <div className="slds-media__figure">
    <span className="slds-icon_container slds-icon-utility-info" title={title}>
      <svg className="slds-icon slds-icon_small slds-icon-text-default" aria-hidden="true">
        <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#info"></use>
      </svg>
      <span className="slds-assistive-text">{title}</span>
    </span>
        </div>
        <div className="slds-media__body">
            {children}
        </div>
    </div>;
}

ScopedNotification.propTypes = {
    children: CustomPropTypes.children.isRequired,
    theme: PropTypes.oneOf(["light", "dark"]),
    title: PropTypes.string,
    className: PropTypes.string,
};