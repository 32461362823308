import React from "react";
import {CustomPropTypes} from "../../propTypes/customPropTypes";
import PropTypes from "prop-types";

export default function DynamicMenu(props) {
    const {children, title, footer, isOpen,
        top, left
    } = props;

    if (!isOpen) {
        return null;
    }

    return <div style={{"position": "relative", display: "inline-block"}}>
        <section aria-describedby="dialog-body-id-15" aria-label="My Favourites" className="slds-popover slds-nubbin_top-left slds-dynamic-menu" role="dialog"
                    style={{
                        position: "absolute",
                        left: left,
                        top: top,
                    }}>
        <div className="slds-popover__body slds-p-horizontal_none" id="dialog-body-id-15">
            <div className="slds-p-vertical_x-small slds-p-horizontal_small">
                <h3 className="slds-dynamic-menu__header slds-m-bottom_x-small" role="presentation">{title}</h3>
                {children}
            </div>
        </div>
        {footer && <footer className="slds-popover__footer">
            {footer}
        </footer>}
    </section>
    </div>
}

DynamicMenu.defaultProps = {
    top: "-0.5rem",
    left: "48px"
};

DynamicMenu.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    children: CustomPropTypes.children,
    title: PropTypes.string,
    footer: CustomPropTypes.children,
    top: PropTypes.string.isRequired,
    left: PropTypes.string.isRequired
};