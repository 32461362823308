import {Log} from "./log";
import {getAuthState} from "./context/authContext";

/**
 *
 * @param resource {string}
 * @param options {RequestInit}
 * @param retry {number}
 * @returns {Promise<T>}
 */
export default function authenticatedFetch(resource, options, retry = 1) {
    const auth = getAuthState();
    if (auth.authenticated) {
        options = options || {};
        options.headers = options.headers || {};
        options.headers["Authorization"] = "Bearer " + auth.token;
    }
    return fetch(resource, options).then(res => {
        if(res.status !== 200) {
            if (res.status === 401 && auth.authenticated && retry > 0) {
                Log.Debug("Got 401 error. Probably need to refresh JWT");
                return auth.refreshTheToken()
                    .then(() => authenticatedFetch(resource, options, retry - 1))
            } else if (res.status === 401 && auth.authenticated && retry > 0) {
                throw new Error("Unauthorized");
            }
            // This suppresses the error body in browser debugger
            //throw new Error("Got error " + res.status + " from server");
        }
        return res;
    });
}