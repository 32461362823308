import {useQuery} from "@apollo/client";
import {QUERY_APPLICATIONS} from "../queries";
import GenericDataTable from "../../common/ui/genericDataTable/genericDataTable";
import DataTableColumn from "../../common/ui/data-table/column";
import {Link} from "react-router-dom";
import Url from "../../common/url";
import {appUrl} from "../../model/app";
import * as React from "react";
import {usePagination} from "../../common/hooks/usePagination";
import {useSearch} from "../../common/hooks/useSearch";
import DataTableCell from "../../common/ui/data-table/cell";

const AppsTable = () => {
    const location = window.location.href
    const page = usePagination();
    const search = useSearch();
    const result = useQuery(QUERY_APPLICATIONS, {
        variables: {
            page: page.getGraphqlPageInput(),
            search: search.getGraphqlSearchInput()
        }
    });
    const {data} = result;

    return <GenericDataTable
        id={"applications-table"}
        items={data && data.appInstances}
        gqlResult={result}
        tableConfigDefault={{}}
        page={page}
        search={search}
    >
        <DataTableColumn label="ID" property="id">
            <DataTableCell render={(props) => (
                <Link to={Url.join(location, `/${props.item.id}`)}>{props.item.id}</Link>
            )}/>
        </DataTableColumn>
        <DataTableColumn label="Name" property="name">
            <DataTableCell render={(props) => (
                <Link to={Url.join(location, `/${props.item.id}`)}>{props.item.name ? props.item.name : "- no name -"}</Link>
            )}/>
        </DataTableColumn>
        <DataTableColumn label="App ID" property="appId"/>
        <DataTableColumn label="Organisation" id="orga" property="organisation">
            <DataTableCell key="type" render={(props) => (
                props.item.organisation ? <Link to={`/configuration/organisations/${props.item.organisation.id}`}>{props.item.organisation.name}</Link> : null
            )}/>
        </DataTableColumn>
        <DataTableColumn label="Actions" id="show-app">
            <DataTableCell render={(props) => {
                return <a href={appUrl(props.item)}>Open App</a>;
            }}/>
        </DataTableColumn>
    </GenericDataTable>;
};

export default AppsTable;