import React from "react"
import PropTypes from "prop-types"
import { Modal } from "../../slds"

export const YesNoDialog = (props) => {
    return <Modal isOpen={props.open[0]} onRequestClose={() => props.open[1](false)} heading={props.title}
                  footer={<>
                      <button className="slds-button slds-button_brand" onClick={() => {
                          props.open[1](false);
                          props.onYes();
                      }}>Yes</button>
                      <button className="slds-button slds-button_neutral" onClick={() => props.open[1](false)}>No</button>
                  </>}>
        {props.text}
    </Modal>;
};

YesNoDialog.propTypes = {
    open: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    onYes: PropTypes.func.isRequired
};