import { useEffect, useState } from "react";
import { Log } from "../log";

// Helper to search in data tables
export function useSearch(initialSearchKeyword, refetchQuery = null) {
    const [search, setSearch] = useState(initialSearchKeyword ? initialSearchKeyword : "");

    useEffect(() => {
        Log.Debug("useSearch.useEffect", search);
        if (refetchQuery) {
            refetchQuery({
                search: search,
            });
        }
    }, [search]);

    return {
        setKeyword: (value) => {
            Log.Debug("useSearch.setKeyword", value);
            setSearch(value)
        },
        keyword: search,
        getGraphqlSearchInput: () => search
    }
}