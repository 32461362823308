import React, { createContext, useEffect, useState } from "react";
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"
import { Log } from "../log";
import dayjs from "dayjs";

export const QUERY_NOTIFICATION = gql`
    query getNotifications {
        getActiveNotifications {
            id
            title
            message
            type
            level
            activeFrom
            activeUntil
        }
    }
`

export const BannerContext = createContext({
    setPreview: () => {
        Log.Debug("BannerContext", "setPreviewNotification not set")
    },
    resetPreview: () => {
        Log.Debug("BannerContext", "resetPreviewNotification not set")
    },
    refetch: () => {
        Log.Debug("BannerContext", "refetch not set")
    },
})

const sortNotifications = (a, b) => {
    // Log.Debug("NotificationPage.sortNotifications", "a", a, "b", b)
    if (dayjs(a?.activeFrom).isBefore(dayjs(b?.activeFrom))) {
        // Log.Debug("NotificationPage.sortNotifications", "a is before b")
        return -1
    }
    if (dayjs(a?.activeFrom).isAfter(dayjs(b?.activeFrom))) {
        // Log.Debug("NotificationPage.sortNotifications", "a is after b")
        return 1
    }
    if (dayjs(a?.activeFrom).isSame(dayjs(b?.activeFrom))) {
        // fallback to activeUntil
        if (dayjs(a?.activeUntil).isBefore(dayjs(b?.activeUntil))) {
            return -1
        }
        if (dayjs(a?.activeUntil).isAfter(dayjs(b?.activeUntil))) {
            return 1
        }
        if (dayjs(a?.activeUntil).isSame(dayjs(b?.activeUntil))) {
            return 0
        }
    }
}

export const BannerContextProvider = ({ children }) => {
    const notificationResult = useQuery(QUERY_NOTIFICATION, {
        fetchPolicy: "network-only",
    })

    const [notifications, setNotifications] = useState([])
    const [previewNotification, setPreviewNotification] = useState([])

    const [showNotification, setShowNotification] = useState(true)
    const [showPreviewNotification, setShowPreviewNotification] = useState(true)

    useEffect(() => {
        if (notificationResult?.data) {
            Log.Debug("BannerContext", "Notification data received", "notificationResult?.data", notificationResult?.data)
            setNotifications(Array.from(notificationResult?.data?.getActiveNotifications).sort(sortNotifications))
        }
    }, [notificationResult?.data])

    const resetPreviewNotification = () => {
        setPreviewNotification([])
    }

    return (
        <BannerContext.Provider
            value={{
                notifications,
                previewNotification,
                showNotification,
                showPreviewNotification,
                setShowNotification,
                setShowPreviewNotification,
                setPreview: setPreviewNotification,
                resetPreview: resetPreviewNotification,
            }}>
            {children}
        </BannerContext.Provider>
    )
}
