import React from "react";
import PropTypes from "prop-types";


export const LoadingWidget = (props) => {
    return <div className="slds-is-relative" style={{height: "100%", padding: "10px"}}>
        Loading {props.widget.type} Widget ...
        <div className="slds-spinner_container">
            <div className="slds-spinner slds-spinner--medium" role="alert">
                <span className="slds-assistive-text">Loading</span>
                <div className="slds-spinner__dot-a"></div>
                <div className="slds-spinner__dot-b"></div>
            </div>
        </div>
    </div>
};

LoadingWidget.propTypes = {
    widget: PropTypes.object.isRequired
};