import React from "react";
import {Link} from "react-router-dom";
import {Button, Card} from "@salesforce/design-system-react";
import Page from "../../../common/ui/page";
import DevicesTable from "./devicesTable";
import {useAuthContext} from "../../../common/context/authContext";


export default function DeviceListPage(props) {
    const auth = useAuthContext();
    const {match} = props;
    const actions = <div>
        <Link to={`new/`}>
            {auth.hasRole("admin") ?
                <Button iconCategory={"utility"} iconName={"add"} iconPosition={"left"}
                        label={"New Device"}/> : null}
        </Link>
    </div>;


    return <Page
        title={"Devices"}
        actions={actions}
        trail={[<Link to={`${match.url}`} key={1}>Devices</Link>]}
    >


        <div className="slds-grid">
            <div className="slds-size--3-of-3">
                <Card heading={""} className="slds-has-top-magnet">
                    <DevicesTable/>
                </Card>

            </div>
        </div>
    </Page>
}