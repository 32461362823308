import React from "react"
import { Form, Input } from "antd"
import { ValidatorFunction } from "./wmbusValidators"
import { useT } from "../../../common/i18n"

interface EditableCellProps<T> extends React.HTMLAttributes<HTMLElement> {
    editing: boolean;
    required: boolean
    validationFunc:  ValidatorFunction | null
    dataIndex: string;
    title: string;
    inputtype: 'number' | 'text';
    record: T;
    index: number;
    children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps<unknown>> = ({
                                                       editing,
                                                       dataIndex,
                                                       title,
                                                       children,
                                                       required,
                                                       validationFunc,
                                                       ...restProps
                                                   }) => {
    const t = useT()
    const inputNode = <Input/>;

    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{margin: 0}}
                    rules={[
                        {
                            required: required,
                            message: `Please Input ${title}!`,
                        },{
                            validator: (_, value) => {
                                if (validationFunc) {
                                    if (!required && (value === "" || value === null)) {
                                        return Promise.resolve();
                                    }
                                    const result  = validationFunc(t, value.toString())

                                    if (result !== undefined) {
                                        return Promise.reject(result);
                                    }
                                }

                                return Promise.resolve();

                            },
                        }
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

export default EditableCell