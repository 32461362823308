import React from "react";
import * as PropTypes from "prop-types";


export default class Error extends React.Component {

    static propTypes = {
        title: PropTypes.string.isRequired,
        message: PropTypes.string.isRequired,
    };

    render() {
        const {title, message} = this.props;
        return <div className="slds-container--fluid">
            <div className="slds-modal__container slds-modal--prompt" role="document" id="prompt-message-wrapper" tabIndex="0">
                <div className="slds-modal__header slds-theme--error slds-theme--alert-texture">
                    <div className="slds-text-heading--medium" id="prompt-heading-id">{title}</div>
                </div>
                <div className="slds-modal__content slds-p-around--medium">
                    <div>
                        <p>{message}</p>
                    </div>
                </div>
            </div>
        </div>
    }
}