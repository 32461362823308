import React from "react";
import Highlighter from "react-highlight-words"
import DOMPurify from 'dompurify'
import parse from 'html-react-parser';
import { Link } from "react-router-dom"
import _ from "underscore"
import DataTableCell from "../data-table/cell";
import { compileHandlebars } from "../../cachedHandlebarsCompiler";
import { Icon } from "../../slds/icons/icon";
import { Tag } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { useT } from "../../i18n";
import { Log } from "../../log";

export const TemplateTableCell = (props) => {

  const t = useT();

  let item = props.item;
  let column = props.column;
  let cell = column.cell;

  // array of strings to be highlighted
  let highlight = props.highlight;
  let cellKey = item.key+cell.format.toString().slice(0,50).replace(/\s/g, "")

  // Post process the given template engine result which we call html
  const  postProcessTemplate = (html)  => {
    const pattern = /\{\{.*?\}\}|.+?(?=\{\{.*?\}\})|.+/g;
    let array = html.match(pattern);

    array = (array || []).map((s, i) => {
      let tok = s.replace(/^\{\{|\}\}$/g, "").split(/[,:]/);
      let func = tok[0];
      let args = tok.slice(1);
      if (func === "icon") {
        let name = args[0];
        let category = args[1];
        return <Icon key={i} name={name} category={category} size={"x-small"}/>;
      }

      return s;
    });

    return array;
  }

  const valueToBooleanTag = (value) => {
    if (value.length === 1 && value[0] === "true") {
      return (
        <Tag icon={<CheckCircleOutlined />} color="success">
          {t("common.bool.yes", "yes")}
        </Tag>
      )
    }
    if (value.length === 1 && value[0] === "false"){
      return (
        <Tag icon={<CloseCircleOutlined />} color="error">
          {t("common.bool.no", "no")}
        </Tag>
      )
    }
    return value
  }

  if (!cell) {
    cell = {
      format: "- missing cell -"
    };
  }

  let container = "no content";
  let url = undefined;

  if (cell.format) {
    container = compileHandlebars(cell.format, item);
  }

  if (cell.href) {
    url = compileHandlebars(cell.href, item);
  }
  if (!cell.isHtml) {
    container = postProcessTemplate(container);
  }

  let cellContent;
  if (url) {
    if (url.startsWith("#")) {
      url = url.substr(1);


      cellContent =
        highlight ?
          <Link to={url}>
            <Highlighter key={cellKey}
              highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
              searchWords={highlight}
              autoEscape
              textToHighlight={containerToString(container)}
            />
          </Link>
          :
          <Link to={url}>{container}</Link>;

    } else {

      cellContent = highlight ?
        <a href={url}>
          <Highlighter key={cellKey}
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={highlight}
            autoEscape
            textToHighlight={containerToString(container)}
          />
        </a>
        :
        <a href={url}>{container}</a>;
    }
  } else if (cell.isHtml) {

    let replaced = parse(DOMPurify.sanitize(container), {
      transform(reactNode, domNode,index) {
        if (domNode.type === "text"){

          let text = domNode.data
          if (column?.filter?.type === "binary"){
            text =  base64ToHex(text).toLowerCase()
          }

          return  <Highlighter
            key={cellKey + index.toString()}
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={highlight}
            autoEscape
            textToHighlight={text}
          />
        }
        return reactNode
      }
    })

    cellContent = <div className="slds-cell-wrap">{replaced}</div>

  } else {

    if (column?.filter?.type === "boolean") {
      container = valueToBooleanTag(container)
      cellContent = <div className="slds-cell-wrap">{container}</div>;
    } else {
      if (column?.filter?.type === "binary") {
        container  = base64ToHex(containerToString(container)).toLowerCase()
      }
      cellContent = highlight ?
        <div className="slds-cell-wrap">
          <Highlighter key={cellKey}
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={highlight}
            autoEscape
            textToHighlight={containerToString(container)}
          />
        </div> :
        <div className="slds-cell-wrap">{container}</div>;
    }
  }

  // The title should only contain strings, not components
  let title = containerToString(container);

  return <DataTableCell {...props} title={title.toString()} antCell={true}>
    {cellContent}
  </DataTableCell>
};


const containerToString = (container) => {
  if (_.isArray(container)) {
    return _.reduce(container, (curr, s) => {
      if (typeof (s) === "string") {
        return curr + s;
      }
      return curr;
    }, "");
  }
  return container.toString();
}

function base64ToHex(str) {
  let raw = ""

  try {
    raw = atob(str);
  }catch (error){
    Log.Error("Failed to convert base64ToHex", "base64", str, "error", error)
    return "binary: failed to convert base64 to hex - try \"triple-stash\" {{{ value }}} or set html flag - see log for error details"
  }
  let result = '';
  for (let i = 0; i < raw.length; i++) {
    const hex = raw.charCodeAt(i).toString(16);
    result += (hex.length === 2 ? hex : '0' + hex);
  }
  return result.toUpperCase();
}
