import * as React from "react";
import {useContext} from "react";
import gql from "graphql-tag";
import GenericDataTable from "../../../common/ui/genericDataTable/genericDataTable";
import {QuerySafe} from "../../../common/graphql";
import DataTableColumn from "../../../common/ui/data-table/column";
import {Link} from "react-router-dom";
import {AppContext} from "../../appPage";
import {Log} from "../../../common/log";
import {RouterContext} from "../../../common/RouterContext";
import DataTableCell from "../../../common/ui/data-table/cell";


const queryAlarmConfigs = gql`
    query getAlarmConfigs($appId: ID!) {
        alarmConfigs(appId:$appId) {
            id
            name
            triggerJs
        }
    }
`;

export function refetchAlarmConfigs(appId) {
    if (!appId) {
        throw new Error("appId is " + appId);
    }
    return {
        query: queryAlarmConfigs,
        variables: {
            appId: appId
        }
    }
}

export default function AlarmConfigTable(props) {

    Log.Debug("AlarmConfigTable.props", props);
    const app = useContext(AppContext);

    const r = useContext(RouterContext);
    console.log("RouterContext: ", r);

    return <QuerySafe query={queryAlarmConfigs} variables={{"appId": app.id}}>{
        (result) => {
            const {data} = result;
            return <GenericDataTable id={"alarm-configs"} hideDetails={true} items={data.alarmConfigs} tableConfigDefault={{}}>
                <DataTableColumn label={"Name"} property="name">
                    <DataTableCell render={(props) => (
                        <Link to={`${props.item.id}/`} key={1}>{props.item.name ? props.item.name : "- no name -"}</Link>
                    )}/>
                </DataTableColumn>
                <DataTableColumn label={"Condition"} property="triggerJs"/>
            </GenericDataTable>
        }
    }
    </QuerySafe>
}