import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Log } from "../../log";

export default function ProgressBar(props) {
    const {min, max, current, simulateProgress, updatePercentages, updateInterval, stopPercent} = props;

    let internalPercent = 0;
    let range = max - min;
    
    if (range) {
        internalPercent = current / range;
    }
    if (internalPercent < 0) {
        internalPercent = 0;
    }
    if (internalPercent > 1) {
        internalPercent = 1;
    }

    const [percent, setPercent] = useState(internalPercent);
    const [stopSimulation  , setStopSimulation] = useState(false);

    useEffect(() => {
        // add percentage every updateInterval
        if (simulateProgress && !stopSimulation) {
            setTimeout(() => {
                let newPercent = percent + updatePercentages;

                // stop simulation if stopPercent is reached
                if (newPercent >= stopPercent) {
                    setStopSimulation(true)
                }
                Log.Debug("Progressbar - Simulating progress, percent", percent, newPercent, stopPercent);
                setPercent(newPercent);
            }, updateInterval);
        }
    }, [simulateProgress, percent, updateInterval, stopPercent, stopSimulation]);

    return (
        <div className="slds-progress-bar" aria-valuemin={min} aria-valuemax={max} aria-valuenow={current} role="progressbar">
            <span className="slds-progress-bar__value" style={{ width: `${(percent * 100).toFixed(0)}%` }}>
                <span className="slds-assistive-text">Progress: {(percent * 100).toFixed(0)}%</span>
            </span>
        </div>
    )
}

ProgressBar.defaultProps = {
    min: 0,
    max: 100,
    current: 0,
    simulateProgress: false,
    updateInterval: 100,
    updatePercentages: 0.1,
    stopPercent: 1,
};

ProgressBar.propTypes = {
    min: PropTypes.number,
    max: PropTypes.number,
    current: PropTypes.number,
    simulateProgress: PropTypes.bool,
    updateInterval: PropTypes.number,
    updatePercentages: PropTypes.number,
    stopPercent: PropTypes.number,
};