import gql from "graphql-tag";
import Page from "../../../common/ui/page";
import HeaderListControls from "../../../common/ui/hederListControls";
import {useGraphqlLoadingComponent} from "../../../common/graphql";
import GenericDataTable from "../../../common/ui/genericDataTable/genericDataTable";
import React, {useContext} from "react";
import {AppContext} from "../../appPage";
import {useQuery} from "@apollo/client";
import {usePagination} from "../../../common/hooks/usePagination";
import {useSort} from "../../../common/hooks/useSort";

const QUERY_SENSOR_DATA = gql`
    query getData($appId: ID!, $page:PaginationInputType, $filter:[FilterInputType!], $sort: SortInputType) {
        appInstance(id:$appId) {
            id
            appId
            configRaw
            name
            type
        }
        sensorData(appId: $appId, page: $page, filter: $filter, sort: $sort) {
            id
            createdAt
            dataRaw
            type
            parseError
            device {
                id
                addr
                name
                description
            }         
        }
    }
`;


function tableConfig(appId, appConfig) {
    let tableConfig = {};

    if (typeof appConfig === "string") {
        appConfig = JSON.parse(appConfig);
    }

    if (appConfig && appConfig.ui && appConfig.ui.sensorDataTable) {
        tableConfig = appConfig.ui.sensorDataTable;
    } else {
        // Default columns
        tableConfig.cols = [
            {
                "heading": "Time",
                "width": "10rem",
                "cell": {
                    "sortProperty": "createdAt",
                    "format": "{{date createdAt 'DD.MM.YYYY HH:mm:ss'}}"
                }
            },
            {
                "heading": "Type",
                "width": "5rem",
                "cell": {
                    "sortProperty": "type",
                    "format": "{{type}}"
                }
            },
            {
                "heading": "Device",
                "width": "10rem",
                "cell": {
                    "href": `#/${appId}/devices/{{device.id}}/tab-0`,
                    "format": "{{device.addr}}"
                }
            },
            {
                "heading": "Description",
                "cell": {
                    "format": "{{device.description}}"
                }
            },
            {
                "heading": "Parse Error",
                "cell": {
                    "format": "{{parseError}}"
                }
            }
        ]
    }

    return tableConfig
}

// TODO: Build generic view for details, including custom view per datasource
export function SensorDataPage() {
    const app = useContext(AppContext);
    const page = usePagination();
    const sort = useSort();

    const sensorDataResult = useQuery(QUERY_SENSOR_DATA, {
        fetchPolicy: "cache-and-network",
        variables: {
            appId: app.id,
            page: page.getGraphqlPageInput(),
            sort: sort.getGraphqlSortInput(),
        }
    });


    const loading = useGraphqlLoadingComponent(sensorDataResult);
    if (loading) {
        return loading;
    }
    const {data} = sensorDataResult;

    page.setPageItemCount(data.sensorData ? data.sensorData.length : 0);

    return <Page title={"Raw Messages"}
                 trail={[]}
                 actions={<HeaderListControls/>}
    >
        <GenericDataTable
            id={"sensor-data-table"}
            tableConfigName={"sensor-data"}
            tableConfigDefault={tableConfig(app.id, data.appInstance.configRaw)}
            items={data.sensorData.map((d) => {
                return {
                    ...d,
                    data: JSON.parse(d.dataRaw)
                };
            })}
            sort={sort}
            gqlResult={sensorDataResult}
            page={page}
        />


    </Page>
}