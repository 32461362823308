import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";


const ListBox = (props) => {
    const {children, className} = props;
    const orientation = props.orientation || "horizontal";
    return <ul className={classNames("slds-listbox", `slds-listbox_${orientation}`, className)} role="listbox" aria-label="Selected Options:" aria-orientation={orientation}>
        {children}
    </ul>
};

ListBox.propTypes = {
    children: PropTypes.any.isRequired,
    className: PropTypes.string,
    orientation: PropTypes.oneOf(["horizontal"])
};
export default ListBox;