import slds from "../slds";
import PropTypes from "prop-types";
import React from "react";
import Button from "../slds/buttons/button";


const HeaderListControls = (props) => {
    const {onClickFilter} = props;

    if (!onClickFilter) {
        // don't render the ButtonGroup when there are no buttons
        return null;
    }

    return <div>
        <slds.ButtonGroup>
            <slds.Tooltip align="top left" content={"Filter"}>
                <Button
                    assistiveText={{icon: 'Filter'}}
                    iconCategory="utility"
                    iconName="filterList"
                    iconVariant="border"
                    variant="icon"
                    onClick={onClickFilter}
                    style={onClickFilter ? {} : {"display": "none"}}
                />
            </slds.Tooltip>
            <slds.Tooltip align="top left" content={"CSV Export"}>
                <Button
                    assistiveText={{icon: 'CSV Export'}}
                    iconCategory="utility"
                    iconName="download"
                    iconVariant="border"
                    variant="icon"
                    style={{"display": "none"}}
                /></slds.Tooltip>
        </slds.ButtonGroup>
    </div>
};

HeaderListControls.propTypes = {
    onClickFilter: PropTypes.func
};

export default HeaderListControls