import React from "react";
import Page from "../../../app/pages/page";
import {useGraphqlLoadingComponent} from "../../../common/graphql";
import {Link, Outlet} from "react-router-dom";
import {Tab, TabNavigation} from "../../../common/ui/tabNavigation";
import {useQuery} from "@apollo/client";
import gql from "graphql-tag";
import Tooltip from "../../../common/slds/tooltip/tooltip";
import {CustomPropTypes} from "../../../common/propTypes/customPropTypes";
import {useT} from "../../../common/i18n";
import DeviceNotFoundPage from "./deviceNotFoundPage";
import {useParams} from "react-router";


export const QUERY_EUI_ASSIGNMENT = gql`
    query getEuiAssignmentForDevice ($deviceId: ID! ) {
        getEuiAssignmentForDevice(deviceId: $deviceId){        
            eui   
            id: eui
        }
    }
`;

const QUERY_DEVICE = gql`
    query device($devId: ID!) {
        device(id: $devId) {
            id
            name
            description
            serial
            addr
            parserCode
            parserEnabled
            propertiesRaw
            tags
            certEnforcesDtls
            location {
                lat
                lon
            }
            organisation{
                id
                name
            }
            deviceType {
                id
                name
                displayName
                parserCode
            }
            # Tokens for API tab
            app {
                id
                name
                apiTokens {
                    id
                    name
                    token
                }
            }
        }
        # For deviceSettings page
        deviceTypes {
            id
            name
            displayName
        }
    }
`;

Tooltip.propTypes = {
    children: CustomPropTypes.children,
};


export default function DeviceDetailPage() {
    const t = useT();
    const devId = useParams().deviceId;

    const deviceResult = useQuery(QUERY_DEVICE, {
        fetchPolicy: 'cache-first',
        variables: {devId: devId},
    });
    const euiResult = useQuery(QUERY_EUI_ASSIGNMENT, {
        variables: {deviceId: devId},
    });


    const loading = useGraphqlLoadingComponent(deviceResult);
    if (loading) {
        return loading;
    }

    const {device} = deviceResult.data;
    if (!device) {
        return <DeviceNotFoundPage devId={devId}/>
    }

    return <Page title={<div>{`${device.name}` || t("device.header.device-name-fallback", "Device")}</div>}
                 trail={[<Link to={`../devices`} key={1}>Devices</Link>, <Link to={`.`} key={2}>{device.name}</Link>]}
                 info={<div>
                     <ul className="slds-list--horizontal slds-has-dividers_left">
                         <li className="slds-item"><Link key={2} to={"/deviceTypes/" + device.deviceType.id + "/overview"}>{device.deviceType.displayName}</Link></li>
                         <li className="slds-item">{device.addr}</li>
                         {device.serial ? <li className="slds-item">Serial: {device.serial}</li> : <div/>}
                         {euiResult.data?.getEuiAssignmentForDevice?.eui ? <li className="slds-item">DevEUI: {euiResult.data?.getEuiAssignmentForDevice?.eui}</li> : <div/>}
                     </ul>
                     {device.description}
                 </div>}

    >
        <TabNavigation tabs={[
            <Tab key={"overview"} to={"overview"}>{t("device.nav.overview", "Overview")}</Tab>,
            <Tab key={"parsed"} to={"device-data"}>{t("device.nav.device-data", "Device Data")}</Tab>,
            <Tab key={"raw"} to={"raw-data"}>{t("device.nav.uplinks", "Uplinks")}</Tab>,
            <Tab key={"downlinks"} to={"downlinks"}>{t("device.nav.downlinks", "Downlinks")}</Tab>,
            <Tab key={"config"} to={"config"}>{t("device.nav.config", "Config")}</Tab>,
            <Tab key={"settings"} to={"settings"}>{t("device.nav.settings", "Settings")}</Tab>,
            <Tab key={"security"} to={"security"}>{t("device.nav.security", "Security")}</Tab>,
        ]}>
            <Outlet/>
        </TabNavigation>
    </Page>;
}