import _ from "underscore";
import WmbusDetailComponent from "./wmbus";
import * as React from "react";
import PropTypes from "prop-types";
import {Accordion, AccordionPanel} from "../../common/ui/accordion";
import Json from "../../common/ui/json";
import {Log} from "../../common/log";
import gql from "graphql-tag";
import {useQuery} from "@apollo/client";

const deviceTraitInfo = {
    "wmbus-data": {
        parsedDataDetails: WmbusDetailComponent,
    }
};


// Pass deviceTraitInfo as string array
export function getDeviceTraitInfo(traits) {
    if (!_.isArray(traits)) {
        return [];
    }

    return traits.map(t => {
        if (deviceTraitInfo[t] === undefined) {
            return undefined;
        }
        return {name: t, ...deviceTraitInfo[t]};
    }).filter(it => it !== undefined);
}

export function ParsedDataDetailComponent(props) {
    let {data, traits} = props;

    const traitInfo = getDeviceTraitInfo(traits);

    Log.Debug("TraitInfo:", traits, traitInfo);

    if (traits.length === 0) {
        // without Accordion
        return <>
            <Json json={data}/>
            <Accordion>
            <AccordionPanel summary="Uplink Data (Json)" id="sensor-data-json-panel">
                <LazyLoadSensorDataComponent sensorDataId={data.sensorDataId} />
            </AccordionPanel>
            </Accordion>
        </>
    }

    return <>
        {traitInfo.map((t, i) => {
            const DetailComponent = t.parsedDataDetails || undefined;
            return <DetailComponent key={i} data={data}/>;
        })}
        <Accordion>
            <AccordionPanel summary="Data (Json)" id="data-json-panel">
                <Json json={data}/>
            </AccordionPanel>
            <AccordionPanel summary="Uplink Data (Json)" id="sensor-data-json-panel">
              <LazyLoadSensorDataComponent sensorDataId={data.sensorDataId} />
            </AccordionPanel>
        </Accordion>
    </>
}

ParsedDataDetailComponent.propTypes = {
    data: PropTypes.object,
    traits: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export const QUERY_SENSOR_DATA = gql`
    query getSensorData($sensorDataId: ID!) {
        getSensorData(id: $sensorDataId){
            id
            createdAt
            deviceId            
            parseError
            dataRaw
        }
    }
`;


function computeDataForSensordata(sensorData){
    return {
        ...sensorData,
        data: sensorData ? JSON.parse(sensorData.dataRaw) : null
    }
}

export function LazyLoadSensorDataComponent(props){
    const {sensorDataId} = props;
    const sensorDataResult = useQuery(QUERY_SENSOR_DATA, {
        variables: {sensorDataId: sensorDataId},
    });

    const sensorDataWithParsedData = computeDataForSensordata(sensorDataResult?.data?.getSensorData)

    return <Json json={sensorDataWithParsedData}/>
}
