import React, {PropsWithChildren, useEffect, useState} from "react";
import {AuthContext, AuthState, getAuthState} from "./authContext";
import {Log} from "../log";

const AuthContextProvider = (props: PropsWithChildren) => {
    const auth = getAuthState();
    const [authState, setAuthState] = useState<AuthState>(auth);
    useEffect(() => {
        auth.changeListeners.push((auth) => {
            Log.Debug("Update AuthContext", auth);
            setAuthState(auth);
            // force reload the page
            // LEADS TO ENDLESS LOOP ON LOGIN!
            //history.replace(history.location);
        });
    });

    return <AuthContext.Provider value={authState}>
        {props.children}
    </AuthContext.Provider>
};

export default AuthContextProvider;