import React from "react"

export const TableSettingsSVG = (props) => (
    <svg
        style={props.style}
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        fill={props.fill ? props.fill : "black"}
        width={props.width ? props.width : "1.15em"}
        height={props.height ? props.height : "1.15em"}
        viewBox="0 0 52 52"
        enableBackground="new 0 0 52 52"
        xmlSpace="preserve">
        <g>
            <g>
                <path
                    d="M21.5,20h9c0.8,0,1.5-0.7,1.5-1.5v-3c0-0.8-0.7-1.5-1.5-1.5h-9c-0.8,0-1.5,0.7-1.5,1.5v3
			C20,19.3,20.7,20,21.5,20z"
                />
                <path
                    d="M46.5,14h-9c-0.8,0-1.5,0.7-1.5,1.5v3c0,0.8,0.7,1.5,1.5,1.5h9c0.8,0,1.5-0.7,1.5-1.5v-3
			C48,14.7,47.3,14,46.5,14z"
                />
                <path d="M5.5,10h41c0.8,0,1.5-0.7,1.5-1.5v-5C48,2.7,47.3,2,46.5,2h-41C4.7,2,4,2.7,4,3.5v5C4,9.3,4.7,10,5.5,10z" />
                <path d="M48,27.4v-1.9c0-0.8-0.7-1.5-1.5-1.5h-2.7c1.3,0.7,2.5,1.6,3.5,2.7C47.6,26.9,47.8,27.2,48,27.4z" />
                <path
                    d="M20,25.5v3c0,0.8,0.7,1.5,1.5,1.5h0.7c0.7-1.2,1.5-2.3,2.5-3.3c1.1-1.1,2.3-2,3.5-2.7h-6.7
			C20.7,24,20,24.7,20,25.5z"
                />
                <path
                    d="M24.7,49.3c-1.5-1.5-2.7-3.3-3.5-5.2c-0.7,0.1-1.2,0.8-1.2,1.5v3c0,0.8,0.7,1.5,1.5,1.5h4
			C25.2,49.8,25,49.5,24.7,49.3z"
                />
                <path
                    d="M14.5,14h-9C4.7,14,4,14.7,4,15.5v3C4,19.3,4.7,20,5.5,20h9c0.8,0,1.5-0.7,1.5-1.5v-3
			C16,14.7,15.3,14,14.5,14z"
                />
                <path
                    d="M14.5,24h-9C4.7,24,4,24.7,4,25.5v3C4,29.3,4.7,30,5.5,30h9c0.8,0,1.5-0.7,1.5-1.5v-3
			C16,24.7,15.3,24,14.5,24z"
                />
                <path
                    d="M14.5,44h-9C4.7,44,4,44.7,4,45.5v3C4,49.3,4.7,50,5.5,50h9c0.8,0,1.5-0.7,1.5-1.5v-3
			C16,44.7,15.3,44,14.5,44z"
                />
                <path
                    d="M14.5,34h-9C4.7,34,4,34.7,4,35.5v3C4,39.3,4.7,40,5.5,40h9c0.8,0,1.5-0.7,1.5-1.5v-3
			C16,34.7,15.3,34,14.5,34z"
                />
            </g>
            <g>
                <path d="M36.1,35c-1.7,0-3,1.3-3,3s1.3,3,3,3c1.6,0,2.9-1.4,3-3C39,36.3,37.7,35,36.1,35z" />
                <path
                    d="M44.5,29.5c-4.7-4.7-12.3-4.7-17,0c-4.7,4.7-4.7,12.3,0,17c4.7,4.7,12.3,4.7,17,0
			C49.2,41.8,49.2,34.2,44.5,29.5z M43.9,39c0,0.3-0.1,0.5-0.5,0.5c-0.4,0.1-0.8,0.1-1.2,0.1c-0.1,0-0.1,0.1-0.1,0.1
			c-0.1,0.5-0.4,0.8-0.5,1.3c-0.1,0.1-0.1,0.1,0,0.2c0.2,0.4,0.5,0.7,0.7,1c0.1,0.2,0.1,0.5-0.1,0.7l-1.3,1.3
			c-0.2,0.1-0.3,0.1-0.4,0.1s-0.3-0.1-0.3-0.1c-0.3-0.2-0.7-0.5-1-0.7c0-0.1-0.1-0.1-0.1-0.1H39c-0.3,0.1-0.8,0.4-1.3,0.5
			c-0.1,0-0.1,0.1-0.1,0.1c-0.1,0.4-0.1,0.8-0.1,1.2c-0.1,0.3-0.5,0.5-0.5,0.5h-1.9c-0.3,0-0.5-0.1-0.5-0.5
			c-0.1-0.4-0.1-0.8-0.1-1.2c0-0.1-0.1-0.1-0.1-0.1c-0.5-0.1-0.8-0.4-1.3-0.5H33c-0.1,0-0.1,0.1-0.1,0.1c-0.4,0.2-0.7,0.5-1,0.7
			c-0.1,0.1-0.3,0.1-0.3,0.1c-0.2,0-0.3,0-0.4-0.1l-1.3-1.3c-0.2-0.2-0.2-0.5-0.1-0.7c0.2-0.3,0.5-0.7,0.7-1c0.1-0.1,0-0.1,0-0.2
			c-0.1-0.5-0.4-0.8-0.5-1.3c0-0.1-0.1-0.1-0.1-0.1c-0.4-0.1-0.7-0.1-1.1-0.1c-0.2-0.1-0.4-0.2-0.5-0.4v-2.2
			c0.1-0.3,0.3-0.4,0.5-0.4c0.4-0.1,0.7-0.1,1.1-0.1c0.1,0,0.1-0.1,0.1-0.1c0.1-0.5,0.4-0.8,0.5-1.3c0.1-0.1,0.1-0.1,0-0.2
			c-0.2-0.4-0.5-0.7-0.7-1c-0.1-0.2-0.1-0.5,0.1-0.7l1.3-1.3c0.2-0.1,0.2-0.1,0.4-0.1s0.3,0.1,0.3,0.1c0.4,0.2,0.7,0.5,1,0.7
			c0.1,0.1,0.1,0.1,0.1,0.1H33c0.5-0.1,0.8-0.4,1.3-0.5c0.1,0,0.1-0.1,0.1-0.1c0.1-0.4,0.1-0.7,0.1-1.1c0-0.2,0.2-0.4,0.3-0.5
			c0.1-0.1,0.2-0.1,0.3-0.1h1.9c0,0,0.1,0,0.1,0.1c0.3,0.1,0.3,0.3,0.3,0.5c0.1,0.4,0.1,0.7,0.1,1.1c0,0.1,0.1,0.1,0.1,0.1
			c0.5,0.1,0.8,0.4,1.3,0.5h0.1c0,0,0-0.1,0.1-0.1c0.3-0.2,0.7-0.5,1-0.7c0.1-0.1,0.3-0.1,0.3-0.1c0.1,0,0.3,0.1,0.3,0.1l1.3,1.3
			c0.2,0.2,0.2,0.5,0.1,0.7c-0.2,0.3-0.5,0.7-0.7,1v0.2c0.1,0.3,0.4,0.8,0.5,1.3c0,0.1,0.1,0.1,0.1,0.1c0.4,0.1,0.8,0.1,1.2,0.1
			c0.3,0.1,0.5,0.2,0.5,0.7V39z"
                />
            </g>
        </g>
    </svg>
)

// https://fonts.google.com/icons?selected=Material%20Symbols%20Outlined%3Asettings_remote%3AFILL%400%3Bwght%40400%3BGRAD%400%3Bopsz%4024
export const GoogleSettingsRemoteSVG = (props) => (
    <svg
        style={props.style}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 -960 960 960"
        fill={props.fill ? props.fill : "black"}
        width={props.width ? props.width : "1.15em"}
        height={props.height ? props.height : "1.15em"}>
        <path d="M360-40q-17 0-28.5-11.5T320-80v-480q0-17 11.5-28.5T360-600h240q17 0 28.5 11.5T640-560v480q0 17-11.5 28.5T600-40H360Zm120-350q21 0 35.5-14.5T530-440q0-21-14.5-35.5T480-490q-21 0-35.5 14.5T430-440q0 21 14.5 35.5T480-390ZM338-662l-56-56q40-40 91-61t107-21q56 0 107 21t91 61l-56 56q-29-29-65.5-43.5T480-720q-40 0-76.5 14.5T338-662ZM226-774l-58-58q63-61 143.5-94.5T480-960q88 0 168.5 33.5T790-830l-56 56q-50-52-116-79t-138-27q-72 0-138 27t-116 79Zm174 654h160v-400H400v400Zm0 0h160-160Z" />
    </svg>
)

// https://fonts.google.com/icons?selected=Material+Symbols+Outlined:warehouse:FILL@0;wght@400;GRAD@0;opsz@24&icon.size=24&icon.color=%235f6368&icon.query=House
export const GoogleWarehouseSVG = (props) => (
    <svg
        style={props.style}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 -960 960 960"
        width={props.width ? props.width : "1.15em"}
        height={props.height ? props.height : "1.15em"}
        fill={props.fill ? props.fill : "#black"}>
        <path d="M160-200h80v-320h480v320h80v-426L480-754 160-626v426Zm-80 80v-560l400-160 400 160v560H640v-320H320v320H80Zm280 0v-80h80v80h-80Zm80-120v-80h80v80h-80Zm80 120v-80h80v80h-80ZM240-520h480-480Z" />
    </svg>
)

//  https://fonts.google.com/icons?selected=Material+Symbols+Outlined:account_tree:FILL@0;wght@400;GRAD@0;opsz@24&icon.query=tree&icon.size=24&icon.color=%235f6368
export const GoogleAccountTreeSVG = (props) => (
    <svg
        style={props.style}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 -960 960 960"
        width={props.width ? props.width : "1.15em"}
        height={props.height ? props.height : "1.15em"}
        fill={props.fill ? props.fill : "#black"}>
        <path d="M600-120v-120H440v-400h-80v120H80v-320h280v120h240v-120h280v320H600v-120h-80v320h80v-120h280v320H600ZM160-760v160-160Zm520 400v160-160Zm0-400v160-160Zm0 160h120v-160H680v160Zm0 400h120v-160H680v160ZM160-600h120v-160H160v160Z" />
    </svg>
)

//  https://fonts.google.com/icons?selected=Material+Symbols+Outlined:holiday_village:FILL@0;wght@400;GRAD@0;opsz@24&icon.query=house&icon.size=24&icon.color=%235f6368
export const GoogleHolidayVillageSVG = (props) => (
    <svg
        style={props.style}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 -960 960 960"
        width={props.width ? props.width : "1.15em"}
        height={props.height ? props.height : "1.15em"}
        fill={props.fill ? props.fill : "#black"}>
        <path d="M80-160v-400l240-240 240 240v400H80Zm80-80h120v-120h80v120h120v-287L320-687 160-527v287Zm120-200v-80h80v80h-80Zm360 280v-433L433-800h113l174 174v466h-80Zm160 0v-499L659-800h113l108 108v532h-80Zm-640-80h320-320Z" />
    </svg>
)

//  https://fonts.google.com/icons?selected=Material+Symbols+Outlined:select_all:FILL@0;wght@400;GRAD@0;opsz@24&icon.query=all&icon.size=24&icon.color=%235f6368
export const GoogleSelectAllSVG = (props) => (
    <svg
        style={props.style}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 -960 960 960"
        width={props.width ? props.width : "1.15em"}
        height={props.height ? props.height : "1.15em"}
        fill={props.fill ? props.fill : "#black"}>
        <path d="M280-280v-400h400v400H280Zm80-80h240v-240H360v240ZM200-200v80q-33 0-56.5-23.5T120-200h80Zm-80-80v-80h80v80h-80Zm0-160v-80h80v80h-80Zm0-160v-80h80v80h-80Zm80-160h-80q0-33 23.5-56.5T200-840v80Zm80 640v-80h80v80h-80Zm0-640v-80h80v80h-80Zm160 640v-80h80v80h-80Zm0-640v-80h80v80h-80Zm160 640v-80h80v80h-80Zm0-640v-80h80v80h-80Zm160 640v-80h80q0 33-23.5 56.5T760-120Zm0-160v-80h80v80h-80Zm0-160v-80h80v80h-80Zm0-160v-80h80v80h-80Zm0-160v-80q33 0 56.5 23.5T840-760h-80Z" />
    </svg>
)
