import gql from "graphql-tag";


export const GET_ORGANISATION = gql`
    query ($id: ID!){
        getOrganisation(id: $id) {
            id
            allowWmbusKeysInPlainText
        }
    }
`;

export const WMBUS_KEYS_WITH_PLAIN_TEXT = gql`
    query ($orgId: ID!, $page: PaginationInputType, $sort: SortInputType, $filter:[FilterInputType!]){
        getWmbusKeysForOrganisation(orgId: $orgId, sort: $sort, page: $page, filter: $filter) {
            result {
                id
                meterId
                manufacturer
                encryptionKeyMasked
                encryptionKeyPlainText
                encryptionKeyHash
            }
            totalCount
        }
    }
`;
export const DELETE_WMBUS_KEY = gql`mutation($id: ID!){
    deleteWmbusKey(id: $id) {
        id
    }}
`;
export const UPDATE_WMBUS_KEY = gql`mutation($id: ID!, $wmbusKey: WmbusKeyInput!){
    updateWmbusKey(id: $id, input: $wmbusKey) {
        id
        encryptionKeyMasked
        meterId
        manufacturer
    }}
`;
export const CREATE_WMBUS_KEY = gql`mutation($wmbusKey: WmbusKeyInput!){
    createWmbusKey(input: $wmbusKey) {
        id
        encryptionKeyMasked
        meterId
        manufacturer
    }}
`;