import { useEffect, useRef, useState } from "react";

// Helper to search in data tables.
// This version of the search hook uses a debounce mechanism to avoid too many queries.
// Also, the use of state is mostly avoided to minimise expensive re-renders.
export function useSearchLight(initialSearchKeyword, pagination, refetchQuery = null) {
    // the state of the doExecuteOscillator is not important, we just want to trigger the use
    const [doExecuteOscillator, setDoExecuteOscillator] = useState(false);

    const search = useRef(initialSearchKeyword);
    const timeoutActiveRef = useRef(null);
    const timer = useRef(null);

    const timeoutMillis = 50;

    const createTimeout = () => {
        timer.current = setTimeout(() => {
            // Log.Debug("useSearch.useEffect - end execute countdown 500ms");

            timeoutActiveRef.current = false;
            setDoExecuteOscillator(!doExecuteOscillator);
        }, timeoutMillis);

    }

    const updateSearch = (keyword) => {

        search.current = keyword;

        // Start a countdown to execute search.
        // While the countdown is running, we don't want to start another countdown.
        if (!timeoutActiveRef.current) {
            timeoutActiveRef.current = true;
            createTimeout();
        } else {
            // The timeout is already active, clear the timeout and start again
            // We want the search only to happen if no new changes to the search are incoming
            timeoutActiveRef.current = true;
            clearTimeout(timer.current);
            createTimeout();
        }
    };

    useEffect(() => {

        if (search.current !== null && refetchQuery) {

            //if we change search keyword we want to start from first page
            if (pagination) {
                  pagination.reset();
              }

            refetchQuery(     {
                search: search.current,
            });
        }
    }, [doExecuteOscillator]);

    return {
        setKeyword: (keyword) => {
            updateSearch(keyword);
        },
        keyword: search.current,
        getGraphqlSearchInput: () => search
    }
}