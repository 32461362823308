import i18n from 'i18next';
import {initReactI18next, useTranslation} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpBackend from 'i18next-http-backend';
import ChainedBackend from 'i18next-chained-backend'
import {backendUrl} from "./helper";

console.log("init i18n");

// Use english also as german fallback
// NOTE: Than we can't detect missing keys anymore!
void i18n
    // i18next-http-backend
    // loads translations from your server
    // https://github.com/i18next/i18next-http-backend
    .use(ChainedBackend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: true,
        fallbackLng: 'en',
        preload: ['de', 'en'],
        saveMissing: true,
        //saveMissingTo: 'current', // fallback is the default, everything else might break translations of another language ...
        // Does not work as expected, we use the chained backend with the local resources
        //partialBundledLanguages: true,
        //resources: localResources,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        backend: {
            backends: [
                HttpBackend, // if a namespace can't be loaded via normal http-backend loadPath, then the inMemoryLocalBackend will try to return the correct resources
               // resourcesToBackend(localResources)
            ],
            backendOptions: [
                // https://github.com/i18next/i18next-http-backend
                {
                    // set loadPath: '/locales/resources.json?lng={{lng}}&ns={{ns}}' to adapt to multiLoading
                    // /locales/resources.json?lng=de+en&ns=ns1+ns2
                    allowMultiLoading: false,
                    loadPath: backendUrl() + '/frontend/locales/resources.json?lng={{lng}}&ns={{ns}}',
                    addPath: backendUrl() + '/frontend/locales/add/resources.json?lng={{lng}}&ns={{ns}}',
                    // can be used to reload resources in a specific interval (useful in server environments)
                    reloadInterval: false
                },
                {}
            ]
        },
    });

export function useT() {
    const {t} = useTranslation();
    return t;
}