import React from "react";
import {ButtonGroup} from "@salesforce/design-system-react";
import PropTypes from "prop-types";
import Button from "../slds/buttons/button";
import {useT} from "../i18n";

const DebouncedPagination = (props) => {
    const t = useT();
    const {page} = props;

    const knownTotal = page.getTotal() !== null && page.getTotal() !== undefined;

    return (
        <div className="slds-grid">
            <ButtonGroup className="slds-p-right--small">
                <Button iconName={"back"} onClick={() => page.prev()} disabled={!page.hasPrev()}>
                    {t("pagination.button-prev", "Prev")}
                </Button>
            </ButtonGroup>
            <div className="slds-align-middle">

                {
                    knownTotal && page.getTotal() > 0 &&
                    <div>
                        <span className="slds-text-title_bold slds-p-right--small">
                            {t("pagination.page-range", "Page {{current}} / {{total}}", { current: page.currentPage(), total: page.totalPages() })}
                        </span>
                        {t("pagination.items-from-to", "Items {{from}} .. {{to}}", { from: page.from(), to: page.to() })}{" "}
                        {t("pagination.of-items", "of {{itemCount}}", { itemCount: page.getTotal() })}
                    </div>
                }

                {
                    knownTotal && page.getTotal() === 0 && <div>
                    <span className="slds-text-title_bold slds-p-right--small">
                        {t("pagination.no-items", "No items")}
                    </span>
                </div>
                }

                {
                    !knownTotal &&
                  <div>
                        <span className="slds-text-title_bold slds-p-right--small">
                            {t("pagination.page-current", "Page {{current}}", { current: page.currentPage() })}
                        </span>
                        {t("pagination.items-from-to", "Items {{from}} .. {{to}}", { from: page.from(), to: page.to() })}
                    </div>
                }

            </div>
            <ButtonGroup className="slds-p-left--small">
                <Button iconName={"forward"} iconPosition={"right"} onClick={() => page.next()} disabled={!page.hasNext()}>
                    {t("pagination.button-next", "Next")}
                </Button>
            </ButtonGroup>
        </div>
    )
};

DebouncedPagination.propTypes = {
    page: PropTypes.any,
};

export default DebouncedPagination;
