import React, {useEffect} from 'react';
import Page from "../../common/ui/page";
import {Link} from "react-router-dom";
import Button from "../../common/slds/buttons/button";
import CollapsibleString from "../../organisation/config/wmbus/CollapsibleString";
import {useMutation, useQuery} from "@apollo/client";
import {
    MUTATION_UPLOAD_LICENSE,
    QUERY_API_STATISTICS_SUM,
    QUERY_LICENSE,
    QUERY_ORG_COUNT_GLOBAL,
    QUERY_USER_COUNT_GLOBAL
} from "../queries";
import {CardBody} from "../../common/slds/cards/card";
import {Card} from "@salesforce/design-system-react";
import {useT} from "../../common/i18n";
import {Icon} from "../../common/slds/icons/icon";
import {useNotificationContext} from "../../notifications/notificationContext";
import {Log} from "../../common/log";
import {backendUrl} from "../../common/helper";
import {Flex} from "antd";
import semver from "semver/preload";

export const LicensePage = () => {
    const fullLicense = useQuery(QUERY_LICENSE)
    const [uploadFn, ] = useMutation(MUTATION_UPLOAD_LICENSE)
    const now = new Date()
    const dayStart = new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate()))
    Log.Debug("Day start:", dayStart)
    const initialFilter = [{"field": 'createdAt', "op": "gte", "value": dayStart}]
    // Not using a filter hook as this is a static view (only today's statistics sum)
    const apiCalls = useQuery(QUERY_API_STATISTICS_SUM, {variables: {filter: initialFilter}})
    const orgCount = useQuery(QUERY_ORG_COUNT_GLOBAL)
    const userCount = useQuery(QUERY_USER_COUNT_GLOBAL)
    const t = useT();
    const notify = useNotificationContext()

    const [buildInfo, setBuildInfo] = React.useState({
        "projectName": "lobaro-iot-platform",
        "version": "0.0.0-dev",
        "buildDate": "unknown",
        "revision": "none",
        "branch": "unknown",
        "build": "unknown",
    })

    const uploadLicense = file => {
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                // Check if the license file is the "proper" json, or if it's just a raw license
                if (typeof reader.result !== "string") {
                    reject("Failed to load license as text!")
                }
                const result = JSON.parse(reader.result);
                if (result.license) {
                    resolve(reader.result)
                }
                // Else it has to be a JWT-only string
                if (typeof result !== "string") {
                    reject("Not a valid license file!")
                }
                resolve(JSON.stringify({"license": reader.result}));
            };
            reader.onerror = reject;
            reader.readAsText(file);
        })
            .then(text => {
                return uploadFn({variables: {token: text}})
            })
            .then(res => {
                if (res.errors) {
                    throw new Error(res.errors[0].message)
                }
                notify.success(t("config.settings.license.upload_success", "License uploaded successfully!"))
                Log.Info("License uploaded successfully!", res)
            })
            .catch(err => {
                notify.error("Failed to upload license!\n" + (err?.message || err), err);
            })
    }

    const handleDrop = e => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        uploadLicense(file)
    }

    const expiry = fullLicense.data?.license.claims?.exp;
    const features = Array.isArray(fullLicense.data?.license.claims?.aud) ? fullLicense.data?.license.claims?.aud.toString() : fullLicense.data?.license.claims?.aud

    // Merge license API limits with daily API statistics for better rendering
    const apiStatDisplay = fullLicense.data?.license.claims?.apiLimits?.map(limit => {
        const stat = (Array.isArray(apiCalls.data?.apiStatisticSum) && apiCalls.data?.apiStatisticSum.find(stat => stat.url === limit.url)) || null
        return <ApiCalls key={limit.url} url={limit.url} calls={stat?.count} available={limit.limit}/>
    })
    // Add any API statistics that are not in the license
    const additionalStats = (Array.isArray(apiCalls.data?.apiStatisticSum) && apiCalls.data?.apiStatisticSum.filter(stat => !fullLicense.data?.license.claims?.apiLimits?.find(limit => limit.url === stat.url))
        .map(stat => <ApiCalls key={stat.url} url={stat.url} calls={stat.count}/>)) || null

    // Get buildinfo from /info
    useEffect(() => {fetch(backendUrl() + "/info")
        .then(response => {
            if (!response.ok) {
                throw new Error(response.statusText)
            }
            return response.json()
        })
        .then(res => setBuildInfo(res))
        .catch(err => {
            notify.error("Failed to get build info!\n" + (err.message || err), err)
            setBuildInfo({
                "projectName": "lobaro-iot-platform",
                "version": "0.0.0-dev",
                "buildDate": "unknown",
                "revision": "none",
                "branch": "unknown",
                "build": "unknown",
            })
        })}, [])

    return (
        <Page
            trail={[<Link key={1} to={"./license"}>{t("config.settings.license.title", "License")}</Link>]}
            title={t("config.settings.license.fulltitle", "License information")}
            actions={<div onDragOver={e => e.preventDefault()}
                          onDrop={e => handleDrop(e)}>
                <Button onClick={() => document.getElementById("licenseUploader").click()}
                        iconName={"upload"}>{t("config.settings.license.upload", "Upload new license file")}</Button></div>}
        >
            <Card bordered={false}
                  heading={<header className={"slds-media slds-media_center slds-has-flexi-truncate"}>
                      <h2 className={"slds-card__header-title"}>{t("config.settings.license.title", "License")}</h2>
                    </header>}
            >
                <CardBody inner={true}>
                    <div className={"slds-grid"}>
                        <div className={"slds-col slds-size_3-of-12"}>
                            <Flex align={"baseline"}><div>{t("config.settings.license.rawtitle", "Raw license")}:</div></Flex>
                        </div>
                        <div className={"slds-col slds-size_9-of-12"}>
                            <CollapsibleString
                                fullStr={fullLicense.data?.license.raw || t("config.settings.license.failure", "failed to get license!")}
                                charLimit={25} withEnd={false} className={"slds-text-font_monospace"}/>
                        </div>
                    </div>
                    {fullLicense.data?.license.validationError && <div className={"slds-grid"}>
                        <div
                            className={"slds-col slds-size_3-of-12"}>{t("config.settings.license.parse_error", "Validation error")}:
                        </div>
                        <div className={"slds-col slds-size_7-of-12"}>
                            {fullLicense.data?.license.validationError}
                        </div>
                        <div className={"slds-col slds-size_2-of-12"}>
                            <Icon name={"close"} size={"x-small"} colorVariant={"error"}/>
                        </div>
                    </div>}
                    <div className={"slds-grid"}>
                        <div
                            className={"slds-col slds-size_3-of-12"}>{t("config.settings.license.expiry", "Valid until")}:
                        </div>
                        <div className={"slds-col slds-size_7-of-12"}>
                            {expiry ? new Date(expiry).toLocaleString() : t("config.settings.license.failure", "failed to get license!")}
                        </div>
                        <div className={"slds-col slds-size_2-of-12"}>
                            {expiry && (new Date(expiry) < Date.now()) ?
                                <Icon name={"close"} size={"x-small"} colorVariant={"error"}/> :
                                <Icon name={"check"} size={"x-small"} colorVariant={"success"}/>}
                        </div>
                    </div>
                    <div className={"slds-grid"}>
                        <div
                            className={"slds-col slds-size_3-of-12"}>{t("config.settings.license.feature_other", "Features")}:
                        </div>
                        <div className={"slds-col slds-size_9-of-12"}>
                            {features || t("config.settings.license.failure", "failed to get license!")}
                        </div>
                    </div>
                    <div className={"slds-grid"}>
                        <div
                            className={"slds-col slds-size_3-of-12"}>{t("config.settings.license.licensee", "Issued to")}:
                        </div>
                        <div className={"slds-col slds-size_9-of-12"}>
                            {fullLicense.data?.license.claims?.sub || t("config.settings.license.failure", "failed to get license!")}
                        </div>
                    </div>
                    <hr/>
                    {/*<div className={"slds-grid"}>
                        <div className={"slds-col slds-size_2-of-12"}>Issued by:</div>
                        <div className={"slds-col slds-size_10-of-12"}>
                            {fullLicense.data?.license.claims?.iss || t("config.settings.license.failure", "failed to get license!")}
                        </div>
                    </div>*/}
                    {/*<div className={"slds-grid"}>
                        <div className={"slds-col slds-size_2-of-12"}>Issued at:</div>
                        <div className={"slds-col slds-size_10-of-12"}>
                            {fullLicense.data?.license.claims?.iat ? new Date(Date.parse(fullLicense.data?.license.claims?.iat)).toLocaleString() : t("config.settings.license.failure", "failed to get license!")}
                        </div>
                    </div>*/}
                    <div className={"slds-grid"}>
                        <div
                            className={"slds-col slds-size_3-of-12"}>{t("config.settings.license.max_users", "Maximum users")}:
                        </div>
                        <div className={"slds-col slds-size_7-of-12"}>
                            {((fullLicense.data?.license.claims?.maxUser !== undefined) && ((fullLicense.data.license.claims.maxUser === 0) ? t("common.unlimited", "unlimited") : fullLicense.data?.license.claims?.maxUser.toString())) || "1"}
                        </div>
                        <div className={"slds-col slds-size_2-of-12"}>
                            {userCount.data !== undefined && ((fullLicense.data?.license.claims?.maxUser === 0) || userCount.data.getUserCount <= fullLicense.data?.license.claims?.maxUser) ? <Icon className={"slds-p-right_xx-small"} name={"check"} size={"x-small"} colorVariant={"success"}/> : <Icon className={"slds-p-right_xx-small"} name={"close"} size={"x-small"} colorVariant={"error"}/>}
                            {userCount.data !== undefined ? `${userCount.data.getUserCount || 0} ${t("common.registered", "registered")}`: t("config.settings.user-count.fail", "failed to get user count")}
                        </div>
                    </div>
                    <div className={"slds-grid"}>
                        <div
                            className={"slds-col slds-size_3-of-12"}>{t("config.settings.license.max_orgs", "Maximum organisations")}:
                        </div>
                        <div className={"slds-col slds-size_7-of-12"}>
                            {fullLicense.data?.license.claims?.maxOrg !== undefined && ((fullLicense.data.license.claims.maxOrg === 0) ? t("common.unlimited", "unlimited") : fullLicense.data?.license.claims?.maxOrg.toString()) || "1"}
                        </div>
                        <div className={"slds-col slds-size_2-of-12"}>
                            {orgCount.data !== undefined && ((fullLicense.data?.license.claims?.maxOrg === 0) || orgCount.data.getOrganisationCount <= fullLicense.data?.license.claims?.maxOrg) ? <Icon className={"slds-p-right_xx-small"} name={"check"} size={"x-small"} colorVariant={"success"}/> : <Icon className={"slds-p-right_xx-small"} name={"close"} size={"x-small"} colorVariant={"error"}/>}
                            {orgCount.data !== undefined ? `${orgCount.data.getOrganisationCount || 0} ${t("common.registered", "registered")}`: t("config.settings.org-count.fail", "failed to get org count")}
                        </div>
                    </div>
                    <div className={"slds-grid"}>
                        <div
                            className={"slds-col slds-size_3-of-12"}>{t("config.settings.license.prod_mode", "For production")}:
                        </div>
                        <div className={"slds-col slds-size_9-of-12"}>
                            {fullLicense.data?.license.claims?.dev || fullLicense.data?.license.validationError ?
                                <Icon name={"close"} size={"x-small"} colorVariant={"error"}/> :
                                <Icon name={"check"} size={"x-small"} colorVariant={"success"}/>}
                        </div>
                    </div>
                    <div className={"slds-grid"}>
                        <div
                            className={"slds-col slds-size_3-of-12"}>{t("config.settings.license.ver_range", "Valid for versions")}:
                        </div>
                        <div className={"slds-col slds-size_7-of-12"}>
                            {fullLicense.data?.license.claims?.version !== undefined ? (fullLicense.data?.license.claims?.version.toString() || t("config.settings.license.allVersions", "all versions")) : t("config.settings.license.failure", "failed to get license!")}
                        </div>
                        <div className={"slds-col slds-size_2-of-12"}>
                            {fullLicense.data?.license.claims?.version === undefined || fullLicense.data.license.claims.version === "" || buildInfo?.version !== undefined && (semver.valid(buildInfo.version) && semver.satisfies(buildInfo.version, fullLicense.data?.license.claims?.version)) ? <Icon name={"check"} size={"x-small"} colorVariant={"success"}/> : <Icon name={"close"} size={"x-small"} colorVariant={"error"}/>}
                        </div>
                    </div>
                    <hr/>
                    <VersionInfo info={buildInfo}/>
                    <hr/>
                    <h3 className={"slds-text-heading_small slds-p-bottom_small"}>{t("config.settings.license.api_calls", "Daily API calls")}</h3>
                    {apiStatDisplay}
                    {additionalStats}
                </CardBody>
            </Card>
            <input type={"file"} id={"licenseUploader"} style={{display: "none"}} accept={"application/json"}
                   onChange={e => uploadLicense(e.target.files[0])}
            />
        </Page>
    );
}

const ApiCalls = ({url, calls, available}) => {
    const t = useT();
    return <div className={"slds-grid"}>
        <div className={"slds-col slds-size_3-of-12"}>
            {url}
        </div>
        <div className={"slds-col slds-size_7-of-12"}>
            {`${calls || 0} / ${available || t("common.unlimited", "unlimited")} ${t("common.today", "today")}`}
        </div>
        <div className={"slds-col slds-size-2-of-12"}>
            {available && calls >= available ? <Icon name={"close"} size={"x-small"} colorVariant={"error"}/> :
                <Icon name={"check"} size={"x-small"} colorVariant={"success"}/>}
        </div>
    </div>
}

const VersionInfo = ({info}) => {
    const t = useT();

    const buildDate = new Date(info?.buildDate).toLocaleString() === "Invalid Date" ? info?.buildDate : new Date(info?.buildDate).toLocaleString()
    return <>
        <h3 className={"slds-text-heading_small slds-p-bottom_small"}>{t("config.settings.license.version.title", "Version info")}</h3>
        <div className={"slds-grid"}>
            <div className={"slds-col slds-size_3-of-12"}>
                {t("config.settings.license.version.project-name", "Project name")}
            </div>
            <div className={"slds-col slds-size_9-of-12"}>
                {info?.projectName || t("config.settings.license.version.failure", "failed to get build info!")}
            </div>
        </div>
        <div className={"slds-grid"}>
            <div className={"slds-col slds-size_3-of-12"}>
                {t("config.settings.license.version.version", "Version")}
            </div>
            <div className={"slds-col slds-size_9-of-12"}>
                {info?.version || t("config.settings.license.version.failure", "failed to get build info!")}
            </div>
        </div>
        <div className={"slds-grid"}>
            <div className={"slds-col slds-size_3-of-12"}>
                {t("config.settings.license.version.build-date", "Build date")}
            </div>
            <div className={"slds-col slds-size_9-of-12"}>
                {buildDate || t("config.settings.license.version.failure", "failed to get build info!")}
            </div>
        </div>
        <div className={"slds-grid"}>
            <div className={"slds-col slds-size_3-of-12"}>
                {t("config.settings.license.version.revision", "Revision")}
            </div>
            <div className={"slds-col slds-size_9-of-12"}>
                {info?.revision || t("config.settings.license.version.failure", "failed to get build info!")}
            </div>
        </div>
        <div className={"slds-grid"}>
            <div className={"slds-col slds-size_3-of-12"}>
                {t("config.settings.license.version.branch", "Branch")}
            </div>
            <div className={"slds-col slds-size_9-of-12"}>
                {info?.branch || t("config.settings.license.version.failure", "failed to get build info!")}
            </div>
        </div>
        <div className={"slds-grid"}>
            <div className={"slds-col slds-size_3-of-12"}>
                {t("config.settings.license.version.build-id", "Build Run ID")}
            </div>
            <div className={"slds-col slds-size_9-of-12"}>
                {info?.build || t("config.settings.license.version.failure", "failed to get build info!")}
            </div>
        </div>
    </>
}