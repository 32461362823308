import {Button, Card, Icon} from "@salesforce/design-system-react";
import {Form, Formik, insert} from "formik";
import {Log} from "../../common/log";
import {FormActions, SldsInputField, SldsSelectField} from "../../common/ui/form/formElements";
import {PillContainerField} from "../../common/ui/form/pillContainerField";
import Lookup from "../../common/ui/lookup/lookup";
import * as React from "react";
import {useT} from "../../common/i18n";


export const CreateApiCredentials = props => {
    const t = useT()

    const handleSubmit = props.handleFormikSubmit
    const ROLES = props.roles || []
    const preselect = props.preSelectRoles || [] // should be an array of the role names (only!)
    const getRole = props.getRole || ((name) => {
        return ROLES.find(it => it.name === name) || {name: name, displayName: name + "*"};
    })

    return (<Card
        heading={t("integrations.rest.create-credentials-heading", "Create New Credentials")}
        className="slds-card_boundary slds-p-horizontal--x-small"
        icon={<Icon category="utility" name="record_create" size="small"/>}
    >

        <Formik initialValues={{
            name: t("integrations.rest.api-credentials-heading", "API Credentials"),
            roles: preselect,
        }}
                validate={(values) => {
                    let errors = {};
                    if (!values.name) {
                        errors.name = t("integrations.rest.validation.name-not-empty", "Name must not be empty");
                    }
                    return errors;
                }}
                enableReinitialize={true}
                onSubmit={handleSubmit}
        >{(formik) => {
            return <Form className="slds-m-bottom--medium">
                <SldsInputField className="slds-m-top--small" name={"name"}
                                label={t("integrations.rest.name", "Name")}/>
                <SldsSelectField label={t("integrations.rest.type", "Type")} name={"type"} options={[
                    {label: "Bearer Token", value: "bearer"},
                    {label: "Basic Auth", value: "basic-auth"},
                ]}/>
                <PillContainerField name={"roles"} label={t("integrations.rest.roles", "Roles")}
                                    pillLabelExtractor={(it) => getRole(it).displayName}
                                    renderLookup={() => {
                                        return <Lookup
                                            placeholder={t("integrations.rest.placeholder.add-role", "Add Role ...")}
                                            iconExtractor={() => <Icon category="standard" name="user_role"
                                                                       size="small"/>}
                                            titleExtractor={(r) => r.name}
                                            subtitleExtractor={() => undefined}
                                            valueExtractor={(option) => option.name}
                                            onLookup={(value) => {
                                                let list = formik.values["roles"] || [];
                                                formik.setFieldValue("roles", insert(list, list.length, value));
                                            }}
                                            loadSuggestions={(value, formik) => {
                                                Log.Debug("Suggestions", value, formik);
                                                const search = value.toLowerCase();
                                                return ROLES.map((t) => {
                                                    const displayName = getRole(t.name)?.displayName.toLowerCase();
                                                    const name = getRole(t.name)?.name.toLowerCase();

                                                    if ((name || displayName) && (( name && name.includes(search)) || (displayName && displayName.includes(search)) )) {
                                                        return t;
                                                    }
                                                })
                                                    .filter(it => !!it)
                                                    .filter(item => {
                                                        let roles = formik.values.roles || [];
                                                        return roles.findIndex(it => item.name === it) === -1;
                                                    });
                                            }}

                                        />;
                                    }}/>
                <FormActions>
                    <Button
                        type="submit">{t("integrations.rest.create-credentials", "Create New Credentials")}</Button>
                </FormActions>
            </Form>;
        }}
        </Formik>
    </Card>)
}
