import React, {useState} from "react"
import {Button, Flex} from "antd";
import {CopyOutlined, LeftOutlined, RightOutlined} from "@ant-design/icons";
import "./CollapsibleString.css"
import {useNotificationContext} from "../../../notifications/notificationContext";
import {useT} from "../../../common/i18n";
import {SizeType} from "antd/lib/config-provider/SizeContext";

interface CollapsibleStringProps {
    fullStr: string
    charLimit: number
    withEnd: boolean
    className?: string
}

// Display a string that can be collapsed to a charLimit length for beginning and end; click on it to toggle collapsing
const CollapsibleString : React.FC<CollapsibleStringProps> = ({fullStr, charLimit, withEnd, className}) => {
    const [collapsed, setCollapsed] = useState(true)
    const minDisplay = withEnd ? charLimit * 2 : charLimit

    const t = useT()

    if (fullStr.length <= minDisplay) {
        return <Flex align={"baseline"}>{fullStr}</Flex>
    }

    return (
        <Flex align={"baseline"}>
            <div onClick={() => setCollapsed(!collapsed)} className={`coll-str ${className|| ""}`}>
                {collapsed && fullStr.substring(0, charLimit) || fullStr}
                {collapsed && fullStr.length > minDisplay && '.....' || ''}
                {collapsed && withEnd && fullStr.substring(fullStr.length - charLimit) || ''}
            </div>
            <Button aria-label={collapsed ? t("util.string.expand", "Expand string") as string : t("util.string.collapse", "Collapse string") as string} className={"coll-button"} type={"link"} size={"small"} onClick={() => setCollapsed(!collapsed)} icon={collapsed && <RightOutlined /> || <LeftOutlined />} />
            {/*{collapsed && <RightOutlined style={{verticalAlign: "baseline"}}/> || <LeftOutlined style={{verticalAlign: "baseline"}}/>}</Button>*/}
            <CopyButton text={fullStr}/>
        </Flex>
    )
}

export const CopyButton: React.FC<{ text: string, size?: SizeType, className?: string }> = ({text, size, className}) => {
    const t = useT()
    const notify = useNotificationContext()

    if (!size) {
        size = "small"
    }

    const copyToClipboard = (e: React.MouseEvent) => {
        e.preventDefault()
        window.navigator.clipboard.writeText(text)
            .then(() => notify.success(t("util.string.copy-success", "Copied text to clipboard.")))
            //.then(() => {throw new Error("Das ist ein Test")})
            .catch(err => notify.error(t("util.string.copy-error", {err: err?.message || err}), err)) // "Failed to copy text to clipboard: {{err}}"
    }

    return <Button aria-label={t("util.string.copy", "Copy to Clipboard") as string} className={"coll-button" + (className ? " " + className : "")}
                   type={"link"} size={size} onClick={e => copyToClipboard(e)} icon={<CopyOutlined/>} />
}

export default CollapsibleString;
