import {useQuery} from "@apollo/client";
import SingleLookupField from "../../common/ui/lookup/singleLookupField";
import * as React from "react";
import gql from "graphql-tag";

export default function DeviceTypeLookupField(props) {
    const {orgId} = props
    const deviceTypesResult = useQuery(gql`
        query ($search: String, $orgId: ID) {
            deviceTypes(page: {limit: 25, offset: 0}, search: $search, orgId: $orgId) {
                id
                name
                displayName
                configProperties
                organisationId
                private
            }
        }
    `);

    return <SingleLookupField
        name={"deviceType"} label={"Device Type"}
        subtitleExtractor={it => it.id + " - " + it.name}
        titleExtractor={it => it.displayName}
        loadSuggestions={keyword => deviceTypesResult.refetch({search: keyword, orgId: orgId})
            .then(result => result.data.deviceTypes)
        }
    />;
}