import React from "react";
import PropTypes from "prop-types";
import {Link, useLocation} from "react-router-dom";
import classNames from "classnames"

const TopNavigationLink = (props) => {
    const location = useLocation();
    let {to, label, active, children} = props;

    if (active === undefined) {
        //Log.Debug("TopNavigationLink", location.pathname, to, matchPath(location.pathname, {path: to}));
        active = location.pathname.startsWith(to);
    }
    const cnames = classNames("slds-context-bar__item", {
        "slds-is-active": active,
    })
    return <li className={cnames}>
        <Link to={to} className="slds-context-bar__label-action" title={label}>
        <span className="slds-truncate" title={label}>{children || label}</span>
    </Link>
    </li>
};

TopNavigationLink.propTypes = {
    to: PropTypes.string.isRequired,
    label: PropTypes.string,
    active: PropTypes.bool, // Can override active
    children: PropTypes.any,
};

export default TopNavigationLink