import {useQuery} from "@apollo/client";
import * as React from "react";
import gql from "graphql-tag";
import Lookup from "../../common/ui/lookup/lookup";
import {getIn, insert} from "formik";
import PropTypes from "prop-types";
import {Log} from "../../common/log";
import {PillContainerField} from "../../common/ui/form/pillContainerField";

const DeviceTypeMultiLookupField = (props) => {
    const deviceTypesResult = useQuery(gql`
        query ($search: String) {
            deviceTypes(page: {limit: 25, offset: 0}, search: $search) {
                id
                name
                displayName
                configProperties
            }
        }
    `);
    const {name, label} = props;


    return <PillContainerField
        name={name} label={label} pillLabelExtractor={(it) => it.displayName}
        renderLookup={(formik) => {
            const {form} = formik;
            return <Lookup
                placeholder={"Add Device Type ..."}
                subtitleExtractor={it => it.id + " - " + it.name}
                titleExtractor={it => it.displayName}

                loadSuggestions={keyword => deviceTypesResult.refetch({search: keyword})
                    .then(result => result.data.deviceTypes)
                }
                onLookup={(value) => {
                    Log.Debug("formik.values", form);
                    let list = getIn(form.values, name) || [];
                    form.setFieldValue(name, insert(list, list.length, value));
                }}
            />;
        }}/>;
};

DeviceTypeMultiLookupField.defaultProps = {
    //name: "deviceTypes"
    label: "Device Types"
};

DeviceTypeMultiLookupField.propTpyes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
};
export default DeviceTypeMultiLookupField;
