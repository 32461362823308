import React, { useEffect, useRef, useState } from "react";

export const SearchInput = ({ search, page, highlight }) => {
  const value = useRef("")

  const [doExecuteOscillator, setDoExecuteOscillator] = useState(false);
  const timeoutActiveRef = useRef(null)
  const timer = useRef(null)

  const timeoutMillis = 200;

  const createTimeout = () => {
    timer.current = setTimeout(() => {

      timeoutActiveRef.current = false;
      setDoExecuteOscillator(!doExecuteOscillator);
    }, timeoutMillis);
  }

  const updateSearch = (keyword) => {
    value.current = keyword;

    // Start a countdown to execute search.
    // While the countdown is running, we don't want to start another countdown.
    if (!timeoutActiveRef.current) {
      timeoutActiveRef.current = true;
      createTimeout();
    } else {
      // The timeout is already active, clear the timeout and start again
      // We want the search only to happen if no new changes to the search are incoming
      timeoutActiveRef.current = true;
      clearTimeout(timer.current);
      createTimeout();
    }
  };

  useEffect(() => {
    search.setKeyword(value.current);
    page.reset();
    highlight(value.current);
  }, [doExecuteOscillator]);

  return (
    <div className="slds-col">
      <div className="slds-input-has-icon slds-input-has-icon_right">
        <input
          className="slds-input"
          onChange={(e) => {
            updateSearch(e.target.value)
          }}
        />
        <span className="slds-icon_container slds-icon-utility-search slds-input__icon slds-input__icon_right">
                    <svg className="slds-icon slds-icon slds-icon_x-small slds-icon-text-default" aria-hidden="true">
                        <use href="/assets/icons/utility-sprite/svg/symbols.svg#search"></use>
                    </svg>
                </span>
      </div>
    </div>
  )
}