import {Modal} from "../../../common/slds";
import React from "react";
import {Button, Form, Input} from "antd";
import {createValidator, validateManufacturer, validateMeterId, validateMeterKey} from "./wmbusValidators";
import {useT} from "../../../common/i18n";

interface Props {
    isOpen: boolean
    close: () => void
    onSave: (meterId: string, manufacturer: string, encryptionKey: string) => void
}

const AddKeyModal: React.FC<Props> = (props: Props) => {
    const [form] = Form.useForm()
    const t = useT()

    form.resetFields()

    const meterIdValidator = createValidator(t, validateMeterId)
    const manufacturerValidator = createValidator(t, validateManufacturer)
    const keyValidator = createValidator(t, validateMeterKey)

    const isValid = async () => {
        await form.validateFields()
        const validationErrors = Object.values(form.getFieldsError())
        return !validationErrors.some(e => e.errors.length > 0)
    }
    const save = async () => {
        if (await isValid()) {
            props.onSave(form.getFieldValue("meterId"), form.getFieldValue("manufacturer"), form.getFieldValue("key"))
            props.close()
        }
    }
    return <Modal isOpen={props.isOpen} onRequestClose={props.close}>
        <div
            className="slds-text-heading--medium slds-m-bottom--x-small">{t("org.config.wmbus.add_key", "Add Key") as string}</div>
        <Form
            form={form}
            labelCol={{span: 5}}
            wrapperCol={{span: 18}}
        >
            <Form.Item
                name={"meterId"}
                label={t("org.config.wmbus.meter_id", "Meter Id")}
                rules={[{
                    validator: (_, value) => {
                        return meterIdValidator(value)
                    }
                }]}>
                <Input placeholder={t("org.config.wmbus.meter_id_placeholder", "Optional Meter Id") as string}/>
            </Form.Item>
            <Form.Item
                name={"manufacturer"}
                label={t("org.config.wmbus.manufacturer", "Manufacturer")}
                rules={[{
                    validator: (_, value) => {
                        return manufacturerValidator(value)
                    }
                }]}>
                <Input placeholder={t("org.config.wmbus.manufacturer_placeholder", "Optional Manufacturer") as string}/>
            </Form.Item>
            <Form.Item
                name={"key"}
                label={t("org.config.wmbus.encryption_key", "Encryption Key")}
                required
                rules={[
                    {
                        required: true,
                        message: t("org.config.wmbus.encryption_key_validation", "Key may not be empty.") as string
                    },
                    {
                        validator: (_, value) => {
                            return keyValidator(value)
                        }
                    }
                ]}>
                <Input placeholder={t("org.config.wmbus.encryption_key_placeholder", "Please enter Encryption Key") as string}/>
            </Form.Item>
            <Form.Item>
                <Button onClick={save} type="primary">{t("common.button.save", "Save") as string}</Button>
            </Form.Item>
        </Form>
    </Modal>
}

export default AddKeyModal
