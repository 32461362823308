import { ApolloClient, ApolloLink, InMemoryCache, useQuery } from "@apollo/client"
import { createUploadLink } from "apollo-upload-client"
import { onError } from "apollo-link-error"
import PropTypes from "prop-types"
import React from "react"
import { Query } from "@apollo/client/react/components"
import * as log from "./log"
import { Log } from "./log"
import { Icon, ScopedNotification } from "@salesforce/design-system-react"
import { backendUrl } from "./helper"
import authenticatedFetch from "./authenticatedFetch"


if (!backendUrl()) {
    throw new Error("backendUrl not defined!")
}

//const url = backendUrl() + "/graphql";
const url = backendUrl() + "/gqlgen/query";

let httpLink = createUploadLink({uri: url, fetch: authenticatedFetch, fetchOptions: false});

let wsUrl = `${backendUrl()}/graphqlws`;
wsUrl = wsUrl.replace("http://", "ws://");
wsUrl = wsUrl.replace("https://", "wss://");

log.Debug("GraphqlWs: ", wsUrl);

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent

/*
let apolloLink = split(
    // split based on operation type
    ({query}) => {
        const {kind, operation} = getMainDefinition(query);
        return kind === 'OperationDefinition' && operation === 'subscription';
    },
    new WebSocketLink({
        uri: wsUrl,
        options: {
            reconnect: true
        }
    }),
    httpLink,
);
*/


let apolloLink = httpLink;

apolloLink = ApolloLink.from([
    onError((err) => {
        Log.Error("raw", err)
        const {graphQLErrors, networkError} = err;
        if (graphQLErrors) {
            graphQLErrors.map(({message, locations, path, extensions}) => {
                    let location;
                    try {
                        location = JSON.stringify(locations);
                    } catch (e) {
                        location = locations
                    }
                    log.Error(
                        `[GraphQL error]: Message: ${message}, Location: ${location}, Path: ${path}, extension: ${JSON.stringify(extensions)}`,
                    )
                },
            );
        }

        if (networkError) {
            log.Error(`[Network error]: `, networkError);
        }
    }),
    apolloLink
]);

const client = new ApolloClient({
    link: apolloLink,
    cache: new InMemoryCache({
        resultCaching: true,
    }),
    connectToDevTools: true,
    defaultOptions: {
        watchQuery: {
            fetchPolicy: 'network-only', // cache-and-network
        },
        query: {
            fetchPolicy: 'network-only',
        },
        mutate: {
        }
    }
});

export function useQueryWithLoading(query, options) {
    const queryResult = useQuery(query, options);
    const loading = useGraphqlLoadingComponent(queryResult);
    return [queryResult, loading]
}

// TODO: Use ./slds/spinner/ but needs testing ...
const Spinner = (props) => {
    return <div className={props.containerClass || ""} style={{minHeight: "50px"}}>
        <div className="slds-spinner_container xx-slds-is-fixed">
            <div role="status" className="slds-spinner slds-spinner_small slds-spinner_brand slds-spinner_delayed">
                <span className="slds-assistive-text">Loading</span>
                <div className="slds-spinner__dot-a"></div>
                <div className="slds-spinner__dot-b"></div>
            </div>
        </div>
    </div>;
};

/**
 * Takes a graphQL result and returns a component to display loading and error state.
 * When loading is finished without an error, null is returned.
 *
 * Usage:
 *
 * const loading = useGraphqlLoadingComponent(result);
 * if (loading) {
 *   return loading;
 * }
 *
 * @param result
 * @param opts
 * @returns {null|*}
 */
export function useGraphqlLoadingComponent(result, opts = {}) {
    const {containerClass} = opts || {};

    if (result === undefined || result.networkStatus === undefined) {
        // happens for "skipped" queries
        return null;
    }
    if (result.error) {
        Log.Error(`GraphQL Error:`, result, result.error);
        if (result.error.message?.startsWith("Network error")) {
            return <ScopedNotification
                icon={<Icon
                    assistiveText={{
                        label: 'Warning',
                    }}
                    category="utility"
                    colorVariant="error"
                    name="error"
                    size="small"
                />
                }
                theme="light"
            >
                {result.error.message}
            </ScopedNotification>
        } else if (result.error.message === "xxxGraphQL error: not authorized: not logged in") {
            return <ScopedNotification
                icon={<Icon
                    assistiveText={{
                        label: 'Warning',
                    }}
                    category="utility"
                    colorVariant="error"
                    name="error"
                    size="small"
                />
                }
                theme="light"
            >
                Not Authorized: Not logged in.
            </ScopedNotification>
        }

        return (
            <div>
                <ScopedNotification
                    icon={
                        <Icon
                            assistiveText={{
                                label: "Warning",
                            }}
                            category="utility"
                            colorVariant="error"
                            name="error"
                            size="small"
                        />
                    }
                    theme="light">
                    {result?.error?.message}
                </ScopedNotification>
                {result?.error?.graphQLErrors.map((e, i) => {
                    return (
                        <ScopedNotification
                            key={i}
                            icon={
                                <Icon
                                    assistiveText={{
                                        label: "Warning",
                                    }}
                                    category="utility"
                                    colorVariant="error"
                                    name="error"
                                    size="small"
                                />
                            }
                            theme="light">
                            {e.message}
                        </ScopedNotification>
                    )
                })}
            </div>
        )
    }
    Log.Debug("result data:", result.data, !result.data);
    Log.Debug("result loading:", result.loading);
    Log.Debug("result networkStatus:", result.networkStatus);
    if ((
            result.loading
            && result.networkStatus !== 6 // No spinner when "poll"
            && result.networkStatus !== 3 // No spinner when "fetchMore"
        )) {
        return <Spinner className={""} containerClass={containerClass}/>;
    }
    return null;

}

// Takes a graphQL result and displays Loading and Error state.
// After the data was loaded, the child components are rendered.
const GraphqlLoadingErrorHandler = (props) => {

    const {result, ...otherProps} = props;

    const loading = useGraphqlLoadingComponent(result, otherProps);
    if (loading) {
        return loading;
    }

    if (typeof props.children === 'function') {
        return props.children();
    } else {
        return props.children;
    }
};

GraphqlLoadingErrorHandler.propTypes = {
    children: PropTypes.any,
    result: PropTypes.object.isRequired,
    containerClass: PropTypes.string,
};


const QuerySafe = (props) => {
    const {children, ...queryProps} = props;
    return <Query
        partialRefetch={true} // We need this to update the UI, doc says it's false be default only for backwards compatibility
        notifyOnNetworkStatusChange={true} // Else we get infinite loading sometimes
        {...queryProps}>
        {(result) => {
            return <GraphqlLoadingErrorHandler result={result} containerClass={"slds-card"}>
                {() => {
                    return children(result)
                }}
            </GraphqlLoadingErrorHandler>
        }}
    </Query>
};

QuerySafe.propTypes = {
    query: PropTypes.object.isRequired,
    fetchPolicy: PropTypes.oneOf(['cache-first', 'cache-and-network', 'network-only', 'cache-only', 'no-cache']),
    variables: PropTypes.object,
    pollInterval: PropTypes.number,
};

export {client, QuerySafe, GraphqlLoadingErrorHandler};