/*
* The download function takes a content string, the filename and mimeType as parameters
*
* This solution should work with Internet Explorer 10+, Edge, old and new versions of Chrome, FireFox, Safari, ++
* https://stackoverflow.com/questions/14964035/how-to-export-javascript-array-info-to-csv-on-client-side
*
* Usage: download(csvContent, 'dowload.csv', 'text/csv;encoding:utf-8');
*/
export function download(content, fileName, mimeType) {
    const a = document.createElement('a');
    mimeType = mimeType || 'application/octet-stream';

    if (navigator.msSaveBlob) { // IE10
        navigator.msSaveBlob(new Blob([content], {
            type: mimeType
        }), fileName);
    } else if (URL && 'download' in a) { //html5 A[download]
        a.href = URL.createObjectURL(new Blob([content], {
            type: mimeType
        }));
        a.setAttribute('download', fileName);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    } else {
        location.href = 'data:application/octet-stream,' + encodeURIComponent(content); // only this mime type is supported
    }
}
