import React from "react";
import PropTypes from "prop-types";
import {Button} from "@salesforce/design-system-react";

// Should be wrapped in ContextBarItem
const ContextBarIconAction = (props) => {
    let {iconName, onClick} = props;

    return <div className="slds-context-bar__icon-action">
        <Button iconCategory={"utility"} iconName={iconName} variant="icon" iconSize={"small"} onClick={onClick} />
    </div>
};

ContextBarIconAction.propTypes = {
    iconName: PropTypes.string.isRequired,
    onClick: PropTypes.func,
};

export default ContextBarIconAction