import {Link} from "react-router-dom";
import React, {useContext, useRef, useState} from "react";
import Page from "../../../common/ui/page";
import {useT} from "../../../common/i18n";
import {useNotificationContext} from "../../../notifications/notificationContext";
import {useMutation, useQuery} from "@apollo/client";
import gql from "graphql-tag";
import {Log} from "../../../common/log";
import Papa, {ParseResult} from "papaparse";
import {validateManufacturer, validateMeterId, validateMeterKey, validateSensusMeterId} from "./wmbusValidators";
import {
    FormActions,
    SldsFileSelectorField,
    SldsInputField,
    SubmitButtonField
} from "../../../common/ui/form/formElements";
import {Input, InputRef, Space, Tabs, Table, Row, Col, Flex, Progress, Select, Button} from "antd";
import {Formik} from "formik";
import {v4 as uuidv4} from 'uuid'
import {Form} from "../../../common/ui/form/formik";
import sha256 from "crypto-digest-sync/sha256";
import {useAuthContext} from "../../../common/context/authContext";
import {ColumnsType, ColumnType} from "antd/es/table";
import Tooltip from "../../../common/slds/tooltip/tooltip";
import {Icon} from "../../../common/slds/icons/icon";
import Highlighter from 'react-highlight-words';
import {SorterResult} from "antd/es/table/interface";
import SldsButton from "../../../common/slds/buttons/button.jsx";
import type { BaseSelectRef } from 'rc-select';
import {TFunction} from "i18next";
import {FeatureContext} from "../../../common/context/featureContext";
import {FeatureNotEnabled} from "../../../common/featureNotEnabled";



const ImportStatus = {
    Pending: "pending",
    Done: "done",
    Failed: "failed",
    Skipped: "skipped",
}


type DataIndex = keyof KeyData | "";
/**
 * meterId, key pair to be imported
 */
class KeyData {
    keyId: string
    meterId: string | null;
    manufacturer: string | null;
    encryptionKey: string;
    encryptionKeyHash: string;
    existing: boolean | null; // is there already an existing entry for the id and manufacturer combination?
    valid: string[] | null;
    importStatus: string | null ;



    constructor(meterId, key, manufacturer, existing, valid) {
        this.keyId = uuidv4()
        this.meterId = meterId;
        this.encryptionKey = key;
        this.encryptionKeyHash = getHash(key)
        this.manufacturer = manufacturer;
        this.importStatus = ImportStatus.Pending;
        this.existing = existing
        this.valid = valid
    }
}


export interface ExchangePlatformKey {
    localId: number
    meterId: string
    manufacturer: string
    encryptionKey: string
    importStatus: string | null
}


export interface FilterConfirmProps {
    closeDropdown: boolean;
}

interface TableParams {
    sort?: SorterResult<KeyData>
    searchParams: Map<DataIndex, string | null>
    uiSearchParams: Map<DataIndex, string | null>
}


export default function KeyImportPage() {
    const license = useContext(FeatureContext) as {result: unknown, validateFeatures: (feature: string) => boolean}
    if (!license.validateFeatures("wmbus-api")) {
        return <FeatureNotEnabled/>
    }
    const t = useT();
    const notify = useNotificationContext()
    const auth = useAuthContext()

    // const [rerenderId, setRerenderId] = useState<number>(0)
    const [loadedKeys, setLoadedKeys] = useState<KeyData[]>([])

    const [tableParams, setTableParams] = useState<TableParams>({
        searchParams : new Map<DataIndex, string | null>(),
        uiSearchParams : new Map<DataIndex, string | null>(),
    });
    const searchInput = useRef<InputRef>(null);
    const selectInput = useRef<BaseSelectRef>(null);

    const [progressPercent, setProgressPercent] = useState<null|number>(null);
    const [progressAbsolute, setProgressAbsolute] = useState<null|string>(null);

    const [progressText, setProgressText] = useState<string>("");
    const [error, setError] = useState<null|boolean>(null);
    const [keysAtExchangeKeyPlatform, setKeysAtExchangeKeyPlatform ] = useState<null|number>(null);

    const getImportJob = useQuery(GET_WMBUS_KEY_EXCHANGE_JOB, {
        skip: true,
        fetchPolicy: "no-cache",
    })

    const [startImportJob] = useMutation(START_WMBUS_KEY_IMPORT_JOB, {
        fetchPolicy: "no-cache",
    })


    const [createWmbusKeys] = useMutation(CREATE_WMBUS_KEYS, {
        // duplicate id's are causing issues when cached ...
        fetchPolicy: "no-cache",
    });


    const handleSearch = (confirm: (param?: FilterConfirmProps) => void, dataIndex: DataIndex) => {
        Log.Debug("WmbusConfigPage - handleSearch", dataIndex)
        setTableParams((prev) => {
            prev.searchParams.set(dataIndex, prev.uiSearchParams.get(dataIndex) as string)
            return {...prev}
        })
         confirm()
    }

    const handleReset = (clearFilters: () => void, dataIndex: DataIndex) => {
        setTableParams((prev) => {
            prev.searchParams.delete(dataIndex)
            prev.uiSearchParams.delete(dataIndex)
            return {...prev}
        })
        clearFilters()
    }

    const handleResetAll = (clearFilters: () => void) => {
        clearFilters()
        tableParams.searchParams = new Map<DataIndex, string | null>()
        tableParams.uiSearchParams = new Map<DataIndex, string | null>()
        setTableParams({ ...tableParams })
        // setRerenderId((prev) => prev + 1)
    }


    const resetOnLoad = () => {
        setLoadedKeys([])
        setProgressPercent(null)
        setProgressAbsolute(null)
        setProgressText("")
        setError(null)
        setKeysAtExchangeKeyPlatform(null)
        // handleResetAll(()=>{Log.Debug("resetAllFilters")})
    }





    const saveKeys = async () => {
        setError(null)
        const createdKeys : Array<{encryptionKeyHash: string, manufacturer: string, meterId: string}>  = []
        //500er Chunks
        const keysToSave = loadedKeys.filter((key) => {
            return !key.existing && (key.valid == null || key.valid.length === 0)
        }).map((key) => {
            return {
                meterId: key.meterId,
                manufacturer: key.manufacturer,
                encryptionKey: key.encryptionKey,
            }})
        if (keysToSave.length === 0) {
            notify.success(t("org.config.wmbus.key-import.no-keys-to-save", "No unknown valid keys to save."))
            return
        }
        const chunkSize = 100
        const chunksNum = Math.ceil(keysToSave.length / chunkSize)
        const chunks = Array.from({length: chunksNum}, (_, i) => keysToSave.slice(i * chunkSize, i * chunkSize + chunkSize))

        let errorCnt = 0
        setProgressPercent(1)
        setProgressAbsolute(`(0 / ${keysToSave.length})`)
        const text = t("org.config.wmbus.key-import.save-keys", "Saving Keys...")
        setProgressText(text)

        for (let i = 0; i < chunks.length; i++) {
            // const chunkPromise =  () => { return new Promise((resolve) => {
            const chunkPromise =  () => { return new Promise((resolve) => {
                createWmbusKeys({
                    variables: {
                        wmbusKeys: chunks[i],
                }}).then((batchResult) => {

                    //mark successful and failed keys
                    if (batchResult.data?.createWmbusKeys !== null && batchResult.data?.createWmbusKeys !== undefined && batchResult.data?.createWmbusKeys.length > 0) {
                        createdKeys.push(...batchResult.data?.createWmbusKeys)
                    }

                    setProgressPercent(Math.round((100/chunks.length) * i))
                    setProgressAbsolute(`(${createdKeys.length} / ${keysToSave.length})`)

                    resolve(true)
                    // notify.success(t("org.config.wmbus.key-import.keys-saved", "Keys saved successfully"))
                }).catch((err) => {
                    Log.Error(err)
                    notify.error(t("org.config.wmbus.key-import.save-batch-failed", "Failed to save key batch. Check console for details."))
                    resolve(true)
                })
            })}
            await chunkPromise()
        }
        setProgressPercent(100)
        setProgressAbsolute(`(${createdKeys.length} / ${keysToSave.length})`)
        for (let i = 0; i < loadedKeys.length; i++) {
        const index = createdKeys.findIndex((result) => (result.meterId === loadedKeys[i].meterId || result.meterId == null && loadedKeys[i].meterId === "") && (result.manufacturer === loadedKeys[i].manufacturer || result.manufacturer === null && loadedKeys[i].manufacturer === "") && result.encryptionKeyHash === loadedKeys[i].encryptionKeyHash)
        if (index >= 0) {
            loadedKeys[i].importStatus = ImportStatus.Done
            loadedKeys[i].existing = true
        } else {
            if (loadedKeys[i].existing === true || loadedKeys[i].valid !== null && loadedKeys[i].valid?.length) {
                loadedKeys[i].importStatus = ImportStatus.Skipped
            } else {
                loadedKeys[i].importStatus = ImportStatus.Failed
                Log.Error("Failed to save key no", i, loadedKeys[i])
                errorCnt++
            }
        }
    }
        Log.Debug("Keys saved", createdKeys)
        Log.Debug("Error Count", errorCnt)
        if (errorCnt === 0) {
            notify.success(t("org.config.wmbus.key-import.keys-saved", "Keys saved successfully"))
        } else {
            //ammount in error loggen.
            notify.error(t("org.config.wmbus.key-import.keys-save-failed", {num: errorCnt}))
        }
        existingKeys.refetch().then(() => {Log.Debug("Refetched existing keys")}).catch(() => {Log.Debug("Failed to refetch existing keys")})
    }



    const renderExisting = (existing : boolean, key : KeyData) => {
        if (!!key.existing) {
            return <Tooltip
                left="-10px"
                top="-50px"
                content={t("wmbus-key-import.existing-key-for-filter", "Existing Key Entry for Filter found!")}>
                <Icon name="check" size={"small"}/>
            </Tooltip>
        } else {
            return <Icon name="close" size={"small"}/>
        }
    }
    const renderValid = (valid) => {
        if (!!valid && valid.length === 0) {
            return <Icon name="check" size={"small"} className="slds-m-right--x-small"/>
        } else {
            return <div className={"slds-grid slds-grid_vertical-align-center"}>
                <Icon name="close" size={"small"} className="slds-m-right--x-small"/>
                <ul> {valid && valid?.map((v, i) => {
                    return <li key={i}>{v}</li>
                })
                }
                </ul>
            </div>
        }
    }

    const renderStatus = (status) => {
        switch (status) {
            case ImportStatus.Done:
                return  <Icon name="check" size={"small"}/>
            case ImportStatus.Failed:
                return  <Icon name="close" size={"small"}/>
            case ImportStatus.Skipped:
                return <Icon name="skip" size={"small"}/>
            case ImportStatus.Pending:
                return null
            default:
                return status

        }
    }


    //csv import specific
    const processCSVResults = (results: ParseResult<unknown>) => {
        if (results) {
            const data = results.data;
            const colNames = data[0] as Array<string>;
            const isSensus = colNames.findIndex(v => v.toLowerCase() === "randomkey") >= 0;
            const idxMeterId = colNames.findIndex(v => v.toLowerCase() === (isSensus ? "radioaddress" : "meterid"));
            const idxManufacturer = colNames.findIndex(v => v.toLowerCase() === "manufacturer");
            const idxKey = colNames.findIndex(v => v.toLowerCase() === (isSensus ? "randomkey" : "key"));

            const keyPairs: KeyData[] = [];

            for (let i = 1; i < data.length; i++) {
                const rec = data[i] as Array<string>;
                const validationErrors: string[] = []
                // Note that rec[-1] === undefined
                let key = rec[idxKey];
                if (!key) {
                    Log.Debug("Skip", idxKey, rec, data[0]);
                    //key is mandatory
                    continue;
                }
                key = key.toUpperCase()
                if (key.length !== 16) {
                    key = key.toUpperCase().padStart(32, '0')
                }
                const keyValid = validateMeterKey(t, key);
                if (keyValid !== undefined) {
                    validationErrors.push(keyValid as string);
                }

                const manufacturer = (isSensus ? "SEN" : rec[idxManufacturer]);
                if (!!manufacturer) {
                    const manufacturerValid = validateManufacturer(t, manufacturer);
                    if (manufacturerValid !== undefined) {
                        validationErrors.push(manufacturerValid as string);
                    }
                }

                let meterId = rec[idxMeterId];
                if (!!meterId) {
                    meterId = meterId.toUpperCase(); // Easier to validate Sensus address; not relevant for non-Sensus meters, as their addresses are decimals only
                    const meterIdValid = (isSensus ? validateSensusMeterId(t, meterId) : validateMeterId(t, meterId));
                    if (isSensus) {
                        meterId = extractSensusId(meterId);
                    }
                    if (meterIdValid !== undefined) {
                        if (meterId === "*") {
                            meterId = ""
                        } else {
                            validationErrors.push(meterIdValid as string);
                        }
                    }
                }
                //deduplicate
                const index = keyPairs.findIndex((elem) => {return elem.meterId === meterId && elem.manufacturer === manufacturer && elem.encryptionKey === key})
                if (index === -1) {
                    keyPairs.push(new KeyData(meterId, key, manufacturer, existingCheck(meterId, manufacturer, key), validationErrors));
                }
            }

            setLoadedKeys(keyPairs);
        }
    }


    function existingCheck(meterId: string, manufacturer: string, key: string) : boolean {
        const nullToEmpty = (s: string) => {
            if (s === null || s === undefined) {
                return ''
            } else {
                return s
            }
        }
        return existingKeys.data?.getWmbusKeysForOrganisation?.result?.some(elem => (
                !!meterId === !!elem.meterId
                && nullToEmpty( elem.meterId) === nullToEmpty(meterId))
            && !!elem.manufacturer === !!manufacturer
            && nullToEmpty(elem.manufacturer) === nullToEmpty(manufacturer)
            && elem.encryptionKeyHash === getHash(key))
    }


    const loadCSVFile = (file) => {
        Log.Debug("Loading CSV File");
        resetOnLoad()
        Papa.parse(file, {
            skipEmptyLines: true,
            complete: (results) => {
                Log.Debug("Loading CSV File - DONE");
                processCSVResults(results)
            }
        });
    }


    const existingKeys = useQuery(WMBUS_KEYS_WITH_HASH , {
        // duplicate id's are causing issues when cached ...
        fetchPolicy: "no-cache",
        variables: {
            orgId: auth.organisationId(),
            page: {
                offset: 0,
                limit: 100000,
            },
            sort: {
                field: "id",
                direction: "DESC"
            },
        }
    });


    const getKeysFromExchange = (values) => {
        resetOnLoad()
        const text = t("org.config.wmbus.key-import.save-load", "Loading Keys...")
        localStorage.setItem("lobaro.wmbus.keyimport.exchangetoken", values.token)
        setProgressText(text)


        startImportJob({variables: {
                platformUrl: values.platformUrl,
                token: values.token,
            }
        }).then((result) => {
            Log.Debug("used Job id:", result.data?.startWmbusKeysExchangeJob?.id)
            //poll for job status and result until job is done
            const intervalId = setInterval(() => {
                Log.Debug("used Job id:", result.data?.startWmbusKeysExchangeJob?.id)
                getImportJob.refetch({
                    id: result.data?.startWmbusKeysExchangeJob?.id,
                }).then((queryResult) => {
                    const job = queryResult.data?.getWmbusKeysExchangeJob
                    setProgressPercent(job.progress)
                    if (job.finished === true) {
                        clearInterval(intervalId)
                        const keyPairs: KeyData[] = [];
                        if (job.error === null) {
                            job.result.keys.map((key: ExchangePlatformKey) => {
                                keyPairs.push(new KeyData(key.meterId, key.encryptionKey, key.manufacturer, false, []));
                            })
                            setKeysAtExchangeKeyPlatform(job.result.totalAmountOfKeysAtPlatform)
                            if ( job.result.keys.length === 0) {
                                notify.success(t("org.config.wmbus.keyexchange.no-keys", "No new keys found on exchange platform"))
                            } else {
                                notify.success(t("org.config.wmbus.keyexchange.keys-loaded", "Keys loaded from exchange platform"))
                            }
                            setError(false)
                            setLoadedKeys(keyPairs)
                            setProgressPercent(100)
                            setProgressText("")
                        } else {
                            setError(true)
                            setLoadedKeys(keyPairs)
                            setProgressPercent(100)
                            setProgressText("")
                            const message = errorKeyToMessage(t, job.errorMessageKey)
                            notify.error(message, job.error)

                        }
                    }
                }).catch((err) => {
                    clearInterval(intervalId)
                    setProgressPercent(100)
                    setError(true)
                    const messageKey = err?.graphQLErrors[0]?.extensions?.messageKey
                    const message = errorKeyToMessage(t, messageKey)
                    notify.error(message,err?.graphQLErrors[0])
                })

                // setProgressPercent((p) => {return Math.min((p ? p : 0) + 1, 100)})


            }, 2000 )


        }).catch((err) => {
            setError(true)
            setProgressPercent(100)
            const messageKey = err?.graphQLErrors[0]?.extensions?.messageKey
            const message = errorKeyToMessage(t, messageKey)
            notify.error(message,err?.graphQLErrors[0])
        })
    }


    const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<KeyData> => ({
        filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
            <div style={{padding: 8}} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    // value={selectedKeys[0]}
                    value={tableParams.uiSearchParams.get(dataIndex)?.toString()}
                    onChange={(e) => {
                        setTableParams((prev) => {
                            prev.uiSearchParams.set(dataIndex, e.target.value)
                            return {...prev}
                        })
                    }}
                    onPressEnter={() => {
                        //need as param on func header for antd but needs to be used to avoid build error
                        Log.Debug(setSelectedKeys, selectedKeys)
                        const newSearchText:string[] =  []
                        if (tableParams.uiSearchParams.get(dataIndex) !== null) {
                            newSearchText.push(tableParams.uiSearchParams.get(dataIndex) as string)
                        }
                        handleSearch(confirm, dataIndex)
                    }}
                    style={{marginBottom: 8, display: 'block'}}
                />
                <Flex vertical gap="small" style={{ width: "100%" }}>
                    <Space direction="vertical">
                        <Button onClick={() => {
                            Log.Debug("klicked save button")
                            const newSearchText:string[] =  []
                            if (tableParams.uiSearchParams.get(dataIndex) !== null) {
                                newSearchText.push(tableParams.uiSearchParams.get(dataIndex) as string)
                            }
                            handleSearch(confirm, dataIndex)
                        }} size="large" type="primary" block>
                            {t("common.button.search", "Search")}
                        </Button>
                    </Space>
                    <Space.Compact direction="vertical" style={{ color: "blue" }} block>
                        <Button onClick={() => {
                            clearFilters && handleReset(clearFilters,  dataIndex)
                            confirm()
                        }} size="middle" block>
                            {t("org.config.wmbus.reset", "Reset")}
                        </Button>

                        <Button danger onClick={() => {
                            clearFilters && handleResetAll(clearFilters)
                            confirm()
                        }} size="middle" type="dashed" block>
                            {t("org.config.wmbus.reset_all", "Reset All")}
                        </Button>
                    </Space.Compact>
                </Flex>
            </div>
        ),
        filterIcon: <Icon name="search" size="xx-small" category="utility" color={"#1890ff"}
                              colorVariant={tableParams.searchParams.has(dataIndex) ? "current" : undefined}/>

        ,
        onFilterDropdownOpenChange: (visible) => {
            Log.Debug("exec onFilterDropdownOpenChange", visible)
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            } else {
                    Log.Debug("exec onBlur")
                    setTableParams((prev) => {
                            prev.uiSearchParams.set(dataIndex, prev.searchParams.get(dataIndex) as string)
                            return {...prev}
                        }
                    )
            }
        },
        render: (text) =>
            tableParams.searchParams.get(dataIndex) ? (
                <Highlighter
                    highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
                    searchWords={[tableParams.searchParams.get(dataIndex)]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });


    const getDropDownSearchProps = (dataIndex: DataIndex, options : Array<{value : string|boolean, label: string}>): ColumnType<KeyData> => ({
        filterDropdown: ({confirm, clearFilters}) => (
            <div style={{padding: 8}} onKeyDown={(e) => e.stopPropagation()}>
                <Select
                    ref={selectInput}
                    value={tableParams.uiSearchParams.get(dataIndex)}
                    onChange={(value) => setTableParams((prev) => {
                        prev.uiSearchParams.set(dataIndex, value)
                        return {...prev}
                    })}
                    onClear={() => handleSearch(confirm, dataIndex)}
                    onKeyUp={(event) => {
                        if (event.key === "Enter") {
                            //need as param on func header for antd but needs to be used to avoid build error
                            handleSearch(confirm, dataIndex)
                        }
                    }
                    }
                    options={options}
                    // allowClear={true}
                    style={{
                        width: "100%",
                    }}
                >

                </Select>
                <Flex vertical gap="small" style={{width: "12rem"}}>
                    <Space direction="vertical">
                        <Button onClick={() => handleSearch(confirm, dataIndex)} size="large" type="primary" block>
                            {t("common.button.search", "Search")}
                        </Button>
                    </Space>
                    <Space.Compact direction="vertical" style={{color: "blue"}} block>
                        <Button onClick={() => {
                            const result = clearFilters && handleReset(clearFilters, dataIndex)
                            confirm()
                            return result
                        }} size="middle" block>
                            {t("org.config.wmbus.reset", "Reset")}
                        </Button>

                        <Button danger onClick={() => {
                            const result = clearFilters && handleResetAll(clearFilters)
                            confirm()
                            return result
                        }}
                                size="middle" type="dashed" block>
                            {t("org.config.wmbus.reset_all", "Reset All")}
                        </Button>
                    </Space.Compact>
                </Flex>
            </div>
        ),
        onFilterDropdownOpenChange: (visible) => {
            if (!visible) {
                setTableParams((prev) => {
                        prev.uiSearchParams.set(dataIndex, prev.searchParams.get(dataIndex) as string)
                        return {...prev}
                    }
                )
            }
        },
        filterIcon: () => {
        Log.Debug("eval Icon", dataIndex , tableParams.searchParams.has(dataIndex), tableParams)
        return   <Icon name="search" size="xx-small" category="utility" color={"#1890ff"}
          colorVariant={tableParams.searchParams.has(dataIndex) ? "current" : undefined}/>
    },
    });



    const validCount = loadedKeys.filter((key) => (key.valid == null || key.valid.length === 0)).length
    const existCount = loadedKeys.filter((key) => (key.existing)).length
    const importTryCount = loadedKeys.filter((key) => (key.existing == false && key.valid?.length === 0  && (key.importStatus === ImportStatus.Done || key.importStatus === ImportStatus.Skipped || key.importStatus === ImportStatus.Failed))).length
    const importSuccessCount = loadedKeys.filter((key) => (key.importStatus === ImportStatus.Done)).length

    const columns: ColumnsType<KeyData> = [
        {
            title: t("wmbus-key-import.meter-id", "MeterId"),
            dataIndex: 'meterId',
            key: 'meterId',
            sorter: (a, b) => compareMeterId(a.meterId,b.meterId),
            ...getColumnSearchProps('meterId'),
        },
        {
            title: t("wmbus-key-import.manufacturer", "Manufacturer"),
            dataIndex: 'manufacturer',
            key: 'manufacturer',
            sorter: (a, b) => {
                if (compareAlphabetically(a.manufacturer, b.manufacturer) !== 0) {
                    return compareAlphabetically(a.manufacturer, b.manufacturer)
                }
                return compareMeterId(a.meterId, b.meterId)
            },
            ...getColumnSearchProps('manufacturer'),
        },
        {
            title: t("wmbus-key-import.encryption-key", "Encryption Key"),
            dataIndex: 'encryptionKey',
            key: 'encryptionKey',
            render: (text) => <div>{text}</div>,
        },
        {
            title: t("wmbus-key-import.existing", "Existing ({{count}})", {count: existCount}),
            dataIndex: `existing`,
            key: 'existing',
            render: (existing, key) => renderExisting(existing, key),
            ...getDropDownSearchProps("existing",
                [
                    {value: "true", label: 'True'},
                    {value: "false", label: 'False'},
                ])
        },
        {
            title: <div>
                {t("wmbus-key-import.valid", "Valid ({{count}})", {count: validCount})}
                <Tooltip left="-10px" top="-50px"
                         content={t("wmbus-key-import.tooltip-invalid-are-skipped", "Invalid entries will be skipped.")}>
                    <Icon name="info" size={"small"} className="slds-m-left--x-small"/>
                </Tooltip>
            </div>,
            key: 'valid',
            dataIndex: 'valid',
            render: renderValid,
            ...getDropDownSearchProps("valid",
                [
                    { value: "true", label: 'Valid' },
                    { value: "false", label: 'Invalid' },
                ])
        },
        {
            title: importTryCount > 0 ? t("wmbus-key-import.importstatus-withcnt", "Import Status ({{successCnt}}/{{allCnt}})", {successCnt: importSuccessCount, allCnt: importTryCount}) : t("wmbus-key-import.importStatus-withoutcnt", "Import Status"),
            dataIndex: 'importStatus',
            key: 'importStatus',
            render: renderStatus,
            ...getDropDownSearchProps("importStatus",
                [
                    { value: ImportStatus.Done, label: 'Done' },
                    { value: ImportStatus.Skipped, label: 'Skipped' },
                    { value: ImportStatus.Skipped, label: 'Failed' },
                ])
        },
        {
            title: t("wmbus-key-import.options", "Options"),
            key: 'keyId',
             // dataIndex: 'keyId',
            render: (clickedKey) => {
              return   clickedKey.importStatus !== ImportStatus.Done ?
                    <Button onClick={() => {
                        const newLoadedKeys = loadedKeys.filter((key) => key.keyId != clickedKey.keyId)
                        setLoadedKeys(newLoadedKeys)
                    }
                    }>Remove</Button> : <div/>
            }

        }
    ];

    const tabItems = [
        {
            key: "1",
            label: "CSV",
            children: <Row gutter={16}>
                    <Col>
                    <Formik
                        initialValues={{
                            file: "",
                        }}
                        onSubmit={() => {
                            Log.Debug("Submit")
                        }}
                    >{() => {
                        return <Form>
                            <SldsFileSelectorField label={t("wmbus-key-import.csv-file", "CSV File")}
                                                   buttonLabel={t("wmbus-key-import.csv-file-button", "Select CSV")}
                                                   name={"file"}
                                                   required={false}
                                                   accept={".csv"} onFileChange={(file) => {
                                loadCSVFile(file)
                            }}/>
                        </Form>;
                    }}</Formik>
                    </Col>
                    <Col>
                        <div className={"slds-text-heading_small"}>
                            {t("mbus-key-import.heading-example-csv-files", "Download Example Files:")}
                        </div>
                        <ul className={"slds-list_dotted"}>
                            <li>
                                <a onClick={() => downloadExampleCsv()}>
                                    {t("wmbus-key-import.download-lobaro-csv-button", "Lobaro Format")}
                                </a>
                            </li>
                            <li>
                                <a onClick={() => downloadSensusCsv()}>
                                    {t("wmbus-key-import.download-sensus-csv-button", "Sensus Format")}
                                </a>
                            </li>
                        </ul>
                    </Col>
                </Row>
        },
        {
            key: "2",
            label: "Key Exchange Platform",
            children: <Row>
                <Col span={24}>
                    <Formik
                        initialValues={{
                            token: localStorage.getItem("lobaro.wmbus.keyimport.exchangetoken"),
                            platformUrl: "https://exchange-platform.app",
                        }}
                        onSubmit={(values) => {
                            getKeysFromExchange(values)
                        }}
                    >{() => {
                        return <Form>
                            <SldsInputField label={t("wmbus-key-import.exchange-platform-url", "Exchange Platform Url")}
                                            name={"platformUrl"} type={"text"} required={true}/>

                            <SldsInputField label={t("wmbus-key-import.token", "Token")} name={"token"} type={"text"}
                                            required={true}/>
                            <FormActions>
                                <SubmitButtonField  disabled={!!progressText || !!(progressPercent && progressPercent > 0 && progressPercent < 100)}>{t("wmbus-key-import.load-keys-button", "Load Keys")}</SubmitButtonField>
                            </FormActions>
                        </Form>
                    }}</Formik>
                </Col>
            </Row>,
        }
    ]


    let tableData :KeyData[] = loadedKeys
    if (tableParams.searchParams.size > 0) {
        for (const [key, value] of tableParams.searchParams) {
            const valueAsString = value ? value : ""
            const keyAsString = key ? key : ""
            if (keyAsString === "valid" ){
                if (valueAsString === "true") {
                    tableData = tableData.filter((v) => {
                        return v[keyAsString]?.length === 0
                    })
                }else if (valueAsString === "false") {
                    tableData = tableData.filter((v) => {
                        return v[keyAsString]?.length !== 0
                    })
                }
            } else if (keyAsString === "existing" ){
             if (valueAsString === "true") {
                 tableData = tableData.filter((v) => {
                     return v[keyAsString]
                 })
             } else if (valueAsString === "false") {
                    tableData = tableData.filter((v) => {
                        return v[keyAsString] === false
                    })
             }
            } else if (key !== null && key !== "" && value !== null && value !== "" ){
                tableData = tableData.filter((v) => {
                    return v[keyAsString].toUpperCase().includes(valueAsString.toUpperCase())
                })
            }
        }


    }

    return <Page
        trail={[<Link to={window.location.href}
                      key={1}>{t("org.config.nav.wmbus.key-import.titel", "wMbus Key Import")}</Link>]}
        title={t("org.config.nav.wmbus.key-exchange-platform.titel", "wMbus Key Exchange Platform")}
    >

      <Row justify="space-around" align="middle" gutter={[16, 16]}>
        <Col span={24}>
            <div className={"slds-p-horizontal--small"}>
          <Tabs defaultActiveKey="1" items={tabItems}/>
            </div>
        </Col>
      </Row>
          {progressPercent != null ?
          <Row justify="start" align="middle" gutter={[16, 16]}>
          <Col span={12}><div className={"slds-p-horizontal--small"}>
                  <div className={"slds-align-left"}>{progressText} {progressAbsolute}</div>
              <Progress percent={progressPercent ? progressPercent : 0} status={error ? "exception" : undefined}/></div>
          </Col></Row> : null
          }
        <Row justify="space-around" align="middle" gutter={[16, 16]}>
          <Col span={24}>
              <div className={"slds-p-left--small"}>
                <SldsButton  iconCategory={"utility"} iconName={"save"}
                    disabled={!!(progressPercent && progressPercent > 0 && progressPercent < 100) || !(loadedKeys.length > 0)}
                    onClick={() => {
                    setTimeout(saveKeys, 0)
                }} > {t("wmbus-key-import.save", "Save Keys")}</SldsButton>
              </div>
          </Col>
        </Row>
        <Row justify="space-around" align="middle" gutter={[16, 16]}>
          <Col span={24}>
              <div className={"slds-m-left--small"}>

                  { keysAtExchangeKeyPlatform === null ?
                      <div>{loadedKeys.length} {t("org.config.wmbus.key-import.keys-loaded", "Keys loaded")}</div>
                      : <div>{loadedKeys.length} / {keysAtExchangeKeyPlatform} {t("org.config.wmbus.key-import.keys", "Keys")} · {keysAtExchangeKeyPlatform - loadedKeys.length} {t("org.config.wmbus.key-import.known","Keys already imported")}
                          { loadedKeys.length != tableData.length ? " · " + (loadedKeys.length - tableData.length) + " " +  t("org.config.wmbus.key-import.filtered","Keys filtered") : null }</div>
                  }
              </div>
              <Table columns={columns} dataSource={tableData} rowKey={(record) => record.keyId}/>
          </Col>
      </Row>

    </Page>

}


// functions and querys to create wmbus keys

//saves the keys at the backend
const CREATE_WMBUS_KEYS = gql`mutation($wmbusKeys: [WmbusKeyInput!]){
    createWmbusKeys(input: $wmbusKeys){
        id
        meterId
        manufacturer
        encryptionKeyHash
    }}
`;



const GET_WMBUS_KEY_EXCHANGE_JOB = gql`
    query($id: ID!){
        getWmbusKeysExchangeJob(id: $id){
            id
            finished
            result {
                keys {
                    meterId
                    manufacturer
                    encryptionKey
                }
                totalAmountOfKeysAtPlatform
            }
            errorMessageKey
            error
            progress
        }
    }
`;

const START_WMBUS_KEY_IMPORT_JOB = gql`
    mutation($platformUrl: String!, $token: String!){
        startWmbusKeysExchangeJob(platformUrl: $platformUrl, token: $token){
            id
            finished
            result {
                keys {
                    meterId
                    manufacturer
                    encryptionKey
                }
                totalAmountOfKeysAtPlatform
            }
            errorMessageKey
            error
        }
    }
`;

function getHash(key: string) : string {
    // Turn hex string to bytes
    const keyBytes = new Uint8Array(key.length / 2);
    for (let i = 0; i < key.length; i += 2) {
        keyBytes[i / 2] = parseInt(key.substring(i, i+2), 16);
    }
    const hash = sha256(keyBytes)
    return buf2hex(hash).toUpperCase()
}



function buf2hex(buffer) { // buffer is an ArrayBuffer
    return [...new Uint8Array(buffer)]
        .map(x => x.toString(16).padStart(2, '0'))
        .join('');
}



const WMBUS_KEYS_WITH_HASH = gql`
    query ($orgId: ID!, $page: PaginationInputType, $sort: SortInputType, $filter:[FilterInputType!]){
        getWmbusKeysForOrganisation(orgId: $orgId, sort: $sort, page: $page, filter: $filter) {
            result {
                id
                meterId
                manufacturer
                encryptionKeyHash
            }
            totalCount
        }
    }
`;

const extractSensusId = (meterId: string) : string => {
    const results = /^(?:AE4C|4CAE)([0-9A-F]{8}|[0-9A-F]{11})[0-9A-F]{4}$/.exec(meterId)
    if (results) {
        return results[1]
    }
    return "" // shouldn't happen...
}


const exampleCsv = `meterId;manufacturer;key
12345678;LOB;12345678AAAAAAAA12345678AAAAAAAA
;LOB;aaaaaaaabbbbbbbbccccccccdddddddd
12345678;;FFFFFFFF12345678FFFFFFFF12345678
`

const sensusCsv = `RandomKey;MeterIdentifier;OrderNumber;RadioAddress
0123456789abcdeffedcba9876543210;3SEN1234567890;12345678901;AE4C123456787406
fedbca98765432100123456789abcdef;3SEN1234567809;12345678902;AE4C876543217406
;;;`



function downloadExampleCsv() {
    const element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(exampleCsv));
    element.setAttribute('download', "mbusKeysExample.csv");

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
}

function downloadSensusCsv() {
    const element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(sensusCsv));
    element.setAttribute('download', "mbusKeysSensusExample.csv");

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
}

function compareMeterId(a: string|null, b: string|null) : number {
    if (a === b) {
        return 0
    } else if (a === null || a === "") {
        return -1
    } else if (b === null || b === "") {
        return 1
    } else {
       return Number(a)-Number(b)
    }
}

function compareAlphabetically(a: string|null, b: string|null) : number {
    if (a === b) {
        return 0
    } else if (a === null || a === "") {
        return -1
    } else if (b === null || b === "") {
        return 1
    } else if (a < b) {
        return -1
    }
    return 1
}

function errorKeyToMessage(t: TFunction<"translation", string>, errorKey: string) : string {
    switch (errorKey) {
        case 'exchange_platform_request_error':

        case 'exchange_platform_connection_error':
            return t("org.config.wmbus.keyexchange.error-keys.exchange_platform_connection_error", "KeyExchange Platform not reachable.")
        case 'exchange_platform_authorization_error':
            return t("org.config.wmbus.keyexchange.error-keys.exchange_platform_authorization_error", "Authorization error at KeyExchange Platform. Check used token.")
        case 'exchange_platform_status_not_ok_error':
            return t("org.config.wmbus.keyexchange.error-keys.exchange_platform_status_not_ok_error", "Failed to get keys from exchange platform. Check console for details.")
        case 'exchange_platform_device_result_parser_error':
            return t("org.config.wmbus.keyexchange.error-keys.exchange_platform_device_result_parser_error", "Exchange Platform send an invalid Key response.")
        case 'exchange_platform_device_to_json_error':
            return t("org.config.wmbus.keyexchange.error-keys.exchange_platform_device_to_json_error", "Exchange Platform send an invalid JSON as response.")
        case 'exchange_platform_result_nil_error':
            return t("org.config.wmbus.keyexchange.error-keys.exchange_platform_result_nil_error", "Received an empty response from Exchange Platform.")
        case 'exchange_platform_key_result_parser_error':
            return t("org.config.wmbus.keyexchange.error-keys.exchange_platform_key_result_parser_error", "Received invalid response from Exchange Platform.")
        case 'exchange_platform_key_result_no_devices_error':
            return t("org.config.wmbus.keyexchange.error-keys.exchange_platform_key_result_no_devices_error", "No Devices in Exchánge Platform Organisation.")
        case 'ctx_error':
            return t("org.config.wmbus.keyexchange.error-keys.ctx_error", "Loading Keys took to long. Timeout reached.")
        case 'exchange_platform_status_precondition_error':
            return t("org.config.wmbus.keyexchange.error-keys.exchange_platform_status_precondition_error", "KeyExchange Platform returned Precondition HTTP error.")
        case 'exchange_platform_no_keys_error':
            t("org.config.wmbus.keyexchange.error-keys.exchange_platform_no_keys_error", "KeyExchange Platform returned no Keys.")

    }
    return t("org.config.wmbus.keyexchange.get_keys_failed", "Failed to get keys from exchange platform. Check console for details.")


}