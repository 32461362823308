import Page from "../../common/ui/page";
import {Link, useNavigate} from "react-router-dom";
import * as React from "react";
import {useState} from "react";
import {useQuery} from "@apollo/client";
import GenericDataTable from "../../common/ui/genericDataTable/genericDataTable";
import DataTableColumn from "../../common/ui/data-table/column";
import moment from "moment";
import gql from "graphql-tag";
import {usePagination} from "../../common/hooks/usePagination";
import {useSearch} from "../../common/hooks/useSearch";
import {ButtonGroup} from "@salesforce/design-system-react";
import Button from "../../common/slds/buttons/button";
import {useSort} from "../../common/hooks/useSort";
import {UserRolePills} from "../../components/user/UserRolesField";
import AddUserDialog from "../../components/user/addUserDialog";
import DataTableCell from "../../common/ui/data-table/cell";

const UsersListPage = () => {
    const sort = useSort({property: "lastLogin", direction: "desc"});
    const page = usePagination();
    const search = useSearch();
    const navigate = useNavigate();

    const usersListResult = useQuery(gql`
        query($page: PaginationInputType, $search: String, $sort: SortInputType) {
            getUserList(page: $page, search: $search, sort: $sort) {
                id
                login
                organisation {
                    id
                    name
                }
                baseOrganisation {
                    id
                    name
                }
                createdAt
                roles
                email
                lastLogin
            }
        }
    `, {
        variables: {
            page: page.getGraphqlPageInput(),
            search: search.getGraphqlSearchInput(),
            sort: sort.getGraphqlSortInput()
        }
    });

    const [showNewUserModal, setShowNewUserModal] = useState(false);

    return <Page
      limitWidth={true}
      trail={[<Link to="." key={1}>Users</Link>]}
        title={"Users"}
        actions={<ButtonGroup><Button iconName={"add"} onClick={() => setShowNewUserModal(true)}>Create
            User</Button></ButtonGroup>}
    >
        <AddUserDialog isOpen={showNewUserModal} onRequestClose={() => setShowNewUserModal(false)} onAdded={(user) => {
            navigate(`/configuration/users/${user.id}`);
        }}/>

        <GenericDataTable id={"user-table"} items={usersListResult.data && usersListResult.data.getUserList} tableConfigDefault={{}} gqlResult={usersListResult}
                          page={page} search={search} sort={sort}>
            <DataTableColumn label="ID" property={"id"} sortable={true} isSorted={sort.sortInfo.property === "id"} sortDirection={sort.sortInfo.direction}>
                <DataTableCell key="id" render={(props) => (
                    <Link to={`${props.item.id}`}>{props.item.id}</Link>
                )}/>
            </DataTableColumn>
            <DataTableColumn label="Login" property="login" title={"login"} sortable={true} isSorted={sort.sortInfo.property === "login"} sortDirection={sort.sortInfo.direction}>
                <DataTableCell key="login" render={(props) => (
                    <Link to={`${props.item.id}`}>{props.item.login}</Link>
                )}/>
            </DataTableColumn>
            <DataTableColumn label="E-Mail" property="email" title={"email"} sortable={true} isSorted={sort.sortInfo.property == "email"} sortDirection={sort.sortInfo.direction}/>
            <DataTableColumn label="Base Organisation" property={"baseOrganisationId"} sortable={true} isSorted={sort.sortInfo.property == "baseOrganisationId"} sortDirection={sort.sortInfo.direction}>
                <DataTableCell key="type" render={(props) => {
                    return <Link to={`/configuration/organisations/${props.item.baseOrganisation.id}`}>{props.item.baseOrganisation.name}</Link>;

                }}/>
            </DataTableColumn>
            <DataTableColumn label="Current Organisation" property={"organisationId"} sortable={true} isSorted={sort.sortInfo.property == "organisationId"} sortDirection={sort.sortInfo.direction}>
                <DataTableCell key="type" render={(props) => {
                    return <Link to={`/configuration/organisations/${props.item.organisation.id}`}>{props.item.organisation.name}</Link>;

                }}/>
            </DataTableColumn>
            <DataTableColumn label="Roles" property={"roles"} render={ (props) => {return <UserRolePills roles={props.item.roles}/>}}/>
            <DataTableColumn label={"Created"} property={"createdAt"} title={"createdAt"} sortable={true} isSorted={sort.sortInfo.property === "createdAt"} sortDirection={sort.sortInfo.direction}>
                <DataTableCell render={(props) => {
                    return <div
                        className="slds-cell-wrap">{moment(props.item.createdAt).fromNow()} ({moment(props.item.createdAt).format('DD.MM.YYYY HH:mm:ss')})</div>;
                }}/>
            </DataTableColumn>

            <DataTableColumn label={"Last Login"} property={"lastLogin"} title={"lastLogin"} sortable={true} isSorted={sort.sortInfo.property === "lastLogin"} sortDirection={sort.sortInfo.direction}>
                <DataTableCell render={(props) => {
                    return <div
                        className="slds-cell-wrap">{lastLoginCell(props.item?.lastLogin)}</div>;
                }}/>
            </DataTableColumn>
        </GenericDataTable>
    </Page>;
};

function lastLoginCell(lastLogin){
    if (lastLogin) {
       return `${moment(lastLogin).fromNow()} ${moment(lastLogin).format('DD.MM.YYYY HH:mm:ss')}`
    } else {
        return "never"
    }

}

export default UsersListPage;
