import {Responsive} from 'react-grid-layout';
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import './dashboard.css'
import React from "react";
import PropTypes from "prop-types";
import {WidgetFrame} from "./widgetFrame";
import {WidthProvider} from "./widthProvider";
import {Log} from "../../../common/log";
import _ from "underscore"

const ResponsiveGridLayout = WidthProvider(Responsive);

export const Grid = (props) => {
        const {readOnly, dashboard, onLayoutChange, onDashboardChanged} = props;
        const breakpoints = {lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0};
        const cols = {lg: 12, md: 12, sm: 12, xs: 6, xxs: 3};

        const widgetState = dashboard.widgets || [];
        Log.Debug("WidgetStates:", widgetState)
        const widgets = widgetState.map((widgetState) => {
            /*
             if (!widgetPlugin) {
             // TODO: Render widget with error message - currently a loading indicator is displayed and the setting button is hidden
             console.warn("No WidgetPluginFactory for type '" + widgetState.type + "'! Skipping rendering.");
             return null;
             } */

            return <div key={widgetState.id}>
                <WidgetFrame key={widgetState.id} widget={widgetState} isReadOnly={readOnly}
                             onDelete={() => {
                                 dashboard.widgets = dashboard.widgets.filter((w) => w.id !== widgetState.id);
                                 Log.Debug("Deleted widget:", dashboard.widgets);
                                 if (onDashboardChanged) {
                                     onDashboardChanged(dashboard);
                                 }
                             }}
                             onSettingsChanged={(values) => {
                                 const idx = _.findIndex(dashboard.widgets, (w) => {
                                     return w.id === widgetState.id
                                 });

                                 dashboard.widgets[idx].settings = values;
                                 if (onDashboardChanged) {
                                     onDashboardChanged(dashboard);
                                 }
                             }}

                /></div>;
        });

        Log.Debug("Widgets:", widgets);

        return <ResponsiveGridLayout
            className="column"
            breakpoints={breakpoints}
            cols={cols}
            // Pass "layout" here? - nope
            compactType={"vertical"} // vertical, horizontal
            margin={[0, 0]}
            containerPadding={[0, 0]}
            // useCSSTransforms = false???
            // width={"auto"}
            draggableCancel=".no-drag"
            draggableHandle=".drag"
            rowHeight={30}
            onLayoutChange={(layout) => onLayoutChange && !readOnly ? onLayoutChange(layout) : null}
            isDraggable={!readOnly}
            isResizable={!readOnly}
            children={widgets}
        >
            {widgets}
        </ResponsiveGridLayout>
    }
;


Grid.propTypes = {
    dashboard: PropTypes.object.isRequired,
    layout: PropTypes.array,
    readOnly: PropTypes.bool,
    onLayoutChange: PropTypes.func,
    onDashboardChanged: PropTypes.func,
};




