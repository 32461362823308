import React from "react";
import Page from "../page";
import NewDeviceForm from "./newDeviceForm";
import gql from "graphql-tag";
import {QuerySafe} from "../../../common/graphql";
import {HasPermission} from "../../../common/ui/permissions";
import {Link, useNavigate} from "react-router-dom";


// Actually all the info needed upfront to create a device
const queryDeviceTypes = gql`
    query deviceTypes {
        deviceTypes {
            id
            name
            displayName
        }
    }
`;


export default function NewDevicePage() {
    const navigate = useNavigate();
    return <HasPermission role={"admin"} showError={true}>
        <Page title={"Create New Device"}
              trail={[<Link to="/" key={1}>Devices</Link>, <Link to={`/new`} key={2}>New</Link>]}
        >
            <QuerySafe query={queryDeviceTypes}>
                {(result) => {
                    return <NewDeviceForm result={result} onCreateDevice={(device, actions) => {
                        const newDevice = {
                            appId: device.appId,
                            name: device.name,
                            addr: device.addr,
                            description: device.description,
                            deviceTypeId: device.deviceType.id,
                        };

                        return result.client.mutate({
                            mutation: gql`mutation createDevice($input: DeviceInput!) {
  device: createDevice(input: $input) {
    id
    appId
    name
    addr
    description
    deviceType {
      id
      name
      displayName
    }
  }
}`,
                            variables: {input: newDevice},
                        }).then((result) => {
                            navigate(`../${result.data.device.id}/settings`);
                            actions.setSubmitting(false);
                        }, () => {
                            alert("Failed to create device");
                            actions.setSubmitting(false);
                        });
                    }
                    }/>
                }}
            </QuerySafe>
        </Page>
    </HasPermission>
}

NewDevicePage.propTypes = {
};