import React from "react";
import {useQuery} from "@apollo/client";
import gql from "graphql-tag";
import {Navigate} from "react-router-dom";
import {useGraphqlLoadingComponent} from "../../common/graphql";
import {useAuthContext} from "../../common/context/authContext";
import Roles from "../../model/roles";

export const QUERY_DEVICE_TYPES = gql`
    query($orgId: ID, $onlyWithExistingDevices: Boolean, $withSubOrgs: Boolean) {
        deviceTypes(orgId: $orgId, onlyWithExistingDevices: $onlyWithExistingDevices, withSubOrgs: $withSubOrgs ) {
            id
            displayName
        }
    }
`;
const InitialDevicesPage = () => {
    const auth = useAuthContext();
    const devType = localStorage.getItem('lobaro.devices.devType');
    let scope =  localStorage.getItem('lobaro.devices.scope');
    //after impersonate from admin to lower user scope can still be global or orgWithSubOrgs so we have to adjust
    if ((scope === "global" && !auth.hasRole(Roles.ADMIN)) || ((scope === "organisationWithSub" && !auth.hasRole(Roles.ORG_ADMIN)))) {
        localStorage.setItem('lobaro.devices.scope', "organisation")
        scope = "organisation"
    }



    const deviceTypesResult = useQuery(QUERY_DEVICE_TYPES, {
            variables: {
                orgId: auth.organisationId(),
                onlyWithExistingDevices: true,
                withSubOrgs: !(scope === null || scope === 'organisation')
            }
        }
    );



    if (devType !== null) {
        return <Navigate to={"type/"+devType} replace={true}/>
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore wait for ts migration
    let loading = useGraphqlLoadingComponent(deviceTypesResult);
    if (loading) {
        return loading;
    }


    if (deviceTypesResult.data.deviceTypes?.length === 1)
    {
        return <Navigate to={"type/"+deviceTypesResult.data.deviceTypes[0].id} replace={true}/>
    }
    return <Navigate to={"type/all"} replace={true}/>
}

export default InitialDevicesPage;