import {CustomPropTypes} from "../../propTypes/customPropTypes";
import React from "react";

export function HorizontalListItem(props) {
    const {children} = props;
    return <li className="slds-item">{children}</li>;
}

HorizontalListItem.propTypes = {
    children: CustomPropTypes.children,
};