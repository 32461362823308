import React, {useEffect, useState} from "react";
import {Log} from "../../log";
import Spinner from "../spinner/spinner";
import classNames from "classnames";
import PropTypes from "prop-types";


export default function Thumb(props) {
    const {file, className, width, height} = props;

    const [thumb, setThumb] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!file) return;
        if (typeof (file) === "string") {
            setLoading(false);
            setThumb(file);
            return;
        }

        let reader = new FileReader();
        setLoading(true);
        reader.onloadend = () => {
            setThumb(reader.result);
            setLoading(false);
        };
        try {
            reader.readAsDataURL(file);
        } catch (e) {
            Log.Error("Failed to readAsDataURL: ", file, e);
            setLoading(false);
        }
    }, [file]);

    if (!file) {
        return null;
    }

    if (loading) {
        return <div className="slds-box slds-box--x-small" style={{width: (width || 200)}}>
            <Spinner type={"inlined-container"}/>
        </div>;
    }

    return <div className={classNames("slds-box slds-box--x-small", className)}>
        <img src={thumb}
             alt={file.name}
             style={{maxHeight: height, maxWidth: width}}

        /></div>;
}


Thumb.defaultProps = {
};

Thumb.propTypes = {
    file: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    width: PropTypes.any,
    height: PropTypes.any,
    className: PropTypes.string,
};