import {decodeToken} from "../../common/context/authContext";
import {useMutation, useQuery} from "@apollo/client";
import {useGraphqlLoadingComponent} from "../../common/graphql";
import * as log from "../../common/log";

import {Card} from "@salesforce/design-system-react";
import * as React from "react";
import {useContext} from "react";
import gql from "graphql-tag";
import Page from "../../common/ui/page";
import {Link} from "react-router-dom";
import {useT} from "../../common/i18n";
import {CardBody} from "../../common/slds/cards/card";
import {CreateApiCredentials} from "../../components/organisation/createApiCredentials";
import {ApiCredentials} from "../../components/organisation/apiCredentials";
import {useNotificationContext} from "../../notifications/notificationContext";
import {FrontendConfigContext} from "../../common/context/frontendConfigContext";


const ROLES = [
    {
        name: "admin",
        displayName: "Admin",
        requires: null,
    },
    {
        name: "device-provisioning",
        displayName: "Device Provisioning",
        requires: "canProvision",
    }
]

function getRole(name) {
    return ROLES.find(it => it.name === name) || {name: name, displayName: name + "*"};
}

const QUERY_API_TOKENS = gql`
    query apiTokens {
        apiTokenList {
            id
            appId
            organisationId
            name
            token
            password
        }
    }
`;

const refetchApiTokens = () => ({
    query: QUERY_API_TOKENS
});


const MUTATION_CREATE_API_TOKEN = gql`
    mutation createToken($apiToken: ApiTokenInput!) {
        createApiToken(input: $apiToken) {
            id
            name
            token
            organisationId
        }
    }
`;


const MUTATION_DELETE_API_TOKEN = gql`
    mutation createToken($tokenId: ID!) {
        deleteApiToken(id: $tokenId) {
            id
            name
            token
        }
    }
`;

const AdminTokenDoc = () => {
    const t = useT()
    return <div className="slds-m-vertical--x-small">
        <div className="adminTokenDoc">
            <p>{t("config.settings.admin-token.intro", "Tokens for the Platform Administration. Part of the REST API provided by the backend. Can be used to:")}
            <ul className="slds-list--dotted slds-p-vertical--xx-small">
                <li>{t("config.settings.admin-token.create-orgs", "Create organisations")}</li>
                <li>{t("config.settings.admin-token.delete-orgs", "Delete organisations")}</li>
                <li>{t("config.settings.admin-token.create-mqtt", "Create MQTT endpoints")}</li>
                <li>{t("config.settings.admin-token.delete-mqtt", "Delete MQTT endpoints")}</li>
            </ul>
            {t("config.settings.admin-token.provisioning", "Tokens to allow provisioning devices with IMEI and/or DevEUI can be generated here as well.")}</p>
            <p>{t("config.settings.admin-token.auth-tip", "It is recommended to use Tokens over Basic Auth.")}</p>
            <p><strong>{t("config.settings.admin-token.responsibility", "Always treat Credentials with great care! Leaked admin tokens/credentials can be misused by attackers.")}</strong></p>
        </div>
    </div>
}

export const AdminTokenPage = () => {
    const t = useT()
    const notify = useNotificationContext()
    const config = useContext(FrontendConfigContext)

    const apiTokensResult = useQuery(QUERY_API_TOKENS);

    const [createTokenMutation] = useMutation(MUTATION_CREATE_API_TOKEN, {
        refetchQueries: [refetchApiTokens()],
    });
    const [deleteTokenMutation] = useMutation(MUTATION_DELETE_API_TOKEN, {
        refetchQueries: [refetchApiTokens()],
    });

    const loading = useGraphqlLoadingComponent(apiTokensResult);
    if (loading) {
        return loading;
    }

    let {data} = apiTokensResult;

    const getFeaturedRole = (name) => {
        const res = ROLES.find(it => it.name === name) || {name: name, displayName: name + "*"};
        if (res && res.requires && !(config && config[res.requires])) {
            return undefined;
        }
        return res
    }

    let apiTokens = data.apiTokenList;
    apiTokens = apiTokens.map((t) => {
        return {
            ...t,
            tokenParsed: decodeToken(t.token),
        };
    });

    //only admin tokens relevant
    apiTokens = apiTokens.filter(token => {
        if (token.tokenParsed?.roles){
         return token.tokenParsed.roles.includes("admin") || token.tokenParsed.roles.includes("device-provisioning")
        }
        return false
    })

    function deleteToken(t) {

        if (!window.confirm(`Delete API Token '${t.name}' [${t.id}]?`)) {
            return;
        }

        deleteTokenMutation({
            variables: {
                tokenId: t.id
            }
        }).catch((err) => {
            log.Error("Failed to delete api token:", err);
            alert("Failed to delete api token.");
        });
    }

    const handleSubmit = (values, actions) => {
        createTokenMutation({
            variables: {
                apiToken: {
                    name: values.name,
                    roles: values.roles,
                    generatePassword: values.type === "basic-auth",
                }
            }
        })
            .catch((err) => {
                notify.error("Failed to create token. Check console for details.", err);
            })
            .finally(() => {
                actions.setSubmitting(false);
            });
    }

    return <Page trail={[<Link to={"./"} key={1}>Administration Tokens</Link>]} title={"Administration Tokens"}>
        <Card bordered={false}
              heading={<header className={"slds-media slds-media_center slds-has-flexi-truncate"}>
                  <h2 className={"slds-card__header-title"}>{t("config.settings.admin-token.title", "Admin API Credentials")}</h2>
              </header>}>
            <CardBody inner={true}>
                <AdminTokenDoc/>
                {
                    apiTokens.map((tok) => {
                        return <ApiCredentials tok={tok} deleteToken={deleteToken} key={tok.id} getRole={getRole}/>;
                    })
                }
                <CreateApiCredentials handleFormikSubmit={handleSubmit} roles={ROLES} getRole={getFeaturedRole} preSelectRoles={[getRole("admin").name]}/>
            </CardBody>
        </Card>
    </Page>
}
