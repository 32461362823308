import React from "react";
import {CustomPropTypes} from "../../propTypes/customPropTypes";
import PropTypes from "prop-types";

export function NotificationContainer(props) {
    return <div className="slds-notification-container">
        {props.children}
    </div>;
}

export function Notification(props) {
    const {children, onClose, timeoutMs} = props;

    if (timeoutMs >= 0) {
        setTimeout(() => {
            onClose();
        }, timeoutMs);
    }

    return <>
        <div aria-live="assertive" aria-atomic="true" className="slds-assistive-text">event notification: Tesla - Renewal meeting</div>
        <section className="slds-notification" role="dialog" aria-labelledby="noti52" aria-describedby="dialog-body-id-43">
            <div className="slds-notification__body" id="dialog-body-id-43">
                {/*TODO: Add target link?*/}
                <a className="slds-notification__target slds-media" >
                    {children}
                </a>
                <button className="slds-button slds-button_icon slds-button_icon-container slds-notification__close" title="Close" onClick={() => onClose()}>
                    <svg className="slds-button__icon" aria-hidden="true">
                        <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#close"></use>
                    </svg>
                    <span className="slds-assistive-text">Close</span>
                </button>
            </div>
        </section>
    </>;
}

Notification.propTypes = {
    children: CustomPropTypes.children.isRequired,
    onClose: PropTypes.func.isRequired,
    timeoutMs: PropTypes.number,
};