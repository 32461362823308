import * as React from "react"
import * as PropTypes from "prop-types"
import AceEditor from "react-ace"
import "brace/mode/java"
import "brace/theme/tomorrow"
import "brace/ext/language_tools"


const CodeEditor = (props) => {
    return <AceEditor
        mode="javascript"
        theme="tomorrow"
        name="id"
        enableBasicAutocompletion={true}
        enableLiveAutocompletion={true}
        tabSize={2}
        editorProps={{$blockScrolling: true}}
        {...props}
    />
}

CodeEditor.propTypes = {
    id: PropTypes.string.isRequired,
    value: PropTypes.string,
    defaultValue: PropTypes.string,
    minLines: PropTypes.number,
    maxLines: PropTypes.number,
    width: PropTypes.string,
    readOnly: PropTypes.bool,
    onChange: PropTypes.func,
};

export default CodeEditor