import React, {useContext, useEffect, useState} from "react"
import { Field, Formik, useFormikContext } from "formik"
import { Form } from "../../common/ui/form/formik"
import { FormActions, SldsCheckboxField, SldsFileSelectorField, SldsInput, SubmitButtonField } from "../../common/ui/form/formElements"
import { Log } from "../../common/log"
import gql from "graphql-tag"
import JSZip from "jszip"
import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import { Icon } from "@salesforce/design-system-react"
import ProgressBar from "../../common/slds/progressBar/progressBar"
import Card from "../../common/slds/cards/card"
import { useNavigate } from "react-router-dom"
import ErrorBoundary from "../../common/ui/errorBoundary"
import * as PropTypes from "prop-types"
import { useT } from "../../common/i18n"
import OrganisationLookupField from "../organisation/organisationLookupField";
import {useAuthContext} from "../../common/context/authContext";
import {useGraphqlLoadingComponent} from "../../common/graphql";
import {FeatureContext} from "../../common/context/featureContext";
import {FeatureNotEnabled} from "../../common/featureNotEnabled";

const allowedNameRegex = /[A-Za-z0-9-]+/
const allowedNameNegativeRegex = /[^A-Za-z0-9-]/g

const QUERY_ORGANISATION = gql`
    query($id: ID!) {
        getOrganisation(id: $id) {
            id
            name
        }
    }
`;

const QUERY_DEVICE_TYPE_EXIST = gql`
    query ($name: String!, $field: String!) {
        deviceTypes(filter: [{ field: $field, op: "eq", value: $name }]) {
            description
            displayName
            id
            name
            organisationId
            private
        }
    }
`

const MUTATION_IMPORT_DEVICE_TYPE = gql`
    mutation uploadDeviceType(
        $content: String!
        $overwrite: Boolean!
        $newName: String!
        $useDisplayNameFromZip: Boolean!
        $newDisplayName: String!
        $private: Boolean!
        $organisationId: Int
    ) {
        importDeviceType(
            zipContent: $content
            overwrite: $overwrite
            newName: $newName
            useDisplayNameFromZip: $useDisplayNameFromZip
            newDisplayName: $newDisplayName
            private: $private
            organisationId: $organisationId
        ) {
            id
            organisationId
        }
    }
`

function ErrorThrower(props) {
    if (props.error) {
        throw props.error
    }
    return null
}

ErrorThrower.propTypes = { error: PropTypes.error }

export default function ImportDeviceTypes({ closeModal }) {
    const license = useContext(FeatureContext)
    if (!license.validateFeatures("lobaro-device-gateway")) {
        return <FeatureNotEnabled/>
    }
    const t = useT()
    const authCtx = useAuthContext()


    const orgResult = useQuery(QUERY_ORGANISATION, {
        variables: {
            id: authCtx.organisationId()
        }
    });


    const expectedFiles = ["deviceType.json", "parser.js"]

    // The control flow validates each state separately. Each state depends on the previous.
    // If a state is invalid, the following states are not processed.
    // used to stop whenever an error occurs
    const [validationError, setValidationError] = useState(null)

    // indicates if a file is selected by the user
    const [newFileSelected, setNewFileSelected] = useState(false)
    // the file data
    const [file, setFile] = useState(null)
    const [fileBase64, setFileBase64] = useState("")
    // check whether file can be opened as a zip file (despite the file ending .zip)
    const [fileIsZip, setFileIsZip] = useState(false)
    // indicates whether all expected files are found in the zip file
    const [expectedFilesFound, setExpectedFilesFound] = useState(false)

    const [deviceTypeName, setDeviceTypeName] = useState("")
    const [deviceTypeDisplayName, setDeviceTypeDisplayName] = useState("")

    // indicates if the deviceType check against the backend has been performed
    const [deviceTypeChecked, setDeviceTypeChecked] = useState(false)
    // indicates if the deviceType already exists
    const [deviceTypeExists, setDeviceTypeExists] = useState(false)
    // if active, the progress bar animation will fill the bar
    const [simulateProgress, setSimulateProgress] = useState(false)
    // disable buttons while loading
    const [disabledButtons, setDisabledButtons] = useState(false)
    // indicates if the zip filename uses not allowed characters
    const [changedName, setChangedName] = useState(false)

    // navigate to the device type page after import
    const navigate = useNavigate()

    // query will be called when zip file has been checked
    const [getDTExists, { error: dtExistsError }] = useLazyQuery(QUERY_DEVICE_TYPE_EXIST)
    // mutation will be called when the user clicks on the import or overwrite button
    const [importDeviceType, { loading: importLoading, error: importError, data: importData }] = useMutation(MUTATION_IMPORT_DEVICE_TYPE)

    const reset = () => {
        Log.Debug("ImportDeviceType - Reset")
        setNewFileSelected(false)
        setFile(null)
        setFileIsZip(false)
        setExpectedFilesFound(false)
        setDeviceTypeChecked(false)
        setDeviceTypeDisplayName("")
        setDeviceTypeName("")
        setDeviceTypeExists(false)
        setSimulateProgress(false)
        setValidationError(null)
    }

    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsBinaryString(file)
            reader.onload = () => {
                const base64 = btoa(reader.result)
                resolve(base64)
            }
            reader.onerror = (error) => reject(error)
        })
    }

    const readZipFile = (file) => {
        Log.Debug("readZipFile - fileSize:", file.size)
        if (file.size > 1024 * 1024 * 10) {
            setValidationError(new Error(t("device-type.import.fs-exceeded-error", "File size exceeds 10 MiB")))
            return
        }
        JSZip.loadAsync(file)
            .then((zip) => {
                setFileIsZip(true)

                let foundFiles = []
                zip.forEach(function (relativePath, zipEntry) {
                    Log.Debug("ReadZipFile - ZipEntry:", zipEntry)
                    if (!zipEntry.dir) {
                        foundFiles.push(zipEntry.name)
                    }
                    if (zipEntry.name === "deviceType.json") {
                        zipEntry.async("string").then((txt) => {
                            try {
                                let dtJson = JSON.parse(txt)

                                let name = dtJson?.name
                                let displayName = dtJson?.displayName
                                Log.Debug("readZipFile - parsed device type data - name:", name, "displayName:", displayName)

                                let sanitizedName = name.replace(allowedNameNegativeRegex, "")
                                if (sanitizedName !== name) {
                                    setChangedName(true)
                                    Log.Debug("readZipFile - removed not allowed characters - filename:", file.name, "name:", sanitizedName)
                                }

                                sanitizedName ? setDeviceTypeName(sanitizedName) : null
                                displayName ? setDeviceTypeDisplayName(displayName) : setDeviceTypeDisplayName(sanitizedName)
                            } catch (e) {
                                Log.Debug("readZipFile - failed to parse json", e)
                                setValidationError(e)
                            }
                        })
                    }
                })
                Log.Debug("ReadZipFile - FoundFiles:", foundFiles, "ExpectedFiles:", expectedFiles)
                // check against expected files
                if (foundFiles.every((fileName) => expectedFiles.includes(fileName))) {
                    Log.Debug("ReadZipFile - All expected files found")
                    setExpectedFilesFound(true)
                }
            })
            .catch((error) => {
                Log.Debug("ReadZipFile - Error:", error)
                setValidationError(error)
            })

        convertToBase64(file)
            .then((result) => {
                setFileBase64(result)
            })
            .catch((error) => {
                setValidationError(error)
            })
    }

    const checkDeviceTypeExists = () => {
        Log.Debug("CheckDeviceTypeExists - Name:", deviceTypeName)
        getDTExists({ variables: { name: deviceTypeName, field: "name" } }).then((result) => {
            Log.Debug("CheckDeviceTypeExists - Result:", result)
            if (result.data.deviceTypes.length > 0) {
                Log.Debug("CheckDeviceTypeExists - DeviceType already exists. Select a different file")
                setDeviceTypeExists(true)
            }
            setDeviceTypeChecked(true)
        })
    }

    const uploadDeviceType = (overwrite, newName, displayName, organisationId, setPrivate) => {
        Log.Debug(
            "UploadDeviceType - Overwrite:",
            overwrite,
            "NewName:",
            newName,
            "DisplayName:",
            displayName,
            "File",
            file,
            "organisationId",
            organisationId,
            "fileBase64",
            fileBase64.length
        )
        importDeviceType({
            variables: {
                content: fileBase64,
                overwrite: overwrite,
                newName: newName,
                useDisplayNameFromZip: false,
                newDisplayName: displayName,
                private: setPrivate,
                organisationId: organisationId,
            },
        })
    }

    // handle errors
    useEffect(() => {
        if (dtExistsError) {
            setValidationError(dtExistsError)
            Log.Debug("ImportDeviceTypes - validationError", dtExistsError)
        }
        if (importError) {
            setValidationError(importError)
            Log.Debug("ImportDeviceTypes - validationError", importError)
        }
        if (fileIsZip && !expectedFilesFound) {
            setValidationError(new Error(t("device-type.import.expected-files-error", "Unable to import - Expected files in zip could not be found")))
        }
    }, [dtExistsError, importError, fileIsZip, expectedFilesFound])

    // read/validate file
    useEffect(() => {
        if (file && newFileSelected) {
            setNewFileSelected(false)
            readZipFile(file)
        }
    }, [file, newFileSelected])

    // check existing deviceTypes
    useEffect(() => {
        if (!deviceTypeChecked && fileIsZip && expectedFilesFound && deviceTypeName) {
            checkDeviceTypeExists()
        }
    }, [deviceTypeChecked, fileIsZip, expectedFilesFound, deviceTypeName])

    //finalize import
    useEffect(() => {
        if (importLoading) {
            setDisabledButtons(true)
        }
        if (importData) {
            Log.Debug("Import device type done - importData?.importDeviceType?.id:", importData?.importDeviceType?.id)
            let deviceTypeId = importData?.importDeviceType?.id
            // wait one second for the ui to render progress
            setTimeout(() => {
                navigate(`/deviceTypes/${deviceTypeId}`)
            }, 1000)
        }
    }, [importLoading, importError, importData])

    const loading = useGraphqlLoadingComponent(orgResult);
    if (loading) {
        return loading;
    }

    return (
        <ErrorBoundary>
            <Formik
                enableReinitialize={true}
                // The values overwrite and createNew will be set when a button is clicked.
                // The state is manged by formik and not in the ImportDeviceTypes component.
                initialValues={{
                    overwrite: false,
                    createNew: false,
                    deviceTypeName: deviceTypeName,
                    deviceTypeDisplayName: deviceTypeDisplayName,
                    isPublic: false,
                    file: file,
                    organisationId: authCtx.organisationId(),
                    organisation: {
                        id: authCtx.organisationId(),
                        name: orgResult.data?.getOrganisation?.name
                    },
                }}
                onSubmit={(values, helpers) => {
                    Log.Debug("OnSubmit - Values:", values)
                    if (values.overwrite) {
                        uploadDeviceType(true, values.deviceTypeName, values.deviceTypeDisplayName, values.organisation?.id, !values.isPublic)
                    } else if (values.createNew) {
                        uploadDeviceType(false, values.deviceTypeName, values.deviceTypeDisplayName, values.organisation?.id, !values.isPublic)
                    }
                    setSimulateProgress(true)
                    helpers.setSubmitting(false)
                }}
                validateOnChange={true}>
                {(form) => {
                    return (
                        <Form>
                            <article className="slds-card">
                                <div className="slds-card__header slds-grid">
                                    <header className="slds-media slds-media_center slds-has-flexi-truncate">
                                        <div className="slds-media__figure">
                                            <Icon category="action" name="new" size="small" />
                                        </div>
                                        <div className="slds-media__body">
                                            <h2 className="slds-card__header-title">
                                                <a href="#" className="slds-card__header-link slds-truncate">
                                                    <span>{t("device-type.import.header", "Import Device Type")}</span>
                                                </a>
                                            </h2>
                                        </div>
                                    </header>
                                </div>
                                <div className="slds-card__body slds-card__body_inner">
                                    <div className="slds-p-top_xx-small"></div>
                                    <SldsFileSelectorField
                                        buttonLabel={t("device-type.import.select-zip", "Select Zip File")}
                                        name={"file"}
                                        accept={".zip"}
                                        onFileChange={(file) => {
                                            reset()
                                            setNewFileSelected(true)
                                            setFile(file)
                                            Log.Debug("SldsFileSelectorField - File:", file)
                                        }}
                                    />
                                    <div className="slds-p-top_small"></div>
                                    {/*<SingleLookupField*/}
                                    {/*    name={"organisation"}*/}
                                    {/*    label={t("device-type.import.organisation", "Organisation")}*/}
                                    {/*    autoFocus={false}*/}
                                    {/*    required={true}*/}
                                    {/*    loadSuggestions={(keyword) =>*/}
                                    {/*        organisationListResult.refetch({ search: keyword }).then((result) => result.data?.getOrganisationList)*/}
                                    {/*    }*/}
                                    {/*    titleExtractor={(it) => it.name}*/}
                                    {/*    subtitleExtractor={(it) => it.id}*/}
                                    {/*/>*/}
                                    <OrganisationLookupField required={true} />
                                    <SldsCheckboxField
                                        name={"isPublic"}
                                        inlineLabel={t("device-type.import.public", "Public Device Type")}
                                        onClick={(e) => {
                                            form.setFieldValue("isPublic", e.target.checked)
                                            form.validateForm()
                                        }}
                                    />
                                    <div className="slds-p-top_small"></div>

                                    {!(deviceTypeExists || changedName) && (
                                        <FormActions>
                                            <CancelButton reset={reset} disabledButtons={disabledButtons} closeModal={closeModal} />
                                            <SubmitButtonField
                                                name="createButton"
                                                className="slds-button slds-button_brand"
                                                iconName={"play"}
                                                disabled={!deviceTypeChecked || deviceTypeExists || disabledButtons}
                                                onClick={() => form.setFieldValue("createNew", true)}>
                                                {t("device-type.import.import", "Import")}
                                            </SubmitButtonField>
                                        </FormActions>
                                    )}

                                    {(deviceTypeExists || changedName) && (
                                        <UpdateDeviceTypeData
                                            newDeviceTypeName={deviceTypeName}
                                            newDeviceTypeDisplayName={deviceTypeDisplayName}
                                            changedName={changedName}
                                            disabledButtons={disabledButtons}
                                            closeModal={closeModal}
                                            reset={reset}
                                            form={form}
                                        />
                                    )}
                                    <div className="slds-m-top--x-small"></div>
                                    <ProgressBar
                                        min={0}
                                        max={100}
                                        updateInterval={50}
                                        simulateProgress={simulateProgress}
                                        current={simulateProgress && importData ? 50 : 0}
                                        stopPercent={simulateProgress && importData ? 1 : 0.5}
                                    />
                                </div>
                                {validationError && (
                                    <footer className="slds-card__footer">
                                        <a className="slds-card__footer-action" href="#">
                                            <div className="slds-scoped-notification slds-media slds-media_center slds-scoped-notification_light" role="status">
                                                <div className="slds-media__figure">
                                                    <span className="slds-icon_container slds-icon-utility-info" title="information">
                                                        <span className="slds-assistive-text">information</span>
                                                        <Icon category="utility" name="warning" colorVariant="error" />
                                                    </span>
                                                </div>
                                                <div className="slds-media__body">
                                                    <p>{validationError.message}</p>
                                                </div>
                                            </div>
                                        </a>
                                    </footer>
                                )}
                            </article>
                        </Form>
                    )
                }}
            </Formik>
        </ErrorBoundary>
    )
}

UpdateDeviceTypeData.propTypes = {
    newDeviceTypeName: PropTypes.string.isRequired,
    newDeviceTypeDisplayName: PropTypes.string.isRequired,
    disabledButtons: PropTypes.bool.isRequired,
    form: PropTypes.object.isRequired,
}

// Update Device Type Data if a device type already exists. Use only inside a Formik Form
function UpdateDeviceTypeData({ newDeviceTypeName, newDeviceTypeDisplayName, changedName, disabledButtons, reset, closeModal, form }) {
    Log.Debug("UpdateDeviceTypeData - form", form)
    const t = useT()

    // queries will be called if the user changes data on collision
    const [getDTExistsOnEdit] = useLazyQuery(QUERY_DEVICE_TYPE_EXIST)

    // new device type name and display to be changed by the user if the device type already exists
    const [deviceTypeName, setDeviceTypeName] = useState(newDeviceTypeName)
    const [deviceTypeDisplayName, setDeviceTypeDisplayName] = useState(newDeviceTypeDisplayName)
    const [deviceTypeExists, setDeviceTypeExists] = useState(false)

    const [validDeviceTypeName, setValidDeviceTypeName] = useState(false)
    const [validDeviceTypeDisplayName, setValidDeviceTypeDisplayName] = useState(false)
    const [deviceTypeDisplayNameExists, setDeviceTypeDisplayNameExists] = useState(false)

    const [firstInput, setFirstInput] = useState(false)

    //flip the switch when first input is detected
    useEffect(() => {
        if (!firstInput && (newDeviceTypeName !== deviceTypeName || newDeviceTypeName !== deviceTypeDisplayName)) {
            Log.Debug("UpdateDeviceTypeData - first input detected")
            setFirstInput(true)
        }
    }, [deviceTypeName, deviceTypeDisplayName])

    // validate new device type data
    useEffect(() => {
        if (deviceTypeName !== null) {
            Log.Debug(
                "UseEffect - ValidDeviceTypeName:",
                validDeviceTypeName,
                "match",
                deviceTypeName.match(allowedNameRegex),
                "valid",
                deviceTypeName.match(allowedNameRegex)?.[0] === newDeviceTypeName
            )

            setValidDeviceTypeName(deviceTypeName.match(allowedNameRegex)?.[0] === deviceTypeName && deviceTypeName.length !== "")
            getDTExistsOnEdit({ variables: { name: deviceTypeName, field: "name" } }).then((result) => {
                Log.Debug("UseEffect - DeviceTypeExists:", result.data.deviceTypes.length > 0)
                setDeviceTypeExists(result.data.deviceTypes.length > 0)
            })
        }
        if (deviceTypeDisplayName !== null) {
            setValidDeviceTypeDisplayName(deviceTypeDisplayName !== "")
            getDTExistsOnEdit({ variables: { name: deviceTypeDisplayName, field: "display_name" } }).then((result) => {
                Log.Debug("UseEffect - DeviceTypeDisplayNameExists:", result.data.deviceTypes.length > 0)
                setDeviceTypeDisplayNameExists(result.data.deviceTypes.length > 0)
            })
        }
    }, [deviceTypeName, deviceTypeDisplayName, firstInput])

    return (
        <Card
            heading={t("device-type.import.overwrite", "Overwrite or Create Device Type")}
            className="slds-card_boundary slds-p-horizontal--x-small"
            icon={<Icon category="utility" name="filter" size="small" />}>
            {!changedName && (
                <div className="slds-text-color--error">
                    {t("device-type.import.exists-error", "The DeviceType already exists. Please change the Device Type Name or select overwrite!")}
                </div>
            )}
            {changedName && (
                <div className="slds-text-color--error">
                    {t("device-type.import.changed-review", "The DeviceType name has been changed. Please review the new input!")}
                </div>
            )}

            <div className="slds-p-top_x-small"></div>

            <Field
                component={SldsInput}
                name="deviceTypeName"
                placeholder="Device Type Name"
                id="dtName"
                label="New Device Type Name"
                autoFocus={true}
                value={newDeviceTypeName}
                onInlineEdit={(input) => {
                    setDeviceTypeName(input)
                }}
                error={validDeviceTypeName ? null : t("device-type.import.invalid-name-field-error", "Invalid Device Type Name")}
            />
            {firstInput && !validDeviceTypeName && (
                <div className="slds-text-color--error">
                    {t("device-type.import.invalid-name-error", "Invalid device type name. Only letters, numbers and dashes are allowed.")}
                </div>
            )}
            {firstInput && deviceTypeExists && (
                <div className="slds-text-color_success">
                    {t("device-type.import.name-exists-error", "This device type name already exists. The device type will be overwritten!")}
                </div>
            )}

            <Field
                component={SldsInput}
                name="deviceTypeDisplayName"
                placeholder={t("device-type.import.display-name", "Device Type Display Name")}
                id="dtdName"
                label={t("device-type.import.new-display-name", "New Device Type Display Name")}
                autoFocus={true}
                value={newDeviceTypeDisplayName}
                onInlineEdit={(input) => {
                    setDeviceTypeDisplayName(input)
                }}
                error={validDeviceTypeDisplayName ? null : t("device-type.import.invalid-dname-field-error", "Invalid Device Type Display Name")}
            />
            {firstInput && !validDeviceTypeDisplayName && (
                <div className="slds-text-color--error">{t("device-type.import.invalid-dname-field-error", "Invalid Device Type Display Name")}</div>
            )}
            {firstInput && deviceTypeDisplayNameExists && deviceTypeName !== deviceTypeDisplayName && (
                <div className="slds-text-color_success">
                    {t(
                        "device-type.import.name-exists-warning",
                        "This device type display name already exists. A device type with duplicate display name will be created!"
                    )}
                </div>
            )}
            {firstInput && deviceTypeExists && deviceTypeDisplayNameExists && deviceTypeName === deviceTypeDisplayName && (
                <div className="slds-text-color_success">{t("device-type.import.dname-exists-warning", "This device type display name already exists.")} </div>
            )}

            <FormActions>
                <CancelButton reset={reset} disabledButtons={disabledButtons} closeModal={closeModal} />
                <SubmitButtonField
                    name="overwriteButton"
                    className="slds-button slds-button_brand"
                    iconName={"loop"}
                    disabled={!deviceTypeExists || disabledButtons || !form.isValid}
                    onClick={() => form.setFieldValue("overwrite", true)}>
                    Overwrite
                </SubmitButtonField>
                <SubmitButtonField
                    name="createNewButton"
                    className="slds-button slds-button_brand"
                    iconName={"play"}
                    disabled={deviceTypeExists || disabledButtons || !form.isValid}
                    onClick={() => form.setFieldValue("createNew", true)}>
                    Create New Device Type
                </SubmitButtonField>
            </FormActions>
        </Card>
    )
}

function CancelButton({ reset, disabledButtons, closeModal }) {
    const { resetForm } = useFormikContext()

    return (
        <SubmitButtonField
            name="cancelButton"
            iconName={"close"}
            disabled={disabledButtons}
            onClick={() => {
                resetForm()
                reset()
                if (closeModal) {
                    closeModal()
                }
            }}>
            Cancel
        </SubmitButtonField>
    )
}
