export const ConfigPropertyType = {
    INT32: {value: "Int32", label: "Int32"},
    UINT32: {value: "Uint32", label: "Uint32"},
    STRING: {value: "String", label: "String"},
    BOOL: {value: "Boolean", label: "Boolean"},
    BYTE_ARRAY: {value: "ByteArray", label: "ByteArray"},
    CRON: {value: "CRON", label: "CRON"},
};

export const DeviceDatasourceType = {
    "": {value: "", label: "None"},
    CHIRPSTACK_GLOBAL: {value: "CHIRPSTACK_GLOBAL", label: "Chirpstack"},
};
