import React, {useState} from "react";
import {Log} from "../common/log";
import {useMutation, useQuery} from "@apollo/client";
import {MUTATE_APPLICATION, QUERY_APPLICATION} from "./queries";
import {useGraphqlLoadingComponent} from "../common/graphql";
import NewDevicePage from "./pages/devices/newDevicePage";
import AlarmPage from "./pages/alarm/alarmPage";
import ContextRoute from "../common/RouterContext";
import DeviceDetailPage from "../organisation/devices/device/deviceDetailPage";
import NewAlarmConfigPage from "./pages/alarm/newAlarmConfigPage";
import AlarmConfigDetailPage from "./pages/alarm/alarmConfigDetailPage";
import {SensorDataPage} from "./pages/sensorData/sensorDataPage";
import ParsedDataPage from "./pages/parsedData/parsedDataPage";
import {DatasourcesPage} from "./pages/datasources/datasourcesPage";
import _ from "underscore";
import {Route, Routes, useMatch} from "react-router-dom";
import DeviceListPage from "./pages/devices/deviceListPage";
import {DashboardPage} from "./pages/dashboard/dashboardPage";
import {DashboardsPage} from "./pages/dashboard/dashboardsPage";
import {IntegrationsPage} from "../organisation/integrations/integrationPage";
import TopNavigation from "../header/topNavigation";
import TopNavigationLink from "../header/topNavigationLink";
import ContextBarIconAction from "../header/contextBarIconAction";
import {Modal} from "../common/slds";
import NavigationEditForm from "./navigationEditForm";
import {useAuthContext} from "../common/context/authContext";
import DeviceDetails from "./temperature/deviceDetails";
import DevicesTable from "./temperature/devicesTable";
import Header from "../header/header";
import {platformConfig} from "../common/platformConfig";
import HeaderAppLogo from "../header/HeaderAppLogo";
import {useFeatureContext} from "../common/context/featureContext";


function createNavigation(match) {
    return [
        {
            label: "Devices",
            url: `${match.url}/devices/`,
            features: ["lobaro-device-gateway"],
        },
        {
            label: "Alarm",
            url: `${match.url}/alarm/`,
            features: ["lobaro-device-gateway"],
        },
        {
            label: "Datasources",
            url: `${match.url}/datasources/`,
            features: ["lobaro-device-gateway"],
        },
        {
            label: "Device Data",
            url: `${match.url}/device-data/`,
            features: ["lobaro-device-gateway"],
        },
        {
            label: "Raw Data",
            url: `${match.url}/raw-data/`,
            features: ["lobaro-device-gateway"],
        },
        {
            label: "Dashboards",
            url: `${match.url}/dashboards/`,
            role: "admin",
            features: ["lobaro-device-gateway"],
        },
        {
            label: "Integrations",
            url: `${match.url}/integrations/`,
            features: ["lobaro-device-gateway", "rest-api", "wmbus-api"],
        },
    ];
}

function buildTopNavigation(navigation, auth, license) {
    return navigation.map((nav, idx) => {
        if(nav.role) {
            if (!auth.hasRole(nav.role)) {
                return null;
            }
            if (nav.features) {
                // For integrations: Any feature is enough
                const allowed = nav.features.map((f) => license.hasFeature(f));
                if (!allowed.includes(true)) {
                    return null;
                }
            }
        }
        return <TopNavigationLink key={idx} to={nav.url} label={nav.label}>{nav.role === "admin" ?
            <i style={{color: "#9b9b9b"}}>{nav.label}</i> : nav.label}</TopNavigationLink>;
    }).filter((n) => n !== null);
}

export const AppContext = React.createContext({});

export default function AppPage() {
    const match = useMatch("/app/:appId");
    const auth = useAuthContext();
    const license = useFeatureContext();

    const [mutateNav] = useMutation(MUTATE_APPLICATION, {variables: {appId: match.params.appId}});
    const appResult = useQuery(QUERY_APPLICATION, {variables: {appId: match.params.appId}});
    const loading = useGraphqlLoadingComponent(appResult);
    const [isEditTopNav, setIsEditTopNav] = useState(false);

    if (loading) {
        return <React.Fragment>
            <AppContext.Provider value={{id: match.params.appId, ...platformConfig}}>
                {loading}
            </AppContext.Provider>
        </React.Fragment>;
    }

    const {appInstance} = appResult.data;

    let nav = JSON.parse(appInstance.navigationRaw || 'null');

    Log.Debug("nav is:", nav);
    let navigation = nav;
    if (_.isEmpty(nav)) {
        navigation = createNavigation(match);
    }
    Log.Debug("navigation is:", navigation);

    const appContext = {...platformConfig, ...appInstance};
    Log.Debug("AppPage.appContext", appContext);

    return <AppContext.Provider value={appContext}>
        <Header logo={<HeaderAppLogo/>} logoLinkTo={'/' /*'/app/' + appContext.id + '/devices/'*/}/>
        <TopNavigation moduleName={appInstance.name}
                       secondary={<React.Fragment>
                           {buildTopNavigation(navigation, auth, license)}
                       </React.Fragment>}
                       tertiary={<React.Fragment>
                           {auth.hasRole("admin") ? <ContextBarIconAction iconName={"settings"}
                                                                          onClick={() => setIsEditTopNav(!isEditTopNav)}/>
                               : null}
                       </React.Fragment>}
        />
        <Modal isOpen={isEditTopNav} onRequestClose={() => setIsEditTopNav(false)}>
            <NavigationEditForm navigation={navigation}
                                onSubmit={(values) => {
                                    return Promise.resolve(mutateNav({
                                        variables: {
                                            input: {navigation: JSON.stringify(values.navEntries)}
                                        }
                                    })).then(() => {
                                        setIsEditTopNav(false);
                                    });
                                }}
                                onCancel={() => setIsEditTopNav(false)}
            />
        </Modal>
        <div className="slds-container--fluid">
            <div className="slds-grid">
                {/* Vertical nav is deprecated, but m<be we need it again for mobile devices?
                !auth.hasRole("admin") ? null : <div className="slds-p-left--xx-small slds-shrink">
                    <VerticalNavigation navigation={navigation} onSubmit={(values, actions) => {
                        return mutateNav({
                            variables: {
                                input: {navigation: JSON.stringify(values.navEntries)}
                            }
                        });
                    }}/>
                </div>*/}
                <div className="slds-border--left slds-grow">
                    <div className="slds-p-around--xx-small slds-size--1-of-1">
                        <Routes>
                            <ContextRoute exact strict path={`${match.pathname}/devices/`} element={<DeviceListPage />}/>
                            <ContextRoute exact strict path={`${match.pathname}/devices/new/`} element={<NewDevicePage/>}/>
                            <ContextRoute strict path={`${match.pathname}/devices/:deviceId/`} element={<DeviceDetailPage />}/>
                            <ContextRoute exact strict path={`${match.pathname}/alarm/`} element={<AlarmPage />}/>
                            <ContextRoute exact strict path={`${match.pathname}/alarm/new/`} element={<NewAlarmConfigPage />}/>
                            <ContextRoute strict path={`${match.pathname}/alarm/:id/`} element={<AlarmConfigDetailPage />}/>
                            <ContextRoute exact strict path={`${match.pathname}/datasources/`} element={<DatasourcesPage />}/>
                            <ContextRoute exact strict path={`${match.pathname}/device-data/`} element={<ParsedDataPage />}/>
                            <ContextRoute exact strict path={`${match.pathname}/raw-data/`} element={<SensorDataPage />}/>
                            <ContextRoute exact strict path={`${match.pathname}/dashboards/`} element={<DashboardsPage />}/>
                            <ContextRoute exact strict path={`${match.pathname}/dashboards/:dashboardId/`}
                                          element={<DashboardPage />}/>
                            <ContextRoute strict path={`${match.pathname}/integrations/`} element={<IntegrationsPage />}/>

                            <Route exact path={`${match.pathname}/temperature/devices/`} element={<DevicesTable />}/>
                            <Route strict path={`${match.pathname}/temperature/devices/:devId`} element={<DeviceDetails />}/>

                            <Route path="*" render={(props) => {
                                Log.Debug("Page not found props", props);
                                return <div>Page not found</div>;
                            }}/>
                        </Routes>
                    </div>
                </div>

            </div>
        </div>
    </AppContext.Provider>;
}
