import {Modal} from "../../../common/slds";
import Button from "../../../common/slds/buttons/button";
import {SldsFileSelectorField} from "../../../common/ui/form/formElements";
import React, {useState} from "react";
import {Formik} from "formik";
import {Log} from "../../../common/log";
import {Form} from "../../../common/ui/form/formik";
import {Accordion, AccordionPanel} from "../../../common/ui/accordion";
import DataTable, {Col, HeaderCol, Row, TableBody, TableHead} from "../../../common/slds/dataTable";
import Papa from "papaparse";
import {useT} from "../../../common/i18n";
import {Checkbox} from "../../../common/slds/inputs/Checkbox";



function useCsvImport() {
    let [results, setResults] = useState("");

    return {
        results: results,

        /**
         * @param file {File}
         */
        loadFile: (file) => {
            Log.Debug("Loading CSV File");
            Papa.parse(file, {
                skipEmptyLines: true,
                complete: (results) => {
                    Log.Debug("Loading CSV File - DONE");
                    setResults(results);
                }
            });
        }
    };
}




export default function ConfigFileImportModal(props) {
    const t = useT();
    let open = props.open
    let setOpen = props.setOpen
    let configForm = props.setValueFunction.form
    let fieldName = props.setValueFunction.fieldName
    let [columnHeaders, setColumnHeaders] = useState(true)

    const csvImport = useCsvImport();


    return <Modal isOpen={open} onRequestClose={() => setOpen(false)} size={'large'} heading={t("device.config.import.heading", "Import Config Value from CSV")}
           footer={<Button iconName={"close"} onClick={() => setOpen(false)}>{t("common.button.close", "Close")}</Button>}

    >

        <Formik
            initialValues={{
                file: "",
            }}
        >{(form) => {
            const file = form.values.file;

            Log.Debug("File:", file);
            return <Form>
                <SldsFileSelectorField label={t("device.config.import.csv-file","CSV File")} buttonLabel={"Select CSV"} name={"file"} required={false}
                                       accept={".csv"} onFileChange={(file) => {
                    csvImport.loadFile(file);
                }}/>
                <Accordion>
                    <AccordionPanel id={"0"} summary={t("device.config.import.hints.title","Hints")}>
                        <div className="slds-text-heading--small">{t("device.config.import.hints.csv-format","CSV Format")}</div>
                        <div>{t("device.config.import.hints.import-csv-file", "Import a CSV file.")}</div>
                        <div>{t("device.config.import.hints.chose-column", "Chose a column.")}</div>
                        <div>{t("device.config.import.hints.empty-column-handling", "All non empty entry's of the column will be put in a comma seperated list.")}</div>
                    </AccordionPanel>
                </Accordion>
                <Checkbox checked={columnHeaders} onClick={() => setColumnHeaders(!columnHeaders)}
                label={t("device.config.import.has-column-name", "Has column names/headlines in first line")}/>
                 <div className="slds-text-heading--medium slds-m-vertical--small">{t("device.config.import.select-column","Select Column")}</div>
                    <DataTable fixedLayout={false}>
                    <TableHead>
                        <HeaderCol>{columnHeaders ? t("device.config.import.column-name", "Column Name") : t("device.config.import.column-number", "Column Number")}</HeaderCol>
                        <HeaderCol>{t("device.config.import.preview", "Preview")}</HeaderCol>
                        <HeaderCol>{t("device.config.import.num-items", "# Items")}</HeaderCol>
                        <HeaderCol>{t("device.config.import.action", "Action")}</HeaderCol>
                    </TableHead>
                    <TableBody>
                        { csvImport.results?.data ? csvImport.results?.data[0].map((e, i) => {
                            return <Row key={i}>
                                <Col wrap={true} width={"15%"}>{ columnHeaders ? csvImport.results?.data[0][i] : i + 1}</Col>
                                <Col wrap={true}  width={"65%"}>{computePreview(csvImport.results?.data, i, columnHeaders)}</Col>
                                <Col wrap={true} width={"10%"}>{countColumnElements(csvImport.results?.data, i, columnHeaders)}</Col>
                                <Col width={"10%"}><Button iconName={"upload"} variant={"brand"} onClick={() => {
                                    configForm.setFieldValue(fieldName, computeValue(csvImport.results?.data, i, columnHeaders), false)
                                    setOpen(false)
                                }}>{t("device.config.import.select", "Select")}</Button></Col>
                            </Row>;
                        }) : null}
                    </TableBody>
                </DataTable>
            </Form>;
        }}</Formik>
    </Modal>
}

function countColumnElements(data, column, hasHeader) {
    let elements = 0;
    //start based on presence of header:
    let i = hasHeader ? 1 : 0;
    for (; data.length > i; i++) {
        if (data[i][column]){
            elements = elements + 1
        }
    }
    return elements
}

function computePreview(data, column, hasHeader) {
    let preview = "";
    let added = 0;
    //start based on presence of header:
    let i = hasHeader ? 1 : 0;

    for (; data.length > i && added < 10; i++) {
        if (data[i][column]  && data[i][column].trim() !== ""){
           preview = preview ? preview + ',' + data[i][column].trim() : data[i][column].trim()
            added++
        }
    }
    if (preview && preview.length > 100) {
        preview = preview.substr(0, 100)
        preview += "..."
    } else if (preview && (added < countColumnElements(data, column, hasHeader) )){
        preview = preview.substr(0, 100)
        preview += ",..."
    }
    return preview
}

function computeValue(data, column, hasHeader) {
    let value = "";

    //start based on presence of header:
    let i = hasHeader ? 1 : 0;

    for (; data.length > i; i++) {
        if (data[i][column] && data[i][column].trim() !== ""){
                value = value ? value + ',' + data[i][column].trim() : data[i][column].trim()
        }
    }
    return value
}