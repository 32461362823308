import React, {useContext, useState} from "react";
import Page from "../../common/ui/page";
import {useMutation, useQuery} from "@apollo/client";
import {MUTATE_UPDATE_DEVICE} from "../queries";
import {useGraphqlLoadingComponent} from "../../common/graphql";
import {Link, Navigate, useNavigate} from "react-router-dom";
import * as Yup from "yup";
import * as Log from "../../common/log";
import {Form, Formik} from "formik";
import {
    CancelButtonField,
    FormActions,
    Option,
    SldsCheckboxField,
    SldsFormElementCompound,
    SldsInputField,
    SldsSelectField,
    SldsTextareaField,
    SubmitButtonField
} from "../../common/ui/form/formElements";
import gql from "graphql-tag";
import AddHardwareDialog from "./addHardwareDialog";
import {YesNoDialog} from "../../common/slds/modals/YesNoDialog";
import _ from "underscore";
import Button from "../../common/slds/buttons/button";
import {NotifyUser} from "../../common/userNotification";
import {DeviceAppLookupField} from "../../components/device/DeviceAppLookupField";
import OrganisationLookupField from "../../components/organisation/organisationLookupField";
import DeviceTypeLookupField from "../../components/deviceType/deviceTypeLookupField";
import DeviceTagPillContainerField from "../../components/device/DeviceTagPillContainerField";
import {useAuthContext} from "../../common/context/authContext";
import {useParams} from "react-router";
import {FeatureContext} from "../../common/context/featureContext";
import {FeatureNotEnabled} from "../../common/featureNotEnabled";

export const QUERY_EUI_ASSIGNMENT = gql`
    query getEuiAssignmentForDevice ($deviceId: ID! ) {
        getEuiAssignmentForDevice(deviceId: $deviceId){
            eui
            id: eui 
        }
    }
`;


const QUERY_DEVICE = gql`
    query ($id: ID!) {
        device(id: $id) {
            id
            name
            description
            addr
            activationCode
            initialConfigRaw
            deviceType {
                id
                displayName
                configProperties
            }
            app {
                id
                name
            }
            organisation {
                id
                name
            }
            comment
            firmwareVersion
            serial
            tags
        }
    }`;



const HardwareDetailPage = () => {
    const license = useContext(FeatureContext)
    if (!license.validateFeatures("lobaro-device-gateway")) {
        return <Page trail={[]} title={"Hardware detail page"} withPadding={false}>
            <FeatureNotEnabled/>
        </Page>
    }
    const [showNewHardwareModal, setShowNewHardwareModal] = useState(false);
    const deletePending = useState(false);
    const navigate = useNavigate();
    const auth = useAuthContext();
    const id = useParams().id

    const hardwareDetailResult = useQuery(QUERY_DEVICE, {
        variables: {
            id: id,
        }
    });

    const euiResult = useQuery(QUERY_EUI_ASSIGNMENT, {
        variables: {
            deviceId: id,
        }
    });

     const [addActivationCode] = useMutation(gql`
        mutation($id: ID!) {
            generateActivationCode(id: $id)
        }
    `, {
        variables: {
            id: id
        },
        onCompleted: () => hardwareDetailResult.refetch()
    });

    const [updateHardware] = useMutation(MUTATE_UPDATE_DEVICE, {
        variables: {
            id: id
        }
    });
    const [deleteHardware] = useMutation(gql`mutation($id: ID!) {
        deleteDeviceById(id: $id) {
            id
        }
    }`, {
        variables: {
            id: id
        }
    });

    const loadingHW = useGraphqlLoadingComponent(hardwareDetailResult);
    if (loadingHW) {
        return loadingHW;
    }

    const device = hardwareDetailResult.data.device;
    if (!device) {
        return <Navigate to={"/configuration/hardware"} />
    }
    // It's an object
    let initialConfig = JSON.parse(device.initialConfigRaw);

    let devEUI;
    if (euiResult.data?.getEuiAssignmentForDevice?.eui) {
        devEUI = " DevEUI: " + euiResult.data?.getEuiAssignmentForDevice?.eui
    } else {
        devEUI = ""
    }
    Log.Debug("initialConfig", initialConfig);
    // TODO: Use T for translations
    return <Page
        trail={[<Link to="../hardware" key={1}>Hardware List</Link>,
            <Link to={"."} key={2}>{device.id}</Link>]}
        title={`${device.name} (${device.addr})` + devEUI }
        actions={<>
            <Button iconName={"add"} onClick={() => setShowNewHardwareModal(true)}>New Hardware</Button>
            {device.activationCode === "" &&
            <Button iconName={"yubi_key"} onClick={() => addActivationCode()}>Add Activation Code</Button>}
            <Button iconName={"preview"} onClick={() => navigate("/organisation/devices/" + device.id + "/device-data")}>Show Device</Button>
            <Button iconName={"delete"} className="slds-button--destructive" onClick={() => deletePending[1](true)}>Delete Device</Button>
        </>}
    >
        <AddHardwareDialog isOpen={showNewHardwareModal} onRequestClose={() => setShowNewHardwareModal(false)} initialValues={device}/>
        <Formik
            initialValues={{
                ...device,
                initialConfig: initialConfig
            }}
            initialStatus={{
                readOnly: true,
                canEdit: true,
            }}
            enableReinitialize={true}
            validationSchema={Yup.object().strict().shape({
                addr: Yup.string().required().trim()
            })}
            validate={(values) => {
                const errors = {};
                if (values.deviceType && values.organisation) {
                    if (values.deviceType.private && values.organisation.id != values.deviceType.organisationId) {
                        errors.deviceType = 'DeviceType not accessible for Organisation: ' +  values.organisation.id
                    }
                }
                return errors;
            }}
            onSubmit={(values, actions) => {
                Log.Debug("submit", values);

                updateHardware({
                    variables: {
                        id: id,
                        input: {
                            addr: values.addr,
                            name: values.name,
                            description: values.description,
                            deviceTypeId: values.deviceType?.id,
                            appId: values.app?.id || 0, // 0 to clear the app ID, null would be ignored!
                            organisationId: values.organisation?.id || 0, // 0 to clear the org ID, null would be ignored!
                            initialConfig: JSON.stringify(values.initialConfig),
                            properties: JSON.stringify(values.properties),
                            comment: values.comment,
                            firmwareVersion: values.firmwareVersion,
                            serial: values.serial,
                            tags: values.tags
                        }
                    },
                    refetchQueries: [{
                        query: QUERY_DEVICE,
                        variables: {
                            id: id,
                        }
                    }]
                }).catch((err) => {
                    NotifyUser.Error("Failed to update hardware", err);
                }).finally(() => {
                    actions.setSubmitting(false);
                });
            }}
            render={formik => {
                const selectedDeviceType = formik.values.deviceType;
                let configProperties = [];
                let missingConfigValues = {};
                if (selectedDeviceType) {
                    let selectedDeviceTypeConfigProperties = JSON.parse(selectedDeviceType.configProperties) || [];
                    if (!_.isArray(selectedDeviceTypeConfigProperties)) {
                        // Backward compatibility to old empty objects
                        selectedDeviceTypeConfigProperties = [];
                    }
                    configProperties = selectedDeviceTypeConfigProperties;

                    missingConfigValues = {...formik.values.initialConfig};
                    selectedDeviceTypeConfigProperties?.forEach(p => {
                        delete (missingConfigValues[p.name]);
                    });

                    Log.Debug("Missing: ", missingConfigValues);
                }


                return <Form className="slds-m-around--small">
                    <SldsInputField name={"name"} label={"Name"}/>
                    <SldsInputField name={"addr"} label={"Address"}/>
                    <SldsInputField name={"serial"} label={"Serial"}/>
                    <DeviceTagPillContainerField orgId = {device.organisation?.id || auth.organisationId()}/>
                    <SldsTextareaField name={"description"} label={"Description"} rows={3}/>
                    <SldsInputField name={"activationCode"} label={"Activation Code"} readOnly={true}/>
                    <SldsInputField name={"firmwareVersion"} label={"Firmware"}/>
                    <SldsTextareaField name={"comment"} label={"Comment"}/>
                    <DeviceTypeLookupField/>
                    <OrganisationLookupField/>
                    <SldsCheckboxField inlineLabel={"Enforce DTLS if Cert is present"}
                                       name="certEnforcesDtls" id="certEnforcesDtls"/>
                    <DeviceAppLookupField
                        name={"app"}
                        orgId={formik.values?.organisation?.id}
                    />

                    <SldsFormElementCompound label={"Initial Config"} className="slds-m-top--x-small">
                        {
                            configProperties.map(configProperty => {
                                const fieldName = `initialConfig.${configProperty.name}`;
                                const label = `${configProperty.name} (${configProperty.type})`;

                                if (configProperty.type === "Boolean") {
                                    return <SldsSelectField name={fieldName} label={label} key={fieldName}>
                                        <Option value="true">true</Option>
                                        <Option value="false">false</Option>
                                    </SldsSelectField>;
                                }
                                if (configProperty.type === "Int32") {
                                    return <SldsInputField
                                        key={fieldName}
                                        name={fieldName}
                                        type={"number"}
                                        label={label}
                                    />;
                                }
                                if (configProperty.type === "ByteArray") {
                                    return <SldsInputField
                                        key={fieldName}
                                        name={fieldName}
                                        type={"text"}
                                        label={label}
                                        sanitize={value => {
                                            if (typeof value !== "string") {
                                                value = "";
                                            }
                                            value = value.toLowerCase().replace(/\s/g, '');
                                            return value;
                                        }}
                                        validate={value => {
                                            if (value && !value.match(/^[0-9a-fA-F]*$/)) {
                                                return "Please use hexadecimal notation [0-9a-fA-F]";
                                            }
                                        }}
                                    />;
                                }
                                return <SldsInputField name={fieldName} label={label} type={"text"} key={fieldName}/>;
                            })
                        }
                        {_.keys(missingConfigValues).map((k) => {
                            const fieldName = `initialConfig.${k}`;
                            return <SldsInputField name={fieldName} label={`${k} (missing in device type)`} type={"text"} key={fieldName}/>;
                        })}
                    </SldsFormElementCompound>
                    <FormActions>
                        <SubmitButtonField/>
                        <CancelButtonField/>
                    </FormActions>
                </Form>
            }}/>
        <YesNoDialog title={"Delete Device"} text={"Are you sure you want to delete the device and all its data?"}
                     open={deletePending}
                     onYes={() => {
                         deleteHardware().then(() => navigate("/configuration/hardware"));
                     }
                     }/>
    </Page>
};

export default HardwareDetailPage;
