import {Button, Card, Icon} from "@salesforce/design-system-react";
import {SldsFormElementCompound, SldsFormElementRow, SldsInput, SldsTextarea} from "../../common/ui/form/formElements";
import {Formik} from "formik";
import {PillContainerField} from "../../common/ui/form/pillContainerField";
import * as React from "react";
import {useT} from "../../common/i18n";
import {CopyButton} from "../../organisation/config/wmbus/CollapsibleString";

export const ApiCredentials = ({tok, deleteToken, getRole}) => {
    const t = useT()

    return <Card key={tok.id}
                 heading={tok.name}
                 className="slds-card_boundary slds-p-horizontal--x-small"
                 icon={<Icon category="standard" name="data_integration_hub" size="small"/>}
                 headerActions={<div><Button variant="destructive"
                                             onClick={() => deleteToken(tok)}>{t("common.button.delete", "Delete")}</Button>
                 </div>}
    >
        { // When a password is set, show only BasicAuth credentials, else show the token
            tok.password ?
                <div className="slds-m-bottom--x-small">
                    <SldsFormElementCompound label={t("integrations.rest.basic-auth", "BasicAuth")}>
                        <SldsFormElementRow>
                            <SldsInput label={t("integrations.rest.username", "Username")}
                                       field={{value: "token-" + tok.id}}/>
                            <SldsInput label={t("integrations.rest.password", "Password")}
                                       field={{value: tok.password}}/>
                            <SldsInput label={t("integrations.rest.basic-auth-header", "BasicAuth Header")}
                                       field={{value: "Basic " + btoa("token-" + tok.id + ":" + tok.password)}}/>
                        </SldsFormElementRow>
                    </SldsFormElementCompound>
                </div>
                :
                <div className={"slds-grid slds-grid--vertical-align-center"}>
                    <div className={"slds-col"}>
                        <SldsTextarea label={t("integrations.rest.auth-header", "Authorization Header")} rows={3}
                                      field={{value: "Bearer " + tok.token}}/>
                    </div>
                    <div className={"slds-col--bump-left slds-m-top--medium"}>
                        <CopyButton text={tok.token} size={"large"}/>
                    </div>
                </div>
        }

        <Formik
            initialValues={{...tok, "roles": tok.tokenParsed.roles}}
            render={() => {
                return <PillContainerField pillLabelExtractor={(r) => r} name={"roles"}
                                           tooltipExtractor={(r) => getRole(r)?.displayName || r}
                                           label={t("integrations.rest.roles", "Roles")} readOnly={true}/>;
            }}
        />


        {/*<Json json={t.tokenParsed}/>*/}
    </Card>
}
