import React from "react"
import { Button, Col, Form, InputNumber, Row } from "antd"

type Props = {
    value: number,
    onChange: (value: number) => void
    reset: () => void
    max?: number,
}

type ValidationRule = {
    type: "number",
    min?: number,
    max?: number,
}

const CellEditForm: React.FC<Props> = (props) => {
    let validationRule : ValidationRule = {
        type: "number",
        min: 0,
    }
    if (!!props.max) {
        validationRule = {
            ...validationRule,
            max: props.max,
        }
    }
    return <Form
        initialValues={{ value: props.value }}
        onFinish={(values) => props.onChange(values.value)}>
        <Row>
            <Col flex="1">
                <Form.Item
                    name={"value"}
                    rules={[validationRule]}>
                    <InputNumber />
                </Form.Item>
            </Col>
            <Col>
                <Button htmlType={"submit"}>
                    <svg className={"slds-button__icon"} aria-hidden="true">
                        <use xlinkHref={`/assets/icons/action-sprite/svg/symbols.svg#approval`} />
                    </svg>
                </Button>
                <Button onClick={props.reset}>
                    <svg className={"slds-button__icon"} aria-hidden="true">
                        <use xlinkHref={`/assets/icons/action-sprite/svg/symbols.svg#close`} />
                    </svg>
                </Button>
            </Col>
        </Row>
    </Form>
}

export default CellEditForm