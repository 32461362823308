import { useT } from "../../../../common/i18n"
import { useNotificationContext } from "../../../../notifications/notificationContext"
import { useMutation } from "@apollo/client"
import { usePagination } from "../../../../common/hooks/usePagination"
import * as React from "react"
import { useState } from "react"
import { TabsPanel } from "@salesforce/design-system-react"
import DownlinkFormik from "../downlinkFormik"
import GenericDataTable from "../../../../common/ui/genericDataTable/genericDataTable"
import DataTableColumn from "../../../../common/ui/data-table/column"
import Tooltip from "../../../../common/slds/tooltip/tooltip"
import { Icon } from "../../../../common/slds/icons/icon"
import gql from "graphql-tag"

const MUTATION_CREATE_DOWNLINK = gql`
    mutation ($deviceId: ID!, $type: String!, $data: String!, $confirmable: Boolean!) {
        createDownlink(deviceId: $deviceId, type: $type, data: $data, confirmable: $confirmable) {
            id
            createdAt
            type
            sent
            completed
            confirmable
            confirmed
            data
            device {
                id
            }
        }
    }
`

export const DeviceBulkDownlinkTab = (props) => {
    const t = useT()
    const notify = useNotificationContext()
    const [createDownlink] = useMutation(MUTATION_CREATE_DOWNLINK)
    const page = usePagination()
    const [results, setResults] = useState(new Map())

    let multiSelection = props.multiSelection
    return (
        <TabsPanel label="Downlink">
            <DownlinkFormik
                onSubmitFunction={(values) => {
                    setResults(new Map())
                    let promises = []
                    multiSelection.selections.forEach((currentValue) => {
                        promises.push(
                            createDownlink({
                                variables: {
                                    deviceId: currentValue.id,
                                    type: values.type,
                                    data: values.data,
                                    confirmable: !!values.confirmable,
                                },
                            })
                                .catch((err) => {
                                    setResults(results.set(currentValue.id, err))
                                    return true
                                })
                                .finally(() => {
                                    setResults(results.set(currentValue.id, false))
                                    return false
                                })
                        )
                    })
                    Promise.all(promises).then((values) => {
                        const failed = _.filter(values, (v) => {
                            return v === true
                        })
                        if (failed.length > 0) {
                            //"Only use {{allowed}}", {allowed: "[0-9, a-z, A-Z, -]"}
                            notify.error(
                                t("device.bulkoperation.downlinks.failed", "Creation failed for {{ammount}} downlinks", { ammount: failed.length }),
                                "Creation failed for some downlinks"
                            )
                        } else {
                            notify.success(t("device.bulkoperation.downlinks.success", "Successfully created downlinks"))
                        }
                    })
                }}
            />

            <br />
            <h1 className="slds-p-horizontal--xx-small">{t("device.bulkoperation.table-heading", "Affected Devices")}:</h1>
            <GenericDataTable id={"bulk-operation-table"} items={multiSelection.selections} page={page} tableConfigDefault={{}} selection={multiSelection}>
                <DataTableColumn
                    label={t("device.table-config.heading.serial", "Serial")}
                    property={"serial"}
                    render={(props) => {
                        return (
                            <div className="slds-truncate" title={props.item.serial}>
                                <a href={`/#/organisation/devices/${props.item.id}/device-data`}>{props.item.serial}</a>
                            </div>
                        )
                    }}
                />
                <DataTableColumn
                    label={t("device.table-config.heading.address", "Address")}
                    property="addr"
                    title={"Addr"}
                    render={(props) => {
                        return (
                            <div className="slds-truncate" title={props.item.addr}>
                                <a href={`/#/organisation/devices/${props.item.id}/device-data`}>{props.item.addr}</a>
                            </div>
                        )
                    }}
                />
                <DataTableColumn
                    label={t("device.table-config.heading.name", "Name")}
                    property="name"
                    title={"Name"}
                    render={(props) => {
                        return props.item.name
                    }}
                />
                <DataTableColumn
                    label={t("device.table-config.heading.type", "Type")}
                    property={"deviceType"}
                    render={(props) => {
                        return props.item.deviceType.displayName
                    }}
                />
                <DataTableColumn
                    label={t("device.table-config.heading.app", "App")}
                    property={"app"}
                    render={(props) => {
                        return props.item.app ? props.item.app.name : null
                    }}
                />
                <DataTableColumn
                    label={t("common.organisation", "Organisation")}
                    property={"Organisation"}
                    render={(props) => {
                        return props.item.organisation ? props.item.organisation.name : ""
                    }}
                />
                <DataTableColumn
                    label={t("device.bulkoperation.table.result", "Downlink Created")}
                    property={"result"}
                    render={(props) => {
                        if (results.get(props.item.id) == null) {
                            return null
                        }

                        return (
                            <Tooltip
                                left="-10px"
                                top="-50px"
                                content={results.get(props.item.id) ? results.get(props.item.id) : t("common.success", "Success")}>
                                {results.get(props.item.id) ? <Icon name="close" size={"small"} /> : <Icon name="check" size={"small"} />}
                            </Tooltip>
                        )
                    }}
                />
            </GenericDataTable>
        </TabsPanel>
    )
}
