import React from "react";
import {CustomPropTypes} from "../../propTypes/customPropTypes";
import {classnames} from "../../wrapper/classnames";
import PropTypes from "prop-types";

export function CardBody(props) {
    const {children, inner} = props;
    return <div className={classnames("slds-card__body", {
        "slds-card__body_inner": inner,
    })}>
        {children}
    </div>
}

CardBody.propTypes = {
    children: CustomPropTypes.children,
    inner: PropTypes.bool,
};

export function CardFooter(props) {
    const {children} = props;
    return <footer className="slds-card__footer">
        {children}
    </footer>
}

CardFooter.propTypes = {
    children: CustomPropTypes.children,
};

export function CardHeaderTitle(props) {
    const {children} = props;
    return <h2 className="slds-card__header-title">
        {children}
    </h2>
}

CardHeaderTitle.propTypes = {
    children: CustomPropTypes.children,
};

export function CardHeader(props) {
    const {children} = props;
    return <div className="slds-card__header slds-grid">
        {children}
    </div>
}

CardHeader.propTypes = {
    children: CustomPropTypes.children,
};

export default function Card(props) {
    const {children, bordered} = props;
    return <article className={classnames("slds-card", {
        "slds-card_boundary": bordered,
    })}>
        {children}
    </article>
}

Card.propTypes = {
    children: CustomPropTypes.children,
    bordered: PropTypes.bool,
};