import React from "react";
import * as PropTypes from "prop-types";
import Error from "./error";
import {useAuthContext} from "../context/authContext";
import _ from "underscore";

export const HasPermission = (props) => {
    const auth = useAuthContext();
    const {children, role, showError, hidden, isLoggedIn} = props;

    let checkIsLoggedIn = props.isLoggedIn !== undefined; // true or false

    if (hidden) {
        return null;
    }
    /*
    Log.Debug("checkIsLoggedIn", checkIsLoggedIn);
    Log.Debug("isLoggedIn", isLoggedIn);
    Log.Debug("auth.isLoggedIn", auth.isLoggedIn);
    */

    if (checkIsLoggedIn) {
        if (isLoggedIn && !auth.isLoggedIn()) {
            return showError ? <Error title={"Not Authorized"} message={`Not Logged in`}/> : null;
        } else if (!isLoggedIn && auth.isLoggedIn()) {
            // Do not render if logged in
            //Log.Debug("Hide because logged in");
            return null;
        }
    }

    if (typeof role === "string") {
        if (!auth.hasRole("admin")) {
            return showError ? <Error title={"Not Authorized"} message={`Missing role '${role}'`}/> : null;
        }
    }
    if (_.isArray(role)) {
        for (let i = 0; i < role.length; i++) {
            if (auth.hasRole(role[i])) {
                return children;
            }
        }
        return showError ? <Error title={"Not Authorized"} message={`Missing role '${role}'`}/> : null;
    }

    return children
};

HasPermission.propTypes = {
    children: PropTypes.any.isRequired,
    role: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    isLoggedIn: PropTypes.bool,
    showError: PropTypes.bool,
    hidden: PropTypes.bool,
};