// functions to calculate the etag for a given object (e.g. a config downlink),
// or to turn an object into a canonical form for etag calculation.

import crypto from 'crypto';

// canonicForm takes an object and returns the string representation of it,
// printing all key-value pairs sorted alphabetically by key in the format 'key="value"\n'. The key will be forced into lower-case.
// All keys should be strings. Values can be anything that can be converted to a string. TODO: How to handle null, undefined? Empty string? (Shouldn't be a problem currently, platform turns them into empty strings)
export function canonicForm(dataObj) {
    let res = '';
    let copyObj = {} // map values from dataObj to lower case keys
    const orgKeys = Object.keys(dataObj);
    orgKeys.forEach(key => copyObj[key.toLowerCase()] = dataObj[key])
    const keys = orgKeys.map(key => key.toLowerCase()).sort()
    keys.forEach(key => {
        res += `${key}="${copyObj[key]}"\n`;
    })
    return res;
}

// calculateEtag takes an object and returns the etag for it.
// The current implementation uses the sha1 hash of the canonic form of the object.
export function calculateEtag(dataObj) {
    const canonic = canonicForm(dataObj);
    return crypto.createHash('sha1').update(canonic).digest('hex');
}
