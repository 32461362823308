// Graphql
import gql from 'graphql-tag'
import {useGraphqlLoadingComponent} from '../../../common/graphql'
import React, {useContext} from "react";
import {AppContext} from "../../appPage";
import GenericDataTable from "../../../common/ui/genericDataTable/genericDataTable";
import {useQuery} from "@apollo/client";
import {useSort} from "../../../common/hooks/useSort";
import {usePagination} from "../../../common/hooks/usePagination";
import {useCsvExport} from "../../../common/hooks/useCsvExport";

const QUERY_GET_DEVICES = gql`
    query getDevices($appId: ID!, $page:PaginationInputType, $filter:[FilterInputType!], $sort: SortInputType) {
        devices(appId: $appId page: $page, filter: $filter, sort: $sort, scope: "organisation") {
            devices{
                id
                addr
                name
                description
                configRaw
                propertiesRaw
                lastReceived
                deviceType {
                    id
                    displayName
                }
            }
        }
    }
`;

const DevicesTable = () => {
    const appCtx = useContext(AppContext);
    const sort = useSort();
    const page = usePagination(100);

    const devicesResult = useQuery(QUERY_GET_DEVICES, {
        fetchPolicy: "network-only",
        pollInterval: sort.isSorted() || page.fetchedMore ? 0 : 5000, // TODO: Disable live when not on first page
        variables: {
            appId: appCtx.id,
            page: page.getGraphqlPageInput(),
            sort: sort.getGraphqlSortInput(),
        },
        partialRefetch: true
    });

    const csvExport = useCsvExport(QUERY_GET_DEVICES, {
        variables: {
            appId: appCtx.id,
            sort: sort.getGraphqlSortInput(),
        },
        dataExtractor: (result) => result.data.devices.devices
    });

    const loading = useGraphqlLoadingComponent(devicesResult);
    if (loading) {
        return loading;
    }
    const {data} = devicesResult;
    const items = data ? data.devices.devices : null;

    page.setPageItemCount(items ? items.length : 0);


    return <div className="slds-m-bottom--large" style={{ overflow: 'auto' }}>
        <GenericDataTable
            items={items.map((d) => {
                return {
                    ...d,
                    configRaw: JSON.parse(d.configRaw || '{}'),
                    properties: JSON.parse(d.propertiesRaw || '{}'),
                };
            })}
            id="DevicesTable"
            // onSort={this.handleSort}
            selectRows={false}
            tableConfigName={"devices"}
            gqlResult={devicesResult}
            csvExport={csvExport}
            page={page}
            sort={sort}
            tableConfigDefault={{
                cols: [
                    {
                        heading: "Name",
                        cell: {
                            format: "{{#if name}}{{name}}{{else}}- no name -{{/if}}",
                            href: "#{{id}}/tab-0",
                        }
                    },
                    {
                        heading: "Address",
                        cell: {
                            format: "{{addr}}",
                            href: "#{{id}}/tab-0",
                        }
                    },
                    {
                        heading: "Type",
                        cell: {
                            format: "{{deviceType.displayName}}"
                        }
                    },
                    {
                        heading: "Last Received",
                        cell: {
                            format: "{{date lastReceived}}"
                        }
                    },
                    {
                        heading: "Description",
                        cell: {
                            format: "{{description}}"
                        }
                    }
                ]
            }}
        >
        </GenericDataTable>
    </div>;
};

DevicesTable.propTypes = {};

export default DevicesTable


