import {CustomPropTypes} from "../../propTypes/customPropTypes";
import React from "react";
import PropTypes from "prop-types";


export default function ButtonGroup(props) {
    const {children, hasSpace} = props;

    if (hasSpace) {
        return <ul className="slds-button-group-row">
            {React.Children.map(children, (c) => {
                return <li className="slds-button-group-item">{c}</li>;
            })}
        </ul>;
    }

    return <div className="slds-button-group" role="group">
        {children}
    </div>;
}
ButtonGroup.propTypes = {
    children: CustomPropTypes.children,
    hasSpace: PropTypes.bool,
};