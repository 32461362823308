import React from "react";
import {FrontendConfigContext} from "./common/context/frontendConfigContext";
import {backendUrl} from "./common/helper";

const PlatformLogo = (props) => {
    const brandingLogoUrl = React.useContext(FrontendConfigContext)?.brandingConfig.defaultLogoUrl;

    return <div className={props.classNames ? props.classNames : "slds-global-header__logo"}
         style={{backgroundImage: `url(${backendUrl()}${brandingLogoUrl})`}}/>
    ;
}

export default PlatformLogo;