import React, {useState} from "react";
import {useMutation, useQuery} from "@apollo/client";
import {CancelButtonField, FormActions, SldsInputField, SubmitButtonField} from "../../common/ui/form/formElements";
import gql from "graphql-tag";
import {useGraphqlLoadingComponent} from "../../common/graphql";
import {Formik} from "formik";
import {Form} from "../../common/ui/form/formik";
import {Link, useNavigate} from "react-router-dom";
import Page from "../../common/ui/page";
import * as Yup from "yup";
import SingleLookupField from "../../common/ui/lookup/singleLookupField";
import * as Log from "../../common/log";
import ChangePasswordDialog from "../../auth/changePasswordDialog";
import {useAuthContext} from "../../common/context/authContext";
import {getQueryStringParams} from "../../common/helper";
import {NotifyUser} from "../../common/userNotification";
import Button from "../../common/slds/buttons/button";
import UserRolesField from "../../components/user/UserRolesField";
import ButtonGroup from "../../common/slds/buttonGroup/ButtonGroup";
import {useNotificationContext} from "../../notifications/notificationContext";
import DescriptionList, {DescriptionListEntry} from "../../common/slds/descriptionList/descriptionList";
import Heading from "../../common/slds/text/heading";
import {useParams} from "react-router";

const QUERY_USER = gql`
    query($id: ID!) {
        getUser(id: $id) {
            id
            login
            name
            roles
            organisation {
                id
                name
            }
            baseOrganisation {
                id
                name
            }
            createdAt
            email
            oneTimePassword
            lastLogin
        }
    }`;

const MUTATION_UPDATE_USER = gql`
    mutation($id: ID!, $input: UserInput) {
        updateUser(id: $id, input: $input) {
            id
            login
            name
            roles
            organisation {
                id
                name
            }
            createdAt
            email
            oneTimePassword
        }
    }`;

const MUTATION_SEND_ACCOUNT_MAIL = gql`
    mutation($userId: ID!) {
        mail: sendUserEmailInvitation(userId: $userId) {
            to
            cc
            bcc
            subject
        }
    }`;


const MUTATION_DELETE_USER = gql`
    mutation deleteUser($userId: ID!) {
        deleteUser(id:$userId) {
            id
            name
        }
    }
`;

const UserDetailPage = () => {
    const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
    const auth = useAuthContext();
    const navigate = useNavigate();
    const notify = useNotificationContext();
    const userId = useParams().id;

    const userDetailResult = useQuery(QUERY_USER, {
        variables: {
            id: userId,
        }
    });

    const [mutateUpdateUser] = useMutation(MUTATION_UPDATE_USER, {
        variables: {
            id: userId,
        }
    });
    const [mutateSendAccountMail] = useMutation(MUTATION_SEND_ACCOUNT_MAIL, {
        variables: {
            userId: userId,
        }
    });

    const [mutationDeleteUser] = useMutation(MUTATION_DELETE_USER, {
        variables: {userId: userId},
    });

    const organisationListResult = useQuery(gql`
        query($search: String) {
            getOrganisationList(search: $search) {
                id
                name
            }
        }`, {
        variables: {
            page: {
                offset: 0,
                limit: 10
            }
        }
    });
    let isAdmin = auth.hasRole("admin")
    let isOrgAdmin = isAdmin || auth.hasRole("org-admin")
    const userDetail = userDetailResult?.data?.getUser;

    function generateOtp() {
        return mutateUpdateUser({
            variables: {
                input: {
                    oneTimePassword: "generate",
                }
            }
        }).then(() => {
            notify.info("Generated new OTP");
        }).catch((err) => {
            notify.error("Failed to generate OTP", err);
        });
    }

    function sendInvitationMail() {
        let p = Promise.resolve();
        if (!userDetail.oneTimePassword) {
            p = generateOtp();
        }
        p.then(() => {
            mutateSendAccountMail().then((r) => {
                notify.info(<>
                    <Heading size={"small"}>Successfully sent account mail</Heading>
                    <DescriptionList>
                        <DescriptionListEntry label={"To"}>{r.data?.mail?.to}</DescriptionListEntry>
                        <DescriptionListEntry label={"CC"}>{r.data?.mail?.cc}</DescriptionListEntry>
                        <DescriptionListEntry label={"BCC"}>{r.data?.mail?.bcc}</DescriptionListEntry>
                        <DescriptionListEntry label={"Subject"}>{r.data?.mail?.subject}</DescriptionListEntry>
                    </DescriptionList></>);
            }).catch(err => {
                notify.error("Failed to send account mail", err);
            });
        });

    }

    function  deleteUser() {
        if (window.confirm("Delete User " + userDetail.name +" Permanently?")) {
            mutationDeleteUser().then(() => {
                notify.success("deleted user")
                navigate("..", { relative: "path" });
            }, (err) => {
                notify.error("Failed to delete user.", err?.toString());
            });
        }
    }

    const loading = useGraphqlLoadingComponent(userDetailResult);
    if (loading) {
        return loading;
    }


    const passwordResetUrl = userDetail.oneTimePassword && `${window.location.origin}/#/auth/changePassword/${userDetail.id}?otp=${userDetail.oneTimePassword}`;

    return <Page
        trail={[<Link to="../users" key={1}>Users</Link>,
            <Link to={"./" + userDetail.id} key={2}>{userDetail.login}</Link>]}
        title={`${userDetail.name} (${userDetail.login})`}
        actions={<ButtonGroup>
            {isAdmin ? <Button iconName={"resource_territory"} onClick={() => {
            auth.impersonate(userDetail.login)
                .then(() => {
                    const queryStringParams = getQueryStringParams(location.search);
                    const redirectTo = queryStringParams.r || "/";
                    navigate(redirectTo);
                })
                .catch(err => {
                    NotifyUser.Error("Failed to impersonate user", err);
                });
        }}>Impersonate</Button> : null }
            {isAdmin ? <Button iconCategory={"utility"} iconName={"delete"} onClick={() => {
              deleteUser()
            }}>Delete</Button> : null }

    </ButtonGroup>}
        withPadding={true}
    >
        <Formik
            initialValues={{...userDetail}}
            onSubmit={(values, actions) => {
                Log.Debug("submit", values);
                return mutateUpdateUser({
                    variables: {
                        input: {
                            name: values.name,
                            login: values.login,
                            email: values.email,
                            organisationId: values.organisation.id,
                            baseOrganisationId: values.baseOrganisation.id,
                            roles: values.roles,
                        }
                    }
                }).catch((err) => {
                    Log.Error("Failed to update user", err);
                    actions.setFieldError("global", "Failed to update user");
                });
            }}
            initialStatus={{
                readOnly: true,
                canEdit: true,
            }}
            enableReinitialize={true}
            validationSchema={Yup.object().strict().shape({
                name: Yup.string().required().trim()
            })}
        >{() => {
            return <Form>
                <SldsInputField readOnly={true} name={"id"} label={"ID"}/>
                <SldsInputField name={"login"} label={"Login"}/>
                <SldsInputField name={"name"} label={"Name"}/>
                <SldsInputField name={"email"} label={"E-Mail"}/>
                <UserRolesField name={"roles"} label={"Roles"}/>
                <SingleLookupField name={"organisation"} label={"Organisation"}
                                   loadSuggestions={(keyword) => organisationListResult
                                  .refetch({search: keyword})
                                  .then(result => result.data.getOrganisationList)
                              }
                                   titleExtractor={it => it.name}
                                   subtitleExtractor={it => it.id}
                />
                <SingleLookupField name={"baseOrganisation"} label={"Base Organisation"}
                                   loadSuggestions={(keyword) => organisationListResult
                                       .refetch({search: keyword})
                                       .then(result => result.data.getOrganisationList)
                                   }
                                   titleExtractor={it => it.name}
                                   subtitleExtractor={it => it.id}
                />
                <SldsInputField readOnly={true} name={"lastLogin"} label={"Last Login"}/>
                <FormActions>
                    <SubmitButtonField/>
                    <CancelButtonField/>
                </FormActions>
            </Form>;
        }}
        </Formik>

        <div className="slds-p-top--small">
            {isOrgAdmin ? <div> <ChangePasswordDialog userId={userDetail.id}
                                  isOpen={showChangePasswordModal}
                                  onRequestClose={() => setShowChangePasswordModal(false)}/>
            <Button onClick={() => setShowChangePasswordModal(true)}>Change Password</Button> </div> : null}
            <Button iconName="email" onClick={() => sendInvitationMail()}>Send Invitation Mail</Button>
        </div>
        {isOrgAdmin ?
            <div className="slds-p-top--small">
            {passwordResetUrl ?
                <a href={passwordResetUrl} target="_blank">{passwordResetUrl}</a>
                : <Button iconName={"password"} onClick={generateOtp}>Create Password Reset Link</Button>}
        </div> : null }

    </Page>;
};

export default UserDetailPage;