import React from "react"
import { Modal } from "../common/slds"
import { Log } from "../common/log"
import gql from "graphql-tag"
import { useMutation } from "@apollo/client"
import PropTypes from "prop-types"
import { Button, Form, Input } from "antd"

export const MUTATE_UPDATE_USER = gql`
    mutation($id: ID!, $input: UserInput) {
        updateUser(id: $id, input: $input) {
            id
        }
    }`

const ChangePasswordDialog = (props) => {
    let { isOpen, onRequestClose, userId } = props
    let [mutateChangePassword] = useMutation(MUTATE_UPDATE_USER)

    const onSubmit = (values) => {
        mutateChangePassword({
            variables: {
                id: userId, input: {
                    password: values.password,
                },
            },
        }).then(() => {
            onRequestClose()
        }, (e) => {
            Log.Error("Failed to change password", e)
        })
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    return <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
        <Form
            {...formItemLayout}
            initialValues={{}}
            onFinish={onSubmit}
        >
            <Form.Item
                name="password"
                label="New Password"
                rules={[
                    {
                        required: true,
                        message: "Please enter new password!",
                    },
                    {
                        min: 8,
                        message: "Must be at least 8 characters!",
                    },
                    {
                        max: 20,
                        message: "Can have max 20 characters!",
                    },
                ]}
            >
                <Input type={"password"} data-testId={"newPassword"}/>
            </Form.Item>
            <Form.Item
                name="confirmPassword"
                label="Confirm New Password"
                rules={[
                    {
                        required: true,
                        message: "Please enter new password!",
                    },
                    {
                        min: 8,
                        message: "Must be at least 8 characters!",
                    },
                    {
                        max: 20,
                        message: "Can have max 20 characters!",
                    },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('The two passwords that you entered do not match!'));
                        },
                    }),
                ]}
            >
                <Input type={"password"} data-testId={"confirmPassword"}/>
            </Form.Item>
            <Button htmlType="submit">Change Password</Button>
            <Button onClick={onRequestClose}>Cancel</Button>
        </Form>

    </Modal>
}
ChangePasswordDialog.propTypes = {
    userId: PropTypes.string.isRequired, // String comes from GraphQL ID
}

export default ChangePasswordDialog