import {NotifyUser} from "../common/userNotification";
import {Formik} from "formik";
import * as React from "react";
import {useMutation} from "@apollo/client";
import * as log from "../common/log";
import Box from "../common/ui/box";
import DataTable, {Col, Row, TableBody, TableHead} from "../common/slds/dataTable";
import moment from "moment";
import Button from "../common/slds/buttons/button";
import {MUTATE_UPDATE_DEVICE_TYPE, QUERY_DEVICE_TYPE} from "./queries";
import {useT} from "../common/i18n";


const WmbusDataRecordsSelector = (props) => {
    const t = useT();
    const wmbusFields = [];
    let {mbus, deviceType, parseError} = props;
    let showAll = true;
    let dataTableConfig = deviceType.dataTableConfigRaw && JSON.parse(deviceType.dataTableConfigRaw)
    const [updateDeviceType] = useMutation(MUTATE_UPDATE_DEVICE_TYPE, {
        variables: {id: deviceType.id},
        refetchQueries: [{
            query: QUERY_DEVICE_TYPE,
            variables: {
                id: deviceType.id,
            }
        }]
    });


    if (!mbus && parseError) {
        return <Box className="slds-m-top--small"><p>Parse Error: {parseError}</p></Box>;
    }

    if (!mbus) {
        return <div>No mBus Data in Telegram</div>;
    }

    let body = mbus.Body;

    if (!body) {
        return <div>No Data in Body of Telegram</div>;
    }

    //Log.Debug("props", props);
    log.Debug("mbus", mbus);
    //Log.Debug("body", body);


    if (body.DataRecords && body.DataRecords.length > 0) {
        body.DataRecords.forEach((d, i) => {
            if (!showAll && d.DifDataFormat === "special function") {
                return;
            }
            wmbusFields.push({
                id: `${i}`,
                ...d,
            });
        });
    }

    // ------------------------------
    // Is this needed for key stuff?
    // ------------------------------
    // let noDataMsg = null;
    // if (body.IsEncrypted) {
    //     noDataMsg = "Payload encrypted";
    // } else if (!mbus.PayloadKnown) {
    //     let ciNum = parseInt(mbus.CiField, 16);
    //     if (ciNum >= 0xA0 && ciNum <= 0xB7) {
    //         noDataMsg = "Payload format unknown (CiField A1 - B7 are manufacturer specific)";
    //     } else {
    //         noDataMsg = "Payload format unknown";
    //     }
    // }

    return <div>
        <div className="slds-text-heading--medium slds-m-bottom--small">wMbus element Picker</div>
        <Formik
            enableReinitialize={{}}
            initialValues={{}}
            initialStatus={{
                canEdit: true
            }}
            onSubmit={(values, actions) => {
                log.Debug("Submit: ", values, actions);
            }}
        >{() => {
            return <DataTable fixedLayout={false} items={wmbusFields}>
                <TableHead>
                    <Col header>Description</Col>
                    <Col header>Value</Col>
                    <Col header width={"100%"}>Add to Table</Col>
                    {/* <HeaderCol>Format</HeaderCol>*/}
                </TableHead>
                <TableBody>
                    {wmbusFields.map((it, i) => {
                        let unit = it.VifUnit;
                        // Not needed with latest wmbus parser
                        if (unit === "-") {
                            unit = "";
                        }
                        unit = unit.replace("^3", "³");
                        unit = unit.replace("^2", "²");


                        let func = it.DifFunctionString;
                        if (func === "Current Value") {
                            func = "";
                        }

                        let value = it.ValueString;
                        const description = it.VifQuantity;

                        // seconds
                        if (unit === "s") {
                            value = moment.duration(it.ValueScaled, 'seconds').humanize(false);
                            unit = "";
                        }

                        // Time point (date) is old and was changed to Date
                        if (description === "Time point (date)" || description === "Date") {
                            value = moment(value).format("DD.MM.YYYY");
                        }
                        if (description === "Point in Time") {
                            value = moment.unix(it.Value).format("DD.MM.YYYY HH:mm:ss");
                        }

                        // Time point (date & time) is old and was changed to Date
                        if (description === "Time point (date & time)" || description === "Time & Date") {
                            value = moment(value).format("DD.MM.YYYY HH:mm:ss");
                        }

                        //{{data.mbus.Body.DataRecords.1.ValueScaled}}
                        return <Row key={i}>
                            <Col noStripes>{description} {func && `(${func})`}</Col>
                            <Col
                                noStripes>{value} {unit}{it.VifEDescription ? ` (${it.VifEDescription})` : ""}</Col>
                            <Col noStripes><Button hint={true} onClick={() => {
                                let handlebar = "{{"
                                handlebar += "data.mbus.Body.DataRecords." + i + ".ValueScaled}}"
                                let dataTableConfigEntry = {
                                    cell: {
                                        format: handlebar
                                    },
                                    csvFormat: handlebar,
                                    heading: ""
                                }
                                dataTableConfig.cols.push(dataTableConfigEntry)
                                updateDeviceType({
                                    variables: {
                                        input: {
                                            dataTableConfig: JSON.stringify(dataTableConfig),
                                        }
                                    }
                                }).catch((err) => {
                                    NotifyUser.Error(t("device-type.table-config.update-failed", "Failed to save Table Config"), err);
                                });

                            }}>Add Column</Button></Col>
                        </Row>;
                    })}
                </TableBody>
            </DataTable>
        }}</Formik>

    </div>;
}


export default WmbusDataRecordsSelector
