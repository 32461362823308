import PropTypes from "prop-types"

function deprecated(reason) {
    return function (props, propName, componentName) {
        if (props[propName] !== undefined) {
            return new Error(
                `Property ${propName} is deprecated on component ${componentName}: ${reason}`
            );
        }

        return null;
    };
}

export const CustomPropTypes = {
    deprecated: deprecated,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
    child: PropTypes.node,
};
