import React, {useContext} from "react";
import {Link} from "react-router-dom";
import {Button, Card} from "@salesforce/design-system-react";
import Page from "../page";
import AlarmConfigTable from "./alarmConfigTable";
import {Log} from "../../../common/log";
import {RouterContext} from "../../../common/RouterContext";
import {useAuthContext} from "../../../common/context/authContext";


export default function AlarmPage(props) {
    const auth = useAuthContext();

    Log.Debug("AlarmPage.props", props);
    Log.Debug("AlarmPage.RouterContext", useContext(RouterContext));
    const {match} = props;
    const actions = <div>
        <Link to={`new/`} key={1}>
            {auth.hasRole("admin") ?
                <Button iconCategory={"utility"} iconName={"add"} iconPosition={"left"}
                        label={"New Alarm"}/> : null}
        </Link>
    </div>;


    return <Page
        title={"Alarm Configuration"}
        actions={actions}
        trail={[<Link to={`${match.url}`} key={1}>Alarm</Link>]}
    >


        <div className="slds-grid">
            <div className="slds-size--3-of-3">
                <Card heading={""} className="slds-has-top-magnet">
                    <AlarmConfigTable/>
                </Card>

            </div>
        </div>
    </Page>
}