export function base64ToBytes(val) {
    let bytes = [];
    let decoded;
    try {
        decoded = atob(val);
    } catch {
        return bytes;
    }
    for (let i = 0, len = decoded.length; i < len; i++) {
        bytes.push(decoded.charCodeAt(i));
    }
    return bytes;
}
export function bytesToHex(val) {
    let result = [];
    for (let i = 0, len = val.length; i < len; i++) {
        result.push(byteToHex(val[i], 2));
    }
    return result
}

export function byteToHex(d, padding) {
    let hex = Number(d).toString(16);
    padding = typeof (padding) === "undefined" || padding === null ? 2 : padding;

    while (hex.length < padding) {
        hex = "0" + hex;
    }

    return hex.toUpperCase();
}
export function base64ToHex(value) {
    return bytesToHex(base64ToBytes(value))
}