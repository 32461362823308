import {CustomPropTypes} from "../../propTypes/customPropTypes";
import React from "react";
import PropTypes from "prop-types"

export default function Heading(props) {
    const {children, size} = props;
    return <div className={`slds-text-heading--${size}`}>{children}</div>
}
Heading.defaultProps = {
    size: "large"
}
Heading.propTypes = {
    children: CustomPropTypes.children,
    size: PropTypes.oneOf(["large", "medium", "small"]),
}