import React, {useContext, useState} from "react";
import {Notification, NotificationContainer} from "../common/slds/notifications/notification";
import PropTypes from "prop-types";
import {Icon} from "../common/slds/icons/icon";
import {Log} from "../common/log";

export class NotificationContextObj {
    notifications = [];

    create = () => {
        // Implemented in NotificationContextProvider
    };

    error(message, err, timeout = 10000) {
        Log.Error(message, err);
        this.create(<NotificationMessageBody iconName={"error"} iconColorVariant={"error"}>{message}</NotificationMessageBody>, timeout);
    }
    info(message, timeout = 10000) {
        Log.Info(message);
        this.create(<NotificationMessageBody iconName={"info"} iconColorVariant="success">{message}</NotificationMessageBody>, timeout);
    }
    success(message, timeout = 10000) {
        Log.Info(message);
        this.create(<NotificationMessageBody iconName={"check"} iconColorVariant="success">{message}</NotificationMessageBody>, timeout);
    }
}

export const NotificationContext = React.createContext(new NotificationContextObj());

/**
 *
 * @returns {NotificationContextObj}
 */
export function useNotificationContext() {
    return useContext(NotificationContext);
}


export function NotificationMessageBody(props) {
    const {message, children, iconName, iconColorVariant} = props;

    return <>
        <Icon name={iconName} size={"small"} colorVariant={iconColorVariant} className="slds-m-right--x-small"/>
        <div className="slds-media__body">
            {message || children}
        </div>
    </>;
}

NotificationMessageBody.defaultProps = {
    iconName: "check",
};

NotificationMessageBody.propTypes = {
    message: PropTypes.string,
    children: PropTypes.string,
    iconName: PropTypes.string,
    iconColorVariant: Icon.propTypes.colorVariant,// PropTypes.oneOf(['base', 'default', 'error', 'light', 'warning', 'success'])
};

let noteId = 0;

export function NotificationContextProvider(props) {
    const {children} = props;
    let [ctxState] = useState({
        notes: []
    });
    let [, setTick] = useState(0);


    const removeNotification = (key) => {
        //Log.Debug("Remove notification", key, ctxState.notes);
        ctxState.notes = ctxState.notes.filter((n) => n.key != key);
        setTick(Math.random());
    };

    /**
     *
     * @param note {Node}
     * @param timeout {number} in ms
     */
    const createNotification = (note, timeout = 0) => {
        const key = noteId++;
        note = <Notification key={key} onClose={() => removeNotification(key)} timeoutMs={timeout}>{note}</Notification>;
        ctxState.notes = ctxState.notes.concat(note);
        setTick(Math.random());
    };

    let ctx = new NotificationContextObj();
    ctx.create = createNotification;
    ctx.notifications = ctxState.notes;

    /*
    * Icons:
    * Confirm: task2
    *
    *
    * */
    return <NotificationContext.Provider value={ctx}>
        <NotificationContainer>
            {ctx.notifications}
        </NotificationContainer>
        {children}
    </NotificationContext.Provider>;
}

NotificationContextProvider.propTypes = {
    children: PropTypes.any,
};