import "./polyfills"
import React from "react";
import Settings from "@salesforce/design-system-react/module/components/settings";
import {platformConfig} from "./common/platformConfig";
import * as log from "./common/log";
import {Log} from "./common/log";
import Platform from "./platform";
import {backendUrl} from "./common/helper";
import './style'
import {createRoot} from "react-dom/client";


if (platformConfig.devMode) {
    log.Info("DevMode. Auth disabled!");
}

Log.Info("Backend URL:", backendUrl());

Settings.setAppElement("#root");
const container = document.getElementById('root');
const root = createRoot(container);
root.render(<Platform/>);
