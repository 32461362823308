// Graphql
import gql from 'graphql-tag';
import React, {useContext} from "react";
import {QUERY_DATASOURCES} from "./datasourceTable";
import PropTypes from 'prop-types';
import {Field, Form, Formik} from 'formik';
import {
    FormActions,
    Option,
    SldsCheckboxField,
    SldsFormElementCompound,
    SldsFormElementRow,
    SldsInput,
    SldsInputField,
    SldsSelectField
} from '../../../common/ui/form/formElements';
import * as log from "../../../common/log";
import {Log} from "../../../common/log";
import {AppContext} from "../../appPage";
import {useMutation, useQuery} from "@apollo/client";
import {useGraphqlLoadingComponent} from "../../../common/graphql";
import Button from "../../../common/slds/buttons/button";
import {QUERY_DEVICE_TYPES} from "../../../deviceTypes/queries";

const MUTATE_CREATE_DATASOURCE = gql`
    mutation createDs($appId: Int!, $ds: DatasourceInput!, $config: DatasourceConfigInput!) {
        datasources {
            createDataSource(appId: $appId, ds: $ds config: $config) {
                id
                name
                disabled
                type
                configRaw
                autoCreateDevice
                deviceType {
                    id
                    name
                    displayName
                }
            }
        }
    }
`;

const MUTATE_UPDATE_DATASOURCE = gql`
    mutation updateDs($id:String!, $ds: DatasourceInput!, $config: DatasourceConfigInput!) {
        datasources {
            updateDataSource(id: $id, ds: $ds config: $config) {
                id
                name
                disabled
                type
                configRaw
                autoCreateDevice
                deviceType {
                    id
                    name
                    displayName
                }
            }
        }
    }
`;

function rndString(len) {
    let text = "";
    const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < len; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
}

class TtnFields extends React.Component {

    constructor(props) {
        super(props);
        props.formik.setFieldValue("config.server", "tcp://eu.thethings.network:1883");
    }

    render() {
        return <div>
            <Field component={SldsInput} name="config.server" placeholder="tcp://eu.thethings.network:1883"
                   label="Server" id="ttn-server"/>
            <Field component={SldsInput} name="config.appId" placeholder="" label="AppId" id="ttn-appid"/>
            <Field component={SldsInput} name="config.accessKey" placeholder="ttn-account-v2..." label="Access Key"
                   id="ttn-key"/>
        </div>;
    }
}

const ElementIotFields = (props) => {
    const app = useContext(AppContext);
    const values = props.formik.values;
    if (!values.config || !values.config.secret) {
        this.generateSecret();
    }
    let secret = "";
    if (this.props.formik.values.config) {
        secret = this.props.formik.values.config.secret;
    }
    return <div>
        <SldsFormElementCompound>
            <SldsFormElementRow>

                <Field component={SldsInput} name="config.secret" placeholder="password from basic auth"
                       label="Secret" id="element-secret"/>
                <div className="slds-form-element  slds-align-middle">
                    <div className="slds-form-element__control">
                        <Button iconCategory="utility"
                                iconName="refresh"
                                iconVariant="bare" variant="icon" onClick={() => {
                            this.generateSecret();
                        }}/>
                    </div>
                </div>

            </SldsFormElementRow>
        </SldsFormElementCompound>


        <div className="slds-text-heading--label slds-m-top--small">Element-Iot Rule settings</div>
        <dl className="slds-list_horizontal slds-wrap">
            <dt className="slds-item_label slds-text-color_weak slds-truncate">Method:</dt>
            <dd className="slds-item_detail slds-truncate">POST</dd>
            <dt className="slds-item_label slds-text-color_weak slds-truncate">Url:</dt>
            <dd className="slds-item_detail slds-truncate">https://backend.lobaro.com/api/element-iot/data</dd>
            <dt className="slds-item_label slds-text-color_weak slds-truncate">HTTP Header:</dt>
            <dd className="slds-item_detail slds-truncatex">{"Authorization: Basic " + btoa(app.appInstanceAppId + ":" + secret)}</dd>
        </dl>
        <div hidden={true}> Plain: {app.appInstanceAppId + ":" + secret} </div>
    </div>;
};


class LoraserverFields extends React.Component {

    constructor(props) {
        super(props);
        const values = props.formik.values;
        if (!values.config || !values.config.secret) {
            this.generateSecret();
        }
    }

    generateSecret() {
        this.props.formik.setFieldValue("config.secret", rndString(10));
    }


    render() {
        const {app} = this.props;
        Log.Debug("LoraserverFields.app", app);

        let secret = "";
        if (this.props.formik.values.config) {
            secret = this.props.formik.values.config.secret;
        }
        return <div>
            <SldsFormElementCompound>
                <SldsFormElementRow>

                    <Field component={SldsInput} name="config.secret" placeholder="password from basic auth"
                           label="Secret" id="element-secret"/>
                    <div className="slds-form-element  slds-align-middle">
                        <div className="slds-form-element__control">
                            <Button iconCategory="utility"
                                         iconName="refresh"
                                         iconVariant="bare" variant="icon" onClick={() => {
                                this.generateSecret();
                            }}/>
                        </div>
                    </div>

                </SldsFormElementRow>
            </SldsFormElementCompound>


            <div className="slds-text-heading--label slds-m-top--small">Loraserver HTTP Integration settings</div>
            <dl className="slds-list_horizontal slds-wrap">
                <dt className="slds-item_label slds-text-color_weak slds-truncate">Method:</dt>
                <dd className="slds-item_detail slds-truncate">POST</dd>
                <dt className="slds-item_label slds-text-color_weak slds-truncate">Url:</dt>
                <dd className="slds-item_detail slds-truncate">https://backend.lobaro.com/api/loraserver/uplink</dd>
                <dt className="slds-item_label slds-text-color_weak slds-truncate">HTTP Header:</dt>
                <dd className="slds-item_detail slds-truncatex">{"Authorization: Basic " + btoa(app.appId + ":" + secret)}</dd>
            </dl>
            <div hidden={true}> Plain: {app.appId + ":" + secret} </div>
        </div>;
    }
}

LoraserverFields.propTypes = {
    app: PropTypes.any
};

class ActilityFields extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return <div>
            <Field component={SldsInput} name="config.appId" placeholder="Actility AppId" label="App Id"
                   id="actility-app-id"/>

            <div className="slds-text-heading--label slds-m-top--small">Actility settings</div>
            <dl className="slds-list_horizontal slds-wrap">
                <dt className="slds-item_label slds-text-color_weak slds-truncate">POST data to:</dt>
                <dd className="slds-item_detail slds-truncate">https://backend.lobaro.com/api/actility/data</dd>
            </dl>
        </div>;
    }
}

ActilityFields.propTypes = {
    app: PropTypes.any
};

class GsmFields extends React.Component {

    constructor(props) {
        super(props);
        const values = props.formik.values;
        if (!values.config || !values.config.secret) {
            this.generateSecret();
        }
    }

    generateSecret() {
        this.props.formik.setFieldValue("config.secret", rndString(10));
    }

    render() {
        let secret = "";
        if (this.props.formik.values.config) {
            secret = this.props.formik.values.config.secret;
        }
        return <div>
            <SldsFormElementCompound>
                <SldsFormElementRow>
                    <Field component={SldsInput} name="config.secret" placeholder="password from basic auth"
                           label="Secret" id="gsm-secret"/>
                    <div className="slds-form-element  slds-align-middle">
                        <div className="slds-form-element__control">
                            <Button iconCategory="utility"
                                         iconName="refresh"
                                         iconVariant="bare" variant="icon" onClick={() => {
                                this.generateSecret();
                            }}/>
                        </div>
                    </div>

                </SldsFormElementRow>
            </SldsFormElementCompound>


            <div className="slds-text-heading--label slds-m-top--small">Gsm Upload settings</div>
            <dl className="slds-list_horizontal slds-wrap">
                <dt className="slds-item_label slds-text-color_weak slds-truncate">Method:</dt>
                <dd className="slds-item_detail slds-truncate">POST</dd>
                <dt className="slds-item_label slds-text-color_weak slds-truncate">Url:</dt>
                <dd className="slds-item_detail slds-truncate">https://backend.lobaro.com/api/gsm/data?imei=XXX&iccid=XXX</dd>
                <dt className="slds-item_label slds-text-color_weak slds-truncate">HTTP Header:</dt>
                <dd className="slds-item_detail slds-truncatex">{"Authorization: Basic " + btoa(":" + secret)}</dd>
            </dl>
            <div hidden={true}> Plain: {":" + secret} </div>
        </div>;
    }
}

class NbIotFields extends React.Component {

    constructor(props) {
        super(props);
        const values = props.formik.values;
        if (!values.config || !values.config.secret) {
            this.generateSecret();
        }
    }

    generateSecret() {
        this.props.formik.setFieldValue("config.secret", rndString(10));
    }

    render() {
        let secret = "";
        if (this.props.formik.values.config) {
            secret = this.props.formik.values.config.secret;
        }
        return <div>
            <SldsFormElementCompound>
                <SldsFormElementRow>

                    <Field component={SldsInput} name="config.secret" placeholder="password from basic auth"
                           label="Secret" id="gsm-secret"/>
                    <div className="slds-form-element  slds-align-middle">
                        <div className="slds-form-element__control">
                            <Button iconCategory="utility"
                                         iconName="refresh"
                                         iconVariant="bare" variant="icon" onClick={() => {
                                this.generateSecret();
                            }}/>
                        </div>
                    </div>

                </SldsFormElementRow>
            </SldsFormElementCompound>


            <div className="slds-text-heading--label slds-m-top--small">NB.IoT Upload settings</div>
            <dl className="slds-list_horizontal slds-wrap">
                <dt className="slds-item_label slds-text-color_weak slds-truncate">Payload Format:</dt>
                <dd className="slds-item_detail slds-truncate">CBOR or JSON</dd>
                <dt className="slds-item_label slds-text-color_weak slds-truncate">UDP Endpoint:</dt>
                <dd className="slds-item_detail slds-truncate">backend.lobaro.com:1064</dd>
                <dt className="slds-item_label slds-text-color_weak slds-truncate">Required Payload Fields:</dt>
                <dd className="slds-item_detail slds-truncatex">{`{"meta": { "secret:"` + btoa(":" + secret) + `", "imei:"..."}}`}</dd>
            </dl>
            <div hidden={true}> Plain: {":" + secret} </div>
        </div>;
    }
}


function MQTTFields() {

    return <div>
        <SldsInputField name="config.server" label={"Server"} placeholder={"e.g. tcp://mqtt.example.com:1883"}/>
        <SldsInputField name="config.topic" label={"Topic"} placeholder={"e.g. devices/+/data"}/>
        <SldsInputField name="config.user" label={"Username"} placeholder={"MQTT Username"}/>
        <SldsInputField name="config.secret" label={"Password"} placeholder={"MQTT Password"}/>
        <SldsInputField name="config.deviceIdPath" label={"Device Addr Path"} placeholder={"e.g. meta.devEUI"}/>
    </div>;
}

const dataSourceTypes = [
    {
        name: "ttn",
        displayName: "TTN"
    },
    {
        name: "loraserver",
        displayName: "Loraserver"
    },
    {
        name: "element-iot",
        displayName: "Element-Iot"
    },
    {
        name: "actility",
        displayName: "Actility"
    },
    {
        name: "gsm",
        displayName: "Gsm"
    },
    {
        name: "nb-iot",
        displayName: "NB-IoT"
    },
    {
        name: "mqtt",
        displayName: "MQTT"
    },
];

export default function DatasourceForm(props) {

    const deviceTypesResult = useQuery(QUERY_DEVICE_TYPES);


    const app = useContext(AppContext);
    Log.Debug("App:", app);

    const [mutateCreateDs] = useMutation(MUTATE_CREATE_DATASOURCE, {
        refetchQueries: [{
            query: QUERY_DATASOURCES,
            variables: {
                appId: app.id
            }
        }]
    });
    const [mutateUpdateDs] = useMutation(MUTATE_UPDATE_DATASOURCE, {
        refetchQueries: [{
            query: QUERY_DATASOURCES,
            variables: {
                appId: app.id
            }
        }]
    });

    const loadingDeviceTypes = useGraphqlLoadingComponent(deviceTypesResult);
    if (loadingDeviceTypes) {
        return loadingDeviceTypes;
    }
    const {deviceTypes} = deviceTypesResult.data;

    const createDs = (values, actions) => {
        mutateCreateDs({
            variables: {
                appId: app.id,
                ds: {
                    name: values.name,
                    type: values.type,
                    disabled: !values.enabled,
                    autoCreateDevice: values.autoCreateDevice,
                },
                config: {
                    ...values.config
                }
            }
        }).then((val) => {
            log.Debug("promise val:", val);
            props.onSave();
        }).catch((err) => {
            log.Debug("promise err:", err);
            actions.setFieldError("global", err.message);
        });


    };

    const updateDs = (values, actions) => {
        mutateUpdateDs({
            variables: {
                id: values.id,
                ds: {
                    name: values.name,
                    type: values.type,
                    disabled: !values.enabled,
                    autoCreateDevice: values.autoCreateDevice,
                    deviceTypeId: parseInt(values.deviceType.id),
                },
                config: {
                    ...values.config
                }
            }
        }).then((val) => {
            log.Debug("promise val:", val);
            props.onSave();
        }).catch((err) => {
            log.Debug("promise err:", err);
            actions.setFieldError("global", err.message);
        });
    };

    let action = props.item.id === undefined ? 'create' : 'edit';
    const ds = {...props.item};
    ds.config = ds.configRaw ? JSON.parse(ds.configRaw) : null;
    Log.Debug("Edit ds:", ds);
    return <div>
        <Formik initialValues={
            {
                ...ds,
                enabled: !ds.disabled,
            }}
                validate={(values, ) => {
                    let errors = {};
                    if (!values.name) {
                        errors.name = "Name must not be empty";
                    }
                    if (!values.type) {
                        errors.type = "Please select a type";
                    }
                    return errors;
                }}
                enableReinitialize={true}
                onSubmit={(values, actions) => {
                    log.Debug("Submit: ", values, actions);
                    if (action === "create") {
                        createDs(values, actions);
                    } else if (action === "edit") {
                        updateDs(values, actions);
                    }
                    actions.setSubmitting(false);
                }}
                render={(formik) => (
                    <Form className="slds-form slds-form-element_stacked">
                        <Field component={SldsInput} name="name" placeholder="Name of the data source" id="name"
                               label="Name"/>
                        <SldsCheckboxField inlineLabel={"Enabled"} name={"enabled"}/>
                        <SldsCheckboxField inlineLabel={"Auto create device"} name={"autoCreateDevice"}/>
                        <SldsSelectField name={"type"} label={"Type"}>
                            <Option value="">Please select</Option>
                            {
                                dataSourceTypes.map((d) => {
                                    return <Option key={d.name} value={d.name}>{d.displayName}</Option>;
                                })
                            }
                        </SldsSelectField>
                        <SldsSelectField name="deviceType.id" label="Default Device Type">
                            <Option value="">Please select</Option>
                            {
                                deviceTypes.map((d) => {
                                    return <option key={d.id} value={d.id}>{d.displayName}</option>;
                                })
                            }
                        </SldsSelectField>

                        {/* Data source specific properties */}
                        {(() => {
                            switch (formik.values.type) {
                            case undefined:
                            case null:
                            case "":
                                return null;
                            case "ttn":
                                return <TtnFields formik={formik} app={app}/>;
                            case "loraserver":
                                return <LoraserverFields formik={formik} app={app}/>;
                            case "element-iot":
                                return <ElementIotFields formik={formik} app={app}/>;
                            case "actility":
                                return <ActilityFields formik={formik} app={app}/>;
                            case "gsm":
                                return <GsmFields formik={formik} app={app}/>;
                            case "nb-iot":
                                return <NbIotFields formik={formik} app={app}/>;
                            case "mqtt":
                                return <MQTTFields formik={formik} app={app}/>;
                            default:
                                return <div>Unknown Datasource type: {formik.values.type}</div>;
                            }
                        })()}

                        <FormActions>
                            <Button key={1} type="submit"
                                    disabled={formik.isSubmitting}>{action === 'create' ? "Create" : "Save"}</Button>
                            <Button key={2} onClick={props.onCancel} variant="destructive">Cancel</Button>
                        </FormActions>
                    </Form>
                )}
        />
    </div>;
}

DatasourceForm.propTypes = {
    item: PropTypes.object, // The item to edit
    onCancel: PropTypes.func,
    onSave: PropTypes.func,
};

