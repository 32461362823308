import { ApolloProvider } from "@apollo/client"
import { client } from "./common/graphql"
import { IconSettings } from "@salesforce/design-system-react"
import AppPage from "./app/appPage"
import { HashRouter, Route, Routes } from "react-router-dom"
import AuthContextProvider from "./common/context/authContextProvider"
import ErrorBoundary from "./common/ui/errorBoundary"
import React, { Suspense } from "react"
import NonAppModule from "./nonAppModule"
import Playground from "./playground/playground"
import "./lobaro.css"
import { NotificationContextProvider } from "./notifications/notificationContext"
import FrontendConfigContextProvider from "./common/context/frontendConfigContext"
import { FeatureContextProvider } from "./common/context/featureContext"
import { OrganisationContextProvider } from "./common/context/organisationContextProvider"
import { BannerContextProvider } from "./common/context/bannerContextProvider"

const Platform = () => (
    <ErrorBoundary>
        <Suspense fallback="... loading">
            <ApolloProvider client={client}>
                <IconSettings iconPath="/assets/icons">
                    <HashRouter hashType={"slash"}>
                        <NotificationContextProvider>
                            <FrontendConfigContextProvider>
                                <AuthContextProvider>
                                    <OrganisationContextProvider>
                                        <FeatureContextProvider>
                                            <BannerContextProvider>
                                                <Routes>
                                                    <Route path={"/playground"} element={<Playground />} />
                                                    <Route path={"/app/:appId"} element={<AppPage />} />
                                                    <Route path={"*"} element={<NonAppModule />} />
                                                </Routes>
                                            </BannerContextProvider>
                                        </FeatureContextProvider>
                                    </OrganisationContextProvider>
                                </AuthContextProvider>
                            </FrontendConfigContextProvider>
                        </NotificationContextProvider>
                    </HashRouter>
                </IconSettings>
            </ApolloProvider>
        </Suspense>
    </ErrorBoundary>
)

export default Platform
