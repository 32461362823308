import React, { useContext } from "react"
import { Alert, Divider } from "antd"
import { BannerContext } from "../common/context/bannerContextProvider"
import { blue, green, orange, red } from "../configuration/notification/colors"
import { useAuthContext } from "../common/context/authContext"
import { useT } from "../common/i18n"
import Markdown from "react-markdown"
import remarkGfm from "remark-gfm"
import "@uiw/react-md-editor/markdown-editor.css"
import "@uiw/react-markdown-preview/markdown.css"

export const Banner = () => {
    const t = useT()
    const auth = useAuthContext()
    const bannerContext = useContext(BannerContext)

    const notifications = bannerContext?.notifications
    const previewNotification = bannerContext?.previewNotification
    const showBanner = notifications && notifications.length > 0 && notifications[0].type === "banner" && bannerContext?.showNotification
    const showPreview = previewNotification.length > 0 && bannerContext?.showPreviewNotification

    const onClose = () => {
        bannerContext?.setShowNotification(false)
    }
    const onClosePreview = () => {
        bannerContext?.setShowPreviewNotification(false)
    }

    const dividerColor = (level) => {
        switch (level) {
            case "info":
                return blue
            case "success":
                return green
            case "warning":
                return orange
            case "error":
                return red
            default:
                return blue
        }
    }

    return (
        auth.isLoggedIn() && (
            <div>
                {showBanner && !showPreview && (
                    <>
                        {notifications[0]?.title && (
                            <Alert
                                message={<Markdown remarkPlugins={[remarkGfm]}>{notifications[0].title}</Markdown>}
                                description={<Markdown remarkPlugins={[remarkGfm]}>{notifications[0].message}</Markdown>}
                                type={notifications[0].level}
                                afterClose={onClose}
                                banner
                                closable
                            />
                        )}
                        {!notifications[0]?.title && (
                            <Alert
                                message={<Markdown remarkPlugins={[remarkGfm]}>{notifications[0].message}</Markdown>}
                                type={notifications[0].level}
                                afterClose={onClose}
                                banner
                                closable
                            />
                        )}
                        <Divider
                            style={{
                                borderColor: dividerColor(notifications[0].level),
                                backgroundColor: "white",
                                margin: "0px",
                                fontSize: "x-small",
                            }}>
                            {t("banner.divider", "Announcement")}
                        </Divider>
                    </>
                )}
                {showPreview && (
                    <>
                        {previewNotification[0]?.title && (
                            <Alert
                                message={<Markdown remarkPlugins={[remarkGfm]}>{previewNotification[0].title}</Markdown>}
                                description={<Markdown remarkPlugins={[remarkGfm]}>{previewNotification[0].message}</Markdown>}
                                type={previewNotification[0].level}
                                afterClose={onClosePreview}
                                banner
                                closable
                            />
                        )}
                        {!previewNotification[0]?.title && (
                            <Alert
                                message={<Markdown remarkPlugins={[remarkGfm]}>{previewNotification[0].message}</Markdown>}
                                type={previewNotification[0].level}
                                afterClose={onClosePreview}
                                banner
                                closable
                            />
                        )}
                        <Divider
                            style={{
                                borderColor: dividerColor(previewNotification[0].level),
                                backgroundColor: "white",
                                margin: "0px",
                                fontSize: "x-small",
                            }}>
                            {t("banner.divider", "Announcement")}
                        </Divider>
                    </>
                )}
            </div>
        )
    )
}
