import Page from "../../common/ui/page";
import * as React from "react";
import GenericDataTable from "../../common/ui/genericDataTable/genericDataTable";
import {useQuery} from "@apollo/client";
import {QUERY_CRON_LOG_LIST} from "../queries";
import {usePagination} from "../../common/hooks/usePagination";
import {Log} from "../../common/log";
import {useFilter} from "../../common/hooks/useFilter";
import {useSort} from "../../common/hooks/useSort";
import {useT} from "../../common/i18n";
import {Link} from "react-router-dom";
import { thirtyDays } from "../../common/magicNumbers";

// By default, filter cron log entries from the last 90 days. May result in no entries for local development instances, as they usually don't run 24/7
const initialFilters = [{"field": "startTime", "op": "gte", "value": new Date(Date.now() - thirtyDays)}, {"field": "startTime", "op": "lte", "value": new Date(Date.now())}]

const CronLogPage = () => {
    const t = useT()
    const page = usePagination();

    const result = useQuery(QUERY_CRON_LOG_LIST, {
        variables: {
            page: page.getGraphqlPageInput(),
            sort: {field: "startTime", direction: "DESC"},
        }
    });
    const {data} = result;
    Log.Debug("Raw CRON log: ", data)
    const dataParsed = data?.getCronLogList && data?.getCronLogList.map( item => {
        const startTimeParsed = new Date(item.startTime);
        const endTimeParsed = new Date(item.endTime);
        return {
            ...item,
            startTime: startTimeParsed.toLocaleString(),
            endTime: endTimeParsed.toLocaleString(),
        }
    });

    const filters = useFilter(true, initialFilters, result.refetch)

    const sort = useSort({property: "startTime", direction: "DESC"}, result.refetch);

    const defaultTableConfig = {
        cols: [
            {
                heading: t("cron-log.job", "Job"),
                filter: {
                    type: "string",
                    property: "jobName",
                    hasFilter: true,
                },
                cell: {
                    format: "{{jobName}}",
                },
                sortProperty: "jobName",
            },
            {
                heading: t("cron-log.start","Start Time"),
                cell: {
                    format: "{{startTime}}",
                },
                sortProperty: "startTime",
            },
            {
                heading: t("cron-log.end","End Time"),
                cell: {
                    format: "{{endTime}}",
                },
                sortProperty: "endTime",
            },
            {
                heading: t("cron-log.error","Error Occurred"),
                filter: {
                    type: "boolean",
                    property: "errorOccurred",
                    hasFilter: true,
                },
                cell: {
                    format: "{{errorOccurred}}",
                },
                sortProperty: "errorOccurred",
            },
            {
                heading: t("cron-log.result","Execution Result"),
                filter: {
                    type: "string",
                    property: "executionResult",
                    hasFilter: true,
                },
                cell: {
                    format: "{{executionResult}}",
                }
            },
            {
                heading: t("cron-log.exec","Execution Params"),
                width: "1rem",
                filter: {
                    type: "string",
                    property: "executionParams",
                    hasFilter: true,
                },
                cell: {
                    format: "{{executionParams}}",
                }
            },
        ]
    }

    return  <Page
        title={"Cron Log"}
        trail={[<Link to="./" key={1}>Cron Log</Link>]}
    >
      <GenericDataTable
          id={"cron-log-table"}
          items={dataParsed}
          gqlResult={result}
          tableConfigDefault={defaultTableConfig}
          page={page}
          filters={filters}
          filterField={"startTime"}
          sort={sort}
      />
    </Page>

}

export default CronLogPage
