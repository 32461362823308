import {useAuthContext} from "../common/context/authContext";
import AppOverview from "./appOverview";
import React from "react";
import ToolsOverviewPanel from "./toolsOverviewPanel";
import DevicesOverview from "./devicesOverview";
import {Accordion, AccordionPanel} from "../common/ui/accordion";
import UsageStatisticsOverview from "./usageStatisticsOverview";
import {useFeatureContext} from "../common/context/featureContext";

const UserHomePage = () => {
    const auth = useAuthContext();
    const license = useFeatureContext()

    // TODO: Add API usage statistics to front page?
    return <Accordion>
        {auth.isLoggedIn() && license.validateFeatures("lobaro-device-gateway") && <AccordionPanel id={"usageStatistics"} summary={"Usage Statistics"} expanded={true}>
            <UsageStatisticsOverview/>
        </AccordionPanel>}
        {auth.isLoggedIn() && license.validateFeatures("lobaro-device-gateway") && <AppOverview/>}
        {auth.isLoggedIn() && license.validateFeatures("lobaro-device-gateway") && <AccordionPanel id={"devices"} summary={"Devices"} expanded={true}>
            <DevicesOverview/>
        </AccordionPanel>}
        <ToolsOverviewPanel/>
    </Accordion>;
};
export default UserHomePage
