import React, {useEffect, useState} from "react";
import {backendUrl} from "../helper";

export const FrontendConfigContext = React.createContext();


export async function fetchFrontendConfig(setConfig) {
    fetch(`${backendUrl()}/frontend/config`)
        .then(response => response.json())
        .then(function (frontendConfig) {
            setConfig(frontendConfig)
        }).catch(err => console.log("Error in fetching FrontendConfig from Backend: "+ err));
}


export default function FrontendConfigContextProvider(props) {
    let [config, setConfig] = useState();
    useEffect(() => {
        void fetchFrontendConfig(setConfig)
    }, []);

    return <FrontendConfigContext.Provider value={config}>
        {props.children}
    </FrontendConfigContext.Provider>
}
