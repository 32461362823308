import {HasPermission} from "../common/ui/permissions";
import React, {useContext} from "react";
import {useAuthContext} from "../common/context/authContext";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {FrontendConfigContext} from "../common/context/frontendConfigContext";
import {useT} from "../common/i18n";
import {LanguageSelector} from "./LanguageSelector";

export const LoginButton = () => {
    const t = useT();
    let auth = useAuthContext();
    let brandingConfig = useContext(FrontendConfigContext)?.brandingConfig;
    let location = useLocation();
    let navigate = useNavigate();

    let loginAndCreateAccountElement;
    if(brandingConfig?.allowRegistration) {
        loginAndCreateAccountElement = <div className="slds-media">
                <Link
                    className="slds-button slds-button--neutral"
                    to={`/auth/login?r=${location.pathname}`}>{t("login.login","Login")}</Link>
                <Link
                    className="slds-m-left--small slds-align-bottom"
                    to={`/auth/register?r=${location.pathname}`}>
                    {t("login.create-account","Create account")}</Link>
            </div>
    }else {
        loginAndCreateAccountElement = <div className="slds-media">
                <Link
                    className="slds-button slds-button--neutral"
                    to={`/auth/login?r=${location.pathname}`}>{t("login.login","Login")}</Link>
            </div>
    }


    return <div className="slds-grid">
        <div className="slds-col slds-align-middle slds-m-bottom--xsmall- slds-no-flex slds-p-right--small">
            <LanguageSelector/>
        </div>
        <div className="slds-col slds-align-bottom slds-m-right--large slds-no-flex">
            <HasPermission isLoggedIn={true}>
                <div className="slds-media">
                    <div className="slds-media__figure">
                        <svg aria-hidden="true" className="slds-icon slds-icon-standard-user">
                            <use xlinkHref="/assets/icons/standard-sprite/svg/symbols.svg#user"></use>
                        </svg>
                    </div>
                    <div className="slds-media__body">
                        <div className="slds-grid">
                            <div className="slds-col slds-truncate slds-no-flex slds-m-right--small">
                                <p className="slds-text-heading--label">{t("login.account","Account")}</p>
                                <div className="slds-truncate">
                                    <Link to="/auth/my-account">{auth.name()}</Link>
                                </div>
                            </div>
                            <div className="slds-col">
                                <a className="slds-button slds-button--neutral slds-float--left"
                                   onClick={() => {
                                       auth.logout();
                                       navigate("/");
                                   }}>{t("login.logout","Logout")}</a>
                            </div>
                        </div>

                    </div>

                </div>
            </HasPermission>

            <HasPermission isLoggedIn={false}>
                {loginAndCreateAccountElement}
            </HasPermission>

        </div>
        <div className="slds-col slds-align-bottom slds-m-bottom--xsmall- slds-no-flex">
            <div>&#xBB; <a href={brandingConfig?.legalDisclosureUrl ? brandingConfig.legalDisclosureUrl : ""} target="_blank">{t("login.legal-disclosure", "Legal Disclosure")}</a></div>
        </div>
    </div>
}