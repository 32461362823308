import React, { useEffect, useRef, useState } from "react"
import { InputButton } from "../../slds/buttons/button"
import { useT } from "../../i18n"
import { Button, Flex, Input, Select, Space, Tag } from "antd"
import { CheckCircleOutlined, CloseCircleOutlined, MinusCircleOutlined } from "@ant-design/icons"

export const FilterTypeArray = "array";
export const FilterTypeBinary = "binary";

function extractValueFromFilterHook(filterHook, filterProperty) {
    let val = filterHook?.columnFilters?.get(filterProperty)?.value

    if (!val) {
        return null
    }

    let first = val.slice(0, 1)
    let last = val.slice(-1)

    if (first === "%" && last === "%") {
        val = val.slice(1, -1)
    } else if (first === "%") {
        val = val.slice(1)
    } else if (last === "%") {
        val = val.slice(0, -1)
    }

    return val
}

export function ColumnTextFilter(props) {
    //todo search/filter from and use it for update of search
    const filterHook = props.filterHook
    const filterProperty = props.property
    const filterType = props.filterType

    // antd specific props
    const antdProps = props.antdProps

    const confirm = antdProps?.confirm
    const resetUIFilterValues = antdProps?.resetUIFilterValues
    const resetValue = antdProps?.resetValue
    const filterLabel = antdProps?.filterLabel || ""

    const t = useT()
    const [antdValue, setAntdValue] = useState(null)
    const [reset, setReset] = useState(resetValue)

    // Used in legacy slds code.
    // Antd specific code paths do not rely on this state.
    const value = extractValueFromFilterHook(filterHook, filterProperty)
    let [currentValue, setCurrentValue] = useState(value)

    // antd specific code path
    const handleReset = () => {
        setAntdValue(null)
        filterHook.setColumnFilter(filterProperty, null)
        // updateUIFilterValue(filterProperty, null)
        confirm()
    }
    // antd specific code path
    const handleResetAll = () => {
        resetUIFilterValues()
        filterHook.reset()
        confirm()
    }

    if (resetValue > reset) {
        setReset(resetValue)
        handleReset()
    }

    const updateSearch = (filterHook, filterProperty, filterType, filterText) => {
        let searchVal

        if (antdProps) {
            if (filterText == null) {
                filterHook.setColumnFilter(filterProperty, null)
                return
            }

            filterText = filterText.trim()
            if (filterText === "*" || filterText === "**") {
                filterHook.setColumnFilter(filterProperty, null)
                return
            }
        } else {
            if (filterText == null || filterText === "") {
                filterHook.setColumnFilter(filterProperty, null)
                return
            }

            filterText = filterText.trim()
            if (!filterText || filterText === "*" || filterText === "**") {
                filterHook.setColumnFilter(filterProperty, null)
                return
            }
        }

        if (filterText === ""){
            searchVal = filterText
        } else { //binary filter should be transferred as is
                let first = filterText.slice(0, 1)
                let last = filterText.slice(-1)
                if (first === "*" && last === "*") {
                    searchVal = "%" + filterText.slice(1, -1) + "%"
                } else if (first === "*") {
                    searchVal = "%" + filterText.slice(1)
                } else if (last === "*") {
                    searchVal = filterText.slice(0, -1) + "%"
                } else {
                    searchVal = "%" + filterText + "%"
                }
        }

        if (filterType === FilterTypeArray) {
            let newFilter
            if (searchVal === "") {
                newFilter = {
                    field: filterProperty,
                    op: "isnull",
                    value: "true",
                }
            } else {
                newFilter = {
                    filterType: "array",
                    field: filterProperty,
                    op: "ilikein",
                    value: searchVal,
                }
            }
            filterHook.setColumnFilter(filterProperty, newFilter)

        } else if (filterType === FilterTypeBinary) {
            let newFilter = {
                filterType: filterType,
                field: filterProperty,
                op: "ilike", //only ilike is supported when using type binary
                value: searchVal,
            }
            filterHook.setColumnFilter(filterProperty, newFilter)
        } else {
            let newFilter = {
                field: filterProperty,
                op: "ilike",
                value: searchVal,
            }
            filterHook.setColumnFilter(filterProperty, newFilter)
        }
    }

    const inputTagRef = useRef(null)

    const setFocus = () => {
        // I don't like it, but it's the best way to focus on input field after render.
        // All other methods of calling focus failed
        setTimeout(() => inputTagRef.current && inputTagRef.current.focus(), 0)
    }

    useEffect(() => {
        setFocus()
    })

    if (antdProps) {
        return (
            <>
                <Input
                    placeholder={
                        antdValue == null ? t("common.search.searchField", "Search field") + ` ${filterLabel}` : t("common.search.emptyValue", "Empty value")
                    }
                    value={antdValue}
                    onChange={(event) => setAntdValue(event.target.value)}
                    onPressEnter={() => {
                        let emptiableValue = antdValue == null ? "" : antdValue
                        setAntdValue(emptiableValue)
                        updateSearch(filterHook, filterProperty, filterType, emptiableValue)
                        confirm(emptiableValue)
                    }}
                    style={{ marginBottom: 8, display: "block" }}
                    ref={inputTagRef}
                />
                <Flex vertical gap="small" style={{ width: "100%" }}>
                    <Space direction="vertical">
                        <Button
                            onClick={() => {
                                let emptyableValue = antdValue == null ? "" : antdValue
                                setAntdValue(emptyableValue)
                                updateSearch(filterHook, filterProperty,filterType, emptyableValue)
                                confirm(emptyableValue)
                            }}
                            size="large"
                            type="primary"
                            block>
                            {t("common.button.search", "Search")}
                        </Button>
                    </Space>

                    <Space.Compact direction="vertical" style={{ color: "blue" }} block>
                        <Button
                            onClick={() => {
                                handleReset()
                            }}
                            size="middle"
                            block>
                            {t("org.config.wmbus.reset", "Reset")}
                        </Button>

                        <Button
                            danger
                            onClick={() => {
                                handleResetAll()
                            }}
                            size="middle"
                            type="dashed"
                            block>
                            {t("org.config.wmbus.reset_all", "Reset All")}
                        </Button>
                    </Space.Compact>
                </Flex>
            </>
        )
    }

    return (
        <div className="slds-form-element">
            <div className="slds-form-element__control slds-input-has-icon slds-input-has-icon_right">
                <input
                    type="search"
                    className="slds-input slds-combobox__input"
                    style={value ? { background: "#e7f3fe" } : {}}
                    id="combobox-id-1"
                    aria-autocomplete="list"
                    aria-controls="listbox-id-1"
                    autoComplete="off"
                    onChange={(event) => {
                        setCurrentValue(event.target.value)
                    }}
                    onBlur={() => {
                        updateSearch(filterHook, filterProperty, filterType, currentValue)
                    }}
                    onKeyUp={(e) => {
                        if (e.key === "Enter") {
                            updateSearch(filterHook, filterProperty,filterType, currentValue)
                        }
                    }}
                    value={currentValue || ""}
                />
                {value ? <InputButton iconPosition={"right"} iconName={"clear"} onClick={() => updateSearch(filterHook, filterProperty, "")} /> : null}
            </div>
        </div>
    )
}

const valueToColor = (val) => {
    switch (val) {
        case "true":
            return "success"
        case "false":
            return "error"
        default:
            return "default"
    }
}

const valueToIcon = (val) => {
    switch (val) {
        case "true":
            return <CheckCircleOutlined />
        case "false":
            return <CloseCircleOutlined />
        default:
            return <MinusCircleOutlined />
    }
}

const tagRender = (props) => {
    const { label, value, closable, onClose } = props
    const onPreventMouseDown = (event) => {
        event.preventDefault()
        event.stopPropagation()
    }

    return (
        <Tag
            icon={valueToIcon(value)}
            color={valueToColor(value)}
            onMouseDown={onPreventMouseDown}
            closable={closable}
            onClose={onClose}
            style={{
                marginInlineEnd: 4,
            }}>
            {label}
        </Tag>
    )
}

export function ColumnBooleanFilter(props) {
    //todo search/filter from and use it for update of search
    const t = useT()
    const filterHook = props.filterHook
    const filterProperty = props.property

    // Used in legacy slds code.
    // Antd specific code paths do not rely on this state.
    const [currentValue, setCurrentValue] = useState(filterHook?.columnFilters?.get(filterProperty)?.value)

    // antd specific props
    const antdProps = props.antdProps
    const confirm = antdProps?.confirm
    const resetUIFilterValues = antdProps?.resetUIFilterValues
    const resetValue = antdProps?.resetValue
    const filterLabel = antdProps?.filterLabel || ""

    const [antdValue, setAntdValue] = useState(null)
    const [reset, setReset] = useState(resetValue)

    const label = t("common.search.searchField", "Search field") + ` ${filterLabel}`

    // antd specific code path
    const handleReset = () => {
        setAntdValue(null)
        filterHook.setColumnFilter(filterProperty, null)
        // confirm()
    }

    // antd specific code path
    const handleResetAll = () => {
        setCurrentValue(null)
        resetUIFilterValues()
        filterHook.reset()
        confirm()
    }

    // antd specific code path
    const updateSearch = (filterHook, filterProperty, filterValue) => {
        let cleanedValue
        if (antdProps) {
            cleanedValue = filterValue[filterValue.length - 1] || null
        } else {
            cleanedValue = filterValue
        }
        if (!cleanedValue) {
            filterHook.setColumnFilter(filterProperty, null)
            return
        }

        if (cleanedValue === "emptyValue") {
            let newFilter = {
                field: filterProperty,
                op: "isnull",
                value: "true",
            }
            filterHook.setColumnFilter(filterProperty, newFilter)
        } else {
            let newFilter = {
                field: filterProperty,
                op: "eq",
                value: cleanedValue,
            }
            filterHook.setColumnFilter(filterProperty, newFilter)
        }
    }

    if (resetValue > reset) {
        setReset(resetValue)
        handleReset()
    }

    const [dropdownOpen, setDropdownOpen] = useState(false)

    if (antdProps) {
        return (
            <>
                <Flex vertical gap="small" style={{ width: "12rem" }}>
                    <Space direction="vertical">
                        <Select
                            mode="tags"
                            tagRender={tagRender}
                            placeholder={label}
                            // maxCount={1}
                            onClick={() => {
                                setDropdownOpen(!dropdownOpen)
                            }}
                            onBlur={() => {
                                setDropdownOpen(false)
                            }}
                            open={dropdownOpen}
                            value={antdValue}
                            onChange={(value) => {
                                setDropdownOpen(false)
                                if (value?.length > 0) {
                                    setAntdValue([value[value.length - 1]])
                                } else {
                                    setAntdValue([])
                                }
                                updateSearch(filterHook, filterProperty, value)
                            }}
                            onSelect={() => {
                                setDropdownOpen(false)
                            }}
                            style={{
                                width: "100%",
                            }}
                            options={[
                                {
                                    value: "true",
                                    label: t("common.bool.yes", "yes"),
                                },
                                {
                                    value: "false",
                                    label: t("common.bool.no", "no"),
                                },
                                {
                                    value: "emptyValue",
                                    label: t("common.search.emptyValueLower", "empty value"),
                                },
                            ]}
                        />
                    </Space>
                    <Space.Compact direction="vertical" style={{ color: "blue" }} block>
                        <Button
                            onClick={() => {
                                handleReset()
                            }}
                            size="middle"
                            block>
                            {t("org.config.wmbus.reset", "Reset")}
                        </Button>

                        <Button
                            danger
                            onClick={() => {
                                handleResetAll()
                            }}
                            size="middle"
                            type="dashed"
                            block>
                            {t("org.config.wmbus.reset_all", "Reset All")}
                        </Button>
                    </Space.Compact>
                </Flex>
            </>
        )
    }

    return (
        <select
            className="slds-select"
            id={filterProperty}
            value={currentValue}
            onChange={(event) => updateSearch(filterHook, filterProperty, event.target.value)}
            style={currentValue ? { background: "#e7f3fe" } : {}}>
            <option key={"null"} value={""}></option>
            <option key={"true"} value={true} label={t("common.bool.yes", "yes")}>
                {t("common.bool.yes", "yes")}
            </option>
            <option key={"false"} value={false} label={t("common.bool.no", "no")}>
                {t("common.bool.no", "no")}
            </option>
        </select>
    )
}
