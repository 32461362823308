import {Log} from "./log";


export class NotifyUser {
    static Info(message) {
        // TODO: Display as toast to user
        alert(message);
        Log.Info(message)
    }

    static Warn(message) {
        // TODO: Display as toast to user
        alert(message);
        Log.Info("Warning: " + message)
    }

    static Error(message, error) {
        // TODO: in future we might use Toast notifications
        alert(message);
        Log.Error(message, error)
    }
}