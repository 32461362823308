import React from "react";
import {Link, Outlet} from "react-router-dom";
import Page from "../common/ui/page";
import {Tab, TabNavigation} from "../common/ui/tabNavigation";
import {FeatureContext} from "../common/context/featureContext";
import {useAuthContext} from "../common/context/authContext";


const WmbusApiModule = () => {
    const authCtx = useAuthContext();
    const licenseConfig = React.useContext(FeatureContext)
    const hasWmbus = licenseConfig.validateFeatures("wmbus-api")
    const tabs = hasWmbus && [<Tab key={"parser"} to={"parser"}>Parser</Tab>] || [] // Show parser tab only if feature is enabled; but show it with the example telegram even for guests
    if (authCtx.isLoggedIn() && hasWmbus) {
        tabs.push(<Tab key={"api"} to={"api"}>API</Tab>)
    }

    if (tabs.length === 0) {
        return <Page title={"wMbus API"}
                     trail={[<Link to={"/wmbus"} key={1}>wMbus API</Link>]}>
            <Outlet/>
        </Page>
    }

    return <TabNavigation tabs={tabs}>
        <Page title={"wMbus API"}
              trail={[<Link to={"/wmbus"} key={1}>wMbus API</Link>]}
        >
            <Outlet/>
        </Page>
    </TabNavigation>;
};

export default WmbusApiModule;