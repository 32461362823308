import React, {useContext, useState} from "react";
import {Formik} from "formik";
import {Log} from "../../common/log";
import gql from "graphql-tag";
import {useMutation, useQuery} from "@apollo/client";
import {useGraphqlLoadingComponent} from "../../common/graphql";
import {Accordion, AccordionPanel} from "../../common/ui/accordion";
import {
    CancelButtonField,
    FormActions,
    SldsCheckboxField,
    SldsInputField,
    SubmitButtonField
} from "../../common/ui/form/formElements";
import {Modal} from "../../common/slds";
import {Form} from "../../common/ui/form/formik";
import {useAuthContext} from "../../common/context/authContext";
import Button from "../../common/slds/buttons/button";
import ScopedNotification from "../../common/slds/scopedNotifications/scopedNotification";
import DescriptionList, {DescriptionListEntry} from "../../common/slds/descriptionList/descriptionList";
import Roles from "../../model/roles";
import {useNotificationContext} from "../../notifications/notificationContext";
import {useT} from "../../common/i18n";
import {FeatureContext} from "../../common/context/featureContext";
import {FeatureNotEnabled} from "../../common/featureNotEnabled";

const MUTATION_CREATE_INTEGRATION_CHIRPSTACK = gql`
    mutation ($orgId: ID!, $input: IntegrationChirpstackInput) {
        integrationChirpstack: createIntegrationChirpstack(orgId: $orgId, input: $input){
            id
        }
    }
`;

const MUTATION_UPDATE_INTEGRATION_CHIRPSTACK = gql`
    mutation ($id: ID!, $input: IntegrationChirpstackInput!) {
        integrationChirpstack: updateIntegrationChirpstack(id: $id input: $input){
            id
        }
    }
`;

const MUTATION_DELETE_INTEGRATION_CHIRPSTACK = gql`
    mutation ($id: ID!) {
        integrationChirpstack: deleteIntegrationChirpstack(id: $id){
            id
        }
    }
`;

const QUERY_INTEGRATION_CHIRPSTACK = gql`
    query ($orgId: ID!) {
        integrationChirpstack: integrationChirpstackList(orgId: $orgId){
            id
            url
            chirpstackAppId
            chirpstackOrgId
            jwtToken
            isDefault
            lastDownlinkQueued
            lastDownlinkTxAck
            lastErrDownlinkQueued
            lastErrMsgDownlinkQueued       
       
        }
    }
`;

export const LorawanApiTab = () => {
    const license = useContext(FeatureContext)
    if (!license.validateFeatures("lobaro-device-gateway")) {
        return <FeatureNotEnabled/>
    }
    const t = useT();
    const auth = useAuthContext();
    const notify = useNotificationContext();
    const [modalOpen, setModalOpen] = useState(false);
    const orgId = auth.organisationId();
    const canEdit = auth.hasRole(Roles.ADMIN, Roles.ORG_ADMIN);
    const integrationChirpstackResult = useQuery(QUERY_INTEGRATION_CHIRPSTACK, {
        variables: {
            "orgId": orgId,
        }
    });

    const [createIntegrationMutation] = useMutation(MUTATION_CREATE_INTEGRATION_CHIRPSTACK, {
        variables: {
            "orgId": orgId,
        },
        refetchQueries: [{
            query: QUERY_INTEGRATION_CHIRPSTACK,
            variables: {
                "orgId": orgId,
            },
        }]
    });
    const [updateIntegrationMutation] = useMutation(MUTATION_UPDATE_INTEGRATION_CHIRPSTACK, {
        variables: {},
        refetchQueries: [{
            query: QUERY_INTEGRATION_CHIRPSTACK,
            variables: {
                "orgId": orgId,
            },
        }]
    });
    const [deleteIntegrationMutation] = useMutation(MUTATION_DELETE_INTEGRATION_CHIRPSTACK, {
        variables: {},
        refetchQueries: [{
            query: QUERY_INTEGRATION_CHIRPSTACK,
            variables: {
                "orgId": orgId,
            },
        }]
    });

    const loading = useGraphqlLoadingComponent(integrationChirpstackResult);
    if (loading) {
        return loading;
    }

    Log.Debug("integrationChirpstackResult", integrationChirpstackResult);
    const {integrationChirpstack} = integrationChirpstackResult.data;

    return <div className="slds-m-horizontal--x-small">
        <p className="slds-m-bottom--medium">{t("integrations.chirpstack.explanation", "A Chirpstack Integration can be assigned in device so it can be used to schedule downlinks for the device at chirpstack.")}</p>
        {integrationChirpstack.length === 0 ? <ScopedNotification theme="light" className="slds-m-bottom--small">{t("integrations.no-integration-yet", "No integration created yet!")}</ScopedNotification> : null}
        <Accordion>
            {integrationChirpstack.map((integration, i) => {
                Log.Debug("Integration:", integration);
               //TODO: Preview gestalten
                return <AccordionPanel expanded={false} key={i} id={i}
                                       summary={`${integration.url}`}
                                       panelContentActions={`${integration.lastDownlinkQueued}`}
                >
                    <Formik
                        initialValues={{
                            ...integration,
                        }}
                        initialStatus={{
                            readOnly: true,
                            canEdit: canEdit,
                        }}
                        onSubmit={(values) => {
                            Log.Debug("ChirpstackApiTab.submit", values);
                            return updateIntegrationMutation({
                                variables: {
                                    id: integration.id,
                                    input: {
                                        chirpstackOrgId: values.chirpstackOrgId,
                                        chirpstackAppId: values.chirpstackAppId,
                                        url: values.url,
                                        jwtToken: values.jwtToken,
                                        isDefault: values.isDefault,
                                    }
                                }
                            }).then(() => {
                                notify.success(t("integrations.chirpstack.notify.updated-integration", "Updated Chirpstack integration."));
                            }).catch((err) => {
                                notify.error(t("integrations.chirpstack.notify.update-integration-failed", "Failed to update Chirpstack integration."), err);
                            });
                        }}
                    >{() => {
                        return <Form>
                            <SldsInputField name={"url"} label={t("integrations.chirpstack.target-url", "Target URL")} placeholder={"https://loraserver.lobaro.com"} required={true}/>
                            <SldsInputField name={"jwtToken"} label={t("integrations.chirpstack.token", "Token")} placeholder={"eywqet[...]"} required={true}/>
                            <SldsInputField name={"chirpstackAppId"} label={t("integrations.chirpstack.app_id", "AppId")} placeholder={"eywqet[...]"} required={true}/>
                            <SldsInputField name={"chirpstackOrgId"} label={t("integrations.chirpstack.org_id", "OrgId")} placeholder={"eywqet[...]"} required={true}/>
                            <SldsCheckboxField name={"isDefault"} inlineLabel={t("integrations.chirpstack.is_default", "Organisations Default")}
                                               tooltip={t("integrations.chirpstack.is_default_tooltip", "Only one default per Organisation. Is used if Device has no LoraWan Server assigned.")} />

                            <FormActions>
                                <SubmitButtonField/>
                                <CancelButtonField/>
                                <Button variant={"destructive"} iconName={"delete"} onClick={() => {
                                    if (window.confirm(t("integrations.confirm.delete-integration", "Delete integration?"))) {
                                        deleteIntegrationMutation({variables: {id: integration.id}})
                                            .then(() => {
                                                notify.success(t("integrations.http.notify.deleted-integration", "Deleted HTTP integration."));
                                            })
                                            .catch((err) => {
                                                notify.error(t("integrations.http.notify.delete-integration-failed", "Failed to delete HTTP integration"), err);
                                            });
                                    }
                                }}>{t("integrations.http.delete-integration-button", "Delete")}</Button>
                            </FormActions>
                        </Form>;
                    }}</Formik>


                    <div className="slds-text-heading--medium slds-m-top--medium slds-m-bottom--xx-small">{t("integrations.chirpstack.stats", "Last Events")}</div>
                    <DescriptionList>
                        <DescriptionListEntry label={t("integrations.chirpstack.lastDownlinkQueued", "Last time a Downlink was qued")} description={integration.lastDownlinkQueued}/>
                        <DescriptionListEntry label={t("integrations.chirpstack.lastErrDownlinkQueued", "Last time a Error occured while quing a Downlink")} description={integration.lastErrDownlinkQueued}/>
                        <DescriptionListEntry label={t("integrations.chirpstack.lastErrMsgDownlinkQueued", "Msg of last occured Error")} description={integration.lastErrMsgDownlinkQueued}/>
                        <DescriptionListEntry label={t("integrations.chirpstack.lastDownlinkTxAck", "Last time a TxAck was received for Integration")} description={integration.lastDownlinkTxAck}/>
                    </DescriptionList>

                </AccordionPanel>;
            })}
        </Accordion>

        {canEdit ?
            <Button iconName={"add"} iconCategory={"utility"} onClick={() => setModalOpen(true)}>{t("integrations.add-integration-button", "Add Integration")}</Button>
            : null}

        <Modal isOpen={modalOpen} onRequestClose={() => setModalOpen(false)} header={t("integrations.chirpstack.create-http-integration-heading", "Create Chirpstack Integration")}>
            <Formik
                initialValues={{}}
                 onSubmit={(values, actions) => {
                    Log.Debug("ChirpstackApiTab.submit", values);
                    createIntegrationMutation({
                        variables: {
                            input: {
                                chirpstackOrgId: values.chirpstackOrgId,
                                chirpstackAppId: values.chirpstackAppId,
                                url: values.url,
                                jwtToken: values.jwtToken,
                                isDefault:  values.isDefault,
                            }
                        }
                    }).then(() => {
                        notify.success(t("integrations.chirpstack.created-integration", "Created Chirpstack integration."));
                        setModalOpen(false);
                    }).catch((err) => {
                        notify.error(t("integrations.chirpstack.create-integration-failed", "Failed to create Chirpstack integration."), err);
                    }).finally(() => {
                        actions.setSubmitting(false);
                    });

                }}
            >
                <Form>
                    <SldsInputField name={"url"} label={t("integrations.chirpstack.target-url", "Target URL")} placeholder={"https://loraserver.lobaro.com"} required={true}/>
                    <SldsInputField name={"jwtToken"} label={t("integrations.chirpstack.token", "Token")} placeholder={"eywqet[...]"} required={true}/>
                    <SldsInputField name={"chirpstackAppId"} label={t("integrations.chirpstack.app_id", "AppId")} placeholder={"eywqet[...]"} required={true}/>
                    <SldsInputField name={"chirpstackOrgId"} label={t("integrations.chirpstack.org_id", "OrgId")} placeholder={"eywqet[...]"} required={true}/>
                    <SldsCheckboxField name={"isDefault"} inlineLabel={t("integrations.chirpstack.is_default", "Organisations Default")}
                                       tooltip={t("integrations.chirpstack.is_default_tooltip", "Only one default per Organisation. Is used if Device has no LoraWan Server assigned.")}
                    />
                    <FormActions>
                        <SubmitButtonField>Save</SubmitButtonField>
                        <CancelButtonField onClick={() => setModalOpen(false)}/>
                    </FormActions>
                </Form>
            </Formik>
        </Modal>
    </div>;
};