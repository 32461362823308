import React from "react";
import {Field, Form, Formik} from "formik";
import {
    CancelButtonField,
    FormActions,
    SldsCheckboxField,
    SldsInput,
    SldsTextarea,
    SubmitButtonField
} from "../../../common/ui/form/formElements";
import * as PropTypes from "prop-types";
import * as log from "../../../common/log";
import {useAuthContext} from "../../../common/context/authContext";
import {NotifyUser} from "../../../common/userNotification";
import {DeviceAppLookupField} from "../../../components/device/DeviceAppLookupField";
import DeviceTypeLookupField from "../../../components/deviceType/deviceTypeLookupField";
import DeviceTagPillContainerField from "../../../components/device/DeviceTagPillContainerField";
import {useT} from "../../../common/i18n";
import OrganisationLookupField from "../../../components/organisation/organisationLookupField";
import Roles from "../../../model/roles";


const EditDeviceForm = (props) => {
    const t = useT();
    const auth = useAuthContext();
    const {onUpdate, device} = props;


    log.Debug("EditDeviceForm:", device);


    const isAdmin = auth.hasRole("admin");
    const canEditDevice = isAdmin || auth.hasRole("org-admin") ||  auth.hasRole("device-admin");
    return <div>
        <Formik
            initialValues={device}
            enableReinitialize={true}
            initialStatus={{
                readOnly: true,
                canEdit: isAdmin || canEditDevice
            }}
            validate={(values) => {
                let errors = {};
                if (!values.name) {
                    errors.name = t("device.settings.validation.name-empty","Name must not be empty");
                }
                return errors;
            }}
            onSubmit={(values, actions) => {
                log.Debug("Submit: ", values, actions);
                onUpdate(values, actions)
                    .catch((err) => {
                    NotifyUser.Error(t("device.settings.notify.device-update-failed","Failed to update device."), err);
                }).finally(() => {
                    actions.setSubmitting(false);
                });
            }}
            render={(formik) => {
                const {readOnly} = formik.status;

                return <Form className="slds-form slds-form-element_stacked">
                    <Field component={SldsInput} name="name" placeholder={t("device.settings.placeholder.name", "Name of the device")} id="name" label={t("device.settings.name","Name")}
                           readOnly={readOnly}/>
                    <Field component={SldsInput} name="addr" placeholder={t("device.settings.placeholder.address","Address of the device")} id="addr" label={t("device.settings.address","Address")}
                           readOnly={readOnly || !isAdmin}/>
                    <Field component={SldsInput} name="serial" placeholder={t("device.settings.placeholder.serial","Serial of the device")} id="serial" label={t("device.settings.serial","Serial")}
                           readOnly={readOnly}/>
                    <DeviceTagPillContainerField orgId = {device.organisation?.id || auth.organisationId()} readOnly={readOnly}/>
                    <Field component={SldsTextarea} name="description" placeholder={t("device.settings.placeholder.description","Description of the device")} id="description"
                           label={t("device.settings.description","Description")} rows={4} readOnly={readOnly}/>
                    <DeviceTypeLookupField orgId={device.organisation?.id || auth.organisationId()}/>
                    {auth.hasRole("org-admin", "admin") &&
                        <OrganisationLookupField required={!auth.hasRole(Roles.ADMIN)}/>
                    }
                     <SldsCheckboxField inlineLabel={t("device.security.certEnforcesDtls","Enforce DTLS if Cert is present")}
                                       name="certEnforcesDtls" id="certEnforcesDtls"/>
                <DeviceAppLookupField
                        name={"app"}
                        orgId={formik.values?.organisation?.id}
                    />
                    {/* The old shitty one
                    <Field component={DeviceTypeSelector} readOnly={readOnly || isOrgAdmin}
                           name="deviceType" placeholder="Type of the device"
                           id="device-type" label="Device Type" deviceTypes={deviceTypes}/>
                           */}

                    <FormActions hidden={readOnly}>
                        <SubmitButtonField/>
                        <CancelButtonField/>
                    </FormActions>
                </Form>;
            }}
        />

    </div>;
};

export default EditDeviceForm;


EditDeviceForm.propTypes = {
    onUpdate: PropTypes.func.isRequired,
    device: PropTypes.object.isRequired,
    deviceTypes: PropTypes.array.isRequired,
    canEdit: PropTypes.bool,
};
