import {usePagination} from "../../common/hooks/usePagination";
import {useSearch} from "../../common/hooks/useSearch";
import {Link} from "react-router-dom";
import Button from "../../common/slds/buttons/button";
import * as React from "react";
import {useMutation, useQuery} from "@apollo/client";
import {MUTATE_DELETE_FILE, MUTATION_UPLOAD_FIRMWARE, QUERY_FILES} from "../queries";
import Page from "../../common/ui/page";
import DataTableColumn from "../../common/ui/data-table/column";

import GenericDataTable from "../../common/ui/genericDataTable/genericDataTable";
import {useNotificationContext} from "../../notifications/notificationContext";
import {Formik} from "formik";
import {Form} from "../../common/ui/form/formik";
import {
    CancelButtonField,
    FormActions,
    SldsFileSelectorField,
    SubmitButtonField
} from "../../common/ui/form/formElements";
import {useT} from "../../common/i18n";
import DataTableCell from "../../common/ui/data-table/cell";
import {CopyToClipboard} from "react-copy-to-clipboard/src";
import {useContext} from "react";
import {FeatureContext} from "../../common/context/featureContext";
import {FeatureNotEnabled} from "../../common/featureNotEnabled";


export const FirmwareManagmentPage = () => {
    const license = useContext(FeatureContext)
    if (!license.validateFeatures("lobaro-device-gateway")) {
        return <Page trail={[<Link to="." key={1}>Firmware</Link>]}
                     title={"Firmware"}>
            <FeatureNotEnabled/>
        </Page>
    }
    const t = useT()
    const page = usePagination(50);
    const search = useSearch();
    const notify = useNotificationContext();
    const result = useQuery(QUERY_FILES, {
        variables: {
            page: page.getGraphqlPageInput(),
            search: search.getGraphqlSearchInput(),
            pathPrefix: "fw/",
            sort:  {
                field: "created_at",
                direction: "DESC",
            }
        },
        fetchPolicy: "network-only",
    });
    const {data} = result;

    const [mutationDeleteFile] = useMutation(MUTATE_DELETE_FILE);


    let [uploadFirmware] = useMutation(MUTATION_UPLOAD_FIRMWARE);

    const getDownlinkJson = function (file) {
        if (!(file?.name)) {
            return null;
        }

        if (file.name.startsWith("mfw_nrf9160_update_from") && file.name.endsWith(".bin")) {
            return "{\n  \"d\": {\n    \"mfw\": \"" +file.name+"\"\n  },\n  \"q\": \"fw\"\n}";
        }

        if (file.name.startsWith("app-nrf9160") && file.name.endsWith("mcuboot-slot1.hex")) {
            return "{\n  \"d\": {\n    \"app\": \"" +file.name+"\"\n  },\n  \"q\": \"fw\"\n}";
        }
        if (file.name.startsWith("app-nrf9160") && file.name.endsWith("hw3+slot1.hex")) {
            return "{\n  \"d\": {\n    \"app\": \"" +file.name+"\"\n  },\n  \"q\": \"fw\"\n}";
        }
        if (file.name.startsWith("app-hybrid-modbus") && file.name.endsWith("slot1.hex")) {
            return "{\n  \"d\": {\n    \"app\": \"" +file.name+"\"\n  },\n  \"q\": \"fw\"\n}";
        }

        if (file.name.startsWith("app-boot-nrf9160-sec") && file.name.endsWith("slot1.hex")) {
            return "{\n  \"d\": {\n    \"boot\": \"" +file.name+"\"\n  },\n  \"q\": \"fw\"\n}";
        }

        if (file.name.startsWith("app-nrf91-origin")) {
            return "{\n  \"d\": {\n    \"app\": \"" +file.name+"\"\n  },\n  \"q\": \"fw\"\n}";
        }

        if (file.name.startsWith("app-nrf91-secure")) {
            return "{\n  \"d\": {\n    \"boot\": \"" +file.name+"\"\n  },\n  \"q\": \"fw\"\n}";
        }

        return null
    }




    function  deleteFile(file) {
        if (window.confirm("Delete File " + file.name +" Permanently?")) {
            mutationDeleteFile( {
                variables: {id: file.id}
            }).then(() => {
                notify.success("deleted file: " + file.name)
                void result.refetch()
            }, (err) => {
                notify.error("Failed to delete file.", err?.toString());
                void result.refetch()
            });
        }
    }

    return <Page
        trail={[<Link to="." key={1}>Firmware</Link>]}
        title={"Firmware"}>
        <div className={"slds-m-horizontal--x-small slds-m-vertical--x-small"}>
        <Formik
            onSubmit={(values) => {
                uploadFirmware({
                    variables: {
                        file: values.file
                    }
                    }).then(() => {
                    notify.info(t("config.settings.firmware.notify.uploaded", "Uploaded Firmware"));
                    void result.refetch()
                }).catch((e) => {
                    if (e.message === "GraphQL error: file already exists") {
                        notify.error(t("config.settings.firmware.notify.file-exists", "File already exists"), e);
                        void result.refetch()
                    } else {
                        notify.error(t("config.settings.firmware.notify.upload-failed", "Failed to upload Firmware"), e);
                        void result.refetch()
                    }
                });
            }}
            initialValues={{
                file: null,
            }}
            initialStatus={{
                readOnly: false,
                canEdit: true,
            }}
            enableReinitialize={true}
        >
            {() => {
                return <Form>
                    <SldsFileSelectorField name={"file"} label={t("test.config.settings.update-logo", "Upload Firmware")}/>
                    <FormActions>
                        <SubmitButtonField label={"Upload"}/>
                        <CancelButtonField/>
                    </FormActions>
                </Form>;
            }}
        </Formik>


    <h1 className={"slds-m-top--medium"} >Firmware Files</h1>
     </div>
    <div className={"slds-m-bottom--x-small"} >
        <GenericDataTable
            id={"firmware-files-table"}
            items={data && data.getFileList}
            gqlResult={result}
            tableConfigDefault={{}}
            page={page}
            search={search}
        >
            <DataTableColumn label="ID" property="id">
                <DataTableCell render={(props) => {
                    return props.item.id
                }}/>
            </DataTableColumn>
            <DataTableColumn label="Created at" property="created_at">
                <DataTableCell render={(props) => {
                   return new Date(props.item.createdAt).toLocaleString()
                }}/>
            </DataTableColumn>
            <DataTableColumn label="Name" property="name">
                <DataTableCell render={(props) => {
                  return props.item.name
                }}/>
            </DataTableColumn>
            <DataTableColumn label="Downlink" id="downlink">
                <DataTableCell render={(props) => {
                    let downlinkJson = getDownlinkJson(props.item);
                    if (downlinkJson) {
                        return <CopyToClipboard text={downlinkJson} onCopy={(text, result) => {
                            if (result) {
                                notify.success("Copied to clipboard");
                            } else {
                                notify.error("Failed to copy to clipboard");
                            }
                        }}>
                            <Button iconCategory={"utility"} iconName={"copy"}>Copy Downlink</Button>
                        </CopyToClipboard>
                    }
                    return null;
                }}/>
            </DataTableColumn>
            <DataTableColumn label="Delete" id="delete">
                <DataTableCell render={(props) => {
                   return <Button iconCategory={"utility"} iconName={"delete"} onClick={() => {
                        deleteFile(props.item)
                    }}>Delete</Button>
                }}/>
            </DataTableColumn>
        </GenericDataTable>
    </div>
    </Page>;
}