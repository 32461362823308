import React, { useState } from "react";
import {useAuthContext} from "../common/context/authContext";
import {useQuery} from "@apollo/client";
import {useGraphqlLoadingComponent} from "../common/graphql";
import gql from "graphql-tag";
import ChangePasswordDialog from "./changePasswordDialog";
import Button from "../common/slds/buttons/button";
import {SldsInputField } from "../common/ui/form/formElements";
import {Form} from "../common/ui/form/formik";
import {Formik} from "formik";
import Page from "../common/ui/page";
import UserRolesField from "../components/user/UserRolesField";
import HorizontalList from "../common/slds/horizontalList/HorizontalList";
import {HorizontalListItem} from "../common/slds/horizontalList/HorizontalListItem";
import { useT } from "../common/i18n";

const MyAccountPage = () => {
    let auth = useAuthContext();

    const t = useT()

    const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);

    let userResult = useQuery(gql`
        query($id: ID!) {
            getUser(id: $id) {
                id
                login
                name
                email
                organisation {
                    id
                    name
                }
                roles
            }
        }
    `, {
        variables: {
            id: auth.userId()
        }
    });

    const loading = useGraphqlLoadingComponent(userResult);

    if (loading) {
        return loading;
    }

    let user = userResult.data.getUser;

    return <Page trail={[]}
                 title={`My Profile`}
                 info={<HorizontalList>
                     <HorizontalListItem>{user.name}</HorizontalListItem>
                     <HorizontalListItem>{user.email}</HorizontalListItem>
                 </HorizontalList>}
                 withPadding={true}>

        <Formik
            initialValues={
                {
                    ...user,
                }
            }
            initialStatus={{
                readOnly: true,
                canEdit: false,
            }}
            enableReinitialize={true}
        >

            <Form>
                <SldsInputField name={"name"} label={t("profile.fullName","Full Name")}/>
                <SldsInputField name={"login"} label={t("profile.login","Login")}/>
                <SldsInputField name={"email"} label={t("profile.email", "EMail")}/>
                <SldsInputField name={"organisation.name"} label={t("profile.organisation","Organisation")}/>
                <UserRolesField name={"roles"} label={t("profile.roles","Roles")}/>
            </Form>

        </Formik>

        <div className="slds-m-top--small">
        <Button onClick={() => setShowChangePasswordModal(true)}>Change Password</Button>
        <ChangePasswordDialog userId={auth.userId()}
                              isOpen={showChangePasswordModal}
                              onRequestClose={() => setShowChangePasswordModal(false)}/>
        </div>
    </Page>;
};

export default MyAccountPage;