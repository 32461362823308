import React, {useState} from "react";
import {Card} from "@salesforce/design-system-react";
import DataSourcesTable from "./datasourceTable";
import DataSourceCard from "./datasourceCard";
import Page from "../../../common/ui/page";

export function DatasourcesPage() {

    const [item, setItem] = useState();

    return <Page title={"Datasources"} trail={[]}>
        <div className="slds-grid">
            <div className="slds-size--1-of-3">
                <Card heading={""} className="slds-has-top-magnet">
                    <DataSourcesTable onEditItem={(item) => setItem(item)}/>
                    {/*{{template "table".App.DataSourcesTable}}*/}
                </Card>
            </div>
            <div className="slds-size--2-of-3">
                <DataSourceCard item={item} className="slds-has-top-magnet"/>
            </div>
        </div>
    </Page>
}