import React, {useContext} from "react";
import {Field, Form, Formik} from "formik";
import {FormActions, SldsInput, SldsTextarea, SubmitButtonField} from "../../../common/ui/form/formElements";
import {Button} from "@salesforce/design-system-react";
import {Link} from "react-router-dom";
import * as PropTypes from "prop-types";
import * as log from "../../../common/log";
import DeviceTypeSelector from "./deviceTypeSelector";
import {AppContext} from "../../appPage";


function NewDeviceForm(props) {
    const {onCreateDevice, result} = props;

    const app = useContext(AppContext);

    // Preselect generic device
    const defaultDeviceType = result.data.deviceTypes.find((t => t.name === 'generic')) || {};

    return <div>
        <Formik
            initialValues={{appId: app.id, deviceType: defaultDeviceType}}
            validate={(values) => {
                let errors = {};
                if (!values.name) {
                    errors.name = "Name must not be empty"
                }
                if (!values.deviceType.id) {
                    errors.deviceType.id = "Please select a device type"
                }
                return errors
            }}
            onSubmit={(values, actions) => {
                delete values.filterDeviceType;
                log.Debug("Submit: ", values, actions);
                onCreateDevice(values, actions);
            }}
        >
            <Form className="slds-form slds-form-element_stacked">
                <Field component={SldsInput} name="name" placeholder="Name of the device" id="name" label="Name" autoFocus={true}/>
                <Field component={SldsInput} name="addr" placeholder="Address of the device" id="addr" label="Address"/>
                <Field component={SldsTextarea} name="description" placeholder="Description of the device" id="description" label="Description" rows={4}/>
                <Field component={DeviceTypeSelector} name="deviceType" placeholder="Type of the device" id="device-type" label="Device Type" deviceTypes={result.data.deviceTypes}/>

                <FormActions>
                    <SubmitButtonField type="submit">Create</SubmitButtonField>
                    <Link to={".."} className={"slds-m-left--x-small"}><Button variant="destructive">Cancel</Button></Link>
                </FormActions>
            </Form>
        </Formik>

    </div>
}

NewDeviceForm.propTypes = {
    onCreateDevice: PropTypes.func.isRequired,
    // GraphQL result with info needed to create a device like all DeviceTypes
    result: PropTypes.object.isRequired,
};


export default NewDeviceForm