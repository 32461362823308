import React from "react"
import classNames from "classnames"
import PropTypes from "prop-types"


// Children should be Pills
const PillContainerItem = (props) => {
    const {children, className} = props;
    return <li className={classNames("slds-listbox-item", className)} role="presentation">
        {children}
    </li>
};

PillContainerItem.propTypes = {
    children: PropTypes.any.isRequired,
    className: PropTypes.string,
};

export default PillContainerItem