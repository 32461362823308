import _ from 'underscore'
import urlJoin from "url-join"

// Join joins any number of path elements into a single path, adding a
// separating slash if necessary. The result is Cleaned; in particular,
// all empty strings are ignored.
function join(...elem) {
    if (!_.isArray(elem)) {
        return "";
    }
    return urlJoin(elem)
}

const Url = {
    join,
};
export default Url