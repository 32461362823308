import * as React from "react";
import Page from "../../common/ui/page";
import {Link, useLocation} from "react-router-dom";
import {Button} from "@salesforce/design-system-react";
import {useAuthContext} from "../../common/context/authContext";
import Url from "../../common/url"
import AppsTable from "./appsTable";
import {useContext} from "react";
import {FeatureContext} from "../../common/context/featureContext";
import {FeatureNotEnabled} from "../../common/featureNotEnabled";


const AppsPage = () => {
    const license = useContext(FeatureContext)
    const auth = useAuthContext();
    const location = useLocation();
    const actions = <div>
        <Link to={Url.join(location.pathname, "/new")}>
            {auth.hasRole("admin") ?
                <Button iconCategory={"utility"} iconName={"add"} iconPosition={"left"}
                        label={"New Application"}/> : null}
        </Link>
    </div>;

    // TODO: Use T for translations
    return <Page
        trail={[<Link to={"."} key={1}>Applications</Link>]}
        title={"Applications"}
        actions={actions}
    >
        {license.validateFeatures("lobaro-device-gateway") ? <AppsTable/> : <FeatureNotEnabled/>}
    </Page>
};

export default AppsPage;