import {CustomPropTypes} from "../../propTypes/customPropTypes";
import React from "react";

export function MediaBody(props) {
    const {children} = props;
    return <div className="slds-media__body">
        {children}
    </div>
}

MediaBody.propTypes = {
    children: CustomPropTypes.children,
};

export function MediaFigure(props) {
    const {children} = props;
    return <div className="slds-media__figure">
        {children}
    </div>
}

MediaFigure.propTypes = {
    children: CustomPropTypes.children,
};

// Children: <MediaFigure/><MediaBody/>
export default function Media(props) {
    const {children} = props;
    return <div className="slds-media">
        {children}
    </div>
}

Media.propTypes = {
    children: CustomPropTypes.children,
};
