//simple fully controlled Checkbox
import React from "react";
import PropTypes from "prop-types";

export const Checkbox = (props) => {
    const {onClick, checked, label} = props;

    return <div className="slds-form-element">
        <div className="slds-form-element__control">
            <div className="slds-checkbox">
                <input type="checkbox" name="hasHeader" id="columnHeaderPresentCheckbox"
                       checked={checked}
                       onClick={(event) => onClick(event)}
                />
                <label className="slds-checkbox__label" htmlFor="columnHeaderPresentCheckbox">
                    <span className="slds-checkbox_faux"></span>
                    <span className="slds-form-element__label">{label}</span>
                </label>
            </div>
        </div>
    </div>
}
Checkbox.propTypes = {
    onClick: PropTypes.func.isRequired,
    checked: PropTypes.bool.isRequired, //controlled component
    label: PropTypes.string,
};