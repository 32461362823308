// Graphql
import {Mutation, Query} from '@apollo/client/react/components'
import gql from 'graphql-tag'
// Table
import {DataTable, DataTableColumn, DataTableRowActions} from '@salesforce/design-system-react';
import React, {useContext} from "react";
import PropTypes from 'prop-types';
import {GraphqlLoadingErrorHandler} from '../../../common/graphql'
import * as log from "../../../common/log";
import {Log} from "../../../common/log";
import {AppContext} from "../../appPage";
import DataTableCell from "../../../common/ui/data-table/cell";

export const QUERY_DATASOURCES = gql`
    # Here appId is mandatory, but we could leave it null to query all datasources (not used in this app)
    query getDatasources($appId: ID!) {
        datasources(appId: $appId) {
            id
            name
            disabled
            type
            configRaw
            autoCreateDevice
            deviceType {
                id
                name
                displayName
            }
        }
    }
`;

export const MUTATE_DELETE_DATASOURCE = gql`
    mutation deleteDs($id: String!) {
        datasources {
            deleteDataSource(id: $id) {
                id
                name
                disabled
                configRaw
                deviceType {
                    id
                    name
                    displayName
                }
            }
        }
    }
`;


class DataSourcesTableComponent extends React.Component {

    state = {};

    handleRowAction = (item, action) => {
        log.Debug("Item: ", item, "Action:", action);
        if (action.func) {
            action.func(item, action);
        }
    };

    editItem = (item) => {
        //Log.Debug("edit item:", item);
        this.setState({selection: [item]});
        this.props.onEditItem(item);
    };

    selectionChanged = (e, {selection}) => {
        //Log.Debug("Selection changed: ", selection)
        if (selection.length > 2) {
            // when "all" are selected just remove selection
            this.editItem(undefined);
            return
        }

        if (selection.length > 0) {
            this.editItem(selection[selection.length - 1]);
        } else {
            this.editItem(undefined);
        }
        return true;
    };

    render() {
        const datasources = this.props.datasources;
        //Log.Debug("Datasources: ", datasources)

        return <div className="slds-m-bottom--large">
            <DataTable
                fixedLayout
                items={datasources}
                id="DataSourcesTable"
                //onChange={this.selectionChanged}
                onRowChange={this.selectionChanged}
                // onSort={this.handleSort}
                selection={this.state.selection}
                selectRows
                striped
            >
                <DataTableColumn label="Name" property="name"/>
                <DataTableColumn label="Type" property="type"/>
                <DataTableColumn label="Enabled" property="disabled">
                    <DataTableCell render={props => {
                        return <div><input type="checkbox" checked={!props.item.disabled} readOnly={true} /></div>
                    }}/>
                </DataTableColumn>
                <DataTableColumn label="Create Devices" property="autoCreateDevice">
                    <DataTableCell render={props => {
                        return <div><input type="checkbox" checked={props.item.autoCreateDevice} readOnly={true} /></div>
                    }}/>
                </DataTableColumn>
                <DataTableRowActions
                    options={[
                        {
                            label: 'Edit',
                            value: 'edit',
                            func: this.editItem
                        },
                        {
                            label: 'Delete',
                            value: 'delete',
                            func: (item) => {
                                this.props.deleteDatasource({variables: {id: item.id}});
                                this.editItem(undefined); // Or only when editing the deleted one?
                            }
                        },
                    ]}
                    onAction={this.handleRowAction}
                />

            </DataTable>
        </div>;
    }
}

DataSourcesTableComponent.propTypes = {
    onEditItem: PropTypes.func,
    deleteDatasource: PropTypes.func, // graphql
    datasources: PropTypes.array,
};


const DataSourcesTable = (props) => {
    const app = useContext(AppContext);
    Log.Debug("app", app);
    return <Query query={QUERY_DATASOURCES}
                  variables={{
                      appId: app.id
                  }}>


        {(result) => {
            return <GraphqlLoadingErrorHandler result={result}>
                <Mutation mutation={MUTATE_DELETE_DATASOURCE}
                          refetchQueries={[
                              {
                                  query: QUERY_DATASOURCES,
                                  variables: {
                                      appId: app.id
                                  }
                              }

                          ]}>
                    {(deleteDatasource) => {
                        return <DataSourcesTableComponent datasources={result.data.datasources} deleteDatasource={deleteDatasource} {...props} />
                    }}

                </Mutation>
            </GraphqlLoadingErrorHandler>
        }}

    </Query>
};

export default DataSourcesTable;
