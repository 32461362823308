import React, {PropsWithChildren, useEffect, useState} from "react"
import gql from "graphql-tag";
import {useQuery} from "@apollo/client";
import {Log} from "../log";
import { ninetyDays, thirtyDays } from "../magicNumbers";
import {useNotificationContext} from "../../notifications/notificationContext";
import {useT} from "../i18n";
import {useAuthContext} from "./authContext";

const FETCH_FEATURES = gql`
    query TheFeatures {
        platformFeatures {
            expires
            features
            maxOrg
            maxUser
            devMode
        }
    }
`

interface FeaturesRes {
    expires: string,
    features: FeatureNames[],
    maxOrg: number,
    maxUser: number,
}

// TODO: Export from backend
type FeatureNames = "all" | "lobaro-device-gateway" | "rest-api" | "wmbus-api" | "data-persistence"

export class FeatureContextState {
    result: FeaturesRes = {expires: new Date(Date.now()+ninetyDays).toDateString(), features: [], maxOrg: 1, maxUser: 1}
    devMode = false

    constructor(features?: FeaturesRes, devMode?: boolean) {
        if (features) {
            this.result = features
        }
        this.devMode = devMode || false
    }

    validateFeatures = (...feat: FeatureNames[]) => {
        if (!this.result.features || !Array.isArray(this.result.features)) {
            return false
        }
        if (this.result.features.includes("all")) {
            return true
        }
        return feat.every(f => this.result.features.includes(f))
    }
}

export const FeatureContext = React.createContext<FeatureContextState>(new FeatureContextState())

// FeatureContextProvider provides the features of the platform license to the components.
// It requires the translation, notification & authentication context, to notify the user if the license is running out soon or has already run out.
export const FeatureContextProvider = (props: PropsWithChildren) => {
    const features = useQuery(FETCH_FEATURES)
    // TODO: UPDATE WHEN PLATFORM FEATURE FIELDS CHANGE
    const featRes = new FeatureContextState(features.data?.platformFeatures, features.data?.platformFeatures.devMode)
    const [wasNotified, setNotified] = useState(false)

    const notify = useNotificationContext()
    const auth = useAuthContext()
    const t = useT()

    Log.Debug("FeatureContextProvider: ", featRes)
    Log.Debug("FeatureContextProvider Error: ", features.error)
    // Check if license is running out soon
    useEffect(() => auth.onChange(() => {
        if (auth.isLoggedIn() && !wasNotified) {
            const expDate = new Date(featRes.result.expires)
            if (expDate < new Date(Date.now())) {
                Log.Error(t("config.settings.license.expired", "Your license has expired. Please contact your platform administrator to renew your license."))
                notify.error(t("config.settings.license.expired", "Your license has expired. Please contact your platform administrator to renew your license."), "License Expired")
            } else if (expDate < new Date(Date.now() + thirtyDays)) {
                Log.Info(t("config.settings.license.renewal-warning", {date: expDate.toDateString()}))
                notify.info(t("config.settings.license.renewal-warning", {date: expDate.toDateString()}))
            }
            setNotified(true)
        }
    }), [auth.isLoggedIn()])


    return (
        <FeatureContext.Provider value={featRes}>
            {props.children}
        </FeatureContext.Provider>
    )
}

export const useFeatureContext = () => React.useContext(FeatureContext)
